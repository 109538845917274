import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { BsHandbag } from 'react-icons/bs'
import './recent-pay.css'
import { useNavigate } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io'
import axios from 'axios'
import { BasketAPI } from '../../../../api'
import { useEffect, useState } from 'react'
import Loading from '../../../../component/loading'
import returnTime from '../../../../convert-time'
import formatAmount from '../../../../formtMoney'
function RecentPay() {
  const navigate = useNavigate()
  const [lists, setLists] = useState([])
  const [loading, setLoading] = useState(false)
  const getRecentPay = () => {
    setLoading(true)
    axios
      .get(BasketAPI + '/payment-history', {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setLists(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    getRecentPay()
  }, [])
  return (
    <>
      <header>
        <div className="flex justify-start items-center mt-10 pb-4   relative px-4">
          <IoIosArrowForward
            className="text-3xl text-gray-600 border rounded"
            onClick={() => navigate('/setting')}
          />
          <p className="vazir-medium mr-4">سابقه پرداخت ها</p>
        </div>
      </header>
      <div className="vazir-medium min-h-screen px-4 flex flex-col justify-between items-center pb-32">
        <div>
          <p className="text-justify my-5 text-sm">
            در این بخش شما می‌توانید سابقه تمام پرداخت‌های انجام‌شده در حساب کاربری خود را مشاهده
            کنید. جزئیات هر پرداخت، شامل وضعیت تراکنش، تاریخ و ساعت انجام، شماره پیگیری و مبلغ
            پرداختی به نمایش گذاشته شده است تا به شما در مدیریت بهتر مالی کمک کند. در صورت بروز
            هرگونه سوال یا نیاز به پشتیبانی، می‌توانید با واحد پشتیبانی تماس بگیرید.
          </p>

          {loading ? (
            <Loading color="#1e40af" width="100px" />
          ) : (
            lists?.map((lis) => (
              <div
                key={lis.id}
                className="flex flex-col pb-4 bg-white py-3 px-2 rounded-xl mt-4 shadow-box  "
              >
                <section className="flex justify-start items-center">
                  <section className="w-10 h-10 bg-gray-300 flex justify-center items-center rounded-full ml-4">
                    <BsHandbag className="text-xl text-gray-600" />
                  </section>
                  <section className="w-10/12">
                    <section className="flex justify-between items-center">
                      <p>{lis?.child?.name}</p>
                      <p className="text-green-600 flex justify-center items-center">
                        <AiFillCheckCircle />
                        موفق
                      </p>
                    </section>
                    <section className="flex justify-between items-center text-sm text-gray-400 w-6/12 mt-1">
                      <span>{returnTime(lis.created_at).split(',')[0]}</span>
                      <span>{returnTime(lis.created_at).split(',')[1]}</span>
                    </section>
                  </section>
                </section>
                <section className="flex justify-between items-center mt-3 border-t pt-2">
                  <section className="flex justify-between w-8/12 text">
                    <p className="flex justify-start items-center">
                      شماره پیگیری: &nbsp; &nbsp;
                      <span className="relative top-1">{lis.transactionNumber}</span>
                    </p>
                  </section>
                  <section className="relative top-1">
                    <span className="text-gray-800">{formatAmount(lis.amount)}</span>
                    <span className="text-sm text-gray-400 mr-1">تومان</span>
                  </section>
                </section>
              </div>
            ))
          )}

          <div className="flex flex-col pb-4 bg-white py-3 px-2 rounded-xl mt-4 shadow-box hidden">
            <section className="flex justify-start items-center">
              <section className="w-10 h-10 bg-gray-300 flex justify-center items-center rounded-full ml-4">
                <BsHandbag className="text-xl text-gray-600" />
              </section>
              <section className="w-10/12">
                <section className="flex justify-between items-center">
                  <p>اشتراک اقتصادی ماهانه</p>
                  <p className="text-red-600 flex justify-center items-center">
                    <AiFillCloseCircle />
                    ناموفق
                  </p>
                </section>
                <section className="flex justify-between items-center text-sm text-gray-400 w-6/12 mt-1">
                  <span>۱۴۰۱/۰۹/۱۰</span>
                  <span>۱۹:۲۸</span>
                </section>
              </section>
            </section>
            <section className="flex justify-between items-center mt-3 border-t pt-2">
              <section className="flex justify-between items-center w-8/12 text">
                <p className="flex justify-start items-center">
                  شماره پیگیری: &nbsp; &nbsp;
                  <span className="relative top-1">۱۸۵۴۲۰۰۲۵۵۰۰</span>
                </p>
              </section>
              <section className="relative top-1">
                <span className="text-gray-800">۱۸۰.۰۰۰</span>
                <span className="text-sm text-gray-400 mr-1">تومان</span>
              </section>
            </section>
          </div>

          <div className="flex flex-col pb-4 bg-white py-3 px-2 rounded-xl mt-4 shadow-box hidden">
            <section className="flex justify-start items-center">
              <section className="w-10 h-10 bg-gray-300 flex justify-center items-center rounded-full ml-4">
                <BsHandbag className="text-xl text-gray-600" />
              </section>
              <section className="w-10/12">
                <section className="flex justify-between items-center">
                  <p>اشتراک اقتصادی ماهانه</p>
                  <p className="text-red-600 flex justify-center items-center">
                    <AiFillCloseCircle />
                    ناموفق
                  </p>
                </section>
                <section className="flex justify-between items-center text-sm text-gray-400 w-6/12 mt-1">
                  <span>۱۴۰۱/۰۹/۱۰</span>
                  <span>۱۹:۲۸</span>
                </section>
              </section>
            </section>
            <section className="flex justify-between items-center mt-3 border-t pt-2">
              <section className="flex justify-between items-center w-8/12 text">
                <p className="flex justify-start items-center">
                  شماره پیگیری: &nbsp; &nbsp;
                  <span className="relative top-1">۱۸۵۴۲۰۰۲۵۵۰۰</span>
                </p>
              </section>
              <section className="relative top-1">
                <span className="text-gray-800">۱۸۰.۰۰۰</span>
                <span className="text-sm text-gray-400 mr-1">تومان</span>
              </section>
            </section>
          </div>
        </div>
        <div className="w-full flex justify-center  fixed bottom-5 px-5">
          <button
            className="w-full bg-green-500 opacity-70 text-white rounded-lg py-4 vazir-medium mt-14"
            onClick={() => navigate('/contact-support')}
          >
            ارتباط با واحد پشتیبانی
          </button>
        </div>
      </div>
    </>
  )
}
export default RecentPay
