import { createSlice } from '@reduxjs/toolkit'

export const coachInfo = createSlice({
  name: 'coach-information',
  initialState: {
    name: '',
    id: '',
    profile: '',
    proficiency: '',
    voice: '',
    about: ''
  },
  reducers: {
    changeCoachInfo: (state, action) => {
      state.name = action.payload.name
      state.id = action.payload.id
      state.proficiency = action.payload.proficiency
      state.profile = action.payload.profile
      state.voice = action.payload.voice
      state.about = action.payload.about
    },

    clearCoachInfo: (state) => {
      state.name = ''
      state.id = ''
      state.voice = ''
      state.proficiency = ''
      state.profile = ''
      state.about = ''
    }
  }
})
export const { changeCoachInfo, clearCoachInfo } = coachInfo.actions

export default coachInfo.reducer
