import { useNavigate } from 'react-router-dom'
import { FreeMode } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
function ChooseCoach({ link, src, title, color }) {
  const navigate = useNavigate()
  return (
    <>
      <div
        className="w-full mt-10"
        onClick={() => {
          navigate(link)
        }}
      >
        <section className="w-full flex justify-start items-center">
          <div className="w-4 h-4 rounded ml-5" style={{ backgroundColor: color }} />
          <p>{title}</p>
        </section>

        <Swiper
          slidesPerView={1}
          spaceBetween={5}
          freeMode={true}
          grabCursor={true}
          modules={[FreeMode]}
          className="mySwiper"
        >
          {Array.isArray(src) &&
            src?.map((sr) => (
              <SwiperSlide key={sr} className="rounded-xl  py-2">
                <img className="w-full" src={sr} alt="choose coach" />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </>
  )
}
export default ChooseCoach
