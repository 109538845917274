import { FiArrowRight } from 'react-icons/fi'
import { BiSupport } from 'react-icons/bi'
import { HiHome } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

function HeaderAlbum({ title }) {
  const navigate = useNavigate()
  return (
    <>
      <header className="w-full flex justify-between items-center px-4 text-gray-700 py-5">
        <FiArrowRight className="text-2xl" onClick={() => navigate(-1)} />
        <span className="text-lg cursor-pointer">{title}</span>
        <div className="flex">
          <BiSupport
            className="text-2xl ml-2  cursor-pointer"
            onClick={() => {
              navigate('/contact-support')
            }}
          />
          <HiHome
            className="text-2xl  cursor-pointer"
            onClick={() => {
              navigate('/?status=home')
            }}
          />
        </div>
      </header>
    </>
  )
}

export default HeaderAlbum
