function EducationalTools({ text, className }) {
  return (
    <section
      className={`flex justify-start items-center border rounded-lg px-2 py-1 ml-2 mt-1.5 ${className}`}
    >
      <span className="vazir-medium text-sm">{text}</span>
    </section>
  )
}
export default EducationalTools
