import { useRef, useState } from 'react'
import axios from 'axios'
import { BehavioralGoalsAPI } from '../../../../../api'
import { useSelector } from 'react-redux'

function QuestionItem({
  question,
  numQuestion,
  nameInput,
  options,
  questionType,
  answer,
  limitAns
}) {
  const childInfo = useSelector((state) => state.childInfo)
  const inputRef = useRef(null)
  const [reload, setReload] = useState(false)
  const [userAns, setUserAns] = useState(answer ? answer.split(',') : [])

  const updateAnswer = (data = userAns) => {
    if (userAns.length <= limitAns) {
      setReload(!reload)
      const formData = new FormData()
      formData.append('answer', data.join(',')) // Assuming you want to send a comma-separated string of selected options
      formData.append('type', 'multiple')
      formData.append('childId', childInfo?.id)
      axios
        .patch(BehavioralGoalsAPI + '/' + nameInput, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        })
        .then((res) => res.data)
    }
  }
  const updateMultipleAnswer = (ans, isChecked, maxAnswers) => {
    if (maxAnswers === 1) {
      if (isChecked) {
        setUserAns([ans])
        setTimeout(() => {
          updateAnswer([ans])
        }, 50)
      }
    } else {
      if (isChecked && !userAns.includes(ans) && userAns.length < maxAnswers) {
        userAns.push(ans)
        setTimeout(() => {
          updateAnswer([...userAns])
        }, 50)
      } else if (!isChecked && userAns.includes(ans)) {
        const newUserAns = userAns.filter((item) => item !== ans)
        setUserAns(newUserAns)
        setTimeout(() => {
          updateAnswer(newUserAns)
        }, 50)
      }
    }
  }

  const updateDescriptiveAnswer = () => {
    const formData = new FormData()
    formData.append('answer', inputRef.current.value)
    formData.append('type', 'descriptive')
    formData.append('childId', childInfo?.id)
    axios
      .patch(BehavioralGoalsAPI + '/' + nameInput, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
  }
  if (questionType === 'multiple') {
    return (
      <>
        <section className="w-20 bg-blue-100 text-[#3F54CB] rounded py-2 px-4 mb-3  mt-10">
          <span>سوال</span>
          <span>{numQuestion}</span>
        </section>
        <p>
          {question} <span className="text-red-500 text-xs"> (حداکثر پاسخ {limitAns})</span>
        </p>
        <section className="w-full flex flex-wrap justify-start items-center">
          {options &&
            options.map((ans) => (
              <label
                key={ans}
                htmlFor={ans}
                className="text-sm flex justify-start items-center m-2"
              >
                <span className="w-7 h-7 border border-gray-300 flex justify-center items-center rounded ml-2 relative">
                  {userAns.includes(ans) ? (
                    <span className="bg-yellow-400 border-white border-4 w-full h-full rounded z-20 absolute" />
                  ) : null}
                </span>
                <input
                  type="checkbox"
                  id={ans}
                  className="hidden"
                  name={nameInput}
                  onChange={(e) => updateMultipleAnswer(ans, e.target.checked, limitAns)}
                  checked={userAns.includes(ans)}
                />
                {ans}
              </label>
            ))}
        </section>
      </>
    )
  }

  if (questionType === 'descriptive') {
    return (
      <>
        <section className="w-20 bg-blue-100 text-[#3F54CB] rounded py-2 px-4  mt-10 mb-3">
          <span>سوال</span>
          <span>{numQuestion}</span>
        </section>
        <p>{question} ؟</p>
        <section className="w-full ">
          <input
            ref={inputRef}
            type="text"
            id={nameInput}
            className="w-full border-2  px-2 py-2 focus:border-blue-600 mt-2 rounded"
            defaultValue={answer}
            name={nameInput}
            onBlur={updateDescriptiveAnswer}
          />
        </section>
      </>
    )
  }
}

export default QuestionItem
