import { AiFillPhone } from 'react-icons/ai'

import Accordion from './accordion'
import Header from '../../components/detail-header'

function FrequentlyQuestion() {
  return (
    <>
      <Header title="سوالات متداول" />
      <div className="flex flex-col justify-between min-h-[80vh]">
        <section>
          <div className="px-4 mt-4">
            <Accordion title="خرید اشتراک">
              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان
              گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای
              شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
            </Accordion>
          </div>
          <div className="px-4 mt-4">
            <Accordion title="خرید اشتراک">
              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان
              گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای
              شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
            </Accordion>
          </div>
          <div className="px-4 mt-4">
            <Accordion title="خرید اشتراک">
              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان
              گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای
              شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
            </Accordion>
          </div>
        </section>
        <section className="flex justify-center items-center">
          <div className="call-us fixed bottom-10 vazir-medium rounded-xl px-4 py-5 flex justify-center flex-wrap w-11/12">
            <p>
              والدین عزیز اگر هنوز پاسخ سوال خود را پیدا نکرده اید می توانید برای راهنمایی بیشتر با
              ما تماس بگیرید
            </p>
            <button className="mt-4 bg-green-500 w-10/12 py-3 text-white rounded-xl flex justify-center items-center">
              <AiFillPhone className="text-xl ml-2" />
              <span>تماس با سلاله</span>
            </button>
          </div>
        </section>
      </div>
    </>
  )
}

export default FrequentlyQuestion
