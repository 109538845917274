import { useNavigate } from 'react-router-dom'
import './child-list.css'
import { IoIosArrowForward } from 'react-icons/io'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { ParentAPI } from '../../../../api'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import ChildItem from './component/list'
function ChildList() {
  const navigate = useNavigate()
  const info = useSelector((state) => state.information)
  const [childList, setChildList] = useState([])
  const getChildParent = () => {
    const formData = new FormData()
    formData.append('id', info.id)
    axios
      .post(ParentAPI + 'get-child/list', formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setChildList(data.children)
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  useEffect(() => {
    getChildParent()
  }, [])
  return (
    <>
      {/*<div className="bg-child-list" />*/}
      <header className="shadow-bottom">
        <div className="flex justify-center items-center mt-10 pb-4 relative px-4">
          <IoIosArrowForward
            className="text-3xl text-gray-600 border rounded absolute right-4"
            onClick={() => navigate(-1)}
          />
          <p className="vazir-medium mr-4 text">لیست کودکان</p>
        </div>
      </header>
      <div className="flex flex-col  justify-between items-center">
        <div className="w-full mt-4 px-4">
          {childList && childList.length > 0
            ? childList.map((child) => (
                <ChildItem key={child.id} child={child} ReloadFunction={getChildParent} />
              ))
            : null}
        </div>
        <div className="w-full my-4 px-4">
          <button
            className="w-full bg-green-500 opacity-70 text-white rounded-lg py-4 vazir-medium mt-4"
            onClick={() => navigate('/add-child')}
          >
            افزودن کودک جدید
          </button>
        </div>
      </div>
    </>
  )
}

export default ChildList
