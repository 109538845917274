import HeaderAlbum from '../../album/photo-album/component/header'
import { AiFillStar } from 'react-icons/ai'
import PlayWhite from './assets/play-white.svg'
import FaceSleeping from './assets/face-sleeping.svg'
import axios from 'axios'
import { Domain, LullabyAPI, LullabyDetailAPI, LullabyViewAPI } from '../../../../../api'
import { useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import Loading from '../../../../../component/loading'
import { FaPause } from 'react-icons/fa'
import PopUp from '../../../components/pop-up/comment'
import Comment from '../../coach-team-detail/component/comment'
import { useSelector } from 'react-redux'
import { IoBookmark, IoBookmarkOutline } from 'react-icons/io5'
import { toast } from 'react-toastify'

function DetailLullaby() {
  const childInfo = useSelector((state) => state.childInfo)
  const [loading, setLoading] = useState(false)
  const [bookmark, setBookmark] = useState(false)
  const [viewCount, setViewCount] = useState(0)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [detail, setDetail] = useState({})
  const [interVal, setInterVal] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(null)
  const [currentTime, setCurrentTime] = useState(0)

  // eslint-disable-next-line no-unused-vars
  const [duration, setDuration] = useState(0)
  const progressRef = useRef(null)
  const timelineRef = useRef(null)
  const getLullabyDetail = () => {
    setLoading(true)
    axios
      .get(LullabyDetailAPI + '?id=' + id + '&childId=' + childInfo?.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setDetail(data.lullabies)
        setViewCount(data.lullabies?.viewCount)
        setBookmark(data?.bookmark)
        setLoading(false)
      })
  }

  const addPlay = () => {
    axios
      .get(LullabyViewAPI + '/' + id + '?childId=' + childInfo.id, {
        headers: { Authorization: `Brear ${localStorage.getItem('token')}` }
      })
      .then((res) => res.data)
      .then((data) => {
        setViewCount(data)
      })
  }
  const [showForm, setShowForm] = useState(false)

  const handleProgressClick = (event) => {
    const timelineWidth = timelineRef.current.offsetWidth
    const clickX = event.clientX - timelineRef.current.getBoundingClientRect().left
    const progress = clickX / timelineWidth
    const newTime = progress * audioRef.current.duration
    audioRef.current.currentTime = newTime
  }

  const handleProgressDrag = (event) => {
    const timelineWidth = timelineRef.current.offsetWidth
    const dragX = event.clientX - timelineRef.current.getBoundingClientRect().left
    const progress = dragX / timelineWidth
    const newTime = progress * audioRef.current.duration
    audioRef.current.currentTime = newTime
  }
  useEffect(() => {
    getLullabyDetail()
    const audioElement = audioRef.current

    const handleLoadedMetadata = () => {
      setDuration(audioElement.duration)
    }

    const handleTimeUpdate = () => {
      if (audioElement && audioElement.currentTime) {
        setCurrentTime(audioElement.currentTime)
        const progress = (audioElement.currentTime / audioElement.duration) * 100
        progressRef.current.style.width = `${progress}%`
      }
    }

    audioElement.addEventListener('loadedmetadata', handleLoadedMetadata)
    audioElement.addEventListener('timeupdate', handleTimeUpdate)

    return () => {
      setInterVal(null)
    }
  }, [])

  const handleTimeUpdate = () => {
    if (audioRef?.current?.currentTime) {
      setCurrentTime(audioRef.current.currentTime)
      progressRef.current.style.width = `${
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      }%`
    }
  }

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (!isPlaying) {
        setInterval(
          setInterval(() => {
            handleTimeUpdate()
          }, 100)
        )
        audioRef.current.play()
        setIsPlaying(true)
        addPlay()
      } else {
        if (interVal !== null) {
          clearInterval(interVal)
          setInterVal(null) // Reset the interval ID to null
        }
        audioRef.current.pause()
        setIsPlaying(false)
      }
    }
  }

  const checkBookmark = () => {
    if (childInfo?.id?.length > 5) {
      axios
        .get(LullabyAPI + '/user/bookmark?id=' + id + '&childId=' + childInfo.id, {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        })
        .then((res) => res.data)
        .then((data) => {
          setBookmark(data)
        })
    } else {
      toast.error('لطفا کودک خود را از منوی اصلی انتخاب کنید')
    }
  }

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0')
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, '0')

    return `${minutes}:${seconds}`
  }
  return (
    <>
      <HeaderAlbum title={detail.title} />
      {loading ? (
        <Loading color="#1e40af" width="100px" />
      ) : (
        <>
          <PopUp
            setShowMessage={setShowForm}
            showMessage={showForm}
            titleMessage="نظر خود را با ما به اشتراک بگذارید"
            userTeam={detail.title}
            API={LullabyAPI + '/comment'}
            id={detail.id}
            body={`لطفا برای بهبود و بالا بردن خدمات ما نظر خود را درباره لالایی   ${detail.title}  ثبت کنید`}
          />
          <div className="w-full px-4">
            <div className="w-full border border-gray-300 rounded-xl h-20 flex justify-between items-center shadow">
              <div className="h-full flex justify-start items-start">
                <section
                  className="w-20 h-20 ml-2 rounded-r-xl"
                  style={{
                    backgroundImage: `url("${Domain + detail.picture}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                />
                <div className="h-full flex flex-col justify-between items-start pb-2 pt-1">
                  <p className="text-sm text-[#3F54CB]">{detail.title}</p>
                  <span className="text-xs text-gray-400 my-2">{detail?.category?.title}</span>
                  <section className="flex justify-start items-center text-xs">
                    <AiFillStar className="text-orange-600" />
                    <span className="text-gray-600"> {detail.rateCount} </span>&#160;
                    <span className="text-gray-600">({detail.commentCount} نظر)</span>&#160; &#160;
                  </section>
                </div>
              </div>
              <div className="text-sm">
                &#160;
                <span className="text-gray-600  ">{viewCount}</span>&#160;
                <span className="text-gray-600  ">بار پخش</span>&#160;
              </div>

              <div className="text-sm">
                {bookmark ? (
                  <IoBookmark className="ml-2 text-2xl text-blue-600" onClick={checkBookmark} />
                ) : (
                  <IoBookmarkOutline
                    className="ml-2 text-2xl text-blue-600"
                    onClick={checkBookmark}
                  />
                )}
              </div>
            </div>
            <div className="w-full flex justify-center items-center px-5">
              <audio controls ref={audioRef} className="hidden">
                <source src={Domain + detail.voice} />
              </audio>
              {isPlaying ? (
                <buttom
                  onClick={() => togglePlayPause(detail.voice)}
                  className="w-full flex justify-center items-center text-center py-3 px-4 bg-[#3F54CB] text-white rounded-xl mt-3"
                >
                  <FaPause className="text-xl text-white" />
                  <span className="mr-3">توقف لالایی</span>
                </buttom>
              ) : (
                <buttom
                  onClick={() => togglePlayPause(detail.voice)}
                  className="w-full flex justify-center items-center text-center py-3 px-4 bg-[#3F54CB] text-white rounded-xl mt-3"
                >
                  <img src={PlayWhite} alt="play" />
                  <span className="mr-3">پخش لالایی</span>
                </buttom>
              )}
            </div>
            <div className="w-full ">
              <div className="w-full px-4">
                <span
                  className="w-full h-1 rounded-md bg-gray-200 block dir-ltr mt-6"
                  ref={timelineRef}
                  onClick={handleProgressClick}
                  onMouseDown={() => {
                    window.addEventListener('mousemove', handleProgressDrag)
                  }}
                  onMouseUp={() => {
                    window.removeEventListener('mousemove', handleProgressDrag)
                  }}
                >
                  <span ref={progressRef} className="w-6/12 h-1 rounded-md bg-blue-200 block" />
                </span>
                {loading === false && (
                  <div className="dir-ltr text-gray-400 text-center mt-4 text-sm">
                    {formatTime(currentTime)} /
                    {detail?.duration
                      ? detail?.duration
                      : formatTime(audioRef?.current?.duration ? audioRef?.current?.duration : 0)}
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex justify-start items-center mt-5 text-gray-700">
              <span className="ml-4">متن لالایی</span>
              <img src={FaceSleeping} alt="face sleeping" />
            </div>

            <div
              style={{ userSelect: 'none' }}
              dangerouslySetInnerHTML={{
                __html: detail.description
              }}
            />
          </div>
          <Comment
            count={detail?.commentCount}
            rate={detail?.rateCount}
            id={detail?.id}
            API={LullabyAPI + '/comment/user?id='}
            type="lullaby"
            setShowForm={() => setShowForm(true)}
          />
        </>
      )}
    </>
  )
}

export default DetailLullaby
