import { IoIosArrowForward } from 'react-icons/io'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { BasketAPI, Domain, WorkshopAPI } from '../../../../api'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import returnTime from '../../../../convert-time'
import { useSelector } from 'react-redux'
import Message from '../../components/message'
import Loading from '../../../../component/loading'
import { MdArrowForwardIos } from 'react-icons/md'

function WorkshopDetail() {
  const childInfo = useSelector((state) => state.childInfo)
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [loading, setLoading] = useState(false)
  const [workshopDetail, setWorkshopDetail] = useState({})
  const [inBasket, setInBasket] = useState(false)
  const [basketCount, setBasketCount] = useState(0)
  const [basketPrice, setBasketPrice] = useState(0)
  const [userJoined, setUserJoined] = useState(false)

  const [message, setMessage] = useState(null)
  const getWorkshopDetail = () => {
    setLoading(true)
    axios
      .get(WorkshopAPI + `find-one?workshop_id=${id}&child_id=${childInfo.id}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setLoading(false)

        setUserJoined(data.userJoined)
        setWorkshopDetail(data.data)
        BasketDetail(data?.data?.id)
      })
      .catch((e) => {
        toast.error(e.response.data.message)
        setWorkshopDetail({})
        setLoading(false)
      })
  }
  const addChildToWorkshop = () => {
    const formData = new FormData()
    formData.append('child_id', childInfo.id)
    formData.append('workshop_ids', workshopDetail.id)
    axios
      .post(BasketAPI, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        BasketDetail()
      })
      .catch((e) => {
        e.response.data.messages.map((msg) => toast.error(msg))
      })
  }
  const BasketDetail = () => {
    const formData = new FormData()
    formData.append('child_id', childInfo.id)

    axios
      .post(BasketAPI + '/check/child', formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setInBasket(data.isInBasket)
        setBasketCount(data.count)
        setBasketPrice(data.price)
      })
      .catch((e) => {
        e.response.data.messages.map((msg) => toast.error(msg))
      })
  }
  useEffect(() => {
    getWorkshopDetail()
  }, [])

  if (workshopDetail && workshopDetail.type === 'online') {
    return (
      <div className="bg-gray-50 py-10">
        <header>
          <div className="flex justify-center items-center  pb-4 relative px-4">
            <IoIosArrowForward
              className="text-3xl text-gray-600 border rounded absolute right-5"
              onClick={() => navigate(-1)}
            />
            <p className="vazir-medium mr-4">جزییات کارگاه</p>
          </div>
        </header>

        {loading ? (
          <Loading color="#1e40af" />
        ) : (
          <>
            {message && <Message {...message} OnChange={setMessage} />}
            {workshopDetail && (
              <div className="w-full mb-10">
                <section className="w-full relative mt-6">
                  <section className="absolute w-full h-full bg-black opacity-60 " />
                  <img
                    className="w-full h-full"
                    src={Domain + workshopDetail.poster}
                    alt={workshopDetail.name}
                  />
                </section>
                <div className="px-4 flex justify-center items-center  ">
                  <div className="w-full rounded-2xl rounded-2xl vazir-medium bg-white drop-shadow-lg px-3 py-2 relative -top-10 relative">
                    <section className="border-b border-gray-300 pb-4">
                      <div className="flex justify-start items-center">
                        <span className="w-2 h-2 bg-[#3F54CB] rounded-full block ml-2" />{' '}
                        <p className="text-gray-500 text-xs mt-0.5 text-[#3F54CB]">
                          {workshopDetail.category
                            ? workshopDetail.category.title
                            : 'دسته بندی ندارد'}
                        </p>
                      </div>
                      <p className="mt-1 text-sm my-2">{workshopDetail.name}</p>
                      <p className="text-gray-500 text-xs mt-0.5">{workshopDetail.teacher}</p>
                    </section>
                    <div className="vazir-medium mt-4">
                      <div className="flex justify-start items-center">
                        <span className="w-2 h-2 bg-[#3F54CB] rounded-full block ml-2" />{' '}
                        <p className="text-gray-500 text-xs mt-0.5 text-[#3F54CB]">معرفی کارگاه:</p>
                      </div>

                      <div
                        className="text-sm text-gray-800 mt-2 text-justify"
                        dangerouslySetInnerHTML={{
                          __html: workshopDetail.about
                        }}
                      />
                    </div>

                    <div className="w-full flex flex-wrap justify-between items-center mt-5 relative">
                      <section className="flex flex-col justify-between items-center">
                        <section className="flex flex-col justify-center items-center">
                          <p className="text-[10px] text-gray-400">تاریخ برگزاری</p>
                          <p className=" text-gray-700 my-2">
                            {returnTime(workshopDetail.date_start).split(',')[0]}
                          </p>
                        </section>
                        <section className="flex flex-col justify-center items-center mt-2">
                          <p className="text-[10px] text-gray-400">نوع کارگاه</p>
                          <p className=" text-gray-700 my-2">
                            {workshopDetail.type == 'online'
                              ? 'آنلاین'
                              : workshopDetail.type == 'offline'
                              ? 'آفلاین'
                              : 'حضوری'}
                          </p>
                        </section>
                      </section>
                      <span className="h-24 w-[1px] block bg-gray-300" />
                      <section className="flex flex-col justify-between items-center">
                        <section className="flex flex-col justify-center items-center ">
                          <p className="text-[10px] text-gray-400">ساعت برگزاری</p>
                          <p className=" text-gray-700 my-2">{workshopDetail.time_start}</p>
                        </section>
                        <section className="flex flex-col justify-center items-center mt-2">
                          <p className="text-[10px] text-gray-400">ظرفیت باقی مانده</p>
                          <p className=" text-gray-700 my-2">
                            {workshopDetail.children &&
                              workshopDetail.capacity - workshopDetail.children.length}{' '}
                            نفر
                          </p>
                        </section>
                      </section>
                      <span className="h-24 w-[1px] block bg-gray-300" />
                      <section className="flex flex-col justify-between items-center">
                        <section className="flex flex-col justify-center items-center">
                          <p className="text-[10px] text-gray-400">مدت برگزاری</p>
                          <p className=" text-gray-700 my-2">
                            {workshopDetail.workshop_duration} ساعت
                          </p>
                        </section>
                        <section className="flex flex-col justify-center items-center mt-2">
                          <p className="text-[10px] text-gray-400">هزینه کارگاه</p>
                          <p className=" text-gray-700 my-2 ">
                            {workshopDetail.price}
                            تومان
                          </p>
                        </section>
                      </section>

                      <span className="h-[1px] w-full block bg-gray-200 absolute top-1/2 -translate-y-1 " />
                    </div>

                    <div className="flex justify-start items-center mt-4">
                      <span className="w-2 h-2 bg-[#3F54CB] rounded-full block ml-2" />
                      <p className="text-gray-500 text-xs mt-0.5 text-[#3F54CB]">
                        مکان برگزاری کارگاه:
                      </p>
                    </div>
                    <p className="text-sm text-gray-800 mb-4 mt-2">
                      این کارگاه به صورت آنلاین در گوگل میت برگزار می شود ، لینک کارگاه برای شما
                      پیامک می شود یا از طریق لینک که برای شما در این صفحه نمایش داده شده است مشاهده
                      کنید
                    </p>

                    <div className="flex justify-start items-center mt-4">
                      <span className="w-2 h-2 bg-[#3F54CB] rounded-full block ml-2" />
                      <p className="text-gray-500 text-xs mt-0.5 text-[#3F54CB]">
                        لینک برگزاری کارگاه:
                      </p>
                    </div>
                    {userJoined ? (
                      <p className="text-sm text-gray-800 mb-4 mt-2">{workshopDetail.link}</p>
                    ) : (
                      <p className="text-sm text-gray-800 mb-4 mt-2">
                        لینک برای نمایش موجود نیست، لطفا برای مشاهده ی لینک ثبت نام کنید
                      </p>
                    )}

                    <section className="mt-10">
                      <p className="text-[10px] text-gray-400">آلبوم کارگاه</p>
                      <div className="grid grid-cols-3 gap-2">
                        {workshopDetail && workshopDetail.img1 && (
                          <section className="w-24 h-24 my-1">
                            <div
                              className="w-full h-full bg-gray-200 rounded-xl"
                              style={{
                                backgroundImage: `url("${Domain + workshopDetail.img1}")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                              }}
                            />
                          </section>
                        )}

                        {workshopDetail && workshopDetail.img2 && (
                          <section className="w-24 h-24 my-1">
                            <div
                              className="w-full h-full bg-gray-200 rounded-xl"
                              style={{
                                backgroundImage: `url("${Domain + workshopDetail.img2}")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                              }}
                            />
                          </section>
                        )}

                        {workshopDetail && workshopDetail.img3 && (
                          <section className="w-24 h-24 my-1">
                            <div
                              className="w-full h-full bg-gray-200 rounded-xl"
                              style={{
                                backgroundImage: `url("${Domain + workshopDetail.img3}")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                              }}
                            />
                          </section>
                        )}

                        {workshopDetail && workshopDetail.img4 && (
                          <section className="w-24 h-24 my-1">
                            <div
                              className="w-full h-full bg-gray-200 rounded-xl"
                              style={{
                                backgroundImage: `url("${Domain + workshopDetail.img4}")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                              }}
                            />
                          </section>
                        )}
                      </div>
                    </section>
                    {userJoined ? (
                      <button
                        className="w-full bg-green-600  text-white rounded-lg py-4 vazir-medium mt-14  cursor-not-allowed"
                        disabled={true}
                      >
                        &nbsp;{childInfo.name} جان هم اکنون عضو کارگاه است
                      </button>
                    ) : (
                      <>
                        {workshopDetail.children &&
                        workshopDetail.capacity == workshopDetail.children.length ? (
                          <button
                            className="w-full bg-[#3F54CB]  text-white rounded-lg py-4 vazir-medium mt-14 opacity-70 cursor-not-allowed"
                            disabled={true}
                          >
                            {' '}
                            ظرفیت تکمیل شد
                          </button>
                        ) : (
                          <>
                            {inBasket ? (
                              <>
                                <button className="w-full bg-green-600 text-white rounded-lg py-4 vazir-medium mt-14">
                                  کارگاه به سبد خرید {childInfo.name} جان اضافه شد
                                </button>
                              </>
                            ) : (
                              <button
                                className="w-full bg-[#3F54CB] text-white rounded-lg py-4 vazir-medium mt-14"
                                onClick={addChildToWorkshop}
                              >
                                ثبت نام درکارگاه
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <>
          {basketCount + basketPrice > 0 && (
            <div className="w-11/12 mx-auto sticky mt-10 bottom-5 bg-green-500 vazir-medium flex justify-between items-center text-white py-4 px-4 rounded-xl z-50">
              <section
                className="flex justify-start items-center"
                onClick={() => navigate('/basket')}
              >
                <MdArrowForwardIos />
                <span>پرداخت</span>
              </section>
              <section>{basketPrice} تومان</section>
            </div>
          )}
        </>
      </div>
    )
  }
  if (workshopDetail && workshopDetail.type === 'offline') {
    return (
      <div className="bg-gray-50 py-10">
        <header>
          <div className="flex justify-center items-center  pb-4 relative px-4">
            <IoIosArrowForward
              className="text-3xl text-gray-600 border rounded absolute right-5"
              onClick={() => navigate(-1)}
            />
            <p className="vazir-medium mr-4">جزییات کارگاه</p>
          </div>
        </header>

        {loading ? (
          <Loading color="#1e40af" />
        ) : (
          <>
            {message && <Message {...message} OnChange={setMessage} />}
            {workshopDetail && (
              <div className="w-full mb-10">
                <section className="w-full relative mt-6">
                  <section className="absolute w-full h-full bg-black opacity-60 " />
                  <img
                    className="w-full h-full"
                    src={Domain + workshopDetail.poster}
                    alt={workshopDetail.name}
                  />
                </section>
                <div className="px-4 flex justify-center items-center  ">
                  <div className="w-full rounded-2xl rounded-2xl vazir-medium bg-white drop-shadow-lg px-3 py-2 relative -top-10 relative">
                    <section className="border-b border-gray-300 pb-4">
                      <div className="flex justify-start items-center">
                        <span className="w-2 h-2 bg-[#3F54CB] rounded-full block ml-2" />{' '}
                        <p className="text-gray-500 text-xs mt-0.5 text-[#3F54CB]">
                          {workshopDetail.category
                            ? workshopDetail.category.title
                            : 'دسته بندی ندارد'}
                        </p>
                      </div>
                      <p className="mt-1 text-sm my-2">{workshopDetail.name}</p>
                      <p className="text-gray-500 text-xs mt-0.5">{workshopDetail.teacher}</p>
                    </section>
                    <div className="vazir-medium mt-4">
                      <div className="flex justify-start items-center">
                        <span className="w-2 h-2 bg-[#3F54CB] rounded-full block ml-2" />{' '}
                        <p className="text-gray-500 text-xs mt-0.5 text-[#3F54CB]">معرفی کارگاه:</p>
                      </div>
                      <div
                        className="text-sm text-gray-800 mt-2 text-justify"
                        dangerouslySetInnerHTML={{
                          __html: workshopDetail.about
                        }}
                      />
                    </div>

                    <div className="w-full flex flex-wrap justify-between items-center mt-5 relative">
                      <section className="flex flex-col justify-between items-center">
                        <section className="flex flex-col justify-center items-center mt-2">
                          <p className="text-[10px] text-gray-400">نوع کارگاه</p>
                          <p className=" text-gray-700 my-2">
                            {workshopDetail.type == 'online'
                              ? 'آنلاین'
                              : workshopDetail.type == 'offline'
                              ? 'آفلاین'
                              : 'حضوری'}
                          </p>
                        </section>
                      </section>
                      <span className="h-24 w-[1px] block bg-gray-300" />
                      <section className="flex flex-col justify-between items-center">
                        <section className="flex flex-col justify-center items-center">
                          <p className="text-[10px] text-gray-400">مدت برگزاری</p>
                          <p className=" text-gray-700 my-2">
                            {workshopDetail.workshop_duration} ساعت
                          </p>
                        </section>
                      </section>
                      <span className="h-24 w-[1px] block bg-gray-300" />
                      <section className="flex flex-col justify-between items-center">
                        <section className="flex flex-col justify-center items-center mt-2">
                          <p className="text-[10px] text-gray-400">هزینه کارگاه</p>
                          <p className=" text-gray-700 my-2 ">
                            {workshopDetail.price}
                            تومان
                          </p>
                        </section>
                      </section>
                    </div>

                    <section className="mt-5 mb-2">
                      <div className="flex justify-start items-center mt-4">
                        <span className="w-2 h-2 bg-[#3F54CB] rounded-full block ml-2" />{' '}
                        <p className="text-gray-500 text-xs mt-0.5 text-[#3F54CB]">آلبوم کارگاه:</p>
                      </div>
                      <div className="grid grid-cols-3 gap-2">
                        {workshopDetail && workshopDetail.img1 && (
                          <section className="w-24 h-24 my-1">
                            <div
                              className="w-full h-full bg-gray-200 rounded-xl"
                              style={{
                                backgroundImage: `url("${Domain + workshopDetail.img1}")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                              }}
                            />
                          </section>
                        )}

                        {workshopDetail && workshopDetail.img2 && (
                          <section className="w-24 h-24 my-1">
                            <div
                              className="w-full h-full bg-gray-200 rounded-xl"
                              style={{
                                backgroundImage: `url("${Domain + workshopDetail.img2}")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                              }}
                            />
                          </section>
                        )}

                        {workshopDetail && workshopDetail.img3 && (
                          <section className="w-24 h-24 my-1">
                            <div
                              className="w-full h-full bg-gray-200 rounded-xl"
                              style={{
                                backgroundImage: `url("${Domain + workshopDetail.img3}")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                              }}
                            />
                          </section>
                        )}

                        {workshopDetail && workshopDetail.img4 && (
                          <section className="w-24 h-24 my-1">
                            <div
                              className="w-full h-full bg-gray-200 rounded-xl"
                              style={{
                                backgroundImage: `url("${Domain + workshopDetail.img4}")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                              }}
                            />
                          </section>
                        )}
                      </div>
                    </section>

                    <div className="flex justify-start items-center mt-4">
                      <span className="w-2 h-2 bg-[#3F54CB] rounded-full block ml-2" />{' '}
                      <p className="text-gray-500 text-xs mt-0.5 text-[#3F54CB]">
                        لیست سرفصل های کارگاه:
                      </p>
                    </div>
                    {workshopDetail?.film?.map((wf) => (
                      <div
                        key={wf.id}
                        className="w-full flex flex-col px-4 py-2 border border-gray-300 rounded-xl my-3"
                      >
                        <span className="text-sm text-gray-800">قسمت - {wf.session}</span>
                        <section className="w-full flex justify-between items-center    ">
                          <div className="  dir-ltr w-full">
                            <video controls className="w-full">
                              <source src={Domain + wf.film} />
                              Your browser does not support the audio tag.
                            </video>
                          </div>
                        </section>
                      </div>
                    ))}

                    {userJoined ? (
                      <button
                        className="w-full bg-green-600  text-white rounded-lg py-4 vazir-medium mt-14  cursor-not-allowed"
                        disabled={true}
                      >
                        &nbsp;{childInfo.name} جان هم اکنون عضو کارگاه است
                      </button>
                    ) : (
                      <>
                        {workshopDetail.children &&
                        workshopDetail.capacity == workshopDetail.children.length ? (
                          <button
                            className="w-full bg-[#3F54CB]  text-white rounded-lg py-4 vazir-medium mt-14 opacity-70 cursor-not-allowed"
                            disabled={true}
                          >
                            {' '}
                            ظرفیت تکمیل شد
                          </button>
                        ) : (
                          <>
                            {inBasket && childInfo.name ? (
                              <>
                                <button className="w-full bg-[#3F54CB] text-white rounded-lg py-4 vazir-medium mt-14">
                                  کارگاه به سبد خرید {childInfo.name} جان اضافه شد
                                </button>
                              </>
                            ) : (
                              <button
                                className="w-full bg-[#3F54CB] text-white rounded-lg py-4 vazir-medium mt-14"
                                onClick={addChildToWorkshop}
                              >
                                ثبت نام درکارگاه
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <>
          {basketCount + basketPrice > 0 && (
            <div className="w-11/12 mx-auto sticky mt-10 bottom-5 bg-green-500 vazir-medium flex justify-between items-center text-white py-4 px-4 rounded-xl z-50">
              <section
                className="flex justify-start items-center"
                onClick={() => navigate('/basket')}
              >
                <MdArrowForwardIos />
                <span>پرداخت</span>
              </section>
              <section>{basketPrice} تومان</section>
            </div>
          )}
        </>
      </div>
    )
  }
  if (workshopDetail && workshopDetail.type === 'presence') {
    return (
      <div className="bg-gray-50 py-10">
        <header>
          <div className="flex justify-center items-center  pb-4 relative px-4">
            <IoIosArrowForward
              className="text-3xl text-gray-600 border rounded absolute right-5"
              onClick={() => navigate(-1)}
            />
            <p className="vazir-medium mr-4">جزییات کارگاه</p>
          </div>
        </header>

        {loading ? (
          <Loading color="#1e40af" />
        ) : (
          <>
            {message && <Message {...message} OnChange={setMessage} />}
            {workshopDetail && (
              <div className="w-full mb-10">
                <section className="w-full relative mt-6">
                  <section className="absolute w-full h-full bg-black opacity-60 " />
                  <img
                    className="w-full h-full"
                    src={Domain + workshopDetail.poster}
                    alt={workshopDetail.name}
                  />
                </section>
                <div className="px-4 flex justify-center items-center  ">
                  <div className="w-full rounded-2xl rounded-2xl vazir-medium bg-white drop-shadow-lg px-3 py-2 relative -top-10 relative">
                    <section className="border-b border-gray-300 pb-4">
                      <div className="flex justify-start items-center">
                        <span className="w-2 h-2 bg-[#3F54CB] rounded-full block ml-2" />{' '}
                        <p className="text-gray-500 text-xs mt-0.5 text-[#3F54CB]">
                          {workshopDetail.category
                            ? workshopDetail.category.title
                            : 'دسته بندی ندارد'}
                        </p>
                      </div>
                      <p className="mt-1 text-sm my-2">{workshopDetail.name}</p>
                      <p className="text-gray-500 text-xs mt-0.5">{workshopDetail.teacher}</p>
                    </section>
                    <div className="vazir-medium mt-4">
                      <div className="flex justify-start items-center">
                        <span className="w-2 h-2 bg-[#3F54CB] rounded-full block ml-2" />{' '}
                        <p className="text-gray-500 text-xs mt-0.5 text-[#3F54CB]">معرفی کارگاه:</p>
                      </div>
                      <div
                        className="text-sm text-gray-800 mt-2 text-justify"
                        dangerouslySetInnerHTML={{
                          __html: workshopDetail.about
                        }}
                      />
                    </div>

                    <div className="w-full flex flex-wrap justify-between items-center mt-5 relative">
                      <section className="flex flex-col justify-between items-center">
                        <section className="flex flex-col justify-center items-center">
                          <p className="text-[10px] text-gray-400">تاریخ برگزاری</p>
                          <p className=" text-gray-700 my-2">
                            {returnTime(workshopDetail.date_start).split(',')[0]}
                          </p>
                        </section>
                        <section className="flex flex-col justify-center items-center mt-2">
                          <p className="text-[10px] text-gray-400">نوع کارگاه</p>
                          <p className=" text-gray-700 my-2">
                            {workshopDetail.type == 'online'
                              ? 'آنلاین'
                              : workshopDetail.type == 'offline'
                              ? 'آفلاین'
                              : 'حضوری'}
                          </p>
                        </section>
                      </section>
                      <span className="h-24 w-[1px] block bg-gray-300" />
                      <section className="flex flex-col justify-between items-center">
                        <section className="flex flex-col justify-center items-center ">
                          <p className="text-[10px] text-gray-400">ساعت برگزاری</p>
                          <p className=" text-gray-700 my-2">{workshopDetail.time_start}</p>
                        </section>
                        <section className="flex flex-col justify-center items-center mt-2">
                          <p className="text-[10px] text-gray-400">ظرفیت باقی مانده</p>
                          <p className=" text-gray-700 my-2">
                            {workshopDetail.children &&
                              workshopDetail.capacity - workshopDetail.children.length}{' '}
                            نفر
                          </p>
                        </section>
                      </section>
                      <span className="h-24 w-[1px] block bg-gray-300" />
                      <section className="flex flex-col justify-between items-center">
                        <section className="flex flex-col justify-center items-center">
                          <p className="text-[10px] text-gray-400">مدت برگزاری</p>
                          <p className=" text-gray-700 my-2">
                            {workshopDetail.workshop_duration} ساعت
                          </p>
                        </section>
                        <section className="flex flex-col justify-center items-center mt-2">
                          <p className="text-[10px] text-gray-400">هزینه کارگاه</p>
                          <p className=" text-gray-700 my-2 ">
                            {workshopDetail.price}
                            تومان
                          </p>
                        </section>
                      </section>

                      <span className="h-[1px] w-full block bg-gray-200 absolute top-1/2 -translate-y-1 " />
                    </div>

                    <div className="flex justify-start items-center mt-4">
                      <span className="w-2 h-2 bg-[#3F54CB] rounded-full block ml-2" />
                      <p className="text-gray-500 text-xs mt-0.5 text-[#3F54CB]">
                        مکان برگزاری کارگاه:
                      </p>
                    </div>
                    {userJoined ? (
                      <p className="text-sm text-gray-800 mb-4 mt-2">{workshopDetail.address}</p>
                    ) : (
                      <p className="text-sm text-gray-800 mb-4 mt-2">
                        پس از ثبت نام در کارگاه آدرس کارگاه به شما نمایش داده میشود
                      </p>
                    )}

                    <section className="mt-10">
                      <p className="text-[10px] text-gray-400">آلبوم کارگاه</p>
                      <div className="grid grid-cols-3 gap-2">
                        {workshopDetail && workshopDetail.img1 && (
                          <section className="w-24 h-24 my-1">
                            <div
                              className="w-full h-full bg-gray-200 rounded-xl"
                              style={{
                                backgroundImage: `url("${Domain + workshopDetail.img1}")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                              }}
                            />
                          </section>
                        )}

                        {workshopDetail && workshopDetail.img2 && (
                          <section className="w-24 h-24 my-1">
                            <div
                              className="w-full h-full bg-gray-200 rounded-xl"
                              style={{
                                backgroundImage: `url("${Domain + workshopDetail.img2}")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                              }}
                            />
                          </section>
                        )}

                        {workshopDetail && workshopDetail.img3 && (
                          <section className="w-24 h-24 my-1">
                            <div
                              className="w-full h-full bg-gray-200 rounded-xl"
                              style={{
                                backgroundImage: `url("${Domain + workshopDetail.img3}")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                              }}
                            />
                          </section>
                        )}

                        {workshopDetail && workshopDetail.img4 && (
                          <section className="w-24 h-24 my-1">
                            <div
                              className="w-full h-full bg-gray-200 rounded-xl"
                              style={{
                                backgroundImage: `url("${Domain + workshopDetail.img4}")`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center'
                              }}
                            />
                          </section>
                        )}
                      </div>
                    </section>
                    {userJoined ? (
                      <button
                        className="w-full bg-green-600  text-white rounded-lg py-4 vazir-medium mt-14  cursor-not-allowed"
                        disabled={true}
                      >
                        &nbsp;{childInfo.name} جان هم اکنون عضو کارگاه است
                      </button>
                    ) : (
                      <>
                        {workshopDetail.children &&
                        workshopDetail.capacity == workshopDetail.children.length ? (
                          <button
                            className="w-full bg-[#3F54CB]  text-white rounded-lg py-4 vazir-medium mt-14 opacity-70 cursor-not-allowed"
                            disabled={true}
                          >
                            {' '}
                            ظرفیت تکمیل شد
                          </button>
                        ) : (
                          <>
                            {inBasket ? (
                              <>
                                <button className="w-full bg-green-600 text-white rounded-lg py-4 vazir-medium mt-14">
                                  کارگاه به سبد خرید {childInfo.name} جان اضافه شد
                                </button>
                              </>
                            ) : (
                              <button
                                className="w-full bg-[#3F54CB] text-white rounded-lg py-4 vazir-medium mt-14"
                                onClick={addChildToWorkshop}
                              >
                                ثبت نام درکارگاه
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        <>
          {basketCount + basketPrice > 0 && (
            <div className="w-11/12 mx-auto sticky mt-10 bottom-5 bg-green-500 vazir-medium flex justify-between items-center text-white py-4 px-4 rounded-xl z-50">
              <section
                className="flex justify-start items-center"
                onClick={() => navigate('/basket')}
              >
                <MdArrowForwardIos />
                <span>پرداخت</span>
              </section>
              <section>{basketPrice} تومان</section>
            </div>
          )}
        </>
      </div>
    )
  }
}

export default WorkshopDetail
