import { useState } from 'react'

function AgeBox({ setAgeFilter, Function }) {
  const [active, setActive] = useState(0)
  return (
    <>
      <div className="flex-container border-b-2 border-b-gray-200 pb-2 mt-10  ">
        <div className="scrollable-content  ">
          <div
            className={`w-24 flex justify-center items-center opacity-70 cursor-pointer  ${
              active == '0' ? 'grouping-active' : ''
            }`}
            onClick={() => {
              setActive(0)
              setAgeFilter('')
              Function('')
            }}
          >
            <span>همه</span>
          </div>

          <div
            className={`w-24 flex justify-center items-center opacity-70 cursor-pointer ${
              active == '180' ? 'grouping-active' : ''
            }`}
            onClick={() => {
              setActive(180)
              setAgeFilter(180)
              Function(180)
            }}
          >
            <span>نوزاد</span>
          </div>

          <div
            className={`w-24 flex justify-center items-center opacity-70 cursor-pointer ${
              active == '720' ? 'grouping-active' : ''
            }`}
            onClick={() => {
              setActive(720)
              setAgeFilter(720)
              Function(720)
            }}
          >
            <span>نوپا</span>
          </div>

          <div
            className={`w-24 flex justify-center items-center opacity-70 cursor-pointer ${
              active == '2520' ? 'grouping-active' : ''
            }`}
            onClick={() => {
              setActive(2520)
              setAgeFilter(2520)
              Function(2520)
            }}
          >
            <span>خردسال</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default AgeBox
