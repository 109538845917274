function Material({ data }) {
  return (
    <>
      <div className="w-full px-4  pt-5 bg-blue-50 pb-20">
        <p className="text-gray-800 w-full">مواد لازم برای آماده سازی:</p>
        {data &&
          JSON.parse(data).map((dta) => (
            <div key={dta.id} className="w-full flex justify-between items-center my-3">
              <span className="text-sm text-gray-600">{dta.material}</span>
              <span className="text-sm text-gray-800">
                {dta.count} {dta.unit}
              </span>
            </div>
          ))}
      </div>
    </>
  )
}
export default Material
