import HeaderAlbum from '../photo-album/component/header'
import Card from './component/card'
import axios from 'axios'
import { GetAlbumOrder, GetAllAlbum } from '../../../../../api'
import { useEffect, useRef, useState } from 'react'
import { MdArrowForwardIos } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

function ChooseAlbum() {
  const navigate = useNavigate()
  const [albumList, setAlbumList] = useState([])
  const [page, setPage] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState('')
  const listInnerRef = useRef()
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current

      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }
  const [basket, setBasket] = useState([])
  const [total, setTotal] = useState(0)
  const [count, setCount] = useState(0)
  const getOrder = () => {
    axios
      .get(GetAlbumOrder, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setBasket(data.existingOrder)
        setTotal(data.total)
        setCount(data.albumCount)
      })
  }
  useEffect(() => {
    getOrder()
  }, [])
  const getAlbum = () => {
    axios
      .get(GetAllAlbum + `?page=${page}&limit=20&search=${search}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setAlbumList(data)
        if (page === 1) {
          setAlbumList(data)
        } else {
          setAlbumList([...albumList, ...data])
        }
      })
  }

  useEffect(() => {
    getAlbum()
  }, [page])

  return (
    <>
      <HeaderAlbum title="انتخاب آلبوم" />
      <div
        className="w-full flex flex-wrap justify-center items-center "
        onScroll={onScroll}
        ref={listInnerRef}
      >
        {Array.isArray(albumList) &&
          albumList.map((al) => {
            const isAddToBasket = basket.some((item) => item?.album?.id === al.id)
            return (
              <Card key={al.id} data={al} ReloadFunction={getOrder} isAddToBasket={isAddToBasket} />
            )
          })}
      </div>

      <>
        {count > 0 && total > 0 && (
          <div className="w-11/12 mx-auto sticky mt-10 bottom-5 bg-green-500 vazir-medium flex justify-between items-center text-white py-4 px-4 rounded-xl z-50">
            <section
              className="flex justify-start items-center"
              onClick={() => navigate('/basket')}
            >
              <MdArrowForwardIos />
              <span>پرداخت</span>
            </section>
            <section>{total} تومان</section>
          </div>
        )}
      </>
    </>
  )
}
export default ChooseAlbum
