import { useNavigate } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io'
function Header({ title }) {
  const navigate = useNavigate()
  return (
    <header>
      <div className="flex justify-center items-center mt-10 pb-4 shadow-md relative px-4">
        <IoIosArrowForward
          className="text-3xl text-gray-600 border rounded absolute right-5"
          onClick={() => navigate(-1)}
        />
        <p className="vazir-medium mr-4">{title}</p>
      </div>
    </header>
  )
}

export default Header
