import axios from 'axios'
import { BookmarkAPI } from './api'

export function AddBookmark(type, id, childId, getDetail) {
  const formData = new FormData()
  formData.append('type', type)
  formData.append('childId', childId)
  formData.append('itemId', id)
  axios
    .post(BookmarkAPI, formData, {
      headers: {
        Authorization: `Brear ${localStorage.getItem('token')}`
      }
    })
    .then((res) => res.data)
    .then((data) => {
      getDetail(data)
    })
    .catch(() => {})
}

export function RemoveBookmark(id) {
  axios
    .delete(BookmarkAPI + '/' + id, {
      headers: {
        Authorization: `Brear ${localStorage.getItem('token')}`
      }
    })
    .then((res) => res.data)
    .then(() => {})
    .catch(() => {})
}
