import HeaderAlbum from '../../album/photo-album/component/header'
import './articles.css'
import axios from 'axios'
import { ArticleCategoryAPI, Domain } from '../../../../../api'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function MainArticles() {
  const navigate = useNavigate()
  const [data, setData] = useState([])

  function generateLightColor() {
    const hue = Math.floor(Math.random() * 256)
    const saturation = Math.floor(Math.random() * 50) + 50 // Random saturation between 50 and 100
    const lightness = Math.floor(Math.random() * 25) + 75 // Random lightness between 75 and 100

    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`
    const hexColor = hslToHex(color)

    return hexColor
  }

  function hslToHex(hslColor) {
    const [hue, saturation, lightness] = hslColor.match(/\d+/g).map(Number)
    const h = hue / 360
    const s = saturation / 100
    const l = lightness / 100

    const c = (1 - Math.abs(2 * l - 1)) * s
    const x = c * (1 - Math.abs(((h * 6) % 2) - 1))
    const m = l - c / 2

    let r, g, b
    if (h < 1 / 6) {
      r = c
      g = x
      b = 0
    } else if (h < 1 / 3) {
      r = x
      g = c
      b = 0
    } else if (h < 1 / 2) {
      r = 0
      g = c
      b = x
    } else if (h < 2 / 3) {
      r = 0
      g = x
      b = c
    } else if (h < 5 / 6) {
      r = x
      g = 0
      b = c
    } else {
      r = c
      g = 0
      b = x
    }

    r = Math.round((r + m) * 255)
      .toString(16)
      .padStart(2, '0')
    g = Math.round((g + m) * 255)
      .toString(16)
      .padStart(2, '0')
    b = Math.round((b + m) * 255)
      .toString(16)
      .padStart(2, '0')

    return `#${r}${g}${b}`
  }

  const getArticleCategory = () => {
    axios
      .get(ArticleCategoryAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setData(data.data)
      })
  }

  useEffect(() => {
    getArticleCategory()
  }, [])
  return (
    <>
      <HeaderAlbum title="مقالات" />
      <div className="w-full px-4">
        <p className="text-gray-800">مقالات تخصصی ماتیار</p>
        <p className="text-gray-700 text-sm my-4">
          هر مجموعه شامل محتوای تخصصی و کارشناسی شده است که موضوع آن دغدغه بسیاری از والدین است
        </p>
        {data.length > 0 &&
          data.map((dta) => {
            const color = generateLightColor()
            return (
              <div
                key={dta.id}
                className="w-full h-28 flex justify-start items-center px-4 relative rounded-xl overflow-hidden mt-2"
                onClick={() => navigate('/list-articles?catId=' + dta.id + '&catName=' + dta.title)}
              >
                <section
                  className="absolute top-0 right-0 w-full h-full  z-20"
                  style={{
                    background: `linear-gradient(270deg, ${color} 65%, rgba(255, 255, 255, 0) 100%)`
                  }}
                />
                <img
                  src={Domain + dta.picture}
                  alt="matyar image"
                  className="h-full w-auto absolute top-0 left-0 z-10"
                />
                <section className="z-30">
                  <p className="text-gray-800">{dta.title}</p>
                  <p className="text-gray-700 text-sm mt-2">
                    شامل <span> {dta.article} </span> عنوان
                  </p>
                </section>
              </div>
            )
          })}
      </div>
    </>
  )
}
export default MainArticles
