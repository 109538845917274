// import { AiFillEye } from 'react-icons/ai'
// import { FcLike } from 'react-icons/fc'
import { FaRegHeart } from 'react-icons/fa'
import Heart from '../../../articles/detail/svg/Heart.svg'
import Show from '../../../articles/detail/svg/Show.svg'
import { IoBookmark, IoBookmarkOutline } from 'react-icons/io5'
import { useState } from 'react'
import { AddBookmark, RemoveBookmark } from '../../../../../../bookmark'
import { useSelector } from 'react-redux'

function VideoBox({
  ageDetail,
  title,
  like,
  save,
  view,
  time,
  OnClick,
  SaveClick,
  liked,
  setLiked,
  code,
  id
}) {
  const [saved, setSaved] = useState(save)
  const childInfo = useSelector((state) => state.childInfo)

  return (
    <div className="w-full rounded-2xl   vazir-medium bg-white drop-shadow-lg px-3 py-2   -top-10 relative">
      <section
        onClick={SaveClick}
        className="absolute flex justify-center items-center top-2 left-2 "
      >
        {saved ? (
          <IoBookmark
            onClick={() => {
              RemoveBookmark(saved.id)
              setSaved(false)
            }}
            className="w-5 h-5 relative text-[#3F54CB] "
          />
        ) : (
          <IoBookmarkOutline
            onClick={() => {
              setSaved(true)
              AddBookmark('post', id, childInfo.id, setSaved)
            }}
            className="w-5 h-5 relative text-[#3F54CB] "
          />
        )}
      </section>
      <section>
        <p className="text-gray-500 text-xs mt-0.5">{ageDetail}</p>
        <p className="text-gray-500 text-xs mt-0.5">{code}</p>
        <p className="mt-1 text-xs ">{title}</p>
      </section>
      <section className="flex justify-between items-center">
        <section className="flex justify-evenly items-center mt-4">
          <section className="flex justify-start items-center ml-2">
            {liked ? (
              <img
                src={Heart}
                alt="heart"
                className="ml-1 relative -top-0.5 "
                onClick={() => {
                  setLiked(false)
                  OnClick()
                }}
              />
            ) : (
              <FaRegHeart
                className="ml-1 relative -top-0.5 "
                onClick={() => {
                  setLiked(true)
                  OnClick()
                }}
              />
            )}

            <span className="text-xs text-gray-400">{like}</span>
          </section>
          <span className="block h-5 w-[1px] bg-gray-600 opacity-70 ml-2" />
          <section className="flex justify-start items-center">
            <img src={Show} alt="show" className="text-blue-700 ml-1 relative -top-0.5" />
            <span className="text-xs text-gray-400">{view}</span>
          </section>
        </section>
        <section className="mt-4 text-xs text-gray-400">{time}</section>
      </section>
    </div>
  )
}
export default VideoBox
