import HeaderAlbum from '../../album/photo-album/component/header'
import { VscSearch, VscSettings } from 'react-icons/vsc'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import {
  Domain,
  PodcastByCategoryAPI,
  PodcastBannerAPI,
  PodcastCategoryAPI,
  PodcastSectionAPI
} from '../../../../../api'
import { Autoplay, Pagination, EffectFade } from 'swiper'
import 'swiper/css' // core Swiper
import 'swiper/css/effect-fade' // fade effect module

import { useNavigate } from 'react-router-dom'
import Card from './component/suggestion/card'
function MainPodcast() {
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [bannerList, setBannerList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [sectionList, setSectionList] = useState([])
  const [groupingIdState, setGroupingIdState] = useState('')
  const [search, setSearch] = useState('')
  const [podcast, setPodcast] = useState([])
  const listInnerRef = useRef()
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
        getPodcastByCategory(groupingIdState, search, page + 1)
      }
    }
  }
  const getBanner = () => {
    axios
      .get(PodcastBannerAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setBannerList(data)
      })
  }
  const getCategory = () => {
    axios
      .get(PodcastCategoryAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCategoryList(data)
      })
  }
  const getSection = () => {
    axios
      .get(PodcastSectionAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setSectionList(data)
      })
  }

  const getPodcastByCategory = (id = groupingIdState, Search = search, Page = page) => {
    axios
      .get(PodcastByCategoryAPI + `/item?catId=${id}&title=${Search}&page=${Page}&limit=20`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        if (Page == 1) {
          setPodcast(data)
        } else {
          setPodcast([...podcast, ...data])
        }
      })
  }

  useEffect(() => {
    getBanner()
    getCategory()
    getSection()
    getPodcastByCategory()
  }, [])

  return (
    <>
      <HeaderAlbum title="پادکست ها" />
      <div className="w-full flex justify-start items-center px-4">
        <section className="w-11/12 relative">
          <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
          <input
            className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
            placeholder="پادکست خود را جستجو کنید..."
            onChange={(e) => {
              setSearch(e.target.value)
              getPodcastByCategory(groupingIdState, e.target.value, 1)
              setPage(1)
            }}
          />
        </section>
        <section className="w-12 h-12 flex justify-center items-center card-shadow rounded-lg mr-2">
          <VscSettings className="text-2xl text-gray500" />
        </section>
      </div>
      <div className="w-full mt-5 px-4">
        <div className="w-full " id="slider-main-podcast">
          <Swiper
            effect="fade" // Enable fade effect
            fadeEffect={{
              crossFade: true // Enable crossfade for smooth transition
            }}
            loop={true} // Enables infinite loop mode
            autoplay={{
              delay: 3000 // Sets the delay between transitions (in ms)
            }}
            modules={[Autoplay, Pagination, EffectFade]}
            spaceBetween={10}
            slidesPerView={1}
            pagination={{
              clickable: true,
              renderBullet: function (index, className) {
                return `
        <span class="${className}   slider-navigation ${
                  index === this.activeIndex ? 'slider-active' : ''
                }"></span>
      `
              }
            }}
          >
            {bannerList.map((banner) => (
              <SwiperSlide
                key={banner.id}
                className="rounded-xl px-0.5 py-2 cursor-pointer"
                onClick={() => {
                  navigate('/podcast-playlist?id=' + banner?.podcast?.id)
                }}
              >
                <div
                  className="w-full h-72 rounded-2xl overflow-hidden relative"
                  style={{
                    backgroundImage: `url("${Domain + banner.picture}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                >
                  <section className="w-full h-full bg-[rgba(0,0,0,0.4)] absolute top-0 left-0">
                    <span className="text-white absolute bottom-8 right-5">
                      {banner.description}
                    </span>
                  </section>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="w-full  sticky top-0 bg-white z-[999]">
        <div className="flex-container border-b-2 border-b-gray-200 pb-2 mt-10">
          <div className="scrollable-content">
            <div
              className={`w-28 flex justify-center items-center opacity-70 cursor-pointer ${
                groupingIdState === '' ? 'grouping-active' : ''
              }`}
              onClick={() => {
                setGroupingIdState('')
                getPodcastByCategory('', search, 1)
                setPage(1)
              }}
            >
              <span>همه</span>
            </div>

            {Array.isArray(categoryList) &&
              categoryList.map((cat) => (
                <div
                  key={cat.id}
                  className={`w-28 flex justify-center items-center opacity-70 cursor-pointer ${
                    groupingIdState === cat.id ? 'grouping-active' : ''
                  }`}
                  onClick={() => {
                    setGroupingIdState(cat.id)
                    getPodcastByCategory(cat.id, search, 1)
                    setPage(1)
                  }}
                >
                  <span>{cat.title}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
      {groupingIdState === '' &&
        sectionList.map((sec) => (
          <div key={sec.id} className="w-full px-4 mt-6">
            <p className="">{sec.title}</p>
            <Swiper spaceBetween={10} slidesPerView={2.3}>
              {sec?.podcast.map((pod) => (
                <SwiperSlide key={pod.id} className="rounded-xl px-0.5 py-2">
                  <Card data={pod} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ))}

      {groupingIdState !== '' && (
        <>
          <div
            className="grid grid-cols-2 gap-4 justify-items-start content-start w-full px-4 pb-10 h-screen overflow-hidden overflow-y-scroll "
            onScroll={onScroll}
            ref={listInnerRef}
          >
            {groupingIdState !== '' &&
              Array.isArray(podcast) &&
              podcast.map((pod) => <Card key={pod.id} data={pod} />)}
          </div>
        </>
      )}
    </>
  )
}
export default MainPodcast
