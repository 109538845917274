import ReadyToGo from './component/ready-to-go'
// import SuccessfullyRegister from './component/successfully-register'
function BehavioralGoals() {
  return (
    <>
      <ReadyToGo />
      {/*<SuccessfullyRegister />*/}
    </>
  )
}
export default BehavioralGoals
