import { IoIosArrowBack } from 'react-icons/io'
import './coach-item.css'
import StarsRating from 'react-star-rate'
import { useNavigate } from 'react-router-dom'
import { Domain } from '../../../../../api'
function CoachItem({ src, name, ageDetail, commentCount, coaches, id, rateCount, number }) {
  const navigate = useNavigate()

  return (
    <div className="w-full px-1 item" id={number}>
      <div
        className="w-full flex justify-end items-start
      h-28
      {/*h-20*/}
       relative coach-shadow rounded-xl mt-5"
      >
        <section
          style={{
            backgroundImage: `url("${src}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
          className="w-img-coach-item h-full block bg-gray-600     rounded-xl"
        />
        <section className="w-text-coach-item h-full vazir-medium rounded-xl px-4 w-6/12 flex flex-col justify-between items-start mt-2">
          <div className="flex justify-between items-center w-full">
            <section className="flex flex-col">
              <p className="text-sm">{name}</p>
              <p className="text-gray-600 text-xs">{ageDetail}</p>
            </section>
            <section className="flex justify-start items-center">
              <div>
                <StarsRating
                  disabled={true}
                  direction="rtl"
                  allowHalf={false}
                  classNamePrefix="star-rating-comment"
                  value={Math.ceil(rateCount / commentCount)}
                />
              </div>
              <span className="text-gray-600 text-[10px]">({commentCount}نظر)</span>
            </section>
          </div>
          <div className="flex justify-between items-center pb-4 w-full">
            <section className="flex justify-start items-center">
              {coaches &&
                coaches.slice(0, 3).map((coach) => (
                  <span
                    key={coach.id}
                    style={{
                      backgroundImage: `url("${Domain + coach.profile}")`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}
                    className="w-8 h-8 block bg-gray-500 mx-0.5 rounded-full border border-white"
                  />
                ))}
            </section>

            <section
              className="text-xs flex justify-end items-center text-red-400"
              onClick={() => navigate(`/coach-detail?id=${id}`)}
            >
              <span>اطلاعات بیشتر</span>
              <IoIosArrowBack className="text-base " />
            </section>
          </div>
        </section>
      </div>
    </div>
  )
}

export default CoachItem
