import { createSlice } from '@reduxjs/toolkit'

export const information = createSlice({
  name: 'information',
  initialState: {
    name: '',
    id: '',
    profile: ''
  },
  reducers: {
    changeInfo: (state, action) => {
      state.name = action.payload.name
      state.id = action.payload.id
      state.profile = action.payload.profile
    },

    clearInfo: (state) => {
      state.name = ''
      state.id = ''
      state.profile = ''
    }
  }
})
export const { changeInfo, clearInfo } = information.actions

export default information.reducer
