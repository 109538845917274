import { ChildAPI, Domain } from '../../../../../api'
import returnTime from '../../../../../convert-time'
import { RxDotsVertical } from 'react-icons/rx'
import { useState } from 'react'
import { HiPencilAlt } from 'react-icons/hi'
import { FaTrash } from 'react-icons/fa'
import Swal from 'sweetalert2'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

function ChildItem({ child, ReloadFunction }) {
  const navigate = useNavigate()
  const [edit, setEdit] = useState(false)

  const deleteChildFunction = () => {
    axios
      .delete(ChildAPI + child.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then(() => {
        ReloadFunction()
      })
      .catch(() => {})
  }

  const deleteChild = () => {
    Swal.fire({
      title: 'آیا مطمين هستید ؟',
      text: ' میخواهید ' + child.name + ' را از ماتیار حذف کنید ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      cancelButtonText: 'بیخیال',
      confirmButtonText: 'بله حذف شود',
      cancelButtonAriaLabel: 'delete-child-button'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteChildFunction()
        Swal.fire('حذف شد!', 'متاسفانه کودک شما از ماتیار حذف شد', 'success')
      }
    })
  }
  return (
    <section className="w-full flex justify-between items-center vazir-medium shadow  py-3 rounded-lg mt-3 bg-white px-4">
      <section className="flex justify-start items-center">
        <section className="w-16 h-20 overflow-hidden bg-red-300 rounded-xl ml-2 flex justify-center items-center">
          <img src={Domain + child.profile} alt="matyar-child" className=" h-20 w-16" />
        </section>
        <section className="flex flex-col justify-between items-start h-20 py-2">
          <p>{child.name}</p>
          <p className="text-green-600 text-xs">متولد{returnTime(child.birthday).split(',')[0]}</p>
          <p className="text-gray-500 text-xs">
            {' '}
            {child &&
            child.childTeam &&
            child.childTeam[0] &&
            child.childTeam[0].team &&
            child.childTeam[0].team.title
              ? child.childTeam[0].team.title
              : 'کودک شما تیم ندارد!'}
          </p>
        </section>
      </section>
      <sectio className="flex justify-end flex-col items-end h-20 relative overflow-hidden">
        {edit ? (
          <div className="flex justify-between absolute top-1 left-5 animate__animated animate__fadeInLeft">
            <FaTrash className="text-xl text-red-600 mx-1" onClick={deleteChild} />
            <HiPencilAlt
              className="text-2xl text-green-600 mx-1"
              onClick={() => {
                navigate(`/child-info-specific?id=${child.id}`)
              }}
            />
          </div>
        ) : null}
        <RxDotsVertical
          className="text-gray-500 p-0 m-0 text-3xl absolute -left-2 top-0"
          onClick={() => setEdit(!edit)}
        />
        {child.child_subscribe.length > 0 ? (
          <span className="bg-green-200 text-xs px-2 py-1 rounded text-green-600">اشتراک فعال</span>
        ) : (
          <span className="bg-red-200 text-xs px-2 py-1 rounded text-red-600">فاقد اشتراک</span>
        )}
      </sectio>
    </section>
  )
}
export default ChildItem
