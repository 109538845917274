import HeaderAlbum from '../photo-album/component/header'
import { VscSearch, VscSettings } from 'react-icons/vsc'
import Pictures from './svg/picture-album.svg'
import { IoIosArrowBack } from 'react-icons/io'
import { HiPlus } from 'react-icons/hi'
import Box from './component/box'
import { AiOutlineStar } from 'react-icons/ai'
import { FiTrash2 } from 'react-icons/fi'
import { TbPhoto } from 'react-icons/tb'
import Card from './component/card'
import { useState } from 'react'
import Photos from './svg/image-gallery-white.svg'
import { useNavigate } from 'react-router-dom'
function MainAlbum() {
  const [startCreateAlbum, setStartCreateAlbum] = useState(true)
  const navigate = useNavigate()
  return (
    <>
      <HeaderAlbum title="انتخاب آلبوم" />
      {startCreateAlbum ? (
        <>
          <div
            className={`w-full fixed top-0 left-0 bg-[rgba(0,0,0,0.7)] w-full h-screen z-40 ${
              startCreateAlbum ? 'animate__animated animate__fadeIn' : null
            }`}
            onClick={() => setStartCreateAlbum(false)}
          />
          <div
            className={`fixed bottom-0 w-full py-10 px-4 bg-white rounded-t-3xl z-50 vazir-medium ${
              startCreateAlbum ? 'animate__animated animate__fadeInUp' : null
            }`}
          >
            <div className="w-full flex flex-wrap justify-center items-center">
              <section className="w-24 h-24 bg-[#3F54CB] flex justify-center items-center rounded-[30%]">
                <img src={Photos} alt="photos " />
              </section>
              <section className="w-full">
                <p className="text-center px-4 mt-4 mb-10">عکسی موجود نیست!!!</p>
                <p className="text-sm text-gray-600 text-center px-4 mb-20">
                  برای سفارش آلبوم فیزیکی ابتدا باید از داخل آلبوم ماتیار تعدادی عکس انتخاب کنید
                </p>
              </section>
              <buttom
                className="w-full text-center py-3 px-4 bg-[#3F54CB] text-white rounded-xl"
                onClick={() => navigate('/set-pic')}
              >
                {' '}
                بزن بریم عکس انتخاب کنیم
              </buttom>
            </div>
          </div>
        </>
      ) : null}
      <div className="w-full flex justify-start items-center px-4">
        <section className="w-11/12 relative">
          <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
          <input
            className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
            placeholder="عنوان عکس خود را جستجو کنید..."
          />
        </section>
        <section className="w-12 h-12 flex justify-center items-center card-shadow rounded-lg mr-2">
          <VscSettings className="text-2xl text-gray500" />
        </section>
      </div>
      <div className="w-full mt-4 px-4">
        <div className="w-full rounded-lg border border-[#E2664C] shadow flex justify-start items-center py-2 px-1">
          <section
            className="w-16 h-16 flex justify-center items-center rounded-full ml-4 p-4"
            style={{ backgroundColor: 'rgba(226, 102, 76, 0.4)' }}
          >
            <img src={Pictures} alt="pictures matyar" />
          </section>
          <section className="w-9/12 flex flex-col justify-between items-start">
            <p className="text-gray-500 text-xs">
              به راحتی میتوانی سفارش آلبوم فیزیکی بدهی کافیه لیستی از عکس ها را انتخاب کنی
            </p>
            <section className="flex justify-start items-center text-[#E2664C] mt-2">
              <p className="text-xs">سفارش آلبوم فیزیکی</p>
              <IoIosArrowBack className="text-lg" />
            </section>
          </section>
        </div>
      </div>
      <div className="w-full grid grid-cols-2 gap-2 px-4 mt-4">
        <Box text="آلبوم جدید" Tag={HiPlus} />
        <Box text="مورد علاقه ها" Tag={AiOutlineStar} />
        <Box text="سطل زباله" Tag={FiTrash2} />
        <Box text="آلبوم منتخب" Tag={TbPhoto} />
      </div>
      <div className="w-full grid grid-cols-2 gap-2 px-4 mt-4">
        <p className="w-full col-span-2 mb-4">دسته بندی آلبوم</p>
        <Card canSelect={false} />
      </div>
    </>
  )
}
export default MainAlbum
