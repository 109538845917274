// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import { useNavigate } from 'react-router-dom'
import { Domain } from '../../../../../../../../api'
import { AiFillStar } from 'react-icons/ai'
import { MdPlayArrow } from 'react-icons/md'
import Heart from '../../../../../articles/detail/svg/Heart.svg'
import Comment from '../../../../../articles/detail/svg/comment-green.svg'
import Show from '../../../../../articles/detail/svg/Show.svg'

const SwiperMain = ({ link, data, type }) => {
  const navigate = useNavigate()
  if (type == 'location') {
    return (
      <Swiper spaceBetween={10} slidesPerView={2.5}>
        {Array.isArray(data) &&
          data.map((dt) => (
            <SwiperSlide key={dt.id}>
              <div>
                <div className="w-full">
                  <section
                    className="w-full relative rounded-xl h-48"
                    style={{
                      backgroundImage: `url("${Domain + dt.picture1}")`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}
                    onClick={() => navigate(link + dt.id)}
                  ></section>
                  <p className="text-gray-700 text-sm my-2" onClick={() => navigate(link + dt.id)}>
                    {dt?.category?.title}
                  </p>
                  <div className="w-full flex justify-between items-center flex-wrap">
                    <p className="w-full">{dt.title}</p>
                    <div className="w-full flex justify-between items-center mt-4">
                      <button className="px-2 py-1 rounded-lg bg-[#3F54CB] flex justify-between">
                        <AiFillStar className="text-white" />
                        <span className="text-white mr-1 text-xs">
                          {dt.commentCount == 0 ? 0 : Math.round(dt.rateCount / dt.commentCount)}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    )
  }

  if (type == 'podcast') {
    return (
      <Swiper spaceBetween={10} slidesPerView={2.5}>
        {Array.isArray(data) &&
          data.map((dt) => (
            <SwiperSlide key={dt.id}>
              <div
                className="w-full mt-2"
                onClick={() => navigate('/podcast-playlist?id=' + dt.id)}
              >
                <section
                  className="w-full h-32 rounded-xl"
                  style={{
                    backgroundImage: `url("${Domain + dt.cover}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                />
                <p className="text-xs text-gray-800 my-2">{dt.title}</p>
                <p className="text-xs text-gray-600">
                  تعداد اپیزود: <span>{dt.episodeCount}</span>
                </p>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    )
  }

  if (type == 'animation') {
    return (
      <Swiper spaceBetween={10} slidesPerView={1.2}>
        {Array.isArray(data) &&
          data.map((dt) => (
            <SwiperSlide key={dt.id}>
              <section
                className="w-full flex justify-between items-center mt-4 bg-gray-200 rounded-xl"
                onClick={() => {
                  navigate(link + dt.id)
                }}
              >
                <div
                  className="w-44 h-24  relative rounded-xl ml-4"
                  style={{
                    backgroundImage: `url("${Domain + dt.cover}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                >
                  <section className="flex justify-center items-center w-full h-full absolute left-0 top-0 bg-[rgba(0,0,0,0.3)]  rounded-xl">
                    <MdPlayArrow className="w-10 h-10 p-2 rounded-full bg-[#3F54CB] text-white" />
                  </section>
                </div>
                <div className="w-9/12">
                  <p className="text-sm text-gray-800">{dt.title}</p>
                  <span className="text-xs text-gray-700 my-2">{dt?.category?.title}</span>
                  <section className="w-full flex justify-start items-center mt-2">
                    <div className="flex justify-start items-center ml-5">
                      <img src={Heart} alt="show icon matyar" className="w-5 h-5 ml-2" />
                      <span className="text-gray-700 text-xs">{dt.rateCount}</span>
                    </div>
                    <div className="flex justify-start items-center ml-5">
                      <img src={Comment} alt="show icon matyar" className="w-5 h-5 ml-2" />
                      <span className="text-gray-700 text-xs">{dt.commentCount}</span>
                    </div>
                    <div className="flex justify-start items-center ml-5">
                      <img src={Show} alt="show icon matyar" className="w-5 h-5 ml-2" />
                      <span className="text-gray-700 text-xs">{dt.viewCount}</span>
                    </div>
                  </section>
                </div>
              </section>
            </SwiperSlide>
          ))}
      </Swiper>
    )
  }
}
export default SwiperMain
