import HeaderAlbum from '../../album/photo-album/component/header'
import './food.css'
import { Autoplay, EffectFade, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { BsFillBookmarkFill } from 'react-icons/bs'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Domain, FoodBannerAPI, FoodCategoryAPI, FoodSectionAPI } from '../../../../../api'
import { useNavigate } from 'react-router-dom'
import { FaStar } from 'react-icons/fa6'

function FoodMain() {
  const [bannerList, setBannerList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [sectionList, setSectionList] = useState([])
  const navigate = useNavigate()
  const getBanner = () => {
    axios
      .get(FoodBannerAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setBannerList(data)
      })
  }
  const getCategory = () => {
    axios
      .get(FoodCategoryAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCategoryList(data)
      })
  }
  const getSection = () => {
    axios
      .get(FoodSectionAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setSectionList(data)
      })
  }

  useEffect(() => {
    getBanner()
    getCategory()
    getSection()
  }, [])
  return (
    <>
      <HeaderAlbum title="تغذیه کودک" />
      <div className="w-full px-4" id="slider-main-food">
        <Swiper
          effect="fade" // Enable fade effect
          fadeEffect={{
            crossFade: true // Enable crossfade for smooth transition
          }}
          loop={true} // Enables infinite loop mode
          autoplay={{
            delay: 3000 // Sets the delay between transitions (in ms)
          }}
          modules={[Autoplay, Pagination, EffectFade]}
          spaceBetween={10}
          slidesPerView={1}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return `
        <span class="${className}   slider-navigation ${
                index === this.activeIndex ? 'slider-active' : ''
              }"></span>
      `
            }
          }}
        >
          {bannerList.map((banner) => (
            <SwiperSlide
              key={banner.id}
              className="rounded-xl px-0.5 py-2 cursor-pointer"
              onClick={() => {
                navigate('/food-detail?id=' + banner?.food?.id)
              }}
            >
              <div
                className="w-full h-72 rounded-2xl overflow-hidden relative"
                style={{
                  backgroundImage: `url("${Domain + banner.picture}")`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              >
                <section className="w-full h-full bg-[rgba(0,0,0,0.4)] absolute top-0 left-0">
                  <span className="text-white absolute bottom-8 right-5">{banner.description}</span>
                </section>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="w-full flex justify-between items-center px-4 mt-4">
        <p>دسته بندی ها</p>
        <section className="flex justify-start items-center text-[#3F54CB] hidden ">
          <span className="text-xs">مشاهده همه</span>
          <MdKeyboardArrowLeft />
        </section>
      </div>
      <div className="w-full flex justify-start items-center mt-3 px-4 flex-container">
        <div className="scrollable-content">
          {categoryList.map((cat) => (
            <section
              key={cat.id}
              className="w-28 h-28 rounded-full border-green-400 border-2 p-2 ml-2"
              onClick={() => navigate('/food-list?id=' + cat.id)}
            >
              <section className="w-full h-full flex justify-center items-center rounded-full bg-green-100">
                <section className="w-full flex flex-col justify-center items-center">
                  <img src={Domain + cat.picture} alt="matyar" className="w-18 h-18" />
                  <span className="text-gray-800 text-sm">{cat.title}</span>
                </section>
              </section>
            </section>
          ))}
        </div>
      </div>

      {sectionList.map((sec, idx) => {
        if (idx == 0) {
          return (
            <div key={sec.id} className="w-full px-4 mt-6">
              <p className="mb-4">{sec.title}</p>
              <Swiper spaceBetween={10} slidesPerView={1.3}>
                {sec?.food.map((fod) => (
                  <SwiperSlide key={fod.id} className="rounded-xl px-0.5 py-2">
                    <section className="w-full py-3 rounded-xl shadow-food-box px-4">
                      <div
                        className="w-full h-48 rounded-xl"
                        style={{
                          backgroundImage: `url("${Domain + fod.picture}")`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center'
                        }}
                      />
                      <div className="w-full flex justify-between items-center pt-2">
                        <div className="W-full ">
                          <p
                            className="text-gray-800 text"
                            onClick={() => {
                              navigate('/food-detail?id=' + fod.id)
                            }}
                          >
                            {fod?.title}
                          </p>
                          <section className="flex justify-between items-center text-sm text-gray-600 mt-2 ">
                            {fod?.category?.title}
                          </section>
                        </div>
                        <div className="flex justify-start items-center">
                          <span className="text-black ml-2 text-sm">
                            {!isNaN(Math.ceil(fod.rateCount / fod.commentCount))
                              ? Math.ceil(fod.rateCount / fod.commentCount)
                              : 0}
                          </span>
                          <FaStar className="text-2xl text-yellow-400" />
                        </div>
                      </div>
                    </section>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )
        } else if (idx == 1) {
          return (
            <div key={sec.id} className="w-full px-4 mt-6">
              <p className="mb-4">{sec.title}</p>
              <Swiper spaceBetween={10} slidesPerView={2.3}>
                {sec?.food.map((fod) => (
                  <SwiperSlide key={fod.id} className="rounded-xl px-0.5 py-2">
                    <section className="w-full py-2 rounded-xl shadow-food-box px-2">
                      <div
                        className="w-full h-48 rounded-xl"
                        style={{
                          backgroundImage: `url("${Domain + fod.picture}")`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center'
                        }}
                      />
                      <div className="w-full flex justify-between items-center pt-2">
                        <div className="W-full ">
                          <p
                            className="text-gray-800 text-sm"
                            onClick={() => {
                              navigate('/food-detail?id=' + fod.id)
                            }}
                          >
                            {fod?.title}
                          </p>
                          <section className="flex justify-between items-center text-xs text-gray-600 mt-2 ">
                            {fod?.category?.title}
                          </section>
                        </div>
                        <div className="flex justify-start items-center">
                          <span className="text-black ml-2 text-sm">
                            {!isNaN(Math.ceil(fod.rateCount / fod.commentCount))
                              ? Math.ceil(fod.rateCount / fod.commentCount)
                              : 0}
                          </span>
                          <FaStar className="text-2xl text-yellow-400" />
                        </div>
                      </div>
                    </section>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )
        } else if (idx == 2) {
          return (
            <div key={sec.id} className="w-full px-4 mt-6">
              <p className="mb-4">{sec.title}</p>
              <Swiper spaceBetween={10} slidesPerView={1.3}>
                {sec?.food.map((fod) => (
                  <SwiperSlide key={fod.id} className="rounded-xl px-0.5 py-2">
                    <section className="w-full py-3 rounded-xl shadow-food-box px-4">
                      <div
                        className="w-full h-48 rounded-xl"
                        style={{
                          backgroundImage: `url("${Domain + fod.picture}")`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center'
                        }}
                      />
                      <div className="w-full flex justify-between items-center pt-2">
                        <div className="W-full ">
                          <p
                            className="text-gray-800 text"
                            onClick={() => {
                              navigate('/food-detail?id=' + fod.id)
                            }}
                          >
                            {fod?.title}
                          </p>
                          <section className="flex justify-between items-center text-sm text-gray-600 mt-2 ">
                            {fod?.category?.title}
                          </section>
                        </div>
                        <BsFillBookmarkFill className="text-2xl text-[#3F54CB]" />
                      </div>
                    </section>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )
        } else {
          return (
            <div key={sec.id} className="w-full px-4 mt-6">
              <p className="mb-4">{sec.title}</p>
              <Swiper spaceBetween={10} slidesPerView={2.3}>
                {sec?.food.map((fod) => (
                  <SwiperSlide key={fod.id} className="rounded-xl px-0.5 py-2">
                    <section className="w-full py-3 rounded-xl shadow-food-box px-4">
                      <div
                        className="w-full h-48 rounded-xl"
                        style={{
                          backgroundImage: `url("${Domain + fod.picture}")`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center'
                        }}
                      />
                      <div className="w-full flex justify-between items-center pt-2">
                        <div className="W-full ">
                          <p
                            className="text-gray-800 text-sm"
                            onClick={() => {
                              navigate('/food-detail?id=' + fod.id)
                            }}
                          >
                            {fod?.title}
                          </p>
                          <section className="flex justify-between items-center text-xs text-gray-600 mt-2 ">
                            {fod?.category?.title}
                          </section>
                        </div>
                        <BsFillBookmarkFill className="text-2xl text-[#3F54CB]" />
                      </div>
                    </section>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )
        }
      })}
    </>
  )
}
export default FoodMain
