import { AiFillStar } from 'react-icons/ai'
import { useEffect, useRef } from 'react'
import './rate.css'
function Rate({ percentage, num }) {
  const bgColor = useRef(null)
  const txtColor = useRef(null)
  const svgColor = useRef(null)
  useEffect(() => {
    if (percentage <= 25) {
      bgColor.current.classList.add('red')
      txtColor.current.classList.add('red')
      svgColor.current.classList.add('text-red-700')
    } else if (percentage <= 50) {
      bgColor.current.classList.add('orange')
      txtColor.current.classList.add('orange')
      svgColor.current.classList.add('text-orange-400')
    } else if (percentage <= 75) {
      bgColor.current.classList.add('yellow')
      txtColor.current.classList.add('yellow')
      svgColor.current.classList.add('text-yellow-400')
    } else if (percentage <= 100) {
      bgColor.current.classList.add('green')
      txtColor.current.classList.add('green')
      svgColor.current.classList.add('text-green-700')
    }
  }, [])
  return (
    <div className="w-full flex justify-between items-center">
      <span className="text-gray-400 relative top-0.5 text-xs w-3">%{percentage}</span>
      <div className="bg-gray-200 rounded-full    h-1 dir-ltr w-7/12">
        <section
          ref={bgColor}
          className={`h-full rounded-full`}
          style={{ width: `${percentage}%` }}
        />
      </div>
      <span ref={svgColor} className="w-3">
        <AiFillStar className="text-xl" />
      </span>
      <span ref={txtColor} className={`relative top-0.5 text-xs w-2`}>
        {num}
      </span>
    </div>
  )
}

export default Rate
