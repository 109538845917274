import { Suspense, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Input from '../component/input';
import BG from './svg/bg.svg';

// import Google from '../signup/svg/Google logo.svg'
import Loading from '../../../component/loading';
import { UilUser } from '@iconscout/react-unicons';
import './login.css';
import axios from 'axios';
import { LoginAPI, ParentAPI } from '../../../api';
import Wave from '../signup/svg/wave.svg';
import { toast } from 'react-toastify';
import { changeInfo } from '../../../redux/info-slice';
import { useDispatch } from 'react-redux';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
  const englishNumerals = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  const convertToEnglishNumerals = (input) => {
    return input.replace(/[٠-٩]/g, (match) => englishNumerals[arabicNumerals.indexOf(match)]);
  };
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({
    phone: '',
    password: '',
  });
  const [showCode, setShowCode] = useState(false);
  const [code1, setCode1] = useState(0);
  const [code2, setCode2] = useState(0);
  const [code3, setCode3] = useState(0);
  const [code4, setCode4] = useState(0);
  const [code5, setCode5] = useState(0);
  const LoginFunction = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('phoneNumber', info.phone);
    formData.append('password', info.password);
    axios
      .post(LoginAPI, formData)
      .then((res) => res.data)
      .then(() => {
        toast.success('یک رمز عبور به شماره شما پیامک شده است لطفا رمز را وارد کنید');
        setShowCode(true);

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        toast.error(e?.response?.data);
      });
  };

  const sendCodeFunction = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('phoneNumber', info.phone);
    formData.append('sms', code1 + code2 + code3 + code4 + code5);

    axios
      .post(ParentAPI + 'sms', formData)
      .then((res) => res.data)
      .then((data) => {
        setShowCode(true);
        localStorage.setItem('token', data.access_token);
        navigate('/?status=home');
        dispatch(
          changeInfo({
            name: data.name,
            id: data.id,
            profile: data.profile,
          }),
        );
        toast.success(data.message);

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        toast(e.response.data);
      });
  };

  const code2Ref = useRef(null);
  const code3Ref = useRef(null);
  const code4Ref = useRef(null);
  const code5Ref = useRef(null);

  const handleInputChange = (e, nextRef) => {
    const inputValue = e.target.value;
    if (inputValue.length === 1 && nextRef) {
      nextRef.current.focus();
    }
  };
  return (
    <Suspense fallback={<Loading color="black" width="100" />}>
      <div className="w-screen h-screen overflow-hidden relative">
        {showCode ? (
          <>
            <div
              className={`w-full fixed top-0 left-0 bg-[rgba(0,0,0,0.7)]   h-screen z-40 ${
                showCode ? 'animate__animated animate__fadeIn' : null
              }`}
              onClick={() => setShowCode(false)}
            />
            <div
              className={`fixed bottom-0 w-full py-10 px-4 bg-white rounded-t-3xl z-50 vazir-medium ${
                showCode ? 'animate__animated animate__fadeInUp' : null
              }`}
            >
              <p className="text-xl">رمز عبور موقت</p>
              <p className="text-gray-400 mt-2">برای ورود لطفا رمز عبور پیامک شده را وارد کنید</p>
              <div className="relative grid grid-cols-5 gap-3 mt-4 z-40 dir-ltr">
                <input
                  maxLength={1}
                  max={1}
                  type="number"
                  className="w-full border border-gray-300 bg-transparent py-3 rounded-xl  text-center"
                  onChange={(e) => {
                    setCode1(e.target.value);
                    handleInputChange(e, code2Ref);
                  }}
                />
                <input
                  ref={code2Ref}
                  maxLength={1}
                  type="number"
                  className="w-full border border-gray-300 bg-transparent py-3 rounded-xl  text-center"
                  onChange={(e) => {
                    setCode2(e.target.value);
                    handleInputChange(e, code3Ref);
                  }}
                />
                <input
                  ref={code3Ref}
                  maxLength={1}
                  type="number"
                  className="w-full border border-gray-300 bg-transparent py-3 rounded-xl  text-center"
                  onChange={(e) => {
                    setCode3(e.target.value);
                    handleInputChange(e, code4Ref);
                  }}
                />
                <input
                  ref={code4Ref}
                  maxLength={1}
                  type="number"
                  className="w-full border border-gray-300 bg-transparent py-3 rounded-xl text-center "
                  onChange={(e) => {
                    setCode4(e.target.value);
                    handleInputChange(e, code5Ref);
                  }}
                />
                <input
                  ref={code5Ref}
                  maxLength={1}
                  type="number"
                  className="w-full border border-gray-300 bg-transparent py-3 rounded-xl  text-center"
                  onChange={(e) => {
                    setCode5(e.target.value);
                    handleInputChange(e, code5Ref);
                  }}
                />
              </div>
              <button
                className="w-full bg-blue-800 text-white rounded-xl py-3 shadow-md mt-6"
                onClick={() => {
                  sendCodeFunction();
                }}
              >
                ورود
              </button>
            </div>
          </>
        ) : null}
        {/*<div className="absolute bottom-0 w-full py-10 px-4 bg-white rounded-t-3xl z-50 vazir-medium ">*/}
        {/*  <p className="text-xl">تایید کد بازیابی</p>*/}
        {/*  <p className="text-gray-400 mt-2">*/}
        {/*    لطفا کد ارسال شده به شماره تماس ۰۹۱۰۲۲۵۰۲۰ را وارد کنید*/}
        {/*  </p>*/}
        {/*  <div className="relative flex justify-evenly items-center mt-4">*/}
        {/*    <section className="w-10 h-10 border border-gray-300 flex justify-center items-center rounded-xl ">*/}
        {/*      <input type="number" maxLength={1} className="text-center w-full" />*/}
        {/*    </section>*/}

        {/*    <section className="w-10 h-10 border border-gray-300 flex justify-center items-center rounded-xl ">*/}
        {/*      <input type="number" maxLength={1} className="text-center w-full" />*/}
        {/*    </section>*/}

        {/*    <section className="w-10 h-10 border border-gray-300 flex justify-center items-center rounded-xl ">*/}
        {/*      <input type="number" maxLength={1} className="text-center w-full" />*/}
        {/*    </section>*/}

        {/*    <section className="w-10 h-10 border border-gray-300 flex justify-center items-center rounded-xl ">*/}
        {/*      <input type="number" maxLength={1} className="text-center w-full" />*/}
        {/*    </section>*/}

        {/*    <section className="w-10 h-10 border border-gray-300 flex justify-center items-center rounded-xl ">*/}
        {/*      <input type="number" maxLength={1} className="text-center w-full" />*/}
        {/*    </section>*/}
        {/*  </div>*/}

        {/*  <button className="w-full bg-green-800 text-white rounded-xl py-3 shadow-md mt-6">*/}
        {/*    تایید*/}
        {/*  </button>*/}
        {/*</div>*/}
        <div
          className="w-full flex md:flex-row-reverse flex-col justify-between items-center md:mt-36 mt-0 px-0 md:px-16 lg:px-28 xl:px-56 ">
          <div className="relative w-full">
            <img src={BG} alt="background" className="w-full" />
          </div>
          <div className="w-full px-6">
            <div className="mb-4">
              <p className="vazir-bold text-xl text-gray-800 mt-2">خوش آمدید</p>
              {/*<p className="vazir-medium text-gray-700 mt-1">*/}
              {/*  لطفا فیلدهای زیر را برای ورود پر کنید*/}
              {/*</p>*/}
            </div>
            <div>
              <Input
                inputClassName="vazir-medium text-gray-700 border border-2 py-3 px-14 rounded-xl w-full focus:border-blue-800 mb-3"
                inputId="name"
                inputPlaceholder="شماره تلفن"
                inputType="number"
                iconRight={<UilUser className="text-gray-400 absolute right-5 top-3" />}
                onChange={(e) =>
                  setInfo({ ...info, phone: convertToEnglishNumerals(e.target.value) })
                }
              />

              <button
                className={`w-full rounded-xl text-xl vazir-medium text-white bg-blue-800 py-3 mt-4 hover:bg-blue-700 shadow-lg ${
                  loading ? 'opacity-70 cursor-not-allowed' : 'opacity-100 cursor-pointer'
                }`}
                onClick={LoginFunction}
              >
                {loading ? <Loading color="#fff" width="25" /> : <span>ورود </span>}
              </button>
            </div>
            <section className="flex justify-center items-center my-6">
              <span className="block w-4/12 h-2 bg-blue-800 clip-right gradient-right" />
              <p className="vazir-medium text-gray-800 mx-2">یا ورود با</p>
              <span className="block w-4/12 h-2 bg-blue-800 clip-left gradient-left" />
            </section>

            {/*<button*/}
            {/*  disabled={loading}*/}
            {/*  className={`w-full border border-1 rounded-xl h-12 text-gray-800 vazir-medium py-1 mt-4 flex justify-center items-center z-50 ${*/}
            {/*    loading ? 'opacity-70 cursor-not-allowed' : 'opacity-100 cursor-pointer'*/}
            {/*  }`}*/}
            {/*  onClick={() => alert('google')}*/}
            {/*>*/}
            {/*  <span className="text-gray-700">ثبت نام با گوگل</span>*/}
            {/*  <img src={Google} alt="matyar signup with google" className="mr-5" />*/}
            {/*</button>*/}
            <section className="flex justify-center items-center mt-4">
              <p className="vazir-medium text-gray-700">
                هنوز ثبت نام نکرده اید؟
                <span className="text-blue-800">
                  <Link to="/signup"> ثبت نام</Link>
                </span>
              </p>
            </section>
          </div>
        </div>

        <img src={Wave} alt="wave1" className="w-full fixed bottom-0" />
      </div>
    </Suspense>
  );
}

export default Login;
