import HeaderAlbum from '../../album/photo-album/component/header'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { Domain, VaccineAPI } from '../../../../../api'
import Comment from '../../coach-team-detail/component/comment'
import PopUp from '../../../components/pop-up/comment'

function VaccineDetail() {
  const [data, setData] = useState({})
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const getVaccineDetail = () => {
    axios
      .get(VaccineAPI + '/' + id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setData(data)
      })
  }
  useEffect(() => {
    getVaccineDetail()
  }, [])

  const [showForm, setShowForm] = useState(false)
  return (
    <>
      <PopUp
        setShowMessage={setShowForm}
        showMessage={showForm}
        titleMessage="نظر خود را با ما به اشتراک بگذارید"
        userTeam={data.title}
        API={VaccineAPI + '/comment'}
        id={data.id}
        body={`لطفا برای بهبود و بالا بردن خدمات ما نظر خود را درباره تفذیه   ${data.title}  ثبت کنید`}
      />
      <HeaderAlbum title={data.title} />
      <section
        className="w-full h-32 rounded-bl-3xl"
        style={{
          backgroundImage: `url("${Domain + '/' + data.picture}")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center'
        }}
      />
      <div className="w-full px-2">
        <div
          className="mt-5"
          dangerouslySetInnerHTML={{
            __html: data.description
          }}
        />
      </div>

      <Comment
        count={data?.commentCount}
        rate={data?.rateCount}
        id={data?.id}
        API={VaccineAPI + '/comment/user?id='}
        type="vaccine"
        setShowForm={() => setShowForm(true)}
      />
    </>
  )
}
export default VaccineDetail
