function GetTimeDiff(created_at) {
  const now = new Date()
  const diff = now - new Date(created_at)
  const diffSeconds = Math.floor(diff / 1000)

  // less than a minute ago
  if (diffSeconds < 60) {
    return 'یک دقیقه پیش'
  }

  const diffMinutes = Math.floor(diffSeconds / 60)

  // less than an hour ago
  if (diffMinutes < 60) {
    return `${diffMinutes} دقیقه پیش`
  }

  const diffHours = Math.floor(diffMinutes / 60)

  // less than a day ago
  if (diffHours < 24) {
    return `${diffHours} ساعت پیش`
  }

  const diffDays = Math.floor(diffHours / 24)

  // less than a week ago
  if (diffDays < 7) {
    if (diffDays === 1) {
      return 'دیروز'
    } else {
      return `${diffDays} روز پیش`
    }
  }

  const diffWeeks = Math.floor(diffDays / 7)

  // less than a month ago
  if (diffWeeks < 4) {
    return `${diffWeeks} هفته پیش`
  }

  const diffMonths = Math.floor(diffWeeks / 4)

  // less than a year ago
  if (diffMonths < 12) {
    return `${diffMonths} ماه پیش`
  }

  const diffYears = Math.floor(diffMonths / 12)

  return `${diffYears} سال پیش`
}
export default GetTimeDiff
