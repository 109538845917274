import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import './list-subscription.css'
// import Header from '../../components/detail-header'
import axios from 'axios'
import { BasketAPI, GetAllSubscribe, SubscribeAPI } from '../../../../api'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import returnTime from '../../../../convert-time'
import { useLocation, useNavigate } from 'react-router-dom'
import { MdArrowForwardIos } from 'react-icons/md'

import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import Header from '../../components/detail-header'
import calculateDiscount from '../../../../calcute-offer'

function ListSubscription() {
  const navigate = useNavigate()
  const location = useLocation().pathname
  const childInfo = useSelector((state) => state.childInfo)

  const today = new Date()
  const [economy, setEconomy] = useState([])
  const [long, setLong] = useState([])
  const [subscribe, setSubscribe] = useState([])
  const [timeStart, setTimeStart] = useState('')
  const [timeEnd, setTimeEnd] = useState('')
  const [basketCount, setBasketCount] = useState(0)
  const [basketPrice, setBasketPrice] = useState(0)
  const [hasSubscribeBasket, setHasSubscribeBasket] = useState(false)
  const BasketDetail = () => {
    const formData = new FormData()
    formData.append('child_id', childInfo?.id)
    axios
      .post(BasketAPI + '/check/child', formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setBasketCount(data.count)
        setBasketPrice(data.price)
      })
  }
  const getAllSubscribe = () => {
    const formData = new FormData()
    formData.append('child_id', childInfo?.id)
    axios
      .post(GetAllSubscribe, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setSubscribe(data.user)
        if (
          data.user &&
          data.user[0] &&
          data.user[0].child_subscribe &&
          data.user[0].child_subscribe[0]
        ) {
          setTimeStart(data.user[0].child_subscribe[0].time_start)
          setTimeEnd(data.user[0].child_subscribe[0].time_end)
        }
        setEconomy(data.economy)
        setLong(data.long)
      })
      .catch(() => {})
  }
  const setSubscribeForChild = (id) => {
    if (!childInfo?.id) {
      toast.error('شما هنوز کودکی انتخاب نکرده اید')
    } else {
      if (hasSubscribeBasket) {
        Swal.fire({
          title: 'اضافه کردن اشتراک',
          text: 'شما یک اشتراک فعال در سبد خرید دارید با این کار اشتراک سبد شما حذف می شود و اشتراک جدید جایگزین می شود',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3F54CB',
          cancelButtonColor: '#d33',
          cancelButtonText: 'بیخیال',
          confirmButtonText: 'بله اشتراک به سبد اضافه شود',
          cancelButtonAriaLabel: 'delete-child-button'
        }).then((result) => {
          if (result.isConfirmed) {
            const formData = new FormData()
            formData.append('child_id', childInfo?.id)
            formData.append('subscribe_id', id)
            axios
              .post(BasketAPI, formData, {
                headers: {
                  Authorization: `Brear ${localStorage.getItem('token')}`
                }
              })
              .then((res) => res.data)
              .then((data) => {
                BasketDetail()
                setHasSubscribeBasket(data.hasSubscribe)
                Swal.fire('اضافه شد', 'اشتراک به سبد شما اضافه شد', 'success')
              })
              .catch((e) => {
                toast.error(e.response.data.message)
              })
          }
        })
      } else {
        const formData = new FormData()
        formData.append('child_id', childInfo?.id)
        formData.append('subscribe_id', id)
        axios
          .post(BasketAPI, formData, {
            headers: {
              Authorization: `Brear ${localStorage.getItem('token')}`
            }
          })
          .then((res) => res.data)
          .then((data) => {
            BasketDetail()
            setHasSubscribeBasket(data.hasSubscribe)
          })
          .catch((e) => {
            toast.error(e.response.data.message)
          })
      }
    }
  }
  const testFunc = () => {
    if (!childInfo.id) {
      toast.error('لطفا کودک خود را انتخاب کنید')
    } else {
      const formData = new FormData()
      formData.append('child_id', childInfo.id)
      axios
        .patch(SubscribeAPI + '5819e04b-0f11-49f7-baf5-a82205f530c1', formData, {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        })
        .then((res) => res.data)
        .then(() => {
          getAllSubscribe()
          toast.success('اشتراک برای کودک شما فعال شد')
        })
        .catch((e) => {
          toast.error(e.response.data)
        })
    }
  }

  useEffect(() => {
    getAllSubscribe()
    BasketDetail()
  }, [childInfo])

  return (
    <>
      {location == '/' ? null : <Header title="لیست اشتراک ها" />}

      <div className="w-full px-4 pb-20">
        {subscribe?.length > 0 && childInfo?.id ? (
          <section className="w-full flex justify-between items-center subscribe-shadow rounded-2xl px-4 py-6 mt-4">
            <section style={{ width: 100, height: 100 }}>
              <CircularProgressbar
                value={Math.ceil(
                  (((new Date(timeEnd).getTime() - today.getTime()) / (1000 * 60 * 60 * 24)) *
                    100) /
                    ((new Date(timeEnd).getTime() - new Date(timeStart).getTime()) /
                      (1000 * 60 * 60 * 24))
                )}
                text={`${Math.ceil(
                  (((new Date(timeEnd).getTime() - today.getTime()) / (1000 * 60 * 60 * 24)) *
                    100) /
                    ((new Date(timeEnd).getTime() - new Date(timeStart).getTime()) /
                      (1000 * 60 * 60 * 24))
                )}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: -0.25,

                  // Text size
                  textSize: '16px',

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: 'rgba(63,84,203,0.69)',
                  textColor: '#f88',
                  trailColor: 'rgba(255,136,136,0.2)',
                  backgroundColor: 'gray'
                })}
              />
            </section>
            <section className="w-7/12">
              <ul className="w-full vazir-medium">
                <li className="border rounded-lg flex justify-start items-center py-2 px-2">
                  <p className="w-6/12">اشتراک:</p>
                  <p className="w-6/12 text-xs">
                    {subscribe &&
                    subscribe[0] &&
                    subscribe[0].child_subscribe &&
                    subscribe[0].child_subscribe[0] &&
                    subscribe[0].child_subscribe[0].subscribe
                      ? subscribe[0].child_subscribe[0].subscribe?.name
                      : null}
                  </p>
                </li>

                <li className="border rounded-lg flex justify-start items-center py-2 mt-1 px-2">
                  <p className="w-6/12">شروع:</p>
                  <p className="w-6/12 text-red-600 text-xs mr-5">
                    {subscribe &&
                    subscribe[0] &&
                    subscribe[0].child_subscribe &&
                    subscribe[0].child_subscribe[0]
                      ? returnTime(subscribe[0].child_subscribe[0].time_start).split(',')[0]
                      : null}
                  </p>
                </li>

                <li className="border rounded-lg flex justify-start items-center py-2 mt-1 px-2">
                  <p className="w-6/12">اشتراک:</p>
                  <p className="w-6/12 text-red-600 text-xs mr-5">
                    {subscribe &&
                    subscribe[0] &&
                    subscribe[0].child_subscribe &&
                    subscribe[0].child_subscribe[0]
                      ? returnTime(subscribe[0].child_subscribe[0].time_end).split(',')[0]
                      : null}
                  </p>
                </li>
              </ul>
            </section>
          </section>
        ) : null}

        <section className="vazir-medium ">
          <p className="mt-4 text-lg">اشتراک تستی</p>
          <div className="flex justify-between items-center relative bg-gray-100 rounded-xl px-4 py-2 w-11/12 mr-4 mt-3">
            <section>
              <p>جهت تست</p>
              <p className="text-red-400 text-sm">0 تومان</p>
            </section>
            <section className="flex justify-center items-center bg-blue-700 text-white text-sm px-4 py-1.5 rounded-lg">
              <p className="text-xs " onClick={() => testFunc()}>
                اضافه شود
              </p>
            </section>
          </div>
          {Array.isArray(economy) && economy.length > 0 && (
            <p className="mt-4 text-lg">اشتراک اقتصادی</p>
          )}

          {economy.map((eco) => (
            <div
              key={eco.id}
              className="flex justify-between items-center relative bg-gray-100 rounded-xl px-4 py-2 w-11/12 mr-4 mt-3"
            >
              <section>
                <p>{eco.name}</p>
                <p className="text-red-400 text-sm">
                  {calculateDiscount(eco?.offer?.type, eco?.offer?.offer, eco.price)}
                  <del className="ml-2">{eco.price}</del>
                  تومان
                </p>
              </section>
              <section className="flex justify-center items-center bg-blue-700 text-white text-sm px-4 py-1.5 rounded-lg">
                <p
                  className="text-xs "
                  onClick={() =>
                    setSubscribeForChild(
                      eco.id,
                      calculateDiscount(eco?.offer?.type, eco?.offer?.offer, eco.price)
                    )
                  }
                >
                  اضافه شود
                </p>
              </section>
            </div>
          ))}

          <p className="mt-4 text-lg mt-10">اشتراک بلند مدت</p>
          {long.map((log) => (
            <div
              key={log.data}
              className="flex justify-between items-center relative bg-gray-100 rounded-xl px-4 py-2 w-11/12 mr-4 mt-3"
            >
              <section>
                <p>{log.name}</p>
                <p className="text-red-400 text-sm">{log.price} تومان</p>
              </section>
              <section className="flex justify-center items-center bg-blue-700 text-white text-sm px-4 py-1.5 rounded-lg">
                <p className="text-xs " onClick={() => setSubscribeForChild(log.id, log.price)}>
                  اضافه شود
                </p>
              </section>
            </div>
          ))}
        </section>
      </div>

      <>
        {basketCount + basketPrice > 0 && (
          <div className="w-11/12 mx-auto sticky mt-10 bottom-20 bg-green-500 vazir-medium flex justify-between items-center text-white py-4 px-4 rounded-xl z-[500]">
            <section
              className="flex justify-start items-center"
              onClick={() => navigate('/basket')}
            >
              <MdArrowForwardIos />
              <span>پرداخت</span>
            </section>
            <section>{basketPrice} تومان</section>
          </div>
        )}
      </>
    </>
  )
}

export default ListSubscription
