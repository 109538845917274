import axios from 'axios'
import { Domain, MyWorkshopAPI } from '../../../../../../api'
import { toast } from 'react-toastify'

import Card from '../card'
import returnTime from '../../../../../../convert-time'
import { useEffect, useRef, useState } from 'react'
import Photos from '../../../album/main/svg/image-gallery-white.svg'
import { useSelector } from 'react-redux'

function MyWorkshop() {
  const childInfo = useSelector((state) => state.childInfo)
  const [workshopList, setWorkshopList] = useState([])
  const listInnerRef = useRef()
  const [page, setPage] = useState(1)
  const getAllWorkshop = () => {
    if (childInfo?.id) {
      axios
        .get(MyWorkshopAPI + `?limit=20&search=&status=&page=${page}&childId=${childInfo.id}`, {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        })
        .then((res) => res.data)
        .then((data) => {
          if (page === 1) {
            setWorkshopList(data)
          } else {
            setWorkshopList([...workshopList, ...data])
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message)

          setWorkshopList([])
        })
    }
  }
  useEffect(() => {
    getAllWorkshop()
  }, [page])
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }
  return (
    <>
      <div>
        <div
          ref={listInnerRef}
          onScroll={onScroll}
          className="px-2 overflow-y-scroll hide-scroll pb-52 "
          style={{
            maxHeight: 'calc( 100vh - 18px)'
          }}
        >
          <>
            {Array.isArray(workshopList) && workshopList.length > 0 ? (
              workshopList.map((workshop) => (
                <>
                  <Card
                    id={workshop.id}
                    key={workshop.id}
                    title={
                      workshop.grouping && workshop.grouping
                        ? workshop.grouping.title
                        : 'دسته بندی ندارد'
                    }
                    name={workshop.name}
                    count={workshop.children.length}
                    time={workshop.time_start}
                    date={returnTime(workshop.date_start).split(',')[0]}
                    src={Domain + workshop.poster}
                  >
                    <div
                      className="text-xs text-gray-800 h-[17px] mt-2 overflow-hidden text-start"
                      dangerouslySetInnerHTML={{
                        __html: workshop.about
                      }}
                    />
                  </Card>
                </>
              ))
            ) : (
              <div className="w-full flex flex-wrap justify-center items-center mt-10">
                <section className="w-24 h-24 bg-[#3F54CB] flex justify-center items-center rounded-[30%]">
                  <img src={Photos} alt="photos " />
                </section>
                <section className="w-full">
                  <p className="text-center px-4 mt-4 mb-10">کارگاهی موجود نیست!!!</p>
                  <p className="text-sm text-gray-600 text-center px-4 mb-20">
                    برای شرکت در کارگاه ابتدا باید کارگاه مورد نظر را انتخاب و سپس در آن ثبت کنید
                  </p>
                </section>
                <buttom className="w-full text-center py-3 px-4 bg-[#3F54CB] text-white rounded-xl">
                  {' '}
                  بزن بریم کارگاه انتخاب کنیم
                </buttom>
              </div>
            )}
          </>
        </div>
      </div>
    </>
  )
}
export default MyWorkshop
