import { IoListOutline } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { BsCalendarWeekFill } from 'react-icons/bs'
import { IoMdHome } from 'react-icons/io'
import './footer.css'
function DoctorFooter() {
  const location = useLocation().pathname
  return (
    <>
      <div className="w-full flex justify-between items-center px-4 fixed bottom-0 shadow-doctor-footer pt-5 pb-3 bg-white">
        <section className="w-auto flex justify-start items-center bg-blue-200 rounded px-4 h-8">
          <span
            className={`text-xs text-blue-600 ${
              location == '/doctor-history' ? 'inline' : 'hidden'
            }`}
          >
            {' '}
            تاریخچه پزشکی
          </span>
          <IoListOutline className="text-2xl text-blue-600" />
        </section>

        <section className="w-auto flex justify-start items-center bg-blue-200 rounded   h-8 px-4">
          <span
            className={`text-xs text-blue-600 ${
              location == '/doctor-appointment-your' ? 'inline' : 'hidden'
            }`}
          >
            {' '}
            لیست نوبت ها
          </span>
          <BsCalendarWeekFill className="text-xl text-blue-600 mr-1" />
        </section>

        <section className="w-auto   flex justify-start items-center bg-blue-200 rounded px-4 h-8">
          <span
            className={`text-xs text-blue-600 ${location == '/doctor-main' ? 'inline' : 'hidden'}`}
          >
            {' '}
            صفحه اصلی
          </span>
          <IoMdHome className="text-2xl text-blue-600" />
        </section>
      </div>
    </>
  )
}
export default DoctorFooter
