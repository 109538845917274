import HeaderAlbum from '../../album/photo-album/component/header'
import { CiCirclePlus } from 'react-icons/ci'
import { VscSearch } from 'react-icons/vsc'
import Card from './component/card'
import DoctorFooter from '../footer'

function DoctorAppointmentYour() {
  return (
    <>
      <HeaderAlbum title="نوبت های شما" />
      <div className="w-full px-4 py-5">
        <div className="w-full flex justify-between items-center my-3">
          <p className="text-gray-700">نوبت های پزشکی</p>
          <section className="w-7 h-7 bg-blue-300 rounded flex justify-center items-center">
            <CiCirclePlus className="text-xl text-[#3F54CB]" />
          </section>
        </div>

        <div className="w-full grid grid-cols-2 gap-4 my-2">
          <section className="w-full flex justify-center items-center border border-[#3F54CB] rounded-full">
            <span className="text-[#3F54CB] my-2">نوبت های گذشته</span>
          </section>
          <section className="w-full flex justify-center items-center border border-[#3F54CB] bg-[#3F54CB] rounded-full">
            <span className="text-white my-2">نوبت های آینده</span>
          </section>
        </div>

        <div className="w-full px-4 my-5">
          <section className="w-full relative">
            <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
            <input
              className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
              placeholder="نام پزشک  خود را جستجو کنید..."
            />
          </section>
        </div>

        <div className="w-full">
          <p className="text-gray-700">31 خرداد 1402</p>

          <Card />
        </div>
      </div>
      <DoctorFooter />
    </>
  )
}
export default DoctorAppointmentYour
