import { useEffect, useRef, useState } from 'react'
import HeaderAlbum from '../../album/photo-album/component/header'
import { VscSearch } from 'react-icons/vsc'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Domain, FoodByCategoryAPI } from '../../../../../api'
import AgeBox from '../../../../../component/age'
import { FaStar } from 'react-icons/fa6'

function FoodList() {
  const [search, setSearch] = useState('')

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [foodList, setFoodList] = useState([])
  const listInnerRef = useRef(null)
  const [page, setPage] = useState(1)
  const navigate = useNavigate()
  const [age, setAge] = useState('')
  const getFoodByCategory = (Age = age, Page = page, Search = search) => {
    axios
      .get(
        FoodByCategoryAPI +
          `?catId=${id == null ? '' : id}&search=${Search}&page=${Page}&limit=20&age=${Age}`,
        {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        }
      )
      .then((res) => res.data)
      .then((data) => {
        if (Page === 1) {
          setFoodList(data)
        } else {
          setFoodList([...foodList, ...data])
        }
      })
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
        getFoodByCategory(age, page + 1, search)
      }
    }
  }

  useEffect(() => {
    getFoodByCategory(age, page, search)
  }, [age, search])

  useEffect(() => {
    setPage(1)
  }, [search, age])
  return (
    <>
      <HeaderAlbum title="لیست تغذیه ها" />
      <div className="w-full flex justify-start items-center px-4">
        <section className="w-full relative">
          <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
          <input
            className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
            placeholder="تغذیه خود را جستجو کنید..."
            onChange={(e) => {
              getFoodByCategory(age, 1, e.target.value)
              setSearch(search)
              setPage(1)
            }}
          />
        </section>
      </div>
      <AgeBox setAgeFilter={setAge} Function={getFoodByCategory} />

      <div
        ref={listInnerRef}
        onScroll={onScroll}
        className="grid grid-cols-2 gap-4 px-4 pb-32 overflow-hidden overflow-y-scroll h-screen content-start"
      >
        {foodList.map((fod) => (
          <section
            key={fod.id}
            className="w-full px-2 py-3 rounded-xl shadow-food-box mt-4"
            onClick={() => navigate('/food-detail?id=' + fod.id)}
          >
            <div
              className="w-full h-24 rounded-xl"
              style={{
                backgroundImage: `url("${Domain + fod.picture}")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            />
            <div className="w-full flex justify-between items-center pt-2">
              <div className="w-full">
                <p className="text-gray-800 text-xs">{fod.title}</p>
                <section className="flex justify-between items-center text-xs text-gray-600 mt-2 text-[10px]">
                  {fod?.category?.title}
                </section>
              </div>
              <div className="flex justify-start items-center">
                <span className="text-black ml-2 text-sm">
                  {!isNaN(Math.ceil(fod.rateCount / fod.commentCount))
                    ? Math.ceil(fod.rateCount / fod.commentCount)
                    : 0}
                </span>
                <FaStar className="text-2xl text-yellow-400" />
              </div>
            </div>
          </section>
        ))}
      </div>
    </>
  )
}

export default FoodList
