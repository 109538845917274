import { useRef, useState } from 'react'
import { Domain } from '../../../../../api'
import { BsFillBookmarkFill, BsFillPlayFill } from 'react-icons/bs'
import Heart from '../../articles/detail/svg/Heart.svg'
import Comment from '../../articles/detail/svg/comment-green.svg'
import Show from '../../articles/detail/svg/Show.svg'
import { useNavigate } from 'react-router-dom'
import { CiBookmark } from 'react-icons/ci'
import { AddBookmark, RemoveBookmark } from '../../../../../bookmark'
import { useSelector } from 'react-redux'
import { FaArrowLeft } from 'react-icons/fa'

function Card({ animation }) {
  const [save, setSave] = useState(animation?.save?.length > 0)

  const vidRef = useRef(null)
  const navigate = useNavigate()
  const childInfo = useSelector((state) => state.childInfo)

  return (
    <>
      <video controls ref={vidRef} className="hidden">
        <source src={Domain + animation.video} type="audio/mpeg" />
      </video>
      <div
        key={animation.id}
        className="w-full rounded-3xl border border-blue-500 shadow shadow-blue-500/50 mt-5 "
      >
        <div
          className="w-full h-48  relative  rounded-t-3xl"
          style={{
            backgroundImage: `url("${Domain + animation.cover}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        >
          <section className="flex justify-center items-center w-full h-full absolute left-0 top-0 bg-[rgba(0,0,0,0.3)]  rounded-t-3xl">
            <BsFillPlayFill
              className="w-10 h-10 p-2 rounded-full bg-[#3F54CB] text-white"
              onClick={() => {
                navigate('/animation-detail?id=' + animation.id)
              }}
            />
          </section>
        </div>
        <div className="w-full flex justify-between items-start px-4 mt-4">
          <div className="text-xl flex flex-col justify-start items-start">
            <span
              onClick={() => {
                navigate('/animation-detail?id=' + animation.id)
              }}
            >
              {animation.title}
            </span>
            <div className="flex justify-start items-center mt-3">
              <span
                className="w-10 h-10 rounded-full block ml-2"
                style={{
                  backgroundImage: `url("${Domain + animation?.category?.picture}")`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              />
              <span className="text-sm text-gray-600">{animation?.category?.title}</span>
            </div>
          </div>
          {save ? (
            <BsFillBookmarkFill
              className="text-2xl text-[#3F54CB]"
              onClick={() => {
                RemoveBookmark(animation?.save[0]?.id)
                setSave(false)
              }}
            />
          ) : (
            <CiBookmark
              className="text-3xl"
              onClick={() => {
                AddBookmark('animation', animation.id, childInfo.id)
                setSave(true)
              }}
            />
          )}
        </div>
        <div className="w-full flex justify-between items-center px-4 mb-4">
          <div className=" flex justify-between items-start">
            <section className="w-full flex justify-start items-center mt-2">
              <div className="flex justify-start items-center ml-5">
                <img src={Heart} alt="show icon matyar" className="w-5 h-5 ml-2" />
                <span className="text-gray-700 text-xs">{animation.likeCount}</span>
              </div>
              <div className="flex justify-start items-center ml-5">
                <img src={Comment} alt="show icon matyar" className="w-5 h-5 ml-2" />
                <span className="text-gray-700 text-xs">{animation.commentCount}</span>
              </div>
              <div className="flex justify-start items-center ml-5">
                <img src={Show} alt="show icon matyar" className="w-5 h-5 ml-2" />
                <span className="text-gray-700 text-xs">{animation.viewCount}</span>
              </div>
            </section>
          </div>

          <section
            className="flex justify-start items-center   "
            onClick={() => {
              navigate('/animation-detail?id=' + animation.id)
            }}
          >
            <span className=" text-sm text-[#3F54CB]">مشاهده</span>
            <FaArrowLeft className="text-xl mr-1 text-[#3F54CB] hidden" />
          </section>
        </div>
      </div>
    </>
  )
}

export default Card
