import Header from '../../components/detail-header'

import Phone from './component/phone.svg'
import Message from './component/message.svg'
import Location from './component/location.svg'
import { useState } from 'react'
import axios from 'axios'
import { ViewPoint } from '../../../../api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

function ContactUs() {
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [text, setText] = useState('')
  const createViewPoint = () => {
    if (title.length > 5 && text.length > 5) {
      const formData = new FormData()
      formData.append('title', title)
      formData.append('text', text)
      axios
        .post(ViewPoint, formData, {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        })
        .then(() => {
          toast.success('دیدگاه شما با موفقیت ثبت شد')
          navigate('/?status=home')
        })
        .catch((e) => {
          e.response.data.message.map((msg) => toast.error(msg))
        })
    }
  }
  return (
    <>
      <Header title="ارتباط با ما" />
      <div className="w-full vazir-medium  ">
        <section className="bg-red-400 h-10 ">
          <div className="w-full flex flex-wrap justify-evenly items-start px-8 relative translate-y-1">
            <div className="w-4/12 px-1">
              <div className="w-full flex flex-col justify-center items-center bg-white rounded shadow pb-8 pt-4">
                <section className="w-10 h-10 bg-blue-300 flex justify-center items-center rounded-full">
                  <img src={Location} alt="phone number" />
                </section>
                <p className="text-xs mt-2">آدرس </p>
                <p className="text-xs mt-2">۰۲۱-۹۸۰۰-۲۵۵۸</p>
              </div>
            </div>

            <div className="w-4/12 px-1">
              <div className="w-full flex flex-col justify-center items-center bg-white rounded shadow pb-8 pt-4">
                <section className="w-10 h-10 bg-blue-300 flex justify-center items-center rounded-full">
                  <img src={Message} alt="phone number" />
                </section>
                <p className="text-xs mt-2">آدرس ایمیل </p>
                <p className="text-xs mt-2">۰۲۱-۹۸۰۰-۲۵۵۸</p>
              </div>
            </div>

            <div className="w-4/12 px-1">
              <div className="w-full flex flex-col justify-center items-center bg-white rounded shadow pb-8 pt-4">
                <section className="w-10 h-10 bg-blue-300 flex justify-center items-center rounded-full">
                  <img src={Phone} alt="phone number" />
                </section>
                <p className="text-xs mt-2">شماره تلفن </p>
                <p className="text-xs mt-2">۰۲۱-۹۸۰۰-۲۵۵۸</p>
              </div>
            </div>
          </div>
        </section>
        <div className="px-8">
          <div className="w-full rounded-2xl border border-gray-300 mt-28 px-4">
            <p className="text-lg mt-6 ">ارتباط با ما</p>
            <p className="text-gray-400 text-sm my-4">
              شما میتوانید نظرات و انتقادات خود را از طریق فرم زیر برای ما ارسال کنید
            </p>
            <input
              className="w-full px-3 py-3  border border-gray-300 rounded-xl mt-2 text-sm"
              placeholder="انتخاب موضوع"
              onChange={(e) => setTitle(e.target.value)}
            />

            <textarea
              className="w-full px-3 h-32  border border-gray-300 rounded-xl mt-2 text-sm"
              placeholder="پیام خود را بنویسید"
              onChange={(e) => setText(e.target.value)}
            />
            <div className="w-full flex justify-center my-4">
              <button
                className="  w-10/12 py-3 rounded-xl bg-blue-800 text-white"
                onClick={createViewPoint}
              >
                ارسال پیام
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs
