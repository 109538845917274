import React, { useEffect, useRef, useState } from 'react'
import { UilCalendarAlt } from '@iconscout/react-unicons'
import './toady-activities.css'
import ActivitiesListItem from './list-activities'
import DatePicker from 'react-multi-date-picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import transition from 'react-element-popper/animations/transition'
import DateObject from 'react-date-object'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { GetActivityList } from '../../../../api'
import randomColor from 'randomcolor'
import gregorian from 'react-date-object/calendars/gregorian'
import gregorian_en from 'react-date-object/locales/gregorian_en'
const moment = require('moment-jalaali')
function TodayActivities() {
  const inputRange = useRef(null)
  const showInputRange = useRef(null)
  const childInfo = useSelector((state) => state.childInfo)
  const [activity, setActivity] = useState([])
  const [daysOFWeek, setDaysOFWeek] = useState([
    { name: 'شنبه', numDay: null, numMonth: null, numYear: null },
    { name: 'یکشنبه', numDay: null, numMonth: null, numYear: null },
    { name: 'دوشنبه', numDay: null, numMonth: null, numYear: null },
    { name: 'سه‌شنبه', numDay: null, numMonth: null, numYear: null },
    { name: 'چهارشنبه', numDay: null, numMonth: null, numYear: null },
    { name: 'پنجشنبه', numDay: null, numMonth: null, numYear: null },
    { name: 'جمعه', numDay: null, numMonth: null, numYear: null }
  ])
  const [dateToObject, setDateToObject] = useState('')
  const [maxSizeRange, setMaxSizeRange] = useState(0)
  const minSizeRange = 0
  const [rangeValue, setRangeValue] = useState(0)
  const changeBackgroundSizeInputRange = (min, max) => {
    inputRange.current.style.backgroundSize = `${(inputRange.current.value * 100) / max}%`
    showInputRange.current.style.right = `${(inputRange.current.value * 100) / max}%`
    if (((inputRange.current.value - min) * 100) / (max - min) == 100) {
      showInputRange.current.classList.add('translate-left')
    } else {
      showInputRange.current.classList.remove('translate-left')
    }
    if (((inputRange.current.value - min) * 100) / (max - min) == 0) {
      showInputRange.current.classList.add('translate-right')
    } else {
      showInputRange.current.classList.remove('translate-right')
    }
  }
  const logDate = (e) => {
    const inputDateString = new Date(e)
    // Parse input date string into a Date object
    const inputDate = new Date(inputDateString)

    // Extract date and time components from input Date object
    const year = inputDate.getFullYear()
    const month = inputDate.getMonth() + 1 // add 1 to adjust for 0-based indexing
    const day = inputDate.getDate()
    const hours = inputDate.getHours()
    const minutes = inputDate.getMinutes()
    const seconds = inputDate.getSeconds()

    // Format extracted components into output date string
    const outputDateString = `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

    getActivityFunction(outputDateString)
  }
  const [state, setState] = useState({ format: 'dddd DD MMMM YYYY' })
  const convert = (date, format = state.format) => {
    let object = { date, format }

    setState({
      persian: new DateObject(object).format(),
      jsDate: date.toDate(),
      ...object
    })
  }
  const persianToGregorian = (persianDate) => {
    const result = {
      year: new DateObject({ calendar: persian, date: persianDate }).convert(
        gregorian,
        gregorian_en
      ).year,
      month: new DateObject({ calendar: persian, date: persianDate }).convert(
        gregorian,
        gregorian_en
      ).month.number,
      day: new DateObject({ calendar: persian, date: persianDate }).convert(gregorian, gregorian_en)
        .day
    }

    return new Date(result.year, result.month - 1, result.day)
  }

  const generateDaysOfWeak = (jdstring) => {
    const momentDate = moment(jdstring, 'jYYYYY/jMM/jDD')

    const dateobjectNew = new DateObject(persianToGregorian(jdstring))

    dateobjectNew.calendar = persian
    dateobjectNew.locale = persian_fa

    for (let i = 0; i < daysOFWeek.length; i++) {
      const dayNumber = i - dateobjectNew.weekDay.index

      const date = momentDate.clone().add(dayNumber, 'd')
      daysOFWeek[i].numDay = date.jDate()
      daysOFWeek[i].numMonth = date.jMonth() + 1
      daysOFWeek[i].numYear = date.jYear()
    }

    setDaysOFWeek(daysOFWeek)
  }
  const dateObjectFunction = async (date = Date.now(), year, month, day) => {
    const datePicker = await new DateObject(date)
    datePicker.calendar = await persian
    datePicker.locale = await persian_fa
    await setDateToObject(datePicker)
    await convert(datePicker)
    await logDate(datePicker)

    await generateDaysOfWeak(
      `${year ? year : datePicker.year}/${month ? month : datePicker.month.number}/${
        day ? day : datePicker.day
      }`
    )
  }
  const getActivityFunction = (date) => {
    if (childInfo.id) {
      const formData = new FormData()
      formData.append('child_id', childInfo.id)
      formData.append('time_start', date)
      axios
        .post(GetActivityList, formData, {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        })
        .then((res) => res.data)
        .then((data) => {
          setActivity(data)
          setMaxSizeRange(data.findChildActivities.length + data.findChildActivitiesDo.length)
          setRangeValue(data.findChildActivitiesDo.length)
          inputRange.current.value = data.findChildActivitiesDo.length
          changeBackgroundSizeInputRange(
            data.findChildActivitiesDo.length,
            data.findChildActivities.length + data.findChildActivitiesDo.length
          )
        })
        .catch(() => {})
    }
  }
  useEffect(() => {
    dateObjectFunction()
  }, [childInfo.id])

  return (
    <React.Fragment>
      <div className={`w-full bg-white rounded-t-2xl  px-2 pt-4 pb-20`}>
        <section className="w-full flex justify-between items-center ">
          <div className="w-full">
            <DatePicker
              className="rmdp-mobile"
              onChange={(e) => {
                logDate(e)
                convert(e)
                setDateToObject(e)
                generateDaysOfWeak(`${e.year}/${e.month.number}/${e.day}`)
              }}
              multiple={false}
              calendar={persian}
              locale={persian_fa}
              calendarPosition="bottom-right"
              mobileLabels={{
                OK: 'تایید',
                CANCEL: 'بستن'
              }}
              animations={[
                transition({
                  from: 35,
                  transition: 'all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)'
                })
              ]}
              render={(value, openCalendar) => {
                return (
                  <button
                    className="w-[95vw] flex justify-between items-center  mt-2"
                    onClick={openCalendar}
                  >
                    <span>{state.persian}</span>

                    <UilCalendarAlt className="text-gray-700" />
                  </button>
                )
              }}
            />
          </div>
        </section>
        <div className="flex justify-between items-center mt-5">
          {daysOFWeek &&
            daysOFWeek.map((day) => (
              <section
                key={day.name}
                className={`vazir-medium flex flex-col justify-between items-center border h-28  w-12 rounded-xl py-2 
                    ${day.numDay == dateToObject.day ? 'active-date-toady-activities' : null}
  
                     cursor-pointer`}
                onClick={() => {
                  dateObjectFunction(
                    persianToGregorian(`${day.numYear}/${day.numMonth}/${day.numDay}`),
                    day.numYear,
                    day.numMonth,
                    day.numDay
                  )
                }}
              >
                <span className="text-gray-400  text-[10px]">{day.name}</span>
                <span className="text-gray-500">{day.numDay}</span>
              </section>
            ))}
        </div>
        {childInfo && childInfo.id ? (
          <>
            <section
              className={`mt-8 w-full  ${
                activity && activity.findChildActivities && activity.findChildActivities.length > 0
                  ? null
                  : 'hidden'
              }`}
            >
              <p className="vazir-medium text-gray-700">فعالیت های امروز!</p>
              <div className="relative -top-6">
                <span
                  ref={showInputRange}
                  className="show-range-value vazir-medium text-white px-1 rounded py-0.5 text-xs opacity-0"
                >
                  {rangeValue}&nbsp; از &nbsp;{maxSizeRange}
                </span>
                <input
                  ref={inputRange}
                  min={minSizeRange}
                  max={maxSizeRange}
                  value={rangeValue}
                  type="range"
                  className="w-full mt-5"
                  onTouchStart={() => {
                    showInputRange.current.classList.remove('opacity-0')
                    showInputRange.current.classList.add('opacity-100')
                  }}
                  onTouchEnd={() => {
                    showInputRange.current.classList.add('opacity-0')
                    showInputRange.current.classList.remove('opacity-100')
                  }}
                  onMouseDown={() => {
                    showInputRange.current.classList.remove('opacity-0')
                    showInputRange.current.classList.add('opacity-100')
                  }}
                  onMouseUp={() => {
                    showInputRange.current.classList.add('opacity-0')
                    showInputRange.current.classList.remove('opacity-100')
                  }}
                  // onFocus={() => showInputRange.current.classList.remove('opacity-100')}
                />
              </div>
            </section>
            {activity && activity.findChildActivities && activity.findChildActivities.length > 0 ? (
              <section className="flex justify-between items-center flex-wrap">
                {activity &&
                  activity.findChildActivities &&
                  activity.findChildActivities.map((activity) => (
                    <ActivitiesListItem key={activity.id} color={randomColor()} data={activity} />
                  ))}
              </section>
            ) : (
              <div className="w-full flex justify-center items-center mt-10">
                <p className="text-gray-700">فعالیتی برای کودک شما ثبت نشده است</p>
              </div>
            )}
            {activity &&
              activity.findChildActivitiesDo &&
              activity.findChildActivitiesDo.length > 0 && (
                <section className="mt-10">
                  <p className="vazir-medium text-gray-600">فعالیت های انجام شده</p>
                  <section className="flex justify-between items-center flex-wrap">
                    {activity &&
                      activity.findChildActivitiesDo &&
                      activity.findChildActivitiesDo.map((activity) => (
                        <ActivitiesListItem
                          key={activity.id}
                          color={activity.status == 'undone' ? '#ef4444' : '#3b82f6'}
                          done={
                            activity.status == 'done' || activity.status == 'undone' ? true : false
                          }
                          data={activity}
                        />
                      ))}
                  </section>
                </section>
              )}
          </>
        ) : (
          <div className="w-full flex justify-center items-center mt-10">
            <p className="text-gray-700 text-center">
              لطفا از طریف نشانگر کنار پروفایل کودک خود را انتخاب کنید
            </p>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
export default TodayActivities
