import HeaderAlbum from '../../album/photo-album/component/header'
import VaccineImg from './assets/12.png'
import Card from './card'
import axios from 'axios'
import { getAllVaccine } from '../../../../../api'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

function MyVaccine() {
  const childInfo = useSelector((state) => state.childInfo)
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [vaccines, setVaccines] = useState([])
  const listInnerRef = useRef()
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }
  const getVaccineForUser = () => {
    axios
      .get(getAllVaccine + `?page=${page}&limit=20&title=&child_id=${childInfo?.id}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        if (page === 1) {
          setVaccines(data.data)
        } else {
          setVaccines([...vaccines, ...data.data])
        }
      })
  }

  useEffect(() => {
    getVaccineForUser()
  }, [page])
  return (
    <>
      <HeaderAlbum title="واکسن ها" />
      <div className="w-full px-4">
        <img src={VaccineImg} alt="vaccine matyar" className="w-full h-52" />
      </div>
      <div className="w-full px-4 mt-5 ">
        <div className="w-full flex justify-between items-center">
          <p className="text-gray-800">واکسن های {childInfo.name}</p>
          <p
            className=" text-[#3F54CB]  text-xs flex justify-start items-center "
            onClick={() => navigate('/vaccine-question')}
          >
            راهنمای واکسن
            <IoIosArrowBack className="text-xl text-[#3F54CB] " />
          </p>
        </div>
        <div
          className="w-full  h-screen overflow-hidden overflow-y-scroll "
          ref={listInnerRef}
          onScroll={onScroll}
        >
          {Array.isArray(vaccines) && vaccines.map((vac) => <Card key={vac.id} data={vac} />)}
        </div>
      </div>
    </>
  )
}
export default MyVaccine
