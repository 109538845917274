// import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { BsHandbag } from 'react-icons/bs'
import Header from '../../components/detail-header'
import axios from 'axios'
import { BasketAPI, GetAlbumOrder } from '../../../../api'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Loading from '../../../../component/loading'
import calculateDiscount from '../../../../calcute-offer'
import { toast } from 'react-toastify'

function Basket() {
  const [loading, setLoading] = useState(false)
  const [urlLoading, setUrlLoading] = useState(false)
  const [offerLoading, setOfferLoading] = useState(false)
  const childInfo = useSelector((state) => state.childInfo)
  const navigate = useNavigate()
  const [basketDetail, setBasketDetail] = useState({})
  const [basketPrice, setBasketPrice] = useState(null)
  const [code, setCode] = useState('')
  const getBasketDetail = () => {
    setLoading(true)
    axios
      .get(BasketAPI + '/user?child_id=' + childInfo.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setBasketDetail(data)
        setCode(data?.basket?.offer?.code)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const RemoveAlbum = (id) => {
    axios
      .delete(GetAlbumOrder + '/' + id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        getBasketDetail()
      })
  }

  const RemoveFromBasket = (workshopIds, subscribeIds) => {
    const formData = new FormData()
    formData.append('removed_workshop_ids', workshopIds)
    formData.append('removed_subscribe_id', subscribeIds)
    axios
      .patch(BasketAPI + '/' + basketDetail?.basket?.id, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then(() => {
        getBasketDetail()
      })
  }
  const BuyBasket = () => {
    setUrlLoading(true)
    const formData = new FormData()
    formData.append('child_id', childInfo.id)
    formData.append('id', basketDetail?.basket?.id)
    axios
      .post(BasketAPI + '/buy', formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        window.location = data.url
      })
      .catch(() => {
        setUrlLoading(false)
      })
  }

  const SubmitOffer = () => {
    setOfferLoading(true)
    const formData = new FormData()
    formData.append('code', code)
    formData.append('id', basketDetail?.basket?.id)
    formData.append('child_id', childInfo?.id)
    axios
      .post(BasketAPI + '/submit/offer', formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setOfferLoading(false)
        setBasketPrice(data)

        toast.success('کد تخفیف با موفقیت اعمال شد')
      })
      .catch((e) => {
        setOfferLoading(false)
        toast.error(e.response.data)
      })
  }
  useEffect(() => {
    getBasketDetail()
  }, [])
  return (
    <>
      <Header title="سبد خرید" />
      {loading ? (
        <Loading color="#1e40af" width="100px" />
      ) : (
        <div className="w-full flex flex-col justify-between items-center min-h-screen vazir-medium px-4">
          {basketDetail?.basket?.subscribe && (
            <div className="w-full ">
              <div className="flex flex-col pb-4 bg-white py-3 px-2 rounded-md mt-4 shadow-box">
                <section className="flex justify-start items-center">
                  <section className="w-16 h-16 bg-[#E2664C] flex justify-center items-center rounded-full ml-4">
                    <BsHandbag className="text-3xl text-white" />
                  </section>
                  <section className="w-10/12">
                    <section className="flex justify-between items-center">
                      <p>{basketDetail?.basket?.subscribe?.name}</p>
                    </section>
                    <section className="flex justify-between items-center text-sm text-gray-400 w-6/12 mt-1">
                      <span>
                        <span className="text-gray-800 text-base">
                          {calculateDiscount(
                            basketDetail?.basket?.subscribe?.offer?.type,
                            basketDetail?.basket?.subscribe?.offer?.offer,
                            basketDetail?.basket?.subscribe?.price
                          )}
                          {basketDetail?.basket?.subscribe?.offer?.offer && (
                            <del className="ml-2 text-red-500">
                              {basketDetail?.basket?.subscribe?.price}
                            </del>
                          )}
                        </span>
                        &nbsp; تومان
                      </span>
                    </section>
                  </section>
                </section>
                <section className="flex justify-between items-center w-full border-t border-t-gray-300 mt-4 pt-2">
                  <section className="w-6/12 pl-2">
                    <button
                      className="w-full py-3 text-white my-2 bg-indigo-600 rounded-lg"
                      onClick={() => navigate('/list-subscription')}
                    >
                      تغییر اشتراک
                    </button>
                  </section>
                  <section className="w-6/12 pr-2">
                    <button
                      onClick={() => {
                        RemoveFromBasket(null, basketDetail?.basket?.subscribe?.id)
                      }}
                      className="w-full border py-3 text-red-600 border-gray-300 my-2 rounded-lg"
                    >
                      حذف اشتراک
                    </button>
                  </section>
                </section>
              </div>
            </div>
          )}
          {basketDetail?.basket?.album &&
            basketDetail?.basket?.album.length > 0 &&
            basketDetail?.basket?.album.map((bs) => (
              <div key={bs?.album?.id} className="w-full ">
                <div className="flex flex-col pb-4 bg-white py-3 px-2 rounded-md mt-4 shadow-box">
                  <section className="flex justify-start items-center">
                    <section className="w-16 h-16 bg-[#E2664C] flex justify-center items-center rounded-full ml-4">
                      <BsHandbag className="text-3xl text-white" />
                    </section>
                    <section className="w-10/12">
                      <section className="flex justify-between items-center">
                        <p>{bs?.name}</p>
                      </section>
                      <section className="flex justify-between items-center text-sm text-gray-400 w-6/12 mt-1">
                        <span>
                          <span className="text-gray-800 text-base">
                            {calculateDiscount(bs?.offer?.type, bs?.offer?.offer, bs?.price)}
                            {bs?.offer?.offer && (
                              <del className="ml-2 text-red-500">{bs?.price}</del>
                            )}
                          </span>
                          &nbsp; تومان
                        </span>
                      </section>
                    </section>
                  </section>
                  <section className="flex justify-between items-center w-full border-t border-t-gray-300 mt-4 pt-2">
                    <section className="w-6/12 pl-2">
                      <button
                        className="w-full py-3 text-white my-2 bg-indigo-600 rounded-lg"
                        onClick={() => navigate('/album-collection')}
                      >
                        ادامه خرید
                      </button>
                    </section>
                    <section className="w-6/12 pr-2">
                      <button
                        onClick={() => {
                          RemoveAlbum(bs?.id)
                        }}
                        className="w-full border py-3 text-red-600 border-gray-300 my-2 rounded-lg"
                      >
                        حذف آلبوم
                      </button>
                    </section>
                  </section>
                </div>
              </div>
            ))}
          {basketDetail?.basket?.workshop.map((wrk) => (
            <div key={wrk.id} className="w-full ">
              <div className="flex flex-col pb-4 bg-white py-3 px-2 rounded-md mt-4 shadow-box">
                <section className="flex justify-start items-center">
                  <section className="w-16 h-16 bg-[#E2664C] flex justify-center items-center rounded-full ml-4">
                    <BsHandbag className="text-3xl text-white" />
                  </section>
                  <section className="w-10/12">
                    <section className="flex justify-between items-center">
                      <p>کارگاه {wrk.name}</p>
                    </section>
                    <section className="flex justify-between items-center text-sm text-gray-400 w-6/12 mt-1">
                      <span>
                        <span className="text-gray-800 text-base">
                          {calculateDiscount(wrk?.offer?.type, wrk?.offer?.offer, wrk?.price)}
                          {wrk?.offer?.offer && (
                            <del className="ml-2 text-red-500">{wrk.price}</del>
                          )}
                        </span>
                        &nbsp; تومان
                      </span>
                    </section>
                  </section>
                </section>
                <section className="flex justify-between items-center w-full border-t border-t-gray-300 mt-4 pt-2">
                  <section className="w-6/12 pl-2">
                    <button
                      className="w-full py-3 text-white my-2 bg-indigo-600 rounded-lg"
                      onClick={() => navigate('/workshop?status=presence')}
                    >
                      ادامه خرید
                    </button>
                  </section>
                  <section className="w-6/12 pr-2">
                    <button
                      onClick={() => {
                        RemoveFromBasket(wrk.id, null)
                      }}
                      className="w-full border py-3 text-red-600 border-gray-300 my-2 rounded-lg"
                    >
                      حذف کارگاه
                    </button>
                  </section>
                </section>
              </div>
            </div>
          ))}

          {basketPrice === null ? (
            <div className="border rounded-lg border-gray-300 w-full px-4 mt-5 mb-10">
              <section className="w-full flex justify-between items-center py-2 my-2 border-b border-gray-400 pb-4">
                <span>
                  مجموع
                  <span>({basketDetail?.count ? basketDetail?.count : 0})</span>
                </span>
                <span className="text-gray-400">
                  <span className="text-gray-800 text-base">{basketDetail?.price}</span>
                  &nbsp; تومان
                </span>
              </section>
              <section className="w-full flex justify-between items-center py-2 my-2 border-b border-gray-400 pb-4">
                <span>مالیات</span>
                <span className="text-gray-400">
                  <span className="text-gray-800 text-base">0۰.۰۰۰</span>
                  &nbsp; تومان
                </span>
              </section>
              <section className="w-full flex flex-wrap justify-between items-center py-2 my-2 ">
                <span className="pb-4">تخفیف</span>
                <span className="text-gray-400 pb-4">
                  <span className="text-gray-800 text-base">
                    {isNaN(basketDetail?.basket?.price - basketDetail?.basket?.total)
                      ? 0
                      : basketDetail?.basket?.price - basketDetail?.basket?.total}
                  </span>
                </span>

                <div className="w-full relative">
                  <input
                    type="text"
                    className="w-full border rounded-xl border-red-500 text-sm py-4 px-3"
                    placeholder="لطفا کد تخفیف را وارد کنید"
                    onChange={(e) => setCode(e.target.value)}
                    defaultValue={code}
                  />
                  <button
                    className={` w-28 text-sm h-10 text-white bg-indigo-600 rounded-lg absolute top-1.5 left-2`}
                    disabled={offerLoading}
                    onClick={() => {
                      SubmitOffer()
                    }}
                  >
                    {offerLoading ? <Loading color="#fff" width="30px" /> : ' اعمال تخفیف'}
                  </button>
                </div>
              </section>
              <section className="w-full flex justify-between items-center py-2 my-2 ">
                <span>مبلغ کل پرداختی</span>
                <span>
                  <span className="text-gray-800 text-base">{basketDetail?.basket?.total}</span>
                  &nbsp; تومان
                </span>
              </section>
              <section className="w-full">
                <button
                  disabled={urlLoading}
                  className={`w-full py-3 text-white my-2 bg-indigo-600 rounded-lg ${
                    urlLoading && 'opacity-70'
                  }`}
                  onClick={BuyBasket}
                >
                  {urlLoading ? 'درحال رفتن به درگاه پرداخت' : 'پرداخت'}
                </button>
              </section>
            </div>
          ) : (
            <div className="border rounded-lg border-gray-300 w-full px-4 mt-5 mb-10">
              <section className="w-full flex justify-between items-center py-2 my-2 border-b border-gray-400 pb-4">
                <span>
                  مجموع
                  <span>({basketDetail?.count ? basketDetail?.count : 0})</span>
                </span>
                <span className="text-gray-400">
                  <span className="text-gray-800 text-base">{basketDetail?.price}</span>
                  &nbsp; تومان
                </span>
              </section>
              <section className="w-full flex justify-between items-center py-2 my-2 border-b border-gray-400 pb-4">
                <span>مالیات</span>
                <span className="text-gray-400">
                  <span className="text-gray-800 text-base">0۰.۰۰۰</span>
                  &nbsp; تومان
                </span>
              </section>
              <section className="w-full flex flex-wrap justify-between items-center py-2 my-2 ">
                <span className="pb-4">تخفیف</span>
                <span className="text-gray-400 pb-4">
                  <span className="text-gray-800 text-base">
                    {isNaN(basketPrice?.price - basketPrice?.total)
                      ? 0
                      : basketPrice?.price - basketPrice?.total}
                  </span>
                </span>

                <div className="w-full relative">
                  <input
                    type="text"
                    className="w-full border rounded-xl border-red-500 text-sm py-4 px-3"
                    placeholder="لطفا کد تخفیف را وارد کنید"
                    onChange={(e) => setCode(e.target.value)}
                    defaultValue={code}
                  />
                  <button
                    className={` w-28 text-sm h-10 text-white bg-indigo-600 rounded-lg absolute top-1.5 left-2`}
                    disabled={offerLoading}
                    onClick={() => {
                      SubmitOffer()
                    }}
                  >
                    {offerLoading ? <Loading color="#fff" width="30px" /> : ' اعمال تخفیف'}
                  </button>
                </div>
              </section>
              <section className="w-full flex justify-between items-center py-2 my-2 ">
                <span>مبلغ کل پرداختی</span>
                <span>
                  <span className="text-gray-800 text-base">{basketDetail?.basket?.total}</span>
                  &nbsp; تومان
                </span>
              </section>
              <section className="w-full">
                <button
                  disabled={urlLoading}
                  className={`w-full py-3 text-white my-2 bg-indigo-600 rounded-lg ${
                    urlLoading && 'opacity-70'
                  }`}
                  onClick={BuyBasket}
                >
                  {urlLoading ? 'درحال رفتن به درگاه پرداخت' : 'پرداخت'}
                </button>
              </section>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default Basket
