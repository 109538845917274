import ToadyActivities from '../../toady-activities'

function Child() {
  return (
    <>
      <ToadyActivities />
    </>
  )
}
export default Child
