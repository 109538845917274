import Header from '../../../components/detail-header'
import { FiPlusCircle } from 'react-icons/fi'
import { VscSearch } from 'react-icons/vsc'
import { useState } from 'react'
import Card from './component/card'
import DoctorFooter from '../footer'
function DoctorHistory() {
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState('')
  return (
    <>
      <Header title="تاریخچه پزشکی" />
      <div className="w-full pb-32">
        <div className="w-full flex justify-between items-center px-4 my-5">
          <span className="text-lg text-gray-700">تاریخچه پزشکی</span>
          <span className="w-10 h-10 bg-blue-300 rounded flex justify-center items-center">
            <FiPlusCircle className="text-[#3F54CB] text-2xl" />
          </span>
        </div>
        <div className="w-full grid grid-cols-3 gap-4 px-4">
          <section className="w-full flex justify-center items-center rounded-full h-10 border-2 border-[#3F54CB] bg-[#3F54CB] ">
            <p className="text-white">جلسه تصویری</p>
          </section>
          <section className="w-full  flex justify-center items-center rounded-full h-10 border-2 border-[#3F54CB] ">
            <p className="text-[#3F54CB]">جلسه تصویری</p>
          </section>
          <section className="w-full  flex justify-center items-center rounded-full h-10 border-2 border-[#3F54CB] ">
            <p className="text-[#3F54CB]">جلسه تصویری</p>
          </section>
        </div>
        <div className="w-full px-4 my-5">
          <section className="w-full relative">
            <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
            <input
              className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
              placeholder="نام پزشک  خود را جستجو کنید..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </section>
        </div>
        <div className="w-full px-4">
          <section className="text-md text-gray-700 flex justify-start items-center">
            <p>دیروز</p>,<p>2 استفند 1402</p>
          </section>
          <Card />
        </div>
      </div>
      <DoctorFooter />
    </>
  )
}
export default DoctorHistory
