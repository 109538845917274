import Banner from './component/banner/'
import Subject from './component/subject'
import ChooseCoach from './component/choose-coach'
import ProfileComplete from './component/profile'
import MainSection from './component/most-sales-book'
import axios from 'axios'
import {
  AnimationSectionAPI,
  BasketAPI,
  LocationSectionAPI,
  PodcastSectionAPI
} from '../../../../../api'
import { useEffect, useState } from 'react'
import SvgChooseCoach from './assets/slide2.svg'
import SvgCoockie from './assets/cooking2-banner.svg'
import SvgStore from './assets/store2-banner.svg'
import SvgStore2 from './assets/Frame 23.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

function Home() {
  const childInfo = useSelector((state) => state.childInfo)
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const Authority = searchParams.get('Authority')
  const Status = searchParams.get('Status')
  const [locations, setLocations] = useState([])
  const [podcasts, setPodcasts] = useState([])
  const [animations, setAnimations] = useState([])
  const LocationSection = () => {
    axios
      .get(LocationSectionAPI + '/first', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setLocations(data)
      })
  }

  const podcastSection = () => {
    axios
      .get(PodcastSectionAPI + '/first', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setPodcasts(data)
      })
  }

  const animationSection = () => {
    axios
      .get(AnimationSectionAPI + '/first', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setAnimations(data)
      })
  }

  useEffect(() => {
    LocationSection()
    podcastSection()
    animationSection()
  }, [])

  const transactionFunc = () => {
    const formData = new FormData()
    formData.append('authority', Authority)
    formData.append('child_id', childInfo?.id)
    axios
      .post(BasketAPI + '/check', formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        toast.success('خرید شما با موفقیت ثبت شد')
        navigate('/')
      })
  }

  useEffect(() => {
    if (Authority && Status === 'OK') {
      transactionFunc()
    }
  }, [])

  return (
    <>
      <div className="w-full pb-20 ">
        <div className="w-full px-4">
          <ProfileComplete />

          <Banner />
        </div>
        <Subject />
        <div className="w-full px-4">
          <ChooseCoach
            title="انتخاب مشاور دلخواه"
            link={'/coach-list'}
            src={[SvgChooseCoach]}
            color="#c92828"
          />
          {locations && locations.title && (
            <MainSection
              title={locations.title}
              link="/collection-place"
              subLink="/detail-place?id="
              data={locations?.location}
              type="location"
            />
          )}
          <ChooseCoach
            title="خرید از فروشگاه ماتیار با تخفیف"
            link={'/coach-list'}
            src={[SvgStore]}
            color="#e0680b"
          />
          {podcasts && podcasts.title && (
            <MainSection
              title={podcasts.title}
              link="/main-podcast"
              subLink="/podcast-playlist?id="
              data={podcasts?.podcast}
              type="podcast"
            />
          )}
          <ChooseCoach
            title="آشپزی راحت با ماتیار"
            link={'/food-list'}
            src={[SvgCoockie, SvgStore2]}
            color="#5bc404"
          />

          {animations && animations.title && (
            <MainSection
              title={animations.title}
              link="/animation-group"
              subLink="/animation-detail?id="
              data={animations?.animation}
              type="animation"
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Home
