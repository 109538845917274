import PicBox from './component/box'
import './coach-detail-team.css'
import Comment from './component/comment'
import Header from '../../components/detail-header'
import Members from './component/members'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Domain, TeamAPI, TeamDetail } from '../../../../api'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import PopUp from '../../components/pop-up/comment'
import Loading from '../../../../component/loading'
import { useSelector } from 'react-redux'
import VideoModal from '../../components/video-modal'

function CoachDetailTeam() {
  const location = useLocation()
  const childInfo = useSelector((state) => state.childInfo)
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [teamDetail, setTeamDetail] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const getTeamDetail = () => {
    setLoading(true)
    axios
      .get(TeamDetail + `?id=${id}&child_id=${childInfo.id}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setTeamDetail(data)

        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message)
      })
  }

  useEffect(() => {
    getTeamDetail()
  }, [])
  const AgeRange = (daysDifference) => {
    if (daysDifference <= 180) {
      return 'نوزاد'
    } else if (daysDifference <= 720) {
      return 'نوپا'
    } else if (daysDifference <= 2520) {
      return 'خردسال'
    }
  }

  return (
    <>
      <PopUp
        setShowMessage={setShowForm}
        showMessage={showForm}
        titleMessage="اشتراک شما به پایان رسیده است"
        userTeam="تست"
        API={TeamAPI + 'comment'}
        id={teamDetail.id}
        body={`  لطفا برای کمک به بهبود آموزش ها و تخصص تیم
               ${teamDetail.title}   
              نظر و امتیاز خود را ثبت کنید `}
      />
      <Header title="جزییات تیم" />
      <div className="hidden">
        <VideoModal
          videoUrl={Domain + teamDetail?.video}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      </div>
      <div className="relative mt-5">
        {loading ? (
          <Loading color="#1e40af" />
        ) : (
          <>
            <div className="px-4 ">
              <PicBox
                videoUrl={Domain + teamDetail?.video}
                setShowModal={setShowModal}
                showModal={showModal}
                userAlreadyJoin={teamDetail.hasUserTeam}
                src={Domain + teamDetail.cover}
                ID={teamDetail.id}
                title={teamDetail.title}
                ageDetail={`مناسب برای رده سنی ${AgeRange(teamDetail.area_expertise)}`}
                commentCount={teamDetail?.commentCount}
                avgRate={
                  teamDetail?.commentCount == 0
                    ? 0
                    : Math.round(teamDetail?.rateCount / teamDetail?.commentCount)
                }
              />
            </div>

            <section className="vazir-medium px-4   ">
              <p>درباره ی تیم</p>

              <div
                className="text-justify text-gray-400 text-sm px-3 mt-2"
                dangerouslySetInnerHTML={{
                  __html: teamDetail.about
                }}
              />
            </section>
            <Members members={teamDetail.coaches} />
            <section className="px-4 mt-10">
              <p className="vazir-medium">دوره ها و گواهی نامه ها</p>
              <div className="grid grid-cols-3 gap-2">
                {teamDetail && teamDetail.img1 && teamDetail.img1.length > 0 && (
                  <section className="w-full h-28 my-1">
                    <div
                      className="w-full h-full bg-gray-200 rounded-xl"
                      style={{
                        backgroundImage: `url("${Domain + teamDetail.img1}")`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                      }}
                    />
                  </section>
                )}

                {teamDetail && teamDetail.img2 && teamDetail.img2.length > 0 && (
                  <section className="w-full h-28 my-1">
                    <div
                      className="w-full h-full bg-gray-200 rounded-xl"
                      style={{
                        backgroundImage: `url("${Domain + teamDetail.img2}")`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                      }}
                    />
                  </section>
                )}

                {teamDetail && teamDetail.img3 && teamDetail.img3.length > 0 && (
                  <section className="w-full h-28 my-1">
                    <div
                      className="w-full h-full bg-gray-200 rounded-xl"
                      style={{
                        backgroundImage: `url("${Domain + teamDetail.img3}")`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                      }}
                    />
                  </section>
                )}

                {teamDetail && teamDetail.img4 && teamDetail.img4.length > 0 && (
                  <section className="w-full h-28 my-1">
                    <div
                      className="w-full h-full bg-gray-200 rounded-xl"
                      style={{
                        backgroundImage: `url("${Domain + teamDetail.img4}")`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                      }}
                    />
                  </section>
                )}
              </div>
            </section>

            <Comment
              count={teamDetail.commentCount}
              rate={teamDetail.rateCount}
              id={teamDetail.id}
              API={TeamAPI + 'comment/user?id='}
              type="team"
              setShowForm={() => setShowForm(true)}
              showForm={showForm}
            />
          </>
        )}
      </div>
    </>
  )
}

export default CoachDetailTeam
