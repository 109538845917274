// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
// import required modules
import { FreeMode } from 'swiper'

import { IoIosArrowBack } from 'react-icons/io'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import axios from 'axios'
import CommentItem from '../../../comment/component/comment'
import CommentButton from '../../../../../../component/button/commnet'
import { useSelector } from 'react-redux'
export default function Comment({ API, id, type, count, rate, setShowForm, showForm }) {
  const childInfo = useSelector((state) => state.childInfo)
  const navigate = useNavigate()
  const [userComment, setUserComment] = useState(false)
  const [comments, setComments] = useState([])
  const get10Comment = () => {
    axios
      .get(API + id + `&childId=${childInfo?.id}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setComments(data?.AllComment)
        setUserComment(data?.userComment)
      })
  }

  useEffect(() => {
    if (id) {
      get10Comment()
    }
  }, [id, showForm])
  return (
    <div>
      <section className=" mt-10 vazir-medium">
        <section className="flex justify-between items-center mb-3 px-4">
          <p>دیدگاه کاربران</p>
          <section
            className="flex justify-end items-center text-xs text-red-400"
            onClick={() =>
              navigate(
                `/list-comment?id=${id}&type=${type}&commentCount=${count}&rateCount=${rate}`
              )
            }
          >
            <span>{count} نظر</span>
            <IoIosArrowBack />
          </section>
        </section>
        <Swiper
          slidesPerView={1.1}
          spaceBetween={5}
          freeMode={true}
          grabCursor={true}
          modules={[FreeMode]}
          className="mySwiper"
        >
          {Array.isArray(comments) &&
            comments?.map((comment) => (
              <SwiperSlide key={comment.id} className="rounded-xl px-0.5 py-2">
                <CommentItem comment={comment} />
              </SwiperSlide>
            ))}
        </Swiper>
      </section>
      {userComment ? null : (
        <div className="w-full px-4 pb-20 mt-5">
          <CommentButton userHasComment={false} setShowForm={() => setShowForm()} />
        </div>
      )}
    </div>
  )
}
