import { Domain } from '../../../../../../../api'
import { AiFillStar } from 'react-icons/ai'
import { FaPause } from 'react-icons/fa'
import Polygon from '../../../add-lullaby/svg/Polygon.svg'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CiLock } from 'react-icons/ci'

// eslint-disable-next-line no-unused-vars
function Card({ lul, currentAudio, setCurrentAudio }) {
  const navigate = useNavigate()
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(null)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [interVal, setInterVal] = useState(null)

  useEffect(() => {
    const audioElement = audioRef.current

    const handleLoadedMetadata = () => {
      setDuration(audioElement.duration)
    }

    const handleTimeUpdate = () => {
      if (audioElement && audioElement.currentTime) {
        setCurrentTime(audioElement.currentTime)
      }
    }

    audioElement.addEventListener('loadedmetadata', handleLoadedMetadata)
    audioElement.addEventListener('timeupdate', handleTimeUpdate)

    return () => {
      audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
      audioElement.removeEventListener('timeupdate', handleTimeUpdate)
    }
  }, [currentAudio])
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0')
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, '0')
    return `${minutes}:${seconds}`
  }

  const handleTimeUpdate = () => {
    if (audioRef?.current?.currentTime) {
      setCurrentTime(audioRef.current.currentTime)
    }
  }

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (!isPlaying) {
        if (currentAudio && currentAudio !== audioRef?.current) {
          audioRef?.current?.play()
          currentAudio?.pause()
          setIsPlaying(true)
          setInterval(() => {
            handleTimeUpdate()
          }, 100)
          setCurrentAudio(audioRef?.current)
        } else {
          audioRef.current.play()
          setIsPlaying(true)
          setInterval(() => {
            handleTimeUpdate()
          }, 100)
          setCurrentAudio(audioRef?.current)
        }
      } else {
        audioRef.current.pause()
        setIsPlaying(false)
        clearInterval(interVal)
        setInterVal(null)
      }
    }
  }

  const totalTime = formatTime(duration)

  return (
    <div
      key={lul.id}
      className="w-full flex justify-between items-center my-2 border-b border-b-gray-300 pb-2"
    >
      <div className="flex justify-start items-center">
        {lul?.isFree === false ? (
          <section className="rounded-xl h-20 w-20 ml-2 bg-gray-600 flex justify-center items-center">
            <CiLock className="text-white text-3xl" />
          </section>
        ) : (
          <section
            className="rounded-xl h-20 w-20 ml-2"
            style={{
              backgroundImage: `url("${Domain + lul.picture}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          />
        )}

        <div className="flex flex-col justify-start items-start">
          <p
            className="text-gray-700 text-sm"
            onClick={() => {
              if (lul.isFree === false) {
                navigate('/buy-subscription')
              } else {
                navigate('/detail-lullaby?id=' + lul.id)
              }
            }}
          >
            {lul.title}
          </p>
          <span className="text-gray-500 text-xs my-2">{lul.writer}</span>
          <section className="flex justify-start items-center">
            <AiFillStar className="text-yellow-400 ml-1" />
            <span className="text-gray-600 text-xs">
              {isNaN(Math.ceil(lul.rateCount / lul.commentCount))
                ? 0
                : Math.ceil(lul.rateCount / lul.commentCount)}
            </span>
            <span className="text-gray-600 text-[10px] relative -top-2 right-1">
              ({lul.commentCount})
            </span>
          </section>
        </div>
      </div>
      <audio controls ref={audioRef} className="hidden">
        <source src={Domain + lul.voice} />
      </audio>
      <div className="flex justify-end flex-row-reverse items-center">
        {lul?.isFree === false ? (
          <section className="w-8 h-8 rounded-full bg-blue-300 flex justify-center items-center cursor-pointer mr-5">
            <CiLock className="text-xl text-blue-600" />
          </section>
        ) : currentAudio && currentAudio === audioRef?.current && isPlaying ? (
          <section
            className="w-8 h-8 rounded-full bg-blue-300 flex justify-center items-center cursor-pointer mr-5"
            onClick={() => {
              togglePlayPause()
            }}
          >
            <FaPause className="text-xl text-blue-600" />
          </section>
        ) : (
          <img
            src={Polygon}
            alt="polygon"
            className="w-8 h-8 bg-blue-200 rounded-full p-2 cursor-pointer mr-5"
            onClick={() => {
              togglePlayPause()
            }}
          />
        )}

        <audio ref={audioRef} src={Domain + lul.voice} preload="metadata" />
        <p className="text-xs text-gray-700">
          <span>
            {formatTime(currentTime)} / {lul?.duration != null ? lul?.duration : totalTime}
          </span>
        </p>
      </div>
    </div>
  )
}

export default Card
