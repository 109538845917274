import { Domain } from '../../../../../../../../api'
import { useNavigate } from 'react-router-dom'

function Card({ data }) {
  const navigate = useNavigate()
  return (
    <>
      <div className="w-full mt-2 " onClick={() => navigate('/podcast-playlist?id=' + data.id)}>
        <section
          className="w-full h-32 rounded-xl"
          style={{
            backgroundImage: `url("${Domain + data.cover}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
        <p className="text-xs text-gray-800 my-2">{data.title}</p>
        <p className="text-xs text-gray-600">
          تعداد اپیزود: <span>{data.episodeCount}</span>
        </p>
      </div>
    </>
  )
}
export default Card
