import ReactApexChart from 'react-apexcharts'

const ApexChartLine = ({ data }) => {
  return (
    <div id="chart">
      {data && data.options && data.series && data.options.chart && data.options.chart.type ? (
        <ReactApexChart
          options={data.options}
          series={data.series}
          type={data.options.chart.type}
          height={250}
        />
      ) : null}
    </div>
  )
}

export default ApexChartLine
