import Header from '../../components/profile-header'
import { HiHome } from 'react-icons/hi'
import { MdChildCare } from 'react-icons/md'
import { IoDiamond } from 'react-icons/io5'
import './main.css'
import Home from './home'
import Child from './child'
import Subscribe from './subscribe'
import Footer from '../../components/footer'
import { useLocation, useNavigate } from 'react-router-dom'

function Main() {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const status = searchParams.get('status')
  const StatusComponent = () => {
    if (status == 'home' || status == null) {
      return <Home />
    }
    if (status === 'child') {
      return <Child />
    }
    if (status === 'subscribe') {
      return <Subscribe />
    }
  }

  return (
    <>
      <Header />
      <div className="w-full rounded-t-2xl  bg-white relative -top-4  ">
        <div className="w-full flex justify-between items-center pt-2 mb-4">
          <section
            onClick={() => {
              navigate('/?status=home')
            }}
            className={`w-4/12 flex flex-col justify-center items-center text-gray-500 ${
              status === 'home' || status == null ? 'main-nav-active' : null
            } `}
          >
            <HiHome className="text-2xl " />
            <span className="mt-1">خانه</span>
          </section>

          <section
            onClick={() => {
              navigate('?status=' + 'child')
            }}
            className={`w-4/12 flex flex-col justify-center items-center text-gray-500 ${
              status === 'child' ? 'main-nav-active' : null
            } `}
          >
            <MdChildCare className="text-2xl" />
            <span className="mt-1">کودک من</span>
          </section>

          <section
            onClick={() => {
              navigate('?status=' + 'subscribe')
            }}
            className={`w-4/12 flex flex-col justify-center items-center text-gray-500 ${
              status === 'subscribe' ? 'main-nav-active' : null
            } `}
          >
            <IoDiamond className="text-2xl" />
            <span className="mt-1">اشتراک ها</span>
          </section>
        </div>
        <StatusComponent />
        <Footer />
      </div>
    </>
  )
}

export default Main
