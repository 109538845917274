import { VscSearch } from 'react-icons/vsc'
import HeaderAlbum from '../../album/photo-album/component/header'

function DoctorCategory() {
  return (
    <>
      <HeaderAlbum title="دسته بندی" />
      <div className="w-full">
        <section className="w-full relative">
          <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
          <input
            className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
            placeholder="دسته بندی خود را جستجو کنید..."
          />
        </section>

        <p className="my-5">لطفا دسته بندی خود را انتخا کنید</p>
        <div className="w-full grid grid-cols-3 gap-4 px-4">
          <section className="w-full flex justify-center items-center bg-blue-300">
            <span>ICON</span>
          </section>
        </div>
      </div>
    </>
  )
}
export default DoctorCategory
