import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import logger from 'redux-logger'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // Use session storage
import information from './info-slice'
import childInfo from './child-slice'
import coachInfo from './coach-slice'
import childCoach from './child-coach-slice'
import AlbumSetting from './album-slice'
import Basket from './basket-slice'

const reducers = combineReducers({
  information: information,
  childInfo: childInfo,
  coachInfo: coachInfo,
  childCoach: childCoach,
  albumSetting: AlbumSetting,
  basket: Basket
})

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'production') {
      return getDefaultMiddleware()
    }
    return getDefaultMiddleware().concat(logger)
  }
})

export const persist = persistStore(store) // This is used for persisting the state

export default { store, persist }
