import ReactApexChart from 'react-apexcharts'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { GetActivitiesDone } from '../../../../../../api'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

const ChartActivities = () => {
  const childInfo = useSelector((state) => state.childInfo)
  const [data, setDate] = useState({})
  const getActivitiesDone = () => {
    axios
      .get(GetActivitiesDone + '?id=' + childInfo.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setDate(data)
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  useEffect(() => {
    getActivitiesDone()
  }, [])
  return (
    <div id="chart">
      {data && data.series && data.options && data.options.chart && data.options.chart.type ? (
        <ReactApexChart options={data.options} series={data.series} type="bar" height={350} />
      ) : null}
    </div>
  )
}

export default ChartActivities
