import { IoIosArrowBack } from 'react-icons/io'
import { IoIosStar } from 'react-icons/io'
import { Domain } from '../../../../../api'
import { useLocation, useNavigate } from 'react-router-dom'

function Card({ data }) {
  const location = useLocation().pathname
  const navigate = useNavigate()
  return (
    <>
      <div className="w-full rounded-xl shadow-md flex justify-between items-center pl-2 mt-4 border border-gray-300">
        <section
          className="w-32 h-32 rounded-br-xl rounded-tr-xl"
          style={{
            backgroundImage: `url("${Domain + data?.profile}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
        <div className="flex flex-col justify-between items-start text-sm">
          <p className="text-gray-700">{data?.name}</p>
          <p className="text-gray-600 text-md flex justify-start items-start my-3">
            {data?.category?.title}
          </p>
          <section className="flex justify-start items-center text-gray-600 text-xs">
            <IoIosStar className="text-[#3F54CB]" />(<span>{data?.commentCount} نظر</span>) -{' '}
            <span>{data?.visitCount}</span> مشاور آنلاین
          </section>
        </div>

        <section
          className={`w-7 h-7 rounded ${
            !location == '/doctor-detail' && 'bg-blue-300'
          }  flex justify-center items-center`}
        >
          {location == '/doctor-detail' ? null : (
            <>
              <IoIosArrowBack
                className="text-xl text-blue-600"
                onClick={() => {
                  navigate('/doctor-detail?id=' + data?.id)
                }}
              />
            </>
          )}
        </section>
      </div>
    </>
  )
}
export default Card
