import { VscSearch } from 'react-icons/vsc'
import HeaderAlbum from '../../album/photo-album/component/header'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { DoctorByCategoryAPI } from '../../../../../api'

import Card from '../detail/card'

function DoctorList() {
  const [search, setSearch] = useState('')
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [doctorList, setDoctorList] = useState([])
  const listInnerRef = useRef(null)
  const [page, setPage] = useState(1)

  const getDoctorByCategory = (Id = id, Search = search, Page = page) => {
    axios
      .get(DoctorByCategoryAPI + `?catId=${Id}&search=${Search}&page=${Page}&limit=20`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        if (Page === 1) {
          setDoctorList(data)
        } else {
          if (data.data && data.data.length > 0) {
            setDoctorList([...doctorList, ...data.data])
          }
        }
      })
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
        getDoctorByCategory(id, search, page + 1)
      }
    }
  }

  useEffect(() => {
    getDoctorByCategory()
  }, [])
  return (
    <>
      <HeaderAlbum title="لیست پزشکان" />
      <div className="w-full px-4">
        <div className="w-full my-5">
          <section className="w-full relative">
            <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
            <input
              className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
              placeholder="نام پزشک  خود را جستجو کنید..."
              onChange={(e) => {
                setSearch(e.target.value)
                getDoctorByCategory(id, e.target.value, 1)
                setPage(1)
              }}
            />
          </section>
        </div>

        <div
          ref={listInnerRef}
          onScroll={onScroll}
          className="grid grid-cols-1 gap-4   h-screen overflow-hidden overflow-y-scroll content-start"
        >
          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}

          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}

          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}
          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}
          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}
          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}

          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}

          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}

          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}

          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}
          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}
          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}
          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}
          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}

          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}
          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}
          {doctorList.map((doc) => (
            <Card data={doc} key={doc.id} />
          ))}
        </div>
      </div>
    </>
  )
}

export default DoctorList
