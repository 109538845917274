import BackgroundImageTest from './assets/Mask group.png'
import { MdKeyboardArrowRight } from 'react-icons/md'
import './play-list.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { BsDownload, BsPlayCircleFill } from 'react-icons/bs'
import { HiOutlineDotsCircleHorizontal } from 'react-icons/hi'
import { AiFillHeart } from 'react-icons/ai'
import Heart from '../sound-play/assets/heart2.svg'
import { useEffect, useRef, useState } from 'react'
import Episode from './episode'
import axios from 'axios'
import { Domain, EpisodeAPI, PodcastAPI } from '../../../../../api'
import { useSelector } from 'react-redux'
import Subscription from '../../buy-subscription/svg/subscription-model.svg'
import HeaderAlbum from '../../album/photo-album/component/header'
import Loading from '../../../../../component/loading'
function PodcastPlayList() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [like, setLike] = useState(false)
  const [likeCount, setLikeCount] = useState(0)
  const [detail, setDetail] = useState({})
  const [episodes, setEpisodes] = useState([])
  const [page, setPage] = useState(1)
  const listInnerRef = useRef()
  const childInfo = useSelector((state) => state.childInfo)
  const [currentEpisode, setCurrentEpisode] = useState(null)
  const [needSub, setNeedSub] = useState(false)

  const addView = () => {
    axios
      .get(PodcastAPI + '/view/' + id + '?childId=' + childInfo.id, {
        headers: { Authorization: `Brear ${localStorage.getItem('token')}` }
      })
      .then((res) => res.data)
      .then(() => {})
  }
  const getPodcastDetail = () => {
    setLoading(true)
    axios
      .get(PodcastAPI + '/' + id + '?childId=' + childInfo?.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setLoading(false)
        setDetail(data?.podcast)
        setLike(data?.liked)
        setLikeCount(data?.like)
      })
      .catch((e) => {
        setLoading(false)
        if (e.response.data === 'subscribe') {
          setNeedSub(true)
        }
        if (e.response.data === 'child') {
          console.log('child')
        }
      })
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current

      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }

  const getEpisode = () => {
    axios
      .get(EpisodeAPI + `?podId=${id}&page=${page}&search=&limit=10`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        if (page == 1) {
          setEpisodes(data.episodes)
        } else {
          if (data.episodes.length > 0) {
            setEpisodes([...episodes, ...data.episodes])
          }
        }
      })
  }

  const createPostLike = () => {
    axios
      .get(PodcastAPI + `/like?podcast_id=${id}&child_id=${childInfo.id}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setLikeCount(data)
      })
      .catch(() => {})
  }

  useEffect(() => {
    getPodcastDetail()
  }, [])

  useEffect(() => {
    getEpisode()
  }, [page])
  return (
    <div className="h-screen overflow-hidden">
      {loading ? (
        <Loading color="#1e40af" width="100px" />
      ) : (
        <>
          {needSub ? (
            <>
              <HeaderAlbum title="پادکست" />
              <div className="flex justify-center items-center flex-wrap vazir-medium mt-14 px-4">
                <div className="w-48 h-48 bg-blue-200 rounded-full flex justify-center items-center">
                  <img src={Subscription} alt="buy subscription matyar" />
                </div>
                <div className="w-full flex flex-col justify-center items-center flex-wrap mt-5">
                  <p className="text-lg text-center"> شما اشتراک فعالی ندارید</p>
                  <p className="text-sm text-gray-500 text-center w-8/12 mt-3">
                    شما اشتراک فعالی ندارید برای دیدن این پادکست باید اشتراکی خریداری کنید
                  </p>
                </div>

                <button
                  className="w-full bg-[#3F54CB] text-white rounded-lg py-4 vazir-medium mt-14"
                  onClick={() => navigate('/list-subscription')}
                >
                  خرید اشتراک
                </button>
              </div>
            </>
          ) : (
            detail && (
              <>
                <div className="w-full relative h-[30vh]">
                  <div className="w-full flex justify-between items-center z-[20] relative pt-5">
                    <MdKeyboardArrowRight
                      className="text-3xl text-white relative right-3"
                      onClick={() => navigate(-1)}
                    />
                    <span className="text-white text-xl w-full text-center">{detail?.title}</span>
                  </div>
                  <div className="backgroundPlayList absolute top-0 left-0 h-full w-full z-[10]" />
                  <img
                    src={BackgroundImageTest}
                    alt="background podcast matyar"
                    className="absolute top-0 left-0 h-full w-full"
                  />
                </div>
                <div className="w-full flex justify-center items-center flex-wrap z-[50] relative -top-28">
                  <section
                    className="w-40 h-40 relative z-[50]   border-4 border-white rounded-xl"
                    style={{
                      backgroundImage: `url("${Domain + detail?.cover}")`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}
                  />
                  <p className="w-full text-center mt-2">{detail?.title}</p>
                  <p className="w-full text-center text-gray-600 mt-2 text-sm">
                    {detail?.description}
                  </p>
                </div>
                <div className="relative -top-24">
                  <div className="w-full flex justify-evenly items-center">
                    <section className="w-4/12 flex flex-col justify-center items-center  ">
                      <span>{likeCount}</span>
                      <span className="text-gray-500 text-sm">پسندیده ها</span>
                    </section>
                    <span className="w-[1px] h-[30px] bg-gray-300" />
                    <section className="w-4/12 flex flex-col justify-center items-center">
                      <span>{detail?.episodeCount}</span>
                      <span className="text-gray-500 text-sm">اپیزود</span>
                    </section>
                    <span className="w-[1px] h-[30px] bg-gray-300" />
                    <section className="w-4/12 flex flex-col justify-center items-center  ">
                      <span>{detail?.viewCount}</span>
                      <span className="text-gray-500 text-sm">شنیده شده</span>
                    </section>
                  </div>
                  <div className="w-full flex justify-center items-center px-8 mt-5">
                    <section className="w-6/12 px-2 hidden">
                      <div className="w-full rounded-full bg-[#3F54CB] flex justify-center items-center py-2 px-2">
                        <span className="text-white">پخش همه</span>
                        <BsPlayCircleFill className="text-white text-2xl mr-4" />
                      </div>
                    </section>
                    <section className="w-full flex justify-evenly items-center px-4   ">
                      <HiOutlineDotsCircleHorizontal
                        className="text-2xl "
                        onClick={() => {
                          navigate(
                            `/list-comment?id=${detail.id}&type=podcast&commentCount=null&rateCount=null`
                          )
                        }}
                      />
                      <BsDownload className="text-2xl hidden" />
                      {like ? (
                        <AiFillHeart
                          className="text-2xl text-red-600"
                          onClick={() => {
                            setLike(false)
                            createPostLike()
                          }}
                        />
                      ) : (
                        <img
                          src={Heart}
                          alt="Padcoast setting in matyar"
                          onClick={() => {
                            setLike(true)
                            createPostLike()
                          }}
                        />
                      )}
                    </section>
                  </div>
                  <div className="w-full flex justify-between items-center px-4 mt-4">
                    <span>اپیزودها</span>
                  </div>
                  <div
                    className="w-full px-4 mt-3 h-[250px] pb-20 overflow-hidden overflow-y-scroll"
                    onScroll={onScroll}
                    ref={listInnerRef}
                  >
                    {Array.isArray(episodes) &&
                      episodes.map((epi) => (
                        <Episode
                          key={epi.id}
                          data={epi}
                          currentEpisode={currentEpisode}
                          setCurrentEpisode={setCurrentEpisode}
                          addView={addView}
                        />
                      ))}
                  </div>
                </div>
              </>
            )
          )}
        </>
      )}
    </div>
  )
}
export default PodcastPlayList
