import { useState } from 'react'
import Upload from '../../svg/upload.svg'
import Share from '../../svg/share-black.svg'
import Trash from '../../svg/trash-black.svg'
function Footer({ setCanSelect }) {
  const [deleteItem, setDeleteItem] = useState(false)
  const [sendItem, setSendItem] = useState(false)
  const [shareItem, setShareItem] = useState(false)
  return (
    <>
      <footer className="w-full px-4 fixed bottom-5 bg-white ">
        <span className="w-full h-[1px] bg-gray-400 my-2 block" />
        <div className="w-full flex justify-center items-center">
          {deleteItem && (
            <>
              <section className="flex flex-col justify-center items-center w-6/12">
                <span className="text-sm text-gray-800 mt-2 bg-red-200 text-red-600 px-4 py-2 rounded-lg">
                  حذف بشه
                </span>
              </section>

              <section
                className="flex flex-col justify-center items-center w-6/12"
                onClick={() => {
                  setDeleteItem(false)
                  setCanSelect(false)
                }}
              >
                <span className="text-sm text-gray-800 mt-2 bg-blue-200 text-blue-800 px-4 py-2 rounded-lg">
                  بیخیال!
                </span>
              </section>
            </>
          )}

          {sendItem && (
            <>
              <section className="flex flex-col justify-center items-center w-6/12">
                <span className="text-sm text-gray-800 mt-2 bg-blue-200 text-blue-600 px-4 py-2 rounded-lg">
                  ارسال بشه
                </span>
              </section>

              <section
                className="flex flex-col justify-center items-center w-6/12"
                onClick={() => {
                  setSendItem(false)
                  setCanSelect(false)
                }}
              >
                <span className="text-sm text-gray-800 mt-2 bg-red-200 text-red-600 px-4 py-2 rounded-lg">
                  بیخیال!
                </span>
              </section>
            </>
          )}

          {shareItem && (
            <>
              <section className="flex flex-col justify-center items-center w-6/12">
                <span className="text-sm text-gray-800 mt-2 bg-blue-200 text-blue-600 px-4 py-2 rounded-lg">
                  اشتراک گذاری
                </span>
              </section>

              <section
                className="flex flex-col justify-center items-center w-6/12"
                onClick={() => {
                  setShareItem(false)
                  setCanSelect(false)
                }}
              >
                <span className="text-sm text-gray-800 mt-2 bg-red-200 text-red-600 px-4 py-2 rounded-lg">
                  بیخیال!
                </span>
              </section>
            </>
          )}

          {!deleteItem && !sendItem && !shareItem && (
            <>
              <section
                className="flex flex-col justify-center items-center w-4/12"
                onClick={() => {
                  setDeleteItem(true)
                  setCanSelect(true)
                }}
              >
                <img src={Trash} alt="trash matyar" />
                <span className="text-xs text-gray-800 mt-2">حذف عکس</span>
              </section>

              <section
                className="flex flex-col justify-center items-center w-4/12"
                onClick={() => {
                  setSendItem(true)
                  setCanSelect(true)
                }}
              >
                <img src={Upload} alt="upload matyar" />
                <span className="text-xs text-gray-800 mt-2">ارسال به آلبوم فیزیکی</span>
              </section>

              <section
                className="flex flex-col justify-center items-center w-4/12"
                onClick={() => {
                  setShareItem(true)
                  setCanSelect(true)
                }}
              >
                <img src={Share} alt="upload image matyar" />
                <span className="text-xs text-gray-800 mt-2">اشتراک گذاری</span>
              </section>
            </>
          )}
        </div>
      </footer>
    </>
  )
}
export default Footer
