import randomColor from 'randomcolor'
import { useEffect, useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io'
import DatePicker from 'react-multi-date-picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Domain, VaccineAPI } from '../../../../../../api'
import DateObject from 'react-date-object'
import gregorian from 'react-date-object/calendars/gregorian'
import gregorian_fa from 'react-date-object/locales/gregorian_fa'
import persianToEnglish from '../../../../../../persian-to-english'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

function Card({ data }) {
  const childInfo = useSelector((state) => state.childInfo)
  const navigate = useNavigate()
  const [color, setColor] = useState('')

  const creareVaccineChild = (val) => {
    const formData = new FormData()
    formData.append('vacId', data.id)
    formData.append('child_id', childInfo.id)
    formData.append(
      'time',
      new Date(persianToEnglish(new DateObject(val).convert(gregorian, gregorian_fa).format()))
    )
    axios
      .post(VaccineAPI + '/create/child', formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        toast.success(data.message)
      })
  }
  useEffect(() => {
    setColor(randomColor())
  }, [])

  return (
    <>
      <div className="w-full relative py-2 px-4 shadow-xl rounded-xl border border-gray-300 mt-4">
        <div className="w-full flex justify-start items-center">
          <span
            className="w-14 h-14   flex justify-center items-center rounded-2xl ml-2"
            style={{
              backgroundImage: `url("${Domain + data.logo}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          />

          <div className="flex flex-col justify-between items-start">
            <span className="text-gray-800 text-sm" style={{ color: color }}>
              {data?.title}
            </span>
            <span className="text-gray-600 text-xs mt-2">{data?.time}</span>
          </div>
        </div>
        <div className="w-full my-4">
          <div className="w-full flex justify-between items-center mb-2  ">
            <span className="text-xs text-[#3F54CB]"> زمان باقی مانده تا تزریق</span>
            <span className="text-xs text-gray-600 ">
              {data?.submitTime &&
                new DateObject(new Date(data?.submitTime))
                  .convert(persian, persian_fa)
                  .format('dddd DD MMMM YYYY')}
            </span>
          </div>
          <div className="w-full h-[4px] bg-gray-200 relative rounded-xl overflow-hidden dir-ltr  ">
            <section
              style={{
                width: data.percent
              }}
              className="h-full bg-[#3F54CB]"
            ></section>
          </div>
          <div className="w-full flex justify-start flex-wrap items-center my-4">
            <span className="text-sm text-gray-600 w-full">{data?.detail}</span>
            <>
              <section
                className="text-blue-600 flex justify-start items-center     py-1 rounded mt-1"
                onClick={() => {
                  navigate('/vaccine-detail?id=' + data?.id)
                }}
              >
                <span className="text-xs">مطالعه بیشتر</span>
                <IoIosArrowDown className="text-blue-600" />
              </section>
            </>
          </div>
          <div className="w-full">
            {data?.VaccineChild?.length > 0 ? (
              <button className="bg-green-600 text-white py-2 w-[82vw] rounded-xl">
                {new DateObject(new Date(data?.VaccineChild[0].submit_time))
                  .convert(persian, persian_fa)
                  .format('dddd DD MMMM YYYY')}
              </button>
            ) : (
              <DatePicker
                onChange={(val) => {
                  creareVaccineChild(val)
                }}
                className="rmdp-mobile"
                render={(value, openCalendar) => {
                  return (
                    <button
                      className="bg-[#3F54CB] text-white py-2 w-[82vw] rounded-xl"
                      onClick={openCalendar}
                      value={value}
                    >
                      ثبت تزریق واکسن
                    </button>
                  )
                }}
                calendar={persian}
                locale={persian_fa}
                calendarPosition="bottom-left"
              ></DatePicker>
            )}
          </div>
        </div>
        {data?.VaccineChild?.length > 0 ? (
          <span className=" px-2 py-1 bg-green-200 text-green-600 flex justify-center items-center rounded-tr-xl rounded-br-xl absolute text-[10px] top-2 left-0">
            انجام شده
          </span>
        ) : (
          <span className=" px-2 py-1 bg-yellow-200 text-yellow-600 flex justify-center items-center rounded-tr-xl rounded-br-xl absolute text-[10px] top-2 left-0">
            انجام نشده
          </span>
        )}
      </div>
    </>
  )
}

export default Card
