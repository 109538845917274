import HeaderAlbum from '../../album/photo-album/component/header'
import { VscSearch } from 'react-icons/vsc'
import { useEffect, useRef, useState } from 'react'
import { MdPlayArrow } from 'react-icons/md'
import Heart from '../../articles/detail/svg/Heart.svg'
import Comment from '../../articles/detail/svg/comment-green.svg'
import Show from '../../articles/detail/svg/Show.svg'
import axios from 'axios'
import { AnimationAPI, AnimationCategoryAPI, Domain } from '../../../../../api'

function AnimationTeamwork() {
  const listInnerRef = useRef()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [animations, setAnimations] = useState([])
  const [category, setCategory] = useState([])
  const [categoryId, setCategoryId] = useState('')
  const getCategory = () => {
    axios
      .get(AnimationCategoryAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCategory(data)
      })
  }
  const getAnimation = (pge = 1, category = '') => {
    axios
      .get(AnimationAPI + `/all?page=${pge}&search=${search}&categoryId=${category}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        if (pge == 1) {
          setAnimations(data)
        } else {
          setAnimations([...animations, ...data])
        }
      })
  }
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
        getAnimation(page + 1, categoryId)
      }
    }
  }

  useEffect(() => {
    getCategory()
    getAnimation(page, categoryId)
  }, [])

  useEffect(() => {
    getAnimation(1, categoryId)
    setPage(1)
  }, [search])
  return (
    <>
      <HeaderAlbum title="انیمیشن های کارگروهی" />
      <div className="w-full flex justify-start items-center px-4">
        <section className="w-full relative">
          <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
          <input
            onChange={(e) => setSearch(e.target.value)}
            className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
            placeholder="انیمیشن خود را جستجو کنید..."
          />
        </section>
      </div>

      <div className="flex-container border-b-2 border-b-gray-200 pb-2 mt-5 pt-3 sticky top-0 z-[500] bg-white ">
        <div className="scrollable-content">
          <div
            className={`w-24 flex justify-center items-center opacity-70 cursor-pointer ${
              categoryId === '' ? 'grouping-active' : ''
            }`}
            onClick={() => {
              setPage(1)
              setCategoryId('')
              getAnimation(1, '')
            }}
          >
            <span>همه</span>
          </div>

          {category.map((cat) => (
            <div
              key={cat.id}
              className={`w-24 flex justify-center items-center opacity-70 cursor-pointer ${
                categoryId === cat.id ? 'grouping-active' : ''
              }`}
              onClick={() => {
                setPage(1)
                setCategoryId(cat.id)
                getAnimation(1, cat.id)
              }}
            >
              <span>{cat.title}</span>
            </div>
          ))}
        </div>
      </div>
      <div
        className="w-full px-4 h-screen overflow-hidden overflow-y-scroll "
        onScroll={onScroll}
        ref={listInnerRef}
      >
        {animations.map((anim) => (
          <section key={anim.id} className="w-full flex justify-between items-center mt-4">
            <div
              className="w-44 h-24  relative rounded-xl ml-4"
              style={{
                backgroundImage: `url("${Domain + anim.cover}")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            >
              <section className="flex justify-center items-center w-full h-full absolute left-0 top-0 bg-[rgba(0,0,0,0.3)]  rounded-xl">
                <MdPlayArrow className="w-10 h-10 p-2 rounded-full bg-[#3F54CB] text-white" />
              </section>
            </div>
            <div className="w-9/12">
              <p className="text-sm text-gray-800">{anim.title}</p>
              <span className="text-xs text-gray-700 my-2">{anim?.category?.title}</span>
              <section className="w-full flex justify-start items-center mt-2">
                <div className="flex justify-start items-center ml-5">
                  <img src={Heart} alt="show icon matyar" className="w-5 h-5 ml-2" />
                  <span className="text-gray-700 text-xs">{anim.likeCount}</span>
                </div>
                <div className="flex justify-start items-center ml-5">
                  <img src={Comment} alt="show icon matyar" className="w-5 h-5 ml-2" />
                  <span className="text-gray-700 text-xs">{anim.commentCount}</span>
                </div>
                <div className="flex justify-start items-center ml-5">
                  <img src={Show} alt="show icon matyar" className="w-5 h-5 ml-2" />
                  <span className="text-gray-700 text-xs">{anim.viewCount}</span>
                </div>
              </section>
            </div>
          </section>
        ))}
      </div>
    </>
  )
}
export default AnimationTeamwork
