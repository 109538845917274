import HeaderAlbum from '../photo-album/component/header'
import Card from './component/card'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { clearAlbum } from '../../../../../redux/album-slice'
import { MdKeyboardArrowRight } from 'react-icons/md'

function SetPic() {
  const dispatch = useDispatch()
  const albums = useSelector((state) => state.albumSetting.albums)
  const [canSelect, setCanSelect] = useState(true)
  useEffect(() => {
    dispatch(clearAlbum())
    setCanSelect(true)
  }, [])
  return (
    <>
      <HeaderAlbum
        title={
          canSelect && albums.length !== 0
            ? ` ${albums.length} عکس انتخاب شد`
            : 'سفارش آلبوم فیزیکی'
        }
      />
      {albums.length === 0 && (
        <p className="text-red-600 w-full text-center text-sm my-2">
          {' '}
          لطفا حداقل یک عکس انتخاب کنید{' '}
        </p>
      )}
      <div className="w-full grid grid-cols-3 gap-2 px-2">
        <Card canSelect={canSelect} />
      </div>
      <div className="w-full flex justify-between items-center px-4 fixed bottom-5">
        <p className="flex justify-center items-center text-sm border border-blue-800 rounded-lg py-2 px-4 text-blue-800">
          <MdKeyboardArrowRight className="text-lg" />
          ادامه ی انتخاب
        </p>

        <p className="flex justify-center items-center text-sm border border-blue-800 bg-[#3F54CB] text-white rounded-lg py-2 px-4">
          ثبت سفارش
        </p>
      </div>
    </>
  )
}
export default SetPic
