import { Suspense, useRef, useState } from 'react';
import Input from '../component/input';
import Father from './svg/father.svg';
import Mother from './svg/mother.svg';
// import Google from './svg/Google logo.svg'
import Wave from './svg/wave.svg';

import './signup.css';
import Loading from '../../../component/loading';
import axios from 'axios';
import { ParentAPI } from '../../../api';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeInfo } from '../../../redux/info-slice';

function Signup() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [parent, setParent] = useState('');
  const [info, setInfo] = useState({
    phone: '',
    email: '',
    name: '',
    password: '',
  });
  const [showCode, setShowCode] = useState(false);
  const [code1, setCode1] = useState(0);
  const [code2, setCode2] = useState(0);
  const [code3, setCode3] = useState(0);
  const [code4, setCode4] = useState(0);
  const [code5, setCode5] = useState(0);

  const SignupFunction = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('phoneNumber', info.phone);
    formData.append('parent', parent);
    // formData.append('password', info.password)
    // formData.append('email', info.email)
    formData.append('name', info.name);
    axios
      .post(ParentAPI, formData)
      .then((res) => res.data)
      .then(() => {
        toast.success('یک رمز عبور به شماره شما پیامک شده است لطفا رمز را وارد کنید');

        setShowCode(true);

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        if (e.response.data.message == undefined) {
          e?.response?.data?.map((er) => toast.error(er));
        }
        e?.response?.data?.message.map((er) => toast.error(er));
      });
  };

  const sendCodeFunction = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('phoneNumber', info.phone);
    formData.append('sms', code1 + code2 + code3 + code4 + code5);

    axios
      .post(ParentAPI + 'sms', formData)
      .then((res) => res.data)
      .then((data) => {
        setShowCode(true);
        localStorage.setItem('token', data.access_token);
        navigate('/?status=home');
        dispatch(
          changeInfo({
            name: data.name,
            id: data.id,
            profile: data.profile,
          }),
        );
        toast.success(data.message);

        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        toast(e.response.data);
      });
  };

  const code2Ref = useRef(null);
  const code3Ref = useRef(null);
  const code4Ref = useRef(null);
  const code5Ref = useRef(null);

  const handleInputChange = (e, nextRef) => {
    const inputValue = e.target.value;
    if (inputValue.length === 1 && nextRef) {
      nextRef.current.focus();
    }
  };
  return (
    <Suspense fallback={<Loading color="black" width="100" />}>
      {showCode ? (
        <>
          <div
            className={`w-full fixed top-0 left-0 bg-[rgba(0,0,0,0.7)]   h-screen z-40 ${
              showCode ? 'animate__animated animate__fadeIn' : null
            }`}
            onClick={() => setShowCode(false)}
          />
          <div
            className={`fixed bottom-0 w-full py-10 px-4 bg-white rounded-t-3xl z-50 vazir-medium ${
              showCode ? 'animate__animated animate__fadeInUp' : null
            }`}
          >
            <p className="text-xl">رمز عبور موقت</p>
            <p className="text-gray-400 mt-2">برای ورود لطفا رمز عبور پیامک شده را وارد کنید</p>
            <div className="relative grid grid-cols-5 gap-3 mt-4 z-40 dir-ltr">
              <input
                maxLength={1}
                max={1}
                type="number"
                className="w-full border border-gray-300 bg-transparent py-3 rounded-xl  text-center"
                onChange={(e) => {
                  setCode1(e.target.value);
                  handleInputChange(e, code2Ref);
                }}
              />
              <input
                ref={code2Ref}
                maxLength={1}
                type="number"
                className="w-full border border-gray-300 bg-transparent py-3 rounded-xl  text-center"
                onChange={(e) => {
                  setCode2(e.target.value);
                  handleInputChange(e, code3Ref);
                }}
              />
              <input
                ref={code3Ref}
                maxLength={1}
                type="number"
                className="w-full border border-gray-300 bg-transparent py-3 rounded-xl  text-center"
                onChange={(e) => {
                  setCode3(e.target.value);
                  handleInputChange(e, code4Ref);
                }}
              />
              <input
                ref={code4Ref}
                maxLength={1}
                type="number"
                className="w-full border border-gray-300 bg-transparent py-3 rounded-xl text-center "
                onChange={(e) => {
                  setCode4(e.target.value);
                  handleInputChange(e, code5Ref);
                }}
              />
              <input
                ref={code5Ref}
                maxLength={1}
                type="number"
                className="w-full border border-gray-300 bg-transparent py-3 rounded-xl  text-center"
                onChange={(e) => {
                  setCode5(e.target.value);
                  handleInputChange(e, code5Ref);
                }}
              />
            </div>
            <button
              className="w-full bg-blue-800 text-white rounded-xl py-3 shadow-md mt-6"
              onClick={() => {
                sendCodeFunction();
              }}
            >
              ورود
            </button>
          </div>
        </>
      ) : null}
      <div className="flex flex-col justify-between h-screen w-full overflow-hidden">
        <div className="w-full relative px-6">
          <section>
            <p className="vazir-bold text-2xl text-gray-800 mt-2">ثبت نام</p>
            <p className="vazir-medium text-gray-700 mt-4">نسبت شما با کودک</p>
          </section>
          <section className={`flex justify-evenly items-center choose-parent ${parent} py-2`}>
            <div
              className="flex justify-center items-center mom cursor-pointer"
              onClick={() => setParent('mom')}
            >
              <img src={Mother} alt="mother-svg" />
              <span className="vazir-medium">مادر هستم</span>
            </div>
            <div
              className="flex justify-center items-center dad  cursor-pointer"
              onClick={() => setParent('dad')}
            >
              <img src={Father} alt="mother-svg" />
              <span className="vazir-medium">پدر هستم</span>
            </div>
          </section>
          <div className="mt-3">
            <section>
              <Input
                inputClassName="vazir-medium text-gray-700 border border-2 py-3 px-3 rounded-lg w-full focus:border-blue-600"
                inputId="unique-info"
                inputPlaceholder="شماره تلفن را وارد کنید"
                inputType="number"
                onChange={(e) => {
                  if (e.target.value.indexOf('@') === -1) {
                    setInfo({ ...info, phone: e.target.value, email: '' });
                  } else {
                    setInfo({ ...info, email: e.target.value, phone: '' });
                  }
                }}
              />

              <Input
                inputClassName="vazir-medium text-gray-700 border border-2 py-3 px-3 rounded-lg w-full focus:border-blue-600 my-5"
                inputId="name"
                inputPlaceholder="نام و نام خانوادگی خود را وارد کنید"
                inputType="text"
                onChange={(e) => setInfo({ ...info, name: e.target.value })}
              />
            </section>
            <section>
              <button
                type="button"
                className={`w-full rounded-xl text-xl vazir-medium text-white bg-[#3F54CB] py-3 mt-4  shadow-lg ${
                  loading ? 'opacity-70 cursor-not-allowed' : 'opacity-100 cursor-pointer'
                }`}
                onClick={SignupFunction}
              >
                {loading ? <Loading color="#fff" width="25" /> : <span>ثبت نام</span>}
              </button>
            </section>
          </div>
          {/*<section className="flex justify-center items-center my-5 ">*/}
          {/*  <span className="block w-4/12 h-1 clip-right gradient-right" />*/}
          {/*  <p className="vazir-medium text-gray-600 mx-2">یا ثبت نام با</p>*/}
          {/*  <span className="block w-4/12 h-1 clip-left gradient-left" />*/}
          {/*</section>*/}

          <section className="flex justify-center items-center mt-4">
            <p className="vazir-medium text-gray-700">
              قبلا ثبت نام کرده اید؟
              <span className="text-blue-800">
                <Link to="/login">ورود</Link>
              </span>
            </p>
          </section>

          {/*<button*/}
          {/*  disabled={loading}*/}
          {/*  type="button"*/}
          {/*  className={`w-full border border-1 rounded-xl h-12 text-gray-600 vazir-medium py-1 mt-2 flex justify-center items-center z-50 ${*/}
          {/*    loading ? 'opacity-70 cursor-not-allowed' : 'opacity-100 cursor-pointer'*/}
          {/*  }`}*/}
          {/*  onClick={() => alert('google')}*/}
          {/*>*/}
          {/*  <span className="text-gray-700">ثبت نام با گوگل</span>*/}
          {/*  <img src={Google} alt="matyar signup with google" className="mr-5" />*/}
          {/*</button>*/}
        </div>
        <div className="relative w-full mt-20 ">
          <img src={Wave} alt="wave" className="bottom-0 w-full " />
        </div>
      </div>
    </Suspense>
  );
}

export default Signup;
