function DoctorTimeEnd() {
  return (
    <>
      <div className="w-full flex flex-col justify-start items-center">
        <p className=" text-[#3F54CB] mt-16 font-bold "> 60 دقیقه</p>
        <p className="text-gray-700 text-md my-4">تماس صوتی به پایان رسید</p>
        <p className="text-gray-600 text-base my-2">تماس صوتی شما در بخش تاریخچه ذخیره شد</p>
        <section
          className="w-44 h-44 rounded-full"
          style={{
            backgroundImage: `url("https://cdn3.vectorstock.com/i/1000x1000/30/97/flat-business-man-user-profile-avatar-icon-vector-4333097.jpg")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
        <p className="text-md text-gray-700 font-bold my-1">دکتر کاوه</p>

        <button className="w-8/12 border border-[#3F54CB] bg-[#3F54CB] rounded-xl px-4 py-2 text-white mt-2">
          ثبت نظر و امتیاز
        </button>
        <button className="w-8/12 border border-[#3F54CB] text-[#3F54CB] bg-transaprent rounded-xl px-4 py-2 mt-2">
          بازگشت به خانه
        </button>
      </div>
    </>
  )
}
export default DoctorTimeEnd
