import { BsFillCameraVideoFill, BsFillTelephoneFill, BsThreeDotsVertical } from 'react-icons/bs'
import { MdArrowForwardIos } from 'react-icons/md'
import { RiSendPlaneFill } from 'react-icons/ri'
import './chat.css'
import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder'
import './record-voice.css'
import { Domain } from '../../../api'
import { useSelector } from 'react-redux'
import returnTime from '../../../convert-time'
import { io } from 'socket.io-client'
function Chat() {
  const socket = io(`${Domain}chat`, {
    extraHeaders: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    query: { id: localStorage.getItem('token') }
  })
  if (document.getElementById('voice-record')) {
    document.getElementById('voice-record').remove()
  }
  const input = useRef(null)
  const chatPageRef = useRef(null)
  const chatScroll = useRef(null)
  const [page, setPage] = useState(1)

  const coach = useSelector((state) => state.childCoach)
  const childInfo = useSelector((state) => state.childInfo)
  const navigate = useNavigate()
  const [textBoxBlur, setTextBuxBlur] = useState(false)
  const [roomId, setRoomId] = useState('')
  const [messageList, setMessageList] = useState([])
  const [message, setMessage] = useState('')
  const recorderControls = useAudioRecorder()
  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob)
    const audio = document.createElement('audio')
    audio.setAttribute('id', 'voice-record')
    audio.src = url
    audio.controls = true
    document.getElementById('show-message').appendChild(audio)
    // document.body.appendChild(audio)
  }
  const scrollToTop = () => {
    const topRef = chatPageRef.current
    if (topRef) {
      topRef.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const getRoom = () => {
    socket.emit('getRoom', {
      senderId: childInfo.id,
      receiverId: coach.id,
      senderType: 'child',
      receiverType: 'coach'
    })

    socket.on('getRoom', (room) => {
      setRoomId(room.roomId)
      joinRoom(room.roomId)
      getLastMessage(room.roomId)
    })
  }
  const joinRoom = (room) => {
    socket.emit('join', {
      roomId: room
    })
  }
  const getLastMessage = (room) => {
    socket.emit('messages', {
      chatRoomId: room,
      page: page
    })
    socket.on('messages', (messages) => {
      if (page === 1) {
        setMessageList(messages.messages)
      } else {
        setMessageList([...messageList, messages.messages])
      }

      scrollToTop()
    })
  }
  const create = () => {
    if (message.trim().length > 1) {
      socket.emit('create', {
        text: message,
        file: null,
        chatRoomId: roomId,
        senderId: childInfo.id,
        receiverId: coach.id,
        senderType: 'child',
        receiverType: 'coach',
        page: page
      })
    }
  }
  socket.on('create', (messages) => {
    if (page === 1) {
      setMessageList(messages.messages)
    } else {
      setMessageList([...messageList, messages.messages])
    }
    scrollToTop()
    setMessage('')
    input.current.value = ''
  })
  useEffect(() => {
    getRoom()
  }, [page])

  const onScroll = () => {
    if (chatScroll.current) {
      const { scrollTop } = chatScroll.current
      if (scrollTop === 0 && messageList?.length % 300 === 0) {
        setPage(page + 1)
      }
    }
  }

  return (
    <div className="chat-body">
      <header className="flex justify-between items-center px-4 mt-4 vazir-medium shadow-md pb-4 sticky top-0 z-[100] bg-white">
        <div className="flex justify-start items-center">
          <MdArrowForwardIos className="text-2xl text-blue-800" onClick={() => navigate(-1)} />
          <img
            src={Domain + coach.profile}
            alt="profile picture"
            className="rounded-full h-14 w-14 mx-5"
          />
          <section className="flex flex-col">
            <span className="text-sm"> {coach.name}</span>
          </section>
        </div>
        <div className="flex justify-start items-center">
          <BsFillCameraVideoFill className="text-2xl text-blue-800 hidden" />
          <BsFillTelephoneFill className="text-xl text-blue-800 mx-4 hidden" />
          <BsThreeDotsVertical className="text-2xl text-blue-800 hidden" />
        </div>
      </header>

      <div className="chat-page-user" id="chat-page" ref={chatScroll} onScroll={onScroll}>
        {messageList
          ? messageList.map((message) => {
              if (message.sender === childInfo.id) {
                return (
                  <div className="w-10/12 vazir-medium px-3 sender" key={message.id}>
                    <p className="px-3 w-fit bg-gray-200 rounded-lg text-gray-700 mt-4 py-3 chat-triangel relative">
                      {message.text}
                    </p>
                    <span className="text-[10px] text-gray-400">
                      {returnTime(message.created_at).split(',')[1]}
                    </span>
                  </div>
                )
              } else {
                return (
                  <div className="w-full flex justify-end reciver" key={message.id}>
                    <div className="w-10/12 vazir-medium px-3 dir-ltr">
                      <p className="px-3 w-fit bg-blue-700 text-white rounded-lg mt-4 py-3 chat-triangel relative">
                        {message.text}
                      </p>
                      <span className="text-[10px] text-gray-400">
                        {returnTime(message.created_at).split(',')[1]}
                      </span>
                    </div>
                  </div>
                )
              }
            })
          : null}

        <div ref={chatPageRef}></div>
      </div>

      <div className="w-full flex flex-wrap justify-between items-center px-4  bg-white fixed bottom-0">
        <div
          className={`relative ${textBoxBlur && message.length > 0 ? 'w-full' : ' w-full'} ${
            recorderControls.isRecording ? 'hidden' : 'inline-block'
          }`}
          id="show-message"
          onClick={() => input.current.focus()}
        >
          <TextareaAutosize
            ref={input}
            className="bg-blue-100 w-full min-h-[50px] max-h-[30vh] pr-10 rounded-[30px] pt-3"
            placeholder="پیام خود را بنویسید"
            onFocus={() => setTextBuxBlur(true)}
            onBlur={() => setTextBuxBlur(false)}
            onChange={(e) => setMessage(e.target.value)}
            defaultValue={message}
          />
          {/*{textBoxBlur && message.length > 0 ? (*/}
          <RiSendPlaneFill
            className="text-blue-800 absolute bottom-5 right-2 text-2xl"
            onClick={() => create()}
          />
          {/*) : (*/}
          {/*  <>*/}
          {/*    {' '}*/}
          {/*    <input type="file" className="hidden" id="attachment" />*/}
          {/*    <label htmlFor="attachment">*/}
          {/*      <GrAttachment className="text-gray-600 absolute top-3 left-5 text-2xl  hidden" />*/}
          {/*    </label>*/}
          {/*  </>*/}
          {/*)}*/}
        </div>
        {textBoxBlur && message.length > 0 ? null : (
          <div className="hidden">
            <AudioRecorder
              onRecordingComplete={(blob) => addAudioElement(blob)}
              recorderControls={recorderControls}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Chat
