import { createSlice } from '@reduxjs/toolkit'

export const childInfo = createSlice({
  name: 'child-information',
  initialState: {
    name: '',
    id: '',
    birthday: '',
    team: [],
    profile: ''
  },
  reducers: {
    changeChildInfo: (state, action) => {
      state.name = action.payload.name
      state.id = action.payload.id
      state.birthday = action.payload.birthday
      state.team = action.payload.team
      state.profile = action.payload.profile
    },

    clearChildInfo: (state) => {
      state.name = ''
      state.id = ''
      state.birthday = ''
      state.team = []
      state.profile = ''
    }
  }
})
export const { changeChildInfo, clearChildInfo } = childInfo.actions

export default childInfo.reducer
