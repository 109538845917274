import HeaderAlbum from '../../album/photo-album/component/header'

import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { AnimationAPI } from '../../../../../api'
import { useEffect, useRef, useState } from 'react'
import { VscSearch, VscSettings } from 'react-icons/vsc'
import Card from './card'
import { useSelector } from 'react-redux'

function AnimationGroup() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const name = searchParams.get('catName')
  const listInnerRef = useRef()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [animations, setAnimations] = useState([])
  const childInfo = useSelector((state) => state.childInfo)

  const getAnimation = () => {
    axios
      .get(
        AnimationAPI +
          `/all?page=${page}&search=${search}&categoryId=${id == null ? '' : id}&childId=${
            childInfo?.id
          }`,
        {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        }
      )
      .then((res) => res.data)
      .then((data) => {
        if (page == 1) {
          setAnimations(data)
        } else {
          setAnimations([...animations, ...data])
        }
      })
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
        getAnimation()
      }
    }
  }
  useEffect(() => {
    getAnimation()
  }, [search])

  useEffect(() => {
    setPage(1)
  }, [search])
  return (
    <>
      <HeaderAlbum title={name ? `انیمیشن های ${name} ` : `لیست انیمیشن ها`} />
      <div className="w-full flex justify-start items-center px-4 pb-3">
        <section className="w-11/12 relative">
          <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
          <input
            onChange={(e) => setSearch(e.target.value)}
            className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
            placeholder="انیمیشن خود را جستجو کنید..."
          />
        </section>
        <section className="w-12 h-12 flex justify-center items-center card-shadow rounded-lg mr-2">
          <VscSettings className="text-2xl text-gray500" />
        </section>
      </div>
      <div
        className="w-full px-4  pb-32 overflow-hidden overflow-y-scroll h-screen content-start"
        ref={listInnerRef}
        onScroll={onScroll}
      >
        {animations.map((animation) => (
          <Card animation={animation} key={animation.id} />
        ))}
      </div>
    </>
  )
}
export default AnimationGroup
