import Layout from '../index'
import CoachItem from './coach-item'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Domain, GetAllTeam, SubscribeAPI, TeamAPI } from '../../../../api'
import { useSelector } from 'react-redux'
import PopUp from '../../components/pop-up/comment'

function CoachList() {
  const [teams, setTeams] = useState([])
  const childInfo = useSelector((state) => state.childInfo)
  const listInnerRef = useRef()
  const [page, setPage] = useState(1)
  const [subStatus, setSubStatus] = useState(null)
  const [showForm, setShowForm] = useState(false)
  const getAllTeam = () => {
    axios
      .get(GetAllTeam + '?page=' + page, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        if (page === 1) {
          setTeams(data)
        } else {
          setTeams([...teams, ...data])
        }
      })
  }
  useEffect(() => {
    getAllTeam()
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = ''
    }
  }, [page])
  const AgeRange = (daysDifference) => {
    if (daysDifference <= 180) {
      return 'نوزاد'
    } else if (daysDifference <= 720) {
      return 'نوپا'
    } else if (daysDifference <= 2520) {
      return 'خردسال'
    }
  }
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }

  const getChildSubscribe = () => {
    axios
      .get(SubscribeAPI + 'check/user-sub?id=' + childInfo?.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setSubStatus(data?.subStatus)
        setShowForm(data?.subStatus?.comment === 'pending')
      })
  }

  useEffect(() => {
    getChildSubscribe()
  }, [])

  return (
    <>
      {subStatus && subStatus?.comment === 'pending' && (
        <PopUp
          setShowMessage={setShowForm}
          showMessage={showForm}
          titleMessage="اشتراک شما رو به پایان است"
          userTeam={subStatus?.child?.childTeam[0]?.team.title}
          API={TeamAPI + 'comment'}
          id={subStatus?.child?.childTeam[0]?.team.id}
          body={`  لطفا برای کمک به بهبود آموزش ها و تخصص تیم
               ${subStatus?.child?.childTeam[0]?.team.title}   
              نظر و امتیاز خود را ثبت کنید `}
        />
      )}
      <Layout>
        <div className="w-full bg-white px-2 rounded-t-2xl relative -top-5 pt-4">
          <p className="vazir-medium">تیم های راهبری</p>

          <div
            ref={listInnerRef}
            onScroll={onScroll}
            className="flex flex-col justify-start items-start overflow-y-scroll hide-scroll pb-32"
            style={{
              maxHeight: 'calc(100vh - 198px)'
            }}
          >
            {teams.length > 0
              ? teams.map((team) => (
                  <CoachItem
                    coaches={team.coaches}
                    key={team.id}
                    name={team.title}
                    ageDetail={`مناسب برای  ${AgeRange(team.area_expertise)}`}
                    commentCount={team.commentCount}
                    rateCount={team.rateCount}
                    src={Domain + team.profile}
                    id={team.id}
                    number={team.number}
                  />
                ))
              : null}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default CoachList
