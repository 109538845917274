import HeaderAlbum from '../photo-album/component/header'
import Card from './component/card'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { clearAlbum } from '../../../../../redux/album-slice'
import axios from 'axios'
import { GetAlbumOrderUser } from '../../../../../api'

function MyAlbum() {
  const dispatch = useDispatch()

  const [albumList, setAlbumList] = useState([])
  const getUserAlbum = () => {
    axios
      .get(GetAlbumOrderUser, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setAlbumList(data)
      })
  }
  useEffect(() => {
    dispatch(clearAlbum())

    getUserAlbum()
  }, [])
  return (
    <>
      <HeaderAlbum title="آلبوم های شما" />
      <div className="w-full grid grid-cols-2 gap-4 px-4">
        {albumList.map((al) => (
          <Card key={al.id} data={al} ReloadFunction={getUserAlbum} />
        ))}
      </div>
    </>
  )
}
export default MyAlbum
