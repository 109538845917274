import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { BsHeadset } from 'react-icons/bs';
// import { IoBicycle } from 'react-icons/io5'
// import { FaChartArea, FaGraduationCap } from 'react-icons/fa'
// import { HiUsers } from 'react-icons/hi'
import { HiSquares2X2 } from 'react-icons/hi2';
import './footer.css';
import axios from 'axios';
import { HasCoach } from '../../../../api';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeChildCoach } from '../../../../redux/child-coach-slice';
import ActivitySVG from './svg/activity.svg';
import AnalyzeSVG from './svg/analyze.svg';
import CoachSVG from './svg/coach.svg';
import WorkshopSVG from './svg/workshop.svg';

function Footer() {
  const dispatch = useDispatch();
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const childInfo = useSelector((state) => state.childInfo);
  const [childHasCoach, setChildHasCoach] = useState(false);
  const [childActive, setChildActive] = useState(false);
  const hasCoach = () => {
    axios
      .get(HasCoach + childInfo.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setChildHasCoach(true);
        setChildActive(data?.child);
        dispatch(
          changeChildCoach({
            name: data.name,
            id: data.id,
            profile: data.profile,
          }),
        );
        // window.scrollTo(-50, document.getElementById('chat-page').scrollHeight)
      })
      .catch(() => {
        setChildHasCoach(false);
      });
  };
  useEffect(() => {
    hasCoach();
  }, [childInfo]);
  return (
    <>
      {location === '/' && childHasCoach && childActive ? (
        <div
          className="w-16 h-16 bg-green-600  flex justify-center items-center rounded-full shadow shadow-green-400 mr-4 fixed bottom-20 z-[50]"
          onClick={() => navigate('/chat')}
        >
          <BsHeadset className="text-4xl text-white" />
        </div>
      ) : null}

      <footer className="w-full mt-4 fixed bottom-0 bg-white z-[50] flex justify-center items-center  ">
        <div className="w-full flex justify-evenly items-center footer-shadow py-2">
          <NavLink
            to="/workshop?status=presence"
            className={`flex flex-col justify-center items-center vazir-medium text-xs text-gray-600 ${
              location === '/workshop?status=presence' ? 'link - active' : null
            } `}
          >
            {/*{location === '/workshop?status=presence' ? (*/}
            {/*  <>*/}
            {/*    {' '}*/}
            {/*    <FaGraduationCap className="text-2xl relative z-20 text-blue-800" />{' '}*/}
            {/*    <p className="w-8 h-8 rounded-full bg-blue-100 block absolute z-10 -translate-x-2" />{' '}*/}
            {/*  </>*/}
            {/*) : (*/}
            <img src={WorkshopSVG} className="w-10 h-10" />
            {/*)}*/}

            <span className="mt-1">کارگاه</span>
          </NavLink>
          <NavLink
            to="/progress"
            className={`flex flex-col justify-center relative items-center vazir-medium text-xs text-gray-600 ${
              location === '/progress' ? 'link - active' : null
            } `}
          >
            {/*{location === '/progress' ? (*/}
            {/*  <>*/}
            {/*    <FaChartArea className="text-2xl relative z-20 text-blue-800" />*/}
            {/*    <p className="w-8 h-8 rounded-full bg-blue-100 block absolute z-10 -translate-x-2" />*/}
            {/*  </>*/}
            {/*) : (*/}
            <img src={AnalyzeSVG} className="w-10 h-10" />
            {/*)}*/}

            <span className="mt-1">آنالیز کودک</span>
          </NavLink>
          <NavLink
            to="/?status=home"
            className={`flex flex-col justify-center items-center vazir-medium text-xs text-gray-600 ${
              location === '/' ? 'link - active' : null
            } `}
          >
            {location === '/' ? (
              <>
                {' '}
                <HiSquares2X2 className="text-2xl relative z-20 text-blue-800" />{' '}
                <p className="w-8 h-8 rounded-full bg-blue-100 block absolute z-10 -translate-x-2" />{' '}
              </>
            ) : (
              <HiSquares2X2 className="text-3xl text-white bg-blue-800 w-10 h-10 rounded-full p-2" />
            )}
          </NavLink>
          <NavLink
            to="/coach-list"
            className={`flex flex-col justify-center items-center vazir-medium text-xs text-gray-600 ${
              location === '/coach-list' ? 'link - active' : null
            } `}
          >
            {/*{location === '/coach-list' ? (*/}
            {/*  <>*/}
            {/*    {' '}*/}
            {/*    <HiUsers className="text-2xl relative z-20 text-blue-800" />{' '}*/}
            {/*    <p className="w-8 h-8 rounded-full bg-blue-100 block absolute z-10 -translate-x-2" />{' '}*/}
            {/*  </>*/}
            {/*) : (*/}
            <img src={CoachSVG} className="w-10 h-10" />
            {/*// )}*/}
            <span className="mt-1">راهبران</span>
          </NavLink>
          <NavLink
            to="/activity"
            className={`flex flex-col justify-center items-center vazir-medium text-xs text-gray-600 ${
              location === '/activity' ? 'link - active' : null
            } `}
          >
            {/*{location === '/activity' ? (*/}
            {/*  <>*/}
            {/*    <IoBicycle className="text-2xl relative z-20 text-blue-800" />{' '}*/}
            {/*    <p className="w-8 h-8 rounded-full bg-blue-100 block absolute z-10 -translate-x-2" />{' '}*/}
            {/*  </>*/}
            {/*) : (*/}
            <img src={ActivitySVG} className="w-10 h-10" />
            {/*)}*/}
            <span className="mt-1">فعالیت ها</span>
          </NavLink>
        </div>
      </footer>
    </>
  );
}

export default Footer;
