import { useNavigate } from 'react-router-dom'
function Box({ title, ClassName, Icon, link }) {
  const navigate = useNavigate()
  return (
    <>
      <div
        onClick={() => {
          navigate(link)
        }}
        className={`w-full rounded-xl h-28 bg-yellow-700 relative shadow ${ClassName}`}
        style={{
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <section className="flex flex-col justify-start items-start text-white absolute bottom-1 right-2">
          {Icon}
          <span className="text-sm mt-1">{title}</span>
        </section>
      </div>
    </>
  )
}
export default Box
