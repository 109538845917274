export const Domain =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_DOMAIN : process.env.REACT_APP_LOCAL
export const ParentAPI = `${Domain}parents/`
export const LoginAPI = `${ParentAPI}login/`
export const ChildAPI = `${Domain}children/`
export const SubscribeAPI = `${Domain}subscribe/`
export const GetAllSubscribe = `${SubscribeAPI}get-all/user`
export const WorkshopAPI = `${Domain}workshops/`
export const GetAllWorkshop = `${WorkshopAPI}all/detail`
export const MyWorkshopAPI = `${WorkshopAPI}my`
export const GroupingAPI = `${Domain}grouping/`
export const GetAllGrouping = `${GroupingAPI}find/all?search=`
export const SubsetAPI = `${Domain}subset/`
export const GetAllSubset = `${SubsetAPI}find/all?groupingId=`
export const PostAPI = `${Domain}posts/`
export const GetAllPost = `${PostAPI}get-post/user`
export const GetLikePost = `${PostAPI}get/like`
export const CreateLikePost = `${PostAPI}toggle/like`
export const CreateViewPost = `${PostAPI}create/view`
export const CreateSavePost = `${PostAPI}create/view`
export const TeamAPI = `${Domain}teams/`
export const TeamDetail = `${TeamAPI}user/detail`
export const GetAllTeam = `${TeamAPI}user/all`
export const CommentAPI = `${Domain}comment/`
export const GetCommentDetail = `${CommentAPI}user/get-comment`
export const SetChildHeight = `${ChildAPI}set-height`
export const SetChildWeight = `${ChildAPI}set-weight`
export const HasCoach = `${ChildAPI}find-coach/user/`
export const SetChildCircumference = `${ChildAPI}set-circumference`
export const GetChildHeight = `${ChildAPI}user/get-height`
export const GetChildWeight = `${ChildAPI}user/get-weight`
export const GetChildCircumference = `${ChildAPI}user/get-circumference`
export const GetActivitiesDone = `${ChildAPI}user/get-activities`
export const ActivityAPI = `${Domain}activities/`
export const GetActivityList = `${ActivityAPI}get-activity/user`
export const ViewPoint = `${Domain}viewpoint`
export const TicketAPI = `${Domain}tickets`
export const BasketAPI = `${Domain}baskets`
export const BehavioralGoalsAPI = `${Domain}questions`
export const MetadataQuestion = `${BehavioralGoalsAPI}/metadata/question`
export const GetQuestion = `${BehavioralGoalsAPI}/get/question`
export const AnimationAPI = `${Domain}animations`
export const AnimationBaseCategoryAPI = `${AnimationAPI}/all/category`
export const AnimationCategoryAPI = `${AnimationAPI}/category/list`
export const AnimationBannerAPI = `${AnimationAPI}/banner`
export const AnimationSectionAPI = `${AnimationAPI}/user/section`
export const AnimationBaseEffectAPI = `${AnimationAPI}/base-effect`
export const AnimationReviewAPI = `${AnimationAPI}/review`
export const AnimationViewAPI = `${AnimationAPI}/view`
export const ArticleAPI = `${Domain}articles`
export const ArticleCategoryAPI = `${ArticleAPI}/category`
export const ArticleSubsetAPI = `${ArticleAPI}/subset`
export const ArticleViewAPI = `${ArticleAPI}/view`

export const ArticleLikeAPI = `${ArticleAPI}/like`
export const FoodAPI = `${Domain}foods`
export const FoodBannerAPI = `${FoodAPI}/user/banner`
export const FoodCategoryAPI = `${FoodAPI}/user/category`
export const FoodSectionAPI = `${FoodAPI}/user/section`
export const FoodByCategoryAPI = `${FoodAPI}/user/list`
export const LocationAPI = `${Domain}locations`
export const LocationBannerAPI = `${LocationAPI}/user/banner`
export const LocationCategoryAPI = `${LocationAPI}/user/category`
export const LocationCityAPI = `${LocationAPI}/user/city`
export const LocationCollectionAPI = `${LocationAPI}/user/collection`
export const LocationSectionAPI = `${LocationAPI}/user/section`
export const LocationByCategoryAPI = `${LocationAPI}/user/list`
export const VaccineAPI = `${Domain}vaccines`
export const getAllVaccine = `${VaccineAPI}/user/get-all`
export const LullabyAPI = `${Domain}lullabies`
export const LullabyByCategoryAPI = `${Domain}lullabies/user/category`
export const LastLullabyAPI = `${LullabyAPI}/last`
export const LullabyCategoryAPI = `${LullabyAPI}/category`
export const LullabyDetailAPI = `${LullabyAPI}/user/detail`
export const LullabyCommentAPI = `${LullabyAPI}/comment/user/detail`
export const LullabyViewAPI = `${LullabyAPI}/view`
export const PodcastAPI = `${Domain}podcasts`
export const PodcastBannerAPI = `${PodcastAPI}/banner/user`
export const PodcastCategoryAPI = `${PodcastAPI}/user/category`
export const PodcastSectionAPI = `${PodcastAPI}/section`
export const PodcastByCategoryAPI = `${PodcastAPI}/user/category`
export const EpisodeAPI = `${PodcastAPI}/episode/`
export const AlbumAPI = `${Domain}albums`
export const GetAllAlbum = `${AlbumAPI}/user`
export const CreateAlbumOrder = `${AlbumAPI}/create/order`
export const GetAlbumOrder = `${AlbumAPI}/get/order`
export const GetAlbumOrderUser = `${AlbumAPI}/user/order`
export const DoctorAPI = `${Domain}doctors`
export const DoctorBannerAPI = `${DoctorAPI}/user/banner`
export const DoctorCategoryAPI = `${DoctorAPI}/user/category`
export const DoctorSectionAPI = `${DoctorAPI}/user/section`
export const NotificationAPI = `${Domain}notifications/`
export const DoctorByCategoryAPI = `${DoctorAPI}/user/list`
export const BookmarkAPI = `${Domain}save`
