import Header from '../../components/detail-header'
import { MdArrowBackIosNew, MdArrowForwardIos, MdChildCare } from 'react-icons/md'
import { IoIosArrowBack } from 'react-icons/io'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ChildAPI } from '../../../../api'
import DatePicker from 'react-multi-date-picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import transition from 'react-element-popper/animations/transition'
import { toast } from 'react-toastify'
import './add-child-background.css'
import { Radio } from '@mantine/core'

function CustomButton({ direction, handleClick, disabled }) {
  return (
    <i
      onClick={handleClick}
      style={{
        padding: '0 10px',
        fontWeight: 'bold',
        color: disabled ? 'gray' : 'blue'
      }}
      className={disabled ? 'cursor-default' : 'cursor-pointer'}
    >
      {direction === 'right' ? <MdArrowBackIosNew /> : <MdArrowForwardIos />}
    </i>
  )
}

function AddChild() {
  const navigate = useNavigate()
  const info = useSelector((state) => state.information)
  const [name, setName] = useState('')
  const [sex, setSex] = useState('')
  const [date, setDate] = useState('')
  const [profile, setProfile] = useState('')
  const [profileURL, setProfileURL] = useState('')
  const logDate = (e) => {
    const inputDateString = new Date(e)
    // Parse input date string into a Date object
    const inputDate = new Date(inputDateString)

    // Extract date and time components from input Date object
    const year = inputDate.getFullYear()
    const month = inputDate.getMonth() + 1 // add 1 to adjust for 0-based indexing
    const day = inputDate.getDate()
    const hours = inputDate.getHours()
    const minutes = inputDate.getMinutes()
    const seconds = inputDate.getSeconds()

    // Format extracted components into output date string
    const outputDateString = `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

    setDate(outputDateString)
  }
  const addChild = () => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('sex', sex)
    formData.append('birthday', date)
    formData.append('profile', profile)
    formData.append('parent_id', info.id)
    axios
      .post(ChildAPI, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        toast.success(data.message)
        navigate('/child-list')
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  const handleImageChange = (e) => {
    setProfileURL(URL.createObjectURL(e.target.files[0]))
    setProfile(e.target.files[0])
  }
  return (
    <>
      <Header title="اطلاعات کودک" />
      <div className="flex flex-wrap justify-center">
        <input
          type="file"
          className="hidden"
          id="child-add"
          onChange={(e) => handleImageChange(e)}
        />
        <label
          htmlFor="child-add"
          className="w-32 h-32 rounded-full bg-blue-200 flex justify-center items-center my-10 add-child-background"
          style={{
            backgroundImage: profileURL ? `url("${profileURL}")` : '#bfdbfe'
          }}
        >
          {profileURL ? null : <MdChildCare className="text-7xl text-blue-400" />}
        </label>

        <ul className="w-full vazir-medium px-4">
          <li className="w-full flex justify-between items-center border-t pt-4 mt-3">
            <section className="w-full">
              <p className="text-gray-400 text-xs mb-1">نام و نام خانوادگی</p>
              <input
                type="text"
                className="w-full bg-transparent"
                name="child-name"
                onChange={(e) => setName(e.target.value)}
                placeholder="لطفا نام و نام خانوادگی کودک را وارد کنید"
              />
            </section>
          </li>

          <li className="w-full flex justify-between items-center border-t pt-4 mt-3">
            <section className="w-full">
              <p className="text-gray-400 text-xs">جنسیت کودک</p>
              <div className="w-full flex justify-evenly">
                <div className="flex justify-start items-center ">
                  <Radio
                    color="indigo"
                    type="radio"
                    name="sex-child"
                    id="sex-child-boy"
                    onChange={(e) => setSex(e.target.checked ? 'boy' : null)}
                  />
                  <label htmlFor="sex-child-boy">
                    <p className="text-gray-800 mt-2  relative -top-1 mr-2">پسر</p>
                  </label>
                </div>

                <div className="flex justify-start items-center">
                  <Radio
                    color="indigo"
                    type="radio"
                    name="sex-child"
                    id="sex-child-girl"
                    onChange={(e) => setSex(e.target.checked ? 'girl' : null)}
                  />
                  <label htmlFor="sex-child-girl">
                    <p className="text-gray-800 mt-2 relative -top-1 mr-2">دختر</p>
                  </label>
                </div>
              </div>
            </section>
          </li>

          <li className="w-full flex justify-between items-center border-t pt-4 mt-3">
            <section className="w-full">
              <p className="text-gray-400 text-xs"> تاریخ تولد</p>
              <div className="w-full">
                <DatePicker
                  className="rmdp-mobile"
                  onChange={(e) => logDate(e)}
                  multiple={false}
                  calendar={persian}
                  locale={persian_fa}
                  calendarPosition="bottom-right"
                  renderButton={<CustomButton />}
                  mobileLabels={{
                    OK: 'تایید',
                    CANCEL: 'بستن'
                  }}
                  animations={[
                    transition({
                      from: 35,
                      transition: 'all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)'
                    })
                  ]}
                  render={(value, openCalendar) => {
                    return (
                      <button
                        className="w-[90vw] flex justify-between items-center  mt-2"
                        onClick={openCalendar}
                      >
                        <span>{value ? value : 'لطفا تاریخ تولد را انتخاب کنید'}</span>
                        <IoIosArrowBack className="text-xl text-gray-400" />
                      </button>
                    )
                  }}
                />
              </div>
            </section>
          </li>
        </ul>
        <div className="w-full grid grid-cols-2 gap-2 px-2 mt-5">
          <button
            className=" bg-green-600 opacity-70 text-white rounded-lg py-4 mt-3 vazir-medium"
            onClick={addChild}
          >
            اضافه کردن کودک
          </button>

          <button
            className="border-2 border-green-600 text-green-600 opacity-70 text-green-600 rounded-lg py-4 mt-3 vazir-medium"
            onClick={() => {
              navigate('/?status=home')
            }}
          >
            بازگشت به منوی اصلی
          </button>
        </div>
      </div>
    </>
  )
}

export default AddChild
