// import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import Subscription from './svg/subscription-model.svg'
import { IoIosArrowForward } from 'react-icons/io'
function BuySubscription() {
  const navigate = useNavigate()
  return (
    <>
      <header>
        <div className="flex justify-center items-center mt-10 pb-4  relative px-4">
          <IoIosArrowForward
            className="text-3xl text-gray-600 border rounded absolute right-5"
            onClick={() => navigate(-1)}
          />
          <p className="vazir-medium mr-4">خرید اشتراک</p>
        </div>
      </header>
      <div className="flex justify-center items-center flex-wrap vazir-medium mt-14 px-4">
        <div className="w-48 h-48 bg-blue-200 rounded-full flex justify-center items-center">
          <img src={Subscription} alt="buy subscription matyar" />
        </div>
        <div className="w-full flex flex-col justify-center items-center flex-wrap mt-5">
          <p className="text-lg text-center"> شما اشتراک فعالی ندارید</p>
          <p className="text-sm text-gray-500 text-center w-8/12 mt-3">
            شما اشتراک فعالی ندارید برای استفاده از این خدمات باید اشتراکی خریداری کنید
          </p>
        </div>

        <button
          className="w-full bg-[#3F54CB] text-white rounded-lg py-4 vazir-medium mt-14"
          onClick={() => navigate('/list-subscription')}
        >
          خرید اشتراک
        </button>
      </div>
    </>
  )
}
export default BuySubscription
