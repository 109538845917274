import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import SwiperMain from './swiper'

function MainSection({ title, link, data, type, subLink }) {
  const navigate = useNavigate()
  return (
    <>
      <div className="w-full mt-10">
        <section className="w-full flex justify-between items-center mb-5">
          <div className="w-10/12 flex justify-start items-center">
            <div className="w-4 h-4 bg-red-600 rounded ml-5" />
            <p>{title}</p>
          </div>
          <div className="flex justify-center items-center" onClick={() => navigate(link)}>
            <span className="text-blue-800">همه</span>
            <IoIosArrowBack className="text-blue-800" />
          </div>
        </section>
        <SwiperMain data={data} type={type} link={subLink} />
      </div>
    </>
  )
}
export default MainSection
