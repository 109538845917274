import Album from '../../../choose-album/component/card/album.png'
import { useState } from 'react'
import BlueTick from '../../svg/Group 48096012.svg'
import { useDispatch } from 'react-redux'
import { addAlbum } from '../../../../../../../redux/album-slice'
function Card({ canSelect }) {
  const dispatch = useDispatch()
  const [select, setSelect] = useState(false)
  return (
    <>
      <div
        className="w-full flex flex-col justify-start items-start relative"
        onClick={() => {
          if (canSelect) {
            setSelect(!select)
            dispatch(addAlbum('id'))
          }
        }}
      >
        <section
          className="rounded-lg w-full h-24"
          style={{
            backgroundImage: `url("${Album}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
        {canSelect && (
          <>
            {select ? (
              <img
                src={BlueTick}
                alt="blue tick select matyar album"
                className="absolute -right-1 -bottom-1 w-8 h-8 border border-white rounded-full"
              />
            ) : (
              <span className="absolute -right-1 -bottom-1 block border border-2 rounded-full border-white w-8 h-8" />
            )}
          </>
        )}
      </div>
    </>
  )
}
export default Card
