import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import { FreeMode } from 'swiper'
import Member from './member'
import { useEffect, useState } from 'react'
// import { BsTwitter, BsYoutube } from 'react-icons/bs'
// import { AiOutlineInstagram } from 'react-icons/ai'
// import { FaTelegramPlane } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { Domain } from '../../../../../../api'
import { clearCoachInfo } from '../../../../../../redux/coach-slice'
import Waveform from '../audio-player'
import { IoMdCloseCircleOutline } from 'react-icons/io'

export default function Members({ members }) {
  const dispatch = useDispatch()
  const [changeChild, setChangeChild] = useState(false)
  const coachInfo = useSelector((state) => state.coachInfo)
  useEffect(() => {
    if (changeChild) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  }, [changeChild])

  return (
    <>
      {changeChild ? (
        <>
          <div
            className={`w-full fixed top-0 left-0 bg-[rgba(0,0,0,0.7)]  h-screen z-40   `}
            onClick={() => {
              setChangeChild(false)
              dispatch(clearCoachInfo())
            }}
          />

          <div className=" fixed bottom-0 w-full py-3 px-4 bg-white rounded-t-3xl z-50 vazir-medium min-h-[600px] animate__animated animate__fadeInUp animate__fast ">
            <div className="w-full bg-white rounded-t-xl py-4 flex flex-col justify-center items-center px-10 relative">
              <IoMdCloseCircleOutline
                className="absolute right-0 top-0 text-2xl text-gray-500"
                onClick={() => {
                  setChangeChild(false)
                  dispatch(clearCoachInfo())
                }}
              />
              <section
                className="w-32 h-32 rounded-full bg-red-600 "
                style={{
                  backgroundImage: `url("${Domain + coachInfo.profile}")`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              />

              <p className="mt-3 text-xl">{coachInfo.name}</p>
              <p className="text-gray-400 my-3">{coachInfo.proficiency}</p>
              {/*<section className="w-full flex flex-row-reverse justify-center items-center mt-1 my-5">*/}
              {/*  <BsYoutube className="text-red-600 mx-0.5" />*/}
              {/*  <BsTwitter className="text-blue-400 mx-0.5" />*/}
              {/*  <AiOutlineInstagram className="text-gradient mx-0.5" />*/}
              {/*  <FaTelegramPlane className="text-blue-400 mx-0.5" />*/}
              {/*</section>*/}
              <div
                dangerouslySetInnerHTML={{ __html: coachInfo.about }}
                className="text-center text-sm text-gray-600"
              />

              <div className="w-full rounded-xl border border-gray-300 mt-10">
                {coachInfo?.voice && (
                  <Waveform url={Domain + coachInfo.voice} isVoice={changeChild} />
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
      <section className="px-4 mt-10 mb-4">
        <p className="vazir-medium">اعضای تیم</p>
      </section>
      <Swiper
        slidesPerView={2.5}
        spaceBetween={5}
        freeMode={true}
        grabCursor={true}
        modules={[FreeMode]}
        className="mySwiper"
      >
        {members &&
          members.map((member) => (
            <SwiperSlide key={member.id} className="px-2">
              <Member data={member} setChangeChild={setChangeChild} />
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  )
}
