import HeaderAlbum from '../photo-album/component/header'
import Card from './component/card'
import { useEffect, useState } from 'react'
import Footer from './component/footer'
import { useDispatch, useSelector } from 'react-redux'
import { clearAlbum } from '../../../../../redux/album-slice'

function MyAlbum() {
  const dispatch = useDispatch()
  const albums = useSelector((state) => state.albumSetting.albums)
  const [canSelect, setCanSelect] = useState(false)
  useEffect(() => {
    dispatch(clearAlbum())
    setCanSelect(false)
  }, [])
  return (
    <>
      <HeaderAlbum title={canSelect ? ` ${albums.length}عکس انتخاب شد` : 'انتخاب عکس'} />
      <div className="w-full grid grid-cols-3 gap-4 px-4">
        <p className="w-full col-span-3"> امروز | ۱ فروردین</p>
        <Card canSelect={canSelect} />
      </div>
      <Footer setCanSelect={setCanSelect} />
    </>
  )
}
export default MyAlbum
