import { CgMenuGridO } from 'react-icons/cg'
import Scale from '../../svg/scale.svg'
import Head from '../../svg/head.svg'
import Height from '../../svg/height.svg'
import ScatterChart from '../chart/scatter'
import BarChart from '../chart/bar'
import { AiFillPlusCircle } from 'react-icons/ai'
import { useEffect, useState } from 'react'
import ChooseBox from '../../../../components/choose-box'
import './growth-chart.css'
import DatePicker from 'react-multi-date-picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import transition from 'react-element-popper/animations/transition'
import { IoIosArrowBack } from 'react-icons/io'
import ApexChartLine from '../chart/area'
import axios from 'axios'
import {
  GetChildCircumference,
  GetChildHeight,
  GetChildWeight,
  SetChildCircumference,
  SetChildHeight,
  SetChildWeight
} from '../../../../../../api'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

function GrowthChart() {
  const childInfo = useSelector((state) => state.childInfo)
  const [changeChild, setChangeChild] = useState(false)
  const [date, setDate] = useState('')
  const [height, setHeight] = useState(0)
  const [heightDate, setHeightDate] = useState(0)
  const [weight, setWeight] = useState(0)
  const [weightDate, setWeightDate] = useState(0)
  const [circumference, setCircumference] = useState(0)
  const [circumferenceDate, setCircumferenceDate] = useState(0)
  const [chart, setChart] = useState('all')
  const ChooseChart = () => {
    if (chart === 'all') {
      return (
        <>
          <span className="relative top-3 right-2 text-blue-800 text-sm pb-2">دور سر کودک : </span>
          <ScatterChart data={circumferenceDate} />
          <span className="relative top-3 right-2 text-blue-800 text-sm">وزن کودک : </span>
          <ApexChartLine data={weightDate} />
          <span className="relative top-3 right-2 text-blue-800 text-sm">قد کودک : </span>
          <BarChart data={heightDate} />
        </>
      )
    } else if (chart === 'scatter') {
      return <ScatterChart data={circumferenceDate} />
    } else if (chart === 'area') {
      return <ApexChartLine data={weightDate} />
    } else return <BarChart data={heightDate} />
  }
  const logDate = (e) => {
    const inputDateString = new Date(e)
    // Parse input date string into a Date object
    const inputDate = new Date(inputDateString)

    // Extract date and time components from input Date object
    const year = inputDate.getFullYear()
    const month = inputDate.getMonth() + 1 // add 1 to adjust for 0-based indexing
    const day = inputDate.getDate()
    const hours = inputDate.getHours()
    const minutes = inputDate.getMinutes()
    const seconds = inputDate.getSeconds()

    // Format extracted components into output date string
    const outputDateString = `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

    setDate(outputDateString)
  }
  const setHeightFunction = () => {
    const formData = new FormData()
    formData.append('id', childInfo.id)
    formData.append('height', height)
    formData.append('date', date)
    axios
      .post(SetChildHeight, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        toast.success('با موفقیت ثبت شد')
        setChangeChild(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  const setWeightFunction = () => {
    const formData = new FormData()
    formData.append('id', childInfo.id)
    formData.append('weight', weight)
    formData.append('date', date)
    axios
      .post(SetChildWeight, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        toast.success('با موفقیت ثبت شد')
        setChangeChild(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  const setCircumferenceFunction = () => {
    const formData = new FormData()
    formData.append('id', childInfo.id)
    formData.append('circumference', circumference)
    formData.append('date', date)
    axios
      .post(SetChildCircumference, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        toast.success('با موفقیت ثبت شد')
        setChangeChild(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  const getHeightChart = () => {
    axios
      .get(GetChildHeight + '?id=' + childInfo.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setHeightDate(data)
      })
      .catch(() => {
        setHeightDate({})
      })
  }
  const getWeightChart = () => {
    axios
      .get(GetChildWeight + '?id=' + childInfo.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setWeightDate(data)
      })
      .catch(() => {
        setWeightDate({})
      })
  }
  const getCircumferenceChart = () => {
    axios
      .get(GetChildCircumference + '?id=' + childInfo.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCircumferenceDate(data)
      })
      .catch(() => {
        setCircumferenceDate({})
      })
  }
  useEffect(() => {
    if (changeChild) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
    getWeightChart()
    getHeightChart()
    getCircumferenceChart()
  }, [changeChild])
  return (
    <>
      {changeChild ? (
        <>
          <div
            className={`w-full fixed top-0 left-0 bg-[rgba(0,0,0,0.7)] w-full h-screen z-[51] `}
            onClick={() => setChangeChild(false)}
          />
          {chart === 'scatter' ? (
            <div className="w-full px-4 vazir-medium fixed z-[52] flex justify-center items-center pb-[80px]">
              <div className="w-full px-4 bg-white rounded-xl py-4">
                <p>اندازده دور سر کودک</p>
                <span className="text-sm text-gray-400">
                  برای سنجش اندازه دور سر کودک لطفا فیلدهای زیر را پر کنید
                </span>
                <section className="w-full flex justify-between items-center border border-2 border-gray-300 rounded-xl mt-5 p-4 text-gray-400">
                  <div className="w-full">
                    <DatePicker
                      className="rmdp-mobile"
                      onChange={(e) => logDate(e)}
                      multiple={false}
                      calendar={persian}
                      locale={persian_fa}
                      calendarPosition="bottom-right"
                      mobileLabels={{
                        OK: 'تایید',
                        CANCEL: 'بستن'
                      }}
                      animations={[
                        transition({
                          from: 35,
                          transition: 'all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)'
                        })
                      ]}
                      render={(value, openCalendar) => {
                        return (
                          <button
                            className="w-[90vw] flex justify-between items-center"
                            onClick={openCalendar}
                          >
                            <span>{value ? value : 'لطفا تاریخ را انتخاب کنید'}</span>
                            <IoIosArrowBack className="text-xl text-gray-400" />
                          </button>
                        )
                      }}
                    />
                  </div>
                </section>

                <section className="w-full flex justify-between items-center border border-2 border-gray-300 rounded-xl mt-5 p-4 text-gray-400">
                  <input
                    onChange={(e) => setCircumference(e.target.value)}
                    type="number"
                    className="w-full"
                    placeholder="لطفا دور سر کودک را به سانتی متر وارد کنید"
                  />
                </section>

                <button
                  className="w-full bg-blue-800 text-white rounded-xl py-4 shadow-md mt-6"
                  onClick={() => setCircumferenceFunction()}
                >
                  ذخیره
                </button>
              </div>
            </div>
          ) : null}

          {chart === 'area' ? (
            <div className="w-full px-4 vazir-medium fixed z-[52] flex justify-center items-center pb-[80px]">
              <div className="w-full px-4 bg-white rounded-xl py-4">
                <p>وزن کودک</p>
                <span className="text-sm text-gray-400">
                  برای سنجش وزن کودک لطفا فیلدهای زیر را پر کنید
                </span>
                <section className="w-full flex justify-between items-center border border-2 border-gray-300 rounded-xl mt-5 p-4 text-gray-400">
                  <div className="w-full">
                    <DatePicker
                      className="rmdp-mobile"
                      onChange={(e) => logDate(e)}
                      multiple={false}
                      calendar={persian}
                      locale={persian_fa}
                      calendarPosition="bottom-right"
                      mobileLabels={{
                        OK: 'تایید',
                        CANCEL: 'بستن'
                      }}
                      animations={[
                        transition({
                          from: 35,
                          transition: 'all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)'
                        })
                      ]}
                      render={(value, openCalendar) => {
                        return (
                          <button
                            className="w-[90vw] flex justify-between items-center"
                            onClick={openCalendar}
                          >
                            <span>{value ? value : 'لطفا تاریخ را انتخاب کنید'}</span>
                            <IoIosArrowBack className="text-xl text-gray-400" />
                          </button>
                        )
                      }}
                    />
                  </div>
                </section>

                <section className="w-full flex justify-between items-center border border-2 border-gray-300 rounded-xl mt-5 p-4 text-gray-400">
                  <input
                    onChange={(e) => setWeight(e.target.value)}
                    type="number"
                    className="w-full"
                    placeholder="لطفا وزن کودک را به گرم وارد کنید"
                  />
                </section>

                <button
                  className="w-full bg-blue-800 text-white rounded-xl py-4 shadow-md mt-6"
                  onClick={() => setWeightFunction()}
                >
                  ذخیره
                </button>
              </div>
            </div>
          ) : null}

          {chart === 'bar' ? (
            <div className="w-full px-4 vazir-medium fixed z-[52] flex justify-center items-center pb-[80px]">
              <div className="w-full px-4 bg-white rounded-xl py-4">
                <p>قد کودک</p>
                <span className="text-sm text-gray-400">
                  برای سنجش قد کودک لطفا فیلدهای زیر را پر کنید
                </span>
                <section className="w-full flex justify-between items-center border border-2 border-gray-300 rounded-xl mt-5 p-4 text-gray-400">
                  <div className="w-full">
                    <DatePicker
                      className="rmdp-mobile"
                      onChange={(e) => logDate(e)}
                      multiple={false}
                      calendar={persian}
                      locale={persian_fa}
                      calendarPosition="bottom-right"
                      mobileLabels={{
                        OK: 'تایید',
                        CANCEL: 'بستن'
                      }}
                      animations={[
                        transition({
                          from: 35,
                          transition: 'all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)'
                        })
                      ]}
                      render={(value, openCalendar) => {
                        return (
                          <button
                            className="w-[90vw] flex justify-between items-center"
                            onClick={openCalendar}
                          >
                            <span>{value ? value : 'لطفا تاریخ را انتخاب کنید'}</span>
                            <IoIosArrowBack className="text-xl text-gray-400" />
                          </button>
                        )
                      }}
                    />
                  </div>
                </section>

                <section className="w-full flex justify-between items-center border border-2 border-gray-300 rounded-xl mt-5 p-4 text-gray-400">
                  <input
                    onChange={(e) => setHeight(e.target.value)}
                    type="number"
                    className="w-full"
                    placeholder="لطفا قد کودک را به سانتی متر وارد کنید"
                  />
                </section>

                <button
                  className="w-full bg-blue-800 text-white rounded-xl py-4 shadow-md mt-6"
                  onClick={() => setHeightFunction()}
                >
                  ذخیره
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
      <section className="flex justify-evenly items-center vazir-medium mt-5 border-b  pb-4">
        <div
          className="w-32 h-32 rounded-full flex flex-col justify-center items-center chart-active active"
          onClick={(e) => {
            ChooseBox(e, 'chart-active')
            setChart('all')
          }}
        >
          <section className="flex flex-col justify-center items-center w-20 h-20 rounded-full main-gradient-0">
            <CgMenuGridO className="text-white text-4xl" />
          </section>
          <span className="text-center text-sm mt-4">همه</span>
        </div>

        <div
          className="w-32 h-32 rounded-full flex flex-col justify-center items-center chart-active"
          onClick={(e) => {
            ChooseBox(e, 'chart-active')
            setChart('scatter')
          }}
        >
          <section className="flex flex-col justify-center items-center w-20 h-20 rounded-full bg-orange-200">
            <img src={Head} alt="child weight" />
          </section>
          <span className="text-center text-sm mt-4">اندازه دور سر </span>
        </div>
        <div
          className="w-32 h-32 rounded-full flex flex-col justify-center items-center chart-active"
          onClick={(e) => {
            ChooseBox(e, 'chart-active')
            setChart('area')
          }}
        >
          <section className="flex flex-col justify-center items-center w-20 h-20 rounded-full bg-blue-200  child-info true">
            <img src={Scale} alt="child Head" />
          </section>
          <span className="text-center text-sm mt-4">وزن کودک</span>
        </div>

        <div
          className="w-32 h-32 rounded-full flex flex-col justify-center items-center chart-active"
          onClick={(e) => {
            setChart('bar')
            ChooseBox(e, 'chart-active')
          }}
        >
          <section className="flex flex-col justify-center items-center w-20 h-20 rounded-full bg-red-200">
            <img src={Height} alt="child High" />
          </section>
          <span className="text-center text-sm mt-4">قد کودک</span>
        </div>
      </section>
      {chart === 'scatter' ? (
        <div className="w-full vazir-medium px-4 mt-4">
          <p className="text-gray-700">دورسر کودک</p>
          <p className="text-gray-700  text-sm mt-2 mb-3">
            لطفا هر سه ماه یکبار نسبت به ثبت دور سر کودک خود اقدام کنید
          </p>
          <section className="flex justify-start items-center" onClick={() => setChangeChild(true)}>
            <AiFillPlusCircle className="text-[#E2664C]  text-2xl ml-1" />
            <span className="text-[#E2664C] -top-1">دور سر کودک</span>
          </section>
        </div>
      ) : null}

      {chart === 'area' ? (
        <div className="w-full vazir-medium px-4 mt-4">
          <p className="text-gray-700">وزن کودک</p>
          <p className="text-gray-700 text-sm mt-2 mb-3">
            لطفا هر سه ماه یکبار نسبت به ثبت وزن کودک خود اقدام کنید
          </p>
          <section className="flex justify-start items-center" onClick={() => setChangeChild(true)}>
            <AiFillPlusCircle className="text-[#E2664C] text-2xl ml-1" />
            <span className="text-[#E2664C] relative -top-0.5">وزن کودک</span>
          </section>
        </div>
      ) : null}

      {chart === 'bar' ? (
        <div className="w-full vazir-medium px-4 mt-4">
          <p className="text-gray-700">قد کودک</p>
          <p className="text-gray-700  text-sm mt-2 mb-3">
            لطفا هر سه ماه یکبار نسبت به قد کودک خود اقدام کنید
          </p>
          <section className="flex justify-start items-center" onClick={() => setChangeChild(true)}>
            <AiFillPlusCircle className="text-[#E2664C] text-2xl ml-1" />
            <span className="text-[#E2664C] relative top-0.5">قد کودک</span>
          </section>
        </div>
      ) : null}
      <ChooseChart />
    </>
  )
}

export default GrowthChart
