import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton
} from 'video-react'
import './video-modal.css'

function VideoModal({ videoUrl, setShowModal, showModal }) {
  function handleClose() {
    setShowModal(false)
  }

  return (
    <>
      {showModal && (
        <>
          <div
            className="w-screen h-screen left-0 top-0 fixed z-[98] bg-[rgba(0,0,0,0.5)] "
            onClick={handleClose}
          />

          <Player autoPlay className="z-[98]   dir-ltr  ">
            <source src={videoUrl} className="z-[98]   dir-ltr" />

            <ControlBar autoHide={false}>
              <ReplayControl seconds={10} order={1.1} />
              <ForwardControl seconds={30} order={1.2} />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />

              <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
              <VolumeMenuButton disabled />
            </ControlBar>
          </Player>
        </>
      )}
    </>
  )
}

export default VideoModal
