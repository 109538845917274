import React from 'react'
import { createRoot } from 'react-dom/client' // Import createRoot instead of ReactDOM
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import 'animate.css'
import App from './App'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persist, store } from './redux/store'
import '../node_modules/video-react/dist/video-react.css'

const root = createRoot(document.getElementById('root')) // Create a rendering root

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persist}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
  })
}
