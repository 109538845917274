import HeaderAlbum from '../../album/photo-album/component/header'
import {
  ControlBar,
  CurrentTimeDisplay,
  ForwardControl,
  PlaybackRateMenuButton,
  Player,
  ReplayControl,
  TimeDivider,
  VolumeMenuButton
} from 'video-react'

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { AnimationReviewAPI, Domain } from '../../../../../api'
import Loading from '../../../../../component/loading'

function AnimationReview() {
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const name = searchParams.get('name')
  const [show, setShow] = useState(false)
  const [review, setReview] = useState({})

  const getReview = () => {
    setLoading(true)
    axios
      .get(AnimationReviewAPI + '/detail/' + id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setReview(data)
        setLoading(false)
      })
  }

  useEffect(() => {
    getReview()
  }, [])

  return (
    <>
      <HeaderAlbum title={`نقد و بررسی انیمیشن ${name}`} />
      {loading ? (
        <Loading color="#1e40af" width="100px" />
      ) : (
        <>
          <div className="dir-ltr">
            <Player>
              <source src={Domain + review.video} />

              <ControlBar autoHide={false}>
                <ReplayControl seconds={10} order={1.1} />
                <ForwardControl seconds={30} order={1.2} />
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />

                <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                <VolumeMenuButton disabled />
              </ControlBar>
            </Player>
          </div>
          <div className="w-full px-4">
            <p className="text-gray-800 mt-4">نقد و بررسی کارشناسان</p>
            <div className="w-full">
              <div className="flex justify-start items-center mt-5">
                <span
                  className="w-10 h-10 rounded-full block ml-2"
                  style={{
                    backgroundImage: `url("${Domain + review.picture}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                />
                <div className="flex flex-col justify-between items-start">
                  <span className="text-sm text-gray-800">{review.name}</span>
                  <span className="text-xs text-gray-600 mt-2">انیمیشن های رایگان</span>
                </div>
              </div>
            </div>
            <div className="w-full mt-5">
              <div className="text-sm text-gray-400 ">
                <span className="text-gray-700 text-sm">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: review?.description?.substring(0, show ? 20000000 : 90)
                    }}
                  />
                  {show ? null : '...'}
                </span>
              </div>
              {show ? (
                <section
                  className="text-red-600 flex justify-start items-center    py-1 rounded mt-1"
                  onClick={() => {
                    setShow(false)
                  }}
                >
                  <span className="text-xs">خلاصه</span>
                  <IoIosArrowUp className="text-red-600" />
                </section>
              ) : (
                <section
                  className="text-blue-600 flex justify-start items-center     py-1 rounded mt-1"
                  onClick={() => {
                    setShow(true)
                  }}
                >
                  <span className="text-xs">مطالعه بیشتر</span>
                  <IoIosArrowDown className="text-blue-600" />
                </section>
              )}
            </div>
          </div>
          <div className="w-full px-4">
            <p className="text-gray-700 mt-4 mb-2 text-sm">شاخص امتیاز دهی</p>

            {review?.indexEffect &&
              JSON.parse(review.indexEffect).map((itm) => (
                <div key={itm.title} className="w-full rounded-xl border border-gray-300 px-4 mt-2">
                  <div className="w-full flex justify-between items-center my-5">
                    <section className="w-4/12 text-sm text-gray-600">{itm.title}</section>
                    <section className="w-8/12 bg-gray-300 h-2 rounded-full overflow-hidden">
                      <span
                        className=" block h-full bg-green-400"
                        style={{
                          width: itm.percent + '%',
                          backgroundColor:
                            itm.percent > 75 ? 'green' : itm.percent > 50 ? 'orange' : 'red'
                        }}
                      />
                    </section>
                  </div>
                </div>
              ))}
          </div>
        </>
      )}
    </>
  )
}
export default AnimationReview
