import Header from '../../components/detail-header'
import { IoIosArrowBack } from 'react-icons/io'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Domain, ParentAPI } from '../../../../api'
import { toast } from 'react-toastify'
import Input from '../../../../component/input'
import { TiTick } from 'react-icons/ti'
import { MdOutlineCancel } from 'react-icons/md'
import { changeInfo } from '../../../../redux/info-slice'
function Account() {
  const dispatch = useDispatch()
  const info = useSelector((state) => state.information)
  const [name, setName] = useState('')
  const [defaultValueName, setDefaultValueName] = useState('')
  const [parent, setParent] = useState('')
  const [parentEdit, setParentEdit] = useState(false)
  const [phonenumber, setPhonenumber] = useState('')
  const [defaultValuePhonenumber, setDefaultValuePhonenumber] = useState('')
  const [email, setEmail] = useState('')
  const [defaultValueEmail, setDefaultValueEmail] = useState('')
  const [username, setUsername] = useState('')
  const [defaultValueUsername, setDefaultValueUsername] = useState('')
  // const [password, setPassword] = useState('')
  const [profileURL, setProfileURL] = useState('')
  const [profile, setProfile] = useState('')
  const getUserDetail = () => {
    const formData = new FormData()
    formData.append('id', info.id)
    axios
      .post(ParentAPI + 'get-detail', formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setDefaultValueName(data.name)
        setParent(data.parent)
        setDefaultValuePhonenumber(data.phoneNumber)
        setDefaultValueEmail(data.email)
        setDefaultValueUsername(data.username)
        setProfile(data.profile)

        dispatch(
          changeInfo({
            name: data.name,
            profile: data.profile,
            id: data.id
          })
        )
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  const handleImageChange = (e) => {
    setProfileURL(URL.createObjectURL(e.target.files[0]))
    return e.target.files[0]
  }
  const updateProfile = (e) => {
    const formData = new FormData()
    formData.append('profile', handleImageChange(e))
    axios
      .patch(ParentAPI + info.id, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setParentEdit(false)
        dispatch(
          changeInfo({
            name: data.name,
            profile: data.profile,
            id: data.id
          })
        )
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  const updateParent = () => {
    const formData = new FormData()
    formData.append('parent', parent)
    axios
      .patch(ParentAPI + info.id, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        setParentEdit(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  useEffect(() => {
    getUserDetail()
  }, [])
  return (
    <>
      <Header title="حساب کاربری" />
      <div className="flex flex-wrap justify-center">
        <input
          type="file"
          className="hidden"
          id="child-add"
          onChange={(e) => {
            updateProfile(e)
          }}
        />
        <label
          htmlFor="child-add"
          className="w-52 h-52 rounded-full  bg-yellow-200 flex justify-center items-center my-10"
          style={{
            backgroundImage:
              profileURL.length > 1 ? `url("${profileURL}")` : `url("${Domain + profile}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }}
        ></label>
        <ul className="w-full vazir-medium px-4">
          <li className="w-full flex justify-between items-center border-t pt-4 mt-3">
            <Input
              id={info.id}
              API={ParentAPI}
              Change={name}
              onChange={setName}
              Label="نام نام خانوادگی"
              defaultValue={defaultValueName}
              keyFormData="name"
            />
          </li>

          <li className="w-full flex justify-between items-center border-t pt-4 mt-3">
            <section>
              <p className="text-gray-400 text-xs">نسبت با کودک</p>
              <p className="text-gray-800 mt-2">من {parent === 'mom' ? 'مادر' : 'پدر'} هستم </p>
              {parentEdit ? (
                <div className="w-full flex justify-evenly">
                  <div className="flex justify-start items-center ">
                    <input
                      type="radio"
                      name="sex-child"
                      id="sex-child-boy"
                      onChange={(e) => setParent(e.target.checked ? 'dad' : null)}
                    />
                    <label htmlFor="sex-child-boy">
                      <p className="text-gray-800 mt-2  relative -top-1 mr-2">پدر</p>
                    </label>
                  </div>

                  <div className="flex justify-start items-center">
                    <input
                      type="radio"
                      name="sex-child"
                      id="sex-child-girl"
                      onChange={(e) => setParent(e.target.checked ? 'mom' : null)}
                    />
                    <label htmlFor="sex-child-girl">
                      <p className="text-gray-800 mt-2 relative -top-1 mr-2">مادر</p>
                    </label>
                  </div>
                </div>
              ) : null}
            </section>

            <section>
              {parentEdit ? (
                <>
                  <TiTick className="text-xl text-gray-400" onClick={updateParent} />
                  <MdOutlineCancel
                    className="text-xl text-gray-400"
                    onClick={() => {
                      setParentEdit(false)
                      getUserDetail()
                    }}
                  />
                </>
              ) : (
                <IoIosArrowBack
                  className="text-xl text-gray-400"
                  onClick={() => setParentEdit(true)}
                />
              )}
            </section>
          </li>

          <li className="w-full flex justify-between items-center border-t pt-4 mt-3">
            <Input
              id={info.id}
              API={ParentAPI}
              Change={phonenumber}
              onChange={setPhonenumber}
              Label="شماره موبایل"
              defaultValue={defaultValuePhonenumber}
              keyFormData="phoneNumber"
            />
          </li>

          <li className="w-full flex justify-between items-center border-t pt-4 mt-3">
            <Input
              id={info.id}
              API={ParentAPI}
              Change={email}
              onChange={setEmail}
              Label="ایمیل"
              defaultValue={defaultValueEmail}
              keyFormData="email"
            />
          </li>

          <li className="w-full flex justify-between items-center border-t pt-4 mt-3">
            <Input
              id={info.id}
              API={ParentAPI}
              Change={username}
              onChange={setUsername}
              Label="شهر"
              defaultValue={defaultValueUsername}
              keyFormData="username"
            />
          </li>

          {/*<li className="w-full flex justify-between items-center border-t pt-4 mt-3">*/}
          {/*  <Input*/}
          {/*    id={info.id}*/}
          {/*    API={ParentAPI}*/}
          {/*    Change={phonenumber}*/}
          {/*    onChange={setPhonenumber()}*/}
          {/*    Label="ایمیل"*/}
          {/*    defaultValue={defaultValuePhonenumber}*/}
          {/*    keyFormData="phoneNumber"*/}
          {/*  />*/}
          {/*</li>*/}

          {/*<li className="w-full flex justify-between items-center border-t pt-4 mt-3">*/}
          {/*  <section>*/}
          {/*    <p className="text-gray-400 text-xs">رمز عبور دلخواه</p>*/}
          {/*    <p className="text-gray-800 mt-2">*******</p>*/}
          {/*  </section>*/}

          {/*  <section>*/}
          {/*    <IoIosArrowBack className="text-xl text-gray-400" />*/}
          {/*  </section>*/}
          {/*</li>*/}
        </ul>
      </div>
    </>
  )
}

export default Account
