import { useEffect, useRef, useState } from 'react'
import Layout from '../index'
import './activity.css'
import axios from 'axios'
import { Domain, GetAllGrouping, GetAllPost, GetAllSubset } from '../../../../api'
import { toast } from 'react-toastify'
import { CgMenuGridO } from 'react-icons/cg'
import Card from './card'
import returnTime from '../../../../convert-time'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import Loading from '../../../../component/loading'
import { useLocation, useNavigate } from 'react-router-dom'
function Activity() {
  const navigate = useNavigate()
  const childInfo = useSelector((state) => state.childInfo)
  const [groupingList, setGroupingList] = useState([])
  const [AllSubset, setAllSubset] = useState([])
  const [AllPost, setAllPost] = useState([])
  const [loadingCategory, setLoadingCategory] = useState(true)
  const [loadingSubset, setLoadingSubset] = useState(true)
  const [loadingPost, setLoadingPost] = useState(false)
  const [groupingIdState, setGroupingIdState] = useState('')
  const [subsetIdState, setSubsetIdState] = useState('')
  const [page, setPage] = useState(1)
  const listInnerRef = useRef()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const catId = searchParams.get('catId')
  const subId = searchParams.get('subId')
  const getAllPost = (groupingId, subsetId) => {
    setSubsetIdState(subsetId)
    axios
      .get(
        GetAllPost +
          `?groupingId=${groupingId}&subsetId=${subsetId}&child_id=${childInfo.id}&page=${page}`,
        {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        }
      )
      .then((res) => res.data)
      .then((data) => {
        if (page === 1) {
          setAllPost(data)
        } else {
          setAllPost([...AllPost, ...data])
        }

        setLoadingPost(false)
      })
      .catch(() => {
        setLoadingPost(false)
      })
  }
  const getAllSubset = (id) => {
    setPage(1)
    setGroupingIdState(id)
    axios
      .get(GetAllSubset + id + '&search=', {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')} `
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setLoadingSubset(false)
        setAllSubset(data)
        getAllPost(id, 'all')
      })
      .catch((e) => {
        toast.error(e.response.data.message)
        setLoadingSubset(false)
      })
  }
  const getAllGrouping = () => {
    setPage(1)
    axios
      .get(GetAllGrouping, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setGroupingList(data)
        setTimeout(() => {
          getAllSubset(catId ? catId : data[0].id)
        }, 50)

        setSubsetIdState('all')

        setLoadingCategory(false)
      })
      .catch((e) => {
        setLoadingCategory(false)
        toast.error(e.response.data.message)
      })
  }
  useEffect(() => {
    getAllGrouping()
    if (catId) {
      setGroupingIdState(catId)
    }
    if (subId) {
      setGroupingIdState(subId)
    }
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    return () => {
      document.getElementsByTagName('body')[0].style.overflow = ''
    }
  }, [])
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current

      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }

  return (
    <>
      <Layout>
        <div className="w-full bg-white rounded-t-2xl   relative -top-5 pt-4 ">
          <section className="w-full flex justify-between items-center  border-b-2 border-b-gray-200 pb-2">
            {loadingCategory ? (
              <div className="px-2 relative flex justify-between items-center w-full">
                <Skeleton count={1} height={40} width={100} duration={2} />
                <Skeleton count={1} height={40} width={100} duration={2} />
                <Skeleton count={1} height={40} width={100} duration={2} />
              </div>
            ) : groupingList.length > 0 ? (
              groupingList.map((grouping) => (
                <section
                  key={grouping.id}
                  className={`w-4/12 flex justify-center items-center opacity-70 cursor-pointer ${
                    groupingIdState == grouping.id ? 'grouping-active' : null
                  }`}
                  onClick={() => {
                    getAllSubset(grouping.id)
                    getAllPost(grouping.id, 'all')
                    navigate('?catId=' + grouping.id)
                  }}
                >
                  <span>{grouping.title}</span>{' '}
                </section>
              ))
            ) : null}
          </section>

          {loadingSubset ? (
            <div className="relative flex justify-center items-center">
              <Skeleton
                circle
                height="80px"
                width="80px"
                containerClassName="relative w-full flex justify-between items-center mt-2"
                count={3}
              />
            </div>
          ) : (
            <>
              <div className="w-full flex justify-start items-center mt-2 shadow-md">
                {Array.isArray(AllSubset) ? (
                  <div className="flex-container">
                    <div className="w-28 h-28 rounded-full flex flex-col justify-center items-center cursor-pointer">
                      <section
                        className={`flex flex-col justify-center items-center w-20 h-20 rounded-full main-gradient-0 select-section  ${
                          subsetIdState === 'all' ? 'active' : null
                        }`}
                        onClick={() => {
                          getAllPost(groupingIdState, 'all')
                          navigate('?catId=' + groupingIdState + '&subId=' + 'all')
                        }}
                      >
                        <CgMenuGridO className="text-white text-4xl" />
                      </section>
                      <span className="text-center text-sm mt-1">همه</span>
                    </div>
                    {AllSubset.map((subset) => (
                      <div
                        key={subset.id}
                        className="w-28 h-28 rounded-full flex flex-col justify-center items-center cursor-pointer mx-1"
                        onClick={() => {
                          getAllPost(groupingIdState, subset.id)
                          navigate('?catId=' + groupingIdState + '&subId=' + subset.id)
                        }}
                      >
                        <section
                          className={`flex flex-col justify-center items-center w-20 h-20 rounded-full child-info true select-section ${
                            subsetIdState == subset.id ? 'active' : null
                          }`}
                          style={{
                            backgroundColor: subset.color
                          }}
                        >
                          <img src={Domain + subset.img} alt="at home" />
                        </section>
                        <span className="text-center text-sm mt-1">{subset.title}</span>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </>
          )}

          <div
            ref={listInnerRef}
            onScroll={onScroll}
            className="w-full flex justify-start items-center mt-2 flex-wrap overflow-y-scroll  pb-20 px-2"
            style={{
              maxHeight: 'calc(100vh - 330px)'
            }}
          >
            {AllPost?.length > 0
              ? AllPost?.map((post) => (
                  <Card
                    id={post?.id}
                    key={post?.id}
                    name={post?.title}
                    title={post?.subset && post?.subset.title}
                    like={post?.numLikes}
                    view={post?.numViews}
                    time={returnTime(post?.created_at).split(',')[0]}
                    src={Domain + post?.poster}
                    lock={post?.lock}
                  >
                    <div
                      className="text-xs text-gray-800 h-[17px] overflow-hidden text-start"
                      dangerouslySetInnerHTML={{
                        __html: post.text
                      }}
                    />
                  </Card>
                ))
              : null}
            {loadingPost && <Loading color="#1e40af" />}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Activity
