import Family from './svg/family.svg'
import Clock from './svg/clock.svg'
import Document from './svg/document.svg'
import Question from './svg/question-square.svg'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { MetadataQuestion } from '../../../../../../api'
import { useEffect, useState } from 'react'
function ReadyToGo() {
  const [data, setData] = useState({})
  const navigate = useNavigate()
  const childInfo = useSelector((state) => state.childInfo)
  const getMetaData = () => {
    axios
      .get(MetadataQuestion + '?child_id=' + childInfo.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setData(data)
      })
  }
  useEffect(() => {
    getMetaData()
  }, [])
  return (
    <>
      <div className="w-full">
        <div className="w-full flex justify-center items-center">
          <img src={Family} alt="family behavior goal" className="mt-2 w-9/12" />
        </div>
        <div className="w-full flex justify-center flex-col">
          <p className="text-center my-5 text-gray-700">آزمون اهداف رفتاری کودکان</p>
          <span className="text-gray-400 text-sm text-center px-4">
            آزمون اهداف رفتاری برای آشنایی بیشتر با کودک و خانواده او طراحی شده تا مشاوران بتوانند
            بهتر به کودکان مشاوره بدهند
          </span>
        </div>
        <div className="w-full grid grid-cols-3 gap-2 px-4 mt-5">
          <section className="w-full flex flex-col justify-center items-center border border-gray-300 rounded-xl">
            <img src={Document} alt="document behavioral goals" className="mt-3 w-6 h-6" />
            <span className="text-sm text-gray-700 my-3">تعداد انجام</span>
            <p className="mb-3 text-gray-500 text-sm">
              <span>{data?.countDone}</span>
              &nbsp;
              <span>نفر</span>
            </p>
          </section>

          <section className="w-full flex flex-col justify-center items-center border border-gray-300 rounded-xl">
            <img src={Clock} alt="clock behavioral goals" className="mt-3 w-6 h-6" />
            <span className="text-sm text-gray-700 my-3">زمان تقریبی</span>
            <p className="mb-3 text-gray-500 text-sm">
              <span>90</span>
              &nbsp;
              <span>دقیقه</span>
            </p>
          </section>

          <section className="w-full flex flex-col justify-center items-center border border-gray-300 rounded-xl">
            <img src={Question} alt="question behavioral goals" className="mt-3 w-6 h-6" />
            <span className="text-sm text-gray-700 my-3">تعداد سوالات</span>
            <p className="mb-3 text-gray-500 text-sm">
              <span>{data?.count}</span>
              &nbsp;
              <span>سوال</span>
            </p>
          </section>
        </div>
        <div className="w-full px-4 mt-4">
          <button
            className="w-full bg-[#3F54CB]  text-white rounded-lg py-4 vazir-medium"
            onClick={() => navigate('/question')}
          >
            آمده ام بزن بریم
          </button>
        </div>
      </div>
    </>
  )
}
export default ReadyToGo
