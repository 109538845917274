import './card.css'
// import { FcLike } from 'react-icons/fc'
// import { AiFillEye } from 'react-icons/ai'
import { IoIosLock } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import Heart from '../../articles/detail/svg/Heart.svg'
import Show from '../../articles/detail/svg/Show.svg'
// import swal from 'sweetalert'
function Card({
  src,
  name,
  children,
  like,
  view,
  time,
  title,
  lock,
  id
  // coachImage,
}) {
  const navigate = useNavigate()
  return (
    <>
      <div
        className={`relative w-full ${lock ? 'mt-5' : null} `}
        onClick={() => {
          if (lock) {
            navigate('/buy-subscription')
          } else {
            navigate(`/post-detail?id=${id}`)
          }
        }}
      >
        {lock ? (
          <div
            onClick={() => navigate('/buy-subscription')}
            className="w-10 h-10 -top-0.5 left-0 bg-red-600 absolute rounded-full flex flex-col justify-center items-center z-[3] vazir-medium "
            style={{
              boxShadow: '0px 0px 10px red'
            }}
          >
            <span className="text-white text-3xl z-50">
              <IoIosLock />
            </span>
          </div>
        ) : null}
        <div className="w-full flex justify-end items-start h-32 relative coach-shadow rounded-xl mt-5">
          <section
            style={{
              backgroundImage: `url("${src}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
            className="w-img-coach-item h-full block bg-gray-600 absolute right-0 rounded-xl"
          />
          <section className="  h-full vazir-medium rounded-xl px-4 w-8/12 flex flex-col justify-between items-start mt-2">
            <div className="flex justify-between items-center w-full">
              <section className="flex flex-col">
                <span className="vazir-medium text-white main-gradient-270 opacity-90 px-2 py-1 text-xs text-center rounded-full w-fit">
                  {title}
                </span>
                <p className="text-sm mt-2">{name}</p>
                <p className="text-gray-600 text-xs text-gray-500 mt-1">{children}</p>
              </section>
            </div>

            <section className="w-full flex justify-between items-center relative -top-4">
              <section className="flex justify-evenly items-center mt-4">
                <section className="flex justify-start items-center ml-2">
                  <img src={Heart} alt="heart" className="ml-1 relative -top-0.5" />
                  <span className="text-xs text-gray-400">{like}</span>
                </section>
                <span className="block h-3 w-[1px] bg-gray-300 opacity-70 ml-2" />
                <section className="flex justify-start items-center">
                  <img src={Show} className="text-blue-700 ml-1 relative -top-0.5" />
                  <span className="text-xs text-gray-400">{view}</span>
                </section>
              </section>
              <section className="mt-4 text-xs text-gray-400">{time}</section>
            </section>
          </section>
        </div>
      </div>
    </>
  )
}

export default Card
