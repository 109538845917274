import ReactApexChart from 'react-apexcharts'

const BarChart = ({ data }) => {
  return (
    <div id="chart">
      {data && data.options && data.series && data.options.chart && data.options.chart.type ? (
        <ReactApexChart options={data.options} series={data.series} type="bar" height={280} />
      ) : null}
    </div>
  )
}

export default BarChart
