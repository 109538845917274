import PlayVideo from '../detail-activities/svg/playicon.svg'
import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import Accordion from './accordion'
import CountUp from 'react-countup'

function About() {
  const navigate = useNavigate()
  return (
    <>
      <header>
        <div className="flex justify-start items-center mt-10 pb-4 relative px-4">
          <IoIosArrowForward
            className="text-3xl text-gray-600 border rounded"
            onClick={() => navigate(-1)}
          />
          <p className="vazir-medium mr-4">درباره ی ماتیار</p>
        </div>
      </header>
      <div className="px-4">
        <section className="w-full relative mt-6">
          <section className="absolute w-full h-full bg-black opacity-60 rounded-2xl" />
          <img
            className="w-full h-full rounded-2xl"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png"
            alt="test"
          />
          <img
            src={PlayVideo}
            alt="play video"
            className="z-50 w-16 absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 cursor-pointer"
          />
        </section>
        <section className="px-4">
          <section className="w-full bg-white vazir-medium flex justify-evenly items-center shadow-md py-2 rounded-lg -translate-y-1/2 px-2">
            <div className="w-4/12 px-1">
              <section className="bg-gray-200 rounded-lg flex justify-center items-center py-2 flex flex flex-col justify-center items-center">
                <p className="text-center">
                  <CountUp end={850} />
                </p>
                <p className="text-center text-gray-600 text-sm">کودک فعال</p>
              </section>
            </div>
            <div className="w-4/12 px-1">
              <section className="bg-gray-200 rounded-lg flex justify-center items-center py-2 flex flex flex-col justify-center items-center">
                <p className="text-center">
                  <CountUp end={38} />
                </p>
                <p className="text-center text-gray-600 text-sm">مشاور </p>
              </section>
            </div>
            <div className="w-4/12 px-1">
              <section className="bg-gray-200 rounded-lg flex justify-center items-center py-2 flex flex flex-col justify-center items-center">
                <p className="text-center">
                  <CountUp end={12} />
                </p>
                <p className="text-center text-gray-600 text-sm">تیم تخصصی</p>
              </section>
            </div>
          </section>
        </section>
        <section className="vazir-medium border border-gray-200 shadow rounded-xl py-6 px-4">
          <p className="text-sm">درباره ی ماتیار</p>
          <p className="text-xs text-gray-400 mt-4">
            مسیر فرزند پروری مسیری پر چالش است.مادران و پدران با وجود همه توانمندی هایشان گاه در این
            مسیر دچارر دارند.ماتیار قصد دارد در این مسیر همراه و هم نورد ان ها باشند شانه به شانه
            کنار هم والدین ایرانی فارغ از همه مرزها هستیم
          </p>
        </section>
        <section className="vazir-medium border border-gray-200 shadow rounded-xl py-6 px-4 mt-5">
          <p className="text-sm">فلسفه ی ماتیار</p>
          <p className="text-xs text-gray-400 mt-4">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک
            است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
            تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
          </p>
        </section>

        <section className="mt-6">
          <Accordion className="bg-[#E2664C]" title="ماموریت ها">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک
            است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
            تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
          </Accordion>
        </section>

        <section className="mt-6">
          <Accordion className="bg-[#E2664C]" title="ماموریت ها">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک
            است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
            تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
          </Accordion>
        </section>

        <section className="mt-6">
          <Accordion className="bg-[#E2664C]" title="ماموریت ها">
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک
            است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
            تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد
          </Accordion>
        </section>
      </div>
      <br />
      <br />
      <br />
      <br />
    </>
  )
}

export default About
