import { useEffect, useRef, useState } from 'react'

const CircleBorder = () => {
  const canvasRef = useRef(null)
  const [count, setCount] = useState(0)
  const endValue = 9.5
  const duration = 1060000 // in milliseconds
  const increment = (endValue / duration) * 10

  useEffect(() => {
    const interval = setInterval(() => {
      if (count >= endValue) {
        clearInterval(interval)
      } else {
        setCount((prevCount) => prevCount + increment)
      }
    }, 1)

    return () => clearInterval(interval)
  }, [count, endValue, increment])
  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')

    // Set the canvas size
    canvas.width = 174 // Adjust the width and height as needed
    canvas.height = 174

    // Clear the canvas
    context.clearRect(0, 0, canvas.width, canvas.height)

    // Draw the circle border
    const centerX = canvas.width / 2
    const centerY = canvas.height / 2
    const radius = Math.min(centerX, centerY) - 5 // Adjust the border thickness

    context.beginPath()
    context.arc(centerX, centerY, radius, 0, count)
    context.lineWidth = 2 // Adjust the border thickness
    context.strokeStyle = '#E2664C' // Set the border color
    context.stroke()
  }, [count])

  return <canvas ref={canvasRef} className="absolute z-[90]" />
}

export default CircleBorder
