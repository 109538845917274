import HeaderAlbum from '../../album/photo-album/component/header'
import DatePicker from 'react-multi-date-picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import transition from 'react-element-popper/animations/transition'
import { UilCalendarAlt } from '@iconscout/react-unicons'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DateObject from 'react-date-object'
import { RiMessage3Fill } from 'react-icons/ri'
import { FaPhone } from 'react-icons/fa'
import { IoVideocam } from 'react-icons/io5'

function DoctorReserve() {
  const childInfo = useSelector((state) => state.childInfo)

  const [state, setState] = useState({ format: 'dddd DD MMMM YYYY' })
  const convert = (date, format = state.format) => {
    let object = { date, format }

    setState({
      persian: new DateObject(object).format(),
      jsDate: date.toDate(),
      ...object
    })
  }

  const dateObjectFunction = async (date = Date.now()) => {
    const datePicker = await new DateObject(date)
    datePicker.calendar = await persian
    datePicker.locale = await persian_fa

    await convert(datePicker)
  }

  useEffect(() => {
    dateObjectFunction()
  }, [childInfo.id])

  return (
    <>
      <HeaderAlbum title="رزرو" />
      <div className="w-full px-4">
        <p className="text-gray-700 my-5">رزرو مشاور تلفنی</p>
        <div className="w-full">
          <DatePicker
            className="rmdp-mobile"
            onChange={(e) => {
              convert(e)
            }}
            multiple={false}
            calendar={persian}
            locale={persian_fa}
            calendarPosition="bottom-right"
            mobileLabels={{
              OK: 'تایید',
              CANCEL: 'بستن'
            }}
            animations={[
              transition({
                from: 35,
                transition: 'all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)'
              })
            ]}
            render={(value, openCalendar) => {
              return (
                <button
                  className="w-[95vw] flex justify-between items-center  mt-2"
                  onClick={openCalendar}
                >
                  <span>{state.persian}</span>

                  <UilCalendarAlt className="text-gray-700 relative left-4" />
                </button>
              )
            }}
          />
        </div>
        <div className="w-full">
          <p className="text-gray-700 mt-5">ساعت مشاوره را انتخاب کنید:</p>
          <div className="w-full grid grid-cols-3 gap-4 pt-5">
            <section className="w-full py-2 px-2 rounded-full border-2 border-[#3F54CB] text-[#3F54CB] flex justify-center items-center my-1">
              08:00 - 09:00
            </section>
          </div>
        </div>
        <div className="w-full">
          <p className="text-gray-700 mt-5">هزینه ویزیت:</p>
          <div className="w-full rounded-lg border border-gray-300 flex justify-between items-center px-4 py-2 mt-5">
            <section className="w-12 h-12 flex justify-center items-center bg-blue-300 rounded-full">
              <RiMessage3Fill className="text-[#3F54CB] text-2xl" />
            </section>
            <section className="flex flex-col justify-start items-start">
              <p className="text-gray-700 text-sm">مشاوره متنی</p>
              <span className="text-gray-600 text-xs">از طریق پیامرسان ویزیت میشوید</span>
            </section>
            <section className="flex justify-start items-center text-[#3F54CB]">
              <span className="text-xs">100.000</span>
              <span className="text-xs">تومان</span>
            </section>
          </div>

          <div className="w-full rounded-lg border border-gray-300 flex justify-between items-center px-4 py-2 mt-5">
            <section className="w-12 h-12 flex justify-center items-center bg-blue-300 rounded-full">
              <FaPhone className="text-[#3F54CB] text-2xl" />
            </section>
            <section className="flex flex-col justify-start items-start">
              <p className="text-gray-700 text-sm">مشاوره تلفنی</p>
              <span className="text-gray-600 text-xs">از طریق پیامرسان ویزیت میشوید</span>
            </section>
            <section className="flex justify-start items-center text-[#3F54CB]">
              <span className="text-xs">100.000</span>
              <span className="text-xs">تومان</span>
            </section>
          </div>

          <div className="w-full rounded-lg border border-gray-300 flex justify-between items-center px-4 py-2 mt-5">
            <section className="w-12 h-12 flex justify-center items-center bg-blue-300 rounded-full">
              <IoVideocam className="text-[#3F54CB] text-2xl" />
            </section>
            <section className="flex flex-col justify-start items-start">
              <p className="text-gray-700 text-sm">مشاوره ویدیویی</p>
              <span className="text-gray-600 text-xs">از طریق پیامرسان ویزیت میشوید</span>
            </section>
            <section className="flex justify-start items-center text-[#3F54CB]">
              <span className="text-xs">100.000</span>
              <span className="text-xs">تومان</span>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
export default DoctorReserve
