import ListSubscription from '../../list-subscription'

function Subscribe() {
  return (
    <>
      <ListSubscription />
    </>
  )
}
export default Subscribe
