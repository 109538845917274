import StarsRating from 'react-star-rate'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { TeamAPI } from '../../../../../../api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import PlayVideo from '../../../post-detail/svg/playicon.svg'
import {
  ControlBar,
  CurrentTimeDisplay,
  ForwardControl,
  PlaybackRateMenuButton,
  Player,
  ReplayControl,
  TimeDivider,
  VolumeMenuButton
} from 'video-react'

function PicBox({
  ageDetail,
  title,
  commentCount,
  ID,
  avgRate,
  src,
  userAlreadyJoin,
  setShowModal,
  showModal,
  videoUrl
}) {
  const [userJoin, setUserJoin] = useState(userAlreadyJoin)
  const childInfo = useSelector((state) => state.childInfo)
  const navigate = useNavigate()
  const updateChildTeam = () => {
    const formData = new FormData()
    formData.append('child_id', childInfo.id)
    axios
      .patch(TeamAPI + ID, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then(() => {
        toast.success('شما هم اکنون عضو تیم شده اید')
        setUserJoin(true)
      })
      .catch((e) => {
        toast.error(e.response.data)
        if (e.response.data.statusCode == 412) {
          navigate('/buy-subscription')
        }
      })
  }

  return (
    <>
      {!showModal ? (
        <>
          <img className="w-full rounded-2xl " src={src} alt="test" />
          <section
            onClick={() => setShowModal(true)}
            className=" w-14 absolute top-10 left-1/2 -translate-x-1/2 translate-y-1/2 cursor-pointer bg-white p-4 rounded-full"
          >
            <img src={PlayVideo} alt="play video" className="w-10" />
          </section>
        </>
      ) : (
        <>
          <div
            className="fixed top-0 left-0  w-full h-screen bg-[rgba(0,0,0,0.3)] z-[98] animate__animated animate__fadeIn"
            onClick={() => setShowModal(false)}
          />

          <Player
            autoPlay
            className="z-[98]   dir-ltr  rounded-2xl overflow-hidden  animate__animated animate__fadeIn"
          >
            <source
              src={videoUrl}
              className="z-[98]   dir-ltr  rounded-2xl overflow-hidden   animate__animated animate__fadeIn"
            />

            <ControlBar autoHide={false}>
              <ReplayControl seconds={10} order={1.1} />
              <ForwardControl seconds={30} order={1.2} />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />

              <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
              <VolumeMenuButton disabled />
            </ControlBar>
          </Player>
        </>
      )}

      <div
        className={`top-0 px-4  transition duration-150 ease-in-out relative    ${
          showModal == false ? '-top-10' : 'top-0 mb-10'
        } `}
      >
        <div className="w-full rounded-2xl vazir-medium bg-white drop-shadow-lg px-3  relative border border-gray-200 py-5">
          <section className="flex flex-col justify-center items-center ">
            <p className="mt-1 text-lg text-center">{title}</p>
            <p className="text-gray-400 text-sm text-center">{ageDetail}</p>
            <section className="flex justify-center items-center relative ">
              <div className="scale-50">
                <StarsRating
                  disabled={true}
                  defaultValue={avgRate}
                  value={avgRate}
                  classNamePrefix="text-xs"
                  direction="rtl"
                  allowHalf={false}
                />
                <span className="text-gray-600 text-[20px]">({commentCount}نظر)</span>
              </div>
            </section>
          </section>
          <section className="flex justify-center items-center relative ">
            {userJoin ? (
              <button className="text-white w-10/12 rounded-xl bg-green-600 py-2 shadow-lg mb-4 mt-1">
                شما هم اکنون عضو تیم هستید
              </button>
            ) : (
              <button
                className="text-white w-10/12 rounded-xl bg-[#3F54CB] py-2 shadow-lg mb-4 mt-1"
                onClick={updateChildTeam}
              >
                رزرو تیم
              </button>
            )}
          </section>
        </div>
      </div>
    </>
  )
}

export default PicBox
