import { useEffect, useState } from 'react'
import { UilAngleDown, UilBars } from '@iconscout/react-unicons'
import { IoNotificationsSharp } from 'react-icons/io5'
import { AiFillPlusCircle } from 'react-icons/ai'
import './header.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Domain, NotificationAPI, ParentAPI } from '../../../../api'
import { useDispatch, useSelector } from 'react-redux'
import { changeChildInfo, clearChildInfo } from '../../../../redux/child-slice'
import returnTime from '../../../../convert-time'
import { toast } from 'react-toastify'
function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const info = useSelector((state) => state.information)
  const childInfo = useSelector((state) => state.childInfo)
  const [changeChild, setChangeChild] = useState(false)
  const [childList, setChildList] = useState([])
  const [countNotification, setCountNotification] = useState(0)
  const getChildParent = () => {
    const formData = new FormData()
    formData.append('id', info.id)
    axios
      .post(ParentAPI + 'get-child/list', formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setChildList(data.children)
        if (data.children.length == 0) {
          dispatch(clearChildInfo())
        }
      })
      .catch(() => {
        dispatch(clearChildInfo())
      })
  }
  const getNotificationCount = () => {
    axios
      .get(NotificationAPI + 'count/' + childInfo.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCountNotification(data)
      })
  }

  useEffect(() => {
    getChildParent()
    if (childInfo?.id) {
      getNotificationCount()
    }
    if (changeChild) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto'
    }
  }, [changeChild])
  return (
    <header className={`w-full header-bg `}>
      {changeChild ? (
        <>
          <div
            className={`w-full fixed top-0 left-0 bg-[rgba(0,0,0,0.7)] h-screen z-[51] `}
            onClick={() => setChangeChild(false)}
          />
          <div className="w-full px-4 vazir-medium fixed z-[52] top-32 ">
            <div className="w-full px-4 bg-white rounded-xl py-4">
              <div className=" overflow-hidden max-h-[320px] overflow-y-scroll">
                {childList && childList.length > 0
                  ? childList.map((child) => (
                      <section
                        key={child.id}
                        className={`w-full flex justify-start items-center border border ${
                          child.id === childInfo.id ? 'border-orange-700 ' : 'border-gray-300'
                        } rounded-xl mt-5 p-4`}
                        onClick={() => {
                          setChangeChild(false)
                          dispatch(
                            changeChildInfo({
                              name: child.name,
                              id: child.id,
                              birthday: child.birthday,
                              team: child.team,
                              profile: child.profile
                            })
                          )

                          toast(child.name + ' جان انتخاب شد ')
                        }}
                      >
                        <section className="w-14 h-14  rounded-xl overflow-hidden ml-2 flex justify-center items-center pt-4 px-1">
                          <img
                            src={Domain + child.profile}
                            alt="matyar-child"
                            className="h-14 w-14"
                          />
                        </section>
                        <section>
                          <p className="text-gray-700 ">{child.name}</p>
                          <p className="text-blue-700 text-sm">
                            {child.birthday ? returnTime(child.birthday).split(',')[0] : null}
                          </p>
                          <p className="text-gray-400 text-xs">
                            {child.childTeam &&
                            child.childTeam.length > 0 &&
                            child.childTeam[0].team
                              ? ' تیم ' + child.childTeam[0].team.title
                              : 'کودک شما تیم ندارد!'}
                          </p>
                        </section>
                      </section>
                    ))
                  : null}
              </div>
              <section
                className="w-full flex justify-start items-center border border-gray-300 rounded-xl mt-5 px-4 py-3"
                onClick={() => navigate('/add-child')}
              >
                <AiFillPlusCircle className="text-indigo-700 text-4xl ml-2" />

                <p className="text-indigo-700"> افزودن کودک جدید</p>
              </section>
            </div>
          </div>
        </>
      ) : null}

      <div className="flex justify-between items-center py-5 px-2 ">
        <div className="flex items-center">
          <section>
            <section className="w-16 h-16  rounded-full border-2 border-gray-50 relative z-20">
              <img
                src={Domain + info.profile}
                alt="user-icon"
                className="h-full w-full rounded-full z-20"
              />
            </section>
            <section className="arrow-image" onClick={() => setChangeChild(true)}>
              <UilAngleDown className="absolute text-blue-400" />
            </section>
          </section>
          <section className="mr-5">
            <p className="vazir-medium text-white text-sm"> سلام </p>
            {childInfo && childInfo.name.length > 0 ? (
              <p className="vazir-medium text-white text-sm"> حساب کاربری {childInfo.name} جان</p>
            ) : (
              <p className="vazir-medium text-white text-sm"> لطفا کودک را انتخاب کنید</p>
            )}
          </section>
        </div>
        <div className="flex justify-start items-center">
          {childInfo?.id && (
            <div className="relative ">
              {countNotification > 0 ? (
                <span
                  className="absolute -top-4 -right-7 w-7 h-7 bg-red-700
             rounded-full border border-blue-700 -translate-x-1/2 text-white flex justify-center items-center"
                >
                  <span className="text-xs">{countNotification}</span>
                </span>
              ) : null}
              <IoNotificationsSharp
                className="text-2xl ml-2 text-white"
                onClick={() => navigate('/notification')}
              />
            </div>
          )}

          <UilBars onClick={() => navigate('/setting')} className="text-white" />
        </div>
      </div>
    </header>
  )
}
export default Header
