import { IoClose } from 'react-icons/io5'
import { BiCopy } from 'react-icons/bi'
import { useState } from 'react'
import './offerNotification.css'
function OfferNotificationItem({ data, closeNotification, updateNotification }) {
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }
  return (
    <>
      <div className="w-full px-4 mt-2">
        {isCopied && (
          <div className={`success-message animate__animated animate__jello`}>
            کد در کلپیورد شما ذخیره شد
          </div>
        )}
        <div
          className="w-full flex justify-start items-start  border-b border-b-gray-300"
          onClick={() => updateNotification()}
        >
          <section className="w-full px-2">
            <div className="w-full flex justify-between items-start">
              <span className="text-gray-700">{data?.title}</span>
              <IoClose className="text-xl text-gray-500" onClick={closeNotification} />
            </div>
            <div className="text-sm text-gray-400  mt-1">
              <span className="text-gray-400 text-sm">{data?.text}</span>
            </div>
            <div className="w-full flex justify-between items-start mt-3 pb-2">
              <section className="w-full bg-white rounded bg-blue-200 border border-dashed border-2 border-blue-600 flex justify-between items-center py-2 px-4">
                <section className="w-6/12 bg-white rounded flex justify-center items-center px-2">
                  <span className="text-blue-600">{data?.offer?.code}</span>
                </section>
                <section
                  className="w-6/12 flex justify-end items-center px-2"
                  onClick={() => copyToClipboard(data?.offer?.code)}
                >
                  <span className="text-sm text-blue-700">کپی کردن</span>
                  <BiCopy className="mr-2 text-blue-700" />
                </section>
              </section>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
export default OfferNotificationItem
