import Album from '../../../choose-album/component/card/album.png'
import Saved from '../../svg/Group 48096021.svg'
function Card() {
  return (
    <>
      <div className="w-full flex flex-col justify-start items-start relative">
        <section
          className="rounded-xl w-full h-32"
          style={{
            backgroundImage: `url("${Album}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
        <img src={Saved} alt="saved album" className="absolute left-2 top-2" />
        <p className="text-gray-800 mt-1 text-sm">جاذبه های شمال</p>
      </div>
    </>
  )
}
export default Card
