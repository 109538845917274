// import { BsTwitter, BsYoutube } from 'react-icons/bs'
// import { AiOutlineInstagram } from 'react-icons/ai'
// import { FaTelegramPlane } from 'react-icons/fa'

import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'
import { Domain } from '../../../../../../../api'
import { useDispatch } from 'react-redux'
import { changeCoachInfo } from '../../../../../../../redux/coach-slice'

function Member({ data, setChangeChild }) {
  const dispatch = useDispatch()
  return (
    <>
      <section className="w-full my-1 vazir-medium pl-1">
        <div className="w-full rounded-lg flex flex-col justify-start items-center shadow-coach-member">
          <section
            className="w-16 h-16 rounded-full block   mt-5"
            style={{
              backgroundImage: `url("${Domain + data.profile}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          />
          <p className="text-sm text-gray-700 text-center">{data.name}</p>
          <span className="text-gray-500 text-xs hidden">روانشناس بالینی</span>
          {/*<section className="w-full flex flex-row-reverse justify-center items-center mt-1">*/}
          {/*  <BsYoutube className="text-red-600 mx-0.5" />*/}
          {/*  <BsTwitter className="text-blue-400 mx-0.5" />*/}
          {/*  <AiOutlineInstagram className="text-gradient mx-0.5" />*/}
          {/*  <FaTelegramPlane className="text-blue-400 mx-0.5" />*/}
          {/*</section>*/}
          <section
            className="text-xs text-red-400 flex items-center pb-3 mt-3"
            onClick={() => {
              setChangeChild(true)
              dispatch(
                changeCoachInfo({
                  id: data.id,
                  profile: data.profile,
                  proficiency: data.proficiency,
                  voice: data.voice,
                  name: data.name,
                  about: data.about
                })
              )
            }}
          >
            <p>توضیحات بیشتر</p>
            <HiOutlineArrowNarrowLeft className="mr-2" />
          </section>
        </div>
      </section>
    </>
  )
}

export default Member
