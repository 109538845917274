import Gold from '../svg/golden.svg'
function Gift() {
  return (
    <>
      <div className="w-full flex justify-start items-start bg-blue-100 border-2 border-dashed border-blue-600 rounded-lg mt-2">
        <section className="w-4/12 p-5 border-l border-l-2 border-dashed border-gray-400 relative">
          <span className="w-6 h-5 rounded-bl-full rounded-br-full bg-white block absolute -top-2 left-0 border-b border-b-2 border-dashed border-blue-600 -translate-x-1/2" />
          <span className="w-6 h-5 rounded-tl-full rounded-tr-full bg-white block absolute -bottom-2 left-0 border-t border-t-1 border-dashed border-blue-600 -translate-x-1/2" />
          <img src={Gold} alt="gold modal matyar" className="w-full h-full" />
        </section>
        <section className="w-8/12"></section>
      </div>
    </>
  )
}
export default Gift
