import HeaderAlbum from '../../album/photo-album/component/header'

import ClockImage from './svg/clock.svg'
import FireImage from './svg/fire.svg'
import StarImage from './svg/star.svg'
import './food-detail.css'
import { BsFillBookmarkFill } from 'react-icons/bs'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useEffect, useState } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'
import Recipes from './component/recipes'
import Material from './component/material'
import NutritionalValue from './component/nutritional-value'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Domain, FoodAPI } from '../../../../../api'
import Consideration from './fruit/svg/considerations.svg'
import Hints from './fruit/svg/Hints.svg'
import { TiTick } from 'react-icons/ti'
import PopUp from '../../../components/pop-up/comment'
import Comment from '../../coach-team-detail/component/comment'
import { useSelector } from 'react-redux'
import Subscription from '../../buy-subscription/svg/subscription-model.svg'
import Loading from '../../../../../component/loading'
import { AddBookmark, RemoveBookmark } from '../../../../../bookmark'
import { CiBookmark } from 'react-icons/ci'
function FoodDetail() {
  const [save, setSave] = useState(false)

  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [count, setCount] = useState(0)
  const [componentNumber, setComponentNumber] = useState('one')
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [foodDetail, setFoodDetail] = useState({})
  const [needSub, setNeedSub] = useState(false)
  const childInfo = useSelector((state) => state.childInfo)
  const getFoodByCategory = () => {
    axios
      .get(FoodAPI + `/${id}?childId=${childInfo?.id}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setFoodDetail(data?.data)
        setSave(data?.save)
      })
      .catch((e) => {
        if (e.response.data === 'subscribe') {
          setNeedSub(true)
          setLoading(false)
        }
      })
  }
  const MainComponent = () => {
    if (componentNumber === 'three') {
      return <NutritionalValue data={foodDetail.nutritional} />
    }
    if (componentNumber === 'two') {
      return <Recipes data={foodDetail.recipes} />
    }
    if (componentNumber === 'one') {
      return <Material data={foodDetail.material} />
    }
  }

  const AgeRange = (daysDifference) => {
    if (daysDifference <= 180) {
      return 'نوزاد'
    } else if (daysDifference <= 720) {
      return 'نوپا'
    } else if (daysDifference <= 2520) {
      return 'خردسال'
    }
  }

  useEffect(() => {
    getFoodByCategory()
  }, [])
  const [showForm, setShowForm] = useState(false)

  return (
    <>
      {loading ? (
        <Loading color="#1e40af" width="100px" />
      ) : (
        <>
          <PopUp
            setShowMessage={setShowForm}
            showMessage={showForm}
            titleMessage="نظر خود را با ما به اشتراک بگذارید"
            userTeam={foodDetail.title}
            API={FoodAPI + '/comment'}
            id={foodDetail.id}
            body={`لطفا برای بهبود و بالا بردن خدمات ما نظر خود را درباره تفذیه   ${foodDetail.title}  ثبت کنید`}
          />
          <HeaderAlbum title={foodDetail.title} />
          {needSub ? (
            <div className="flex justify-center items-center flex-wrap vazir-medium mt-14 px-4">
              <div className="w-48 h-48 bg-blue-200 rounded-full flex justify-center items-center">
                <img src={Subscription} alt="buy subscription matyar" />
              </div>
              <div className="w-full flex flex-col justify-center items-center flex-wrap mt-5">
                <p className="text-lg text-center"> شما اشتراک فعالی ندارید</p>
                <p className="text-sm text-gray-500 text-center w-8/12 mt-3">
                  شما اشتراک فعالی ندارید برای دیدن این تغذیه باید اشتراکی خریداری کنید
                </p>
              </div>

              <button
                className="w-full bg-[#3F54CB] text-white rounded-lg py-4 vazir-medium mt-14"
                onClick={() => navigate('/list-subscription')}
              >
                خرید اشتراک
              </button>
            </div>
          ) : (
            <>
              <div
                className="w-full h-96 relative"
                style={{
                  backgroundImage: `url("${Domain + foodDetail.picture}")`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              >
                <section className="background-food w-full h-24 absolute bottom-0" />
              </div>
              <div className="w-full flex justify-between items-center px-4 pt-4 bg-blue-50">
                <div className="W-full ">
                  <p className="text-gray-800">{foodDetail.title}</p>
                  <section className="flex justify-between items-center text-xs text-gray-600 mt-3">
                    <span>{foodDetail?.category?.title}</span>
                    &nbsp;&nbsp; <span>.</span>&nbsp;&nbsp;
                    <span>مناسب برای {AgeRange(foodDetail.age)}</span>
                  </section>
                </div>
                {save ? (
                  <BsFillBookmarkFill
                    className="text-xl text-[#3F54CB]"
                    onClick={() => {
                      RemoveBookmark(save.id)
                      setSave(false)
                    }}
                  />
                ) : (
                  <CiBookmark
                    className="text-xl"
                    onClick={() => {
                      setSave(true)
                      AddBookmark('food', foodDetail.id, childInfo.id, setSave)
                    }}
                  />
                )}
              </div>
              <div className="w-full flex justify-between items-center px-4 text-sm text-gray-700 pt-4 bg-blue-50">
                <section className="flex justify-center items-center">
                  <img src={ClockImage} alt="clock food matyar" />
                  <span className="mx-2">{foodDetail.time}</span>
                  <span>دقیقه</span>
                </section>
                <section className="flex justify-center items-center">
                  <img src={FireImage} alt="clock food matyar" />
                  <span className="mx-2">{foodDetail.calories}</span>
                  <span>کالری</span>
                </section>
                <section className="flex justify-center items-center">
                  <img src={StarImage} alt="clock food matyar" />
                  <span className="mx-2">{foodDetail.rateCount}</span>
                </section>
              </div>

              {foodDetail?.material?.length > 3 &&
                foodDetail?.recipes?.length > 3 &&
                foodDetail?.nutritional?.length > 3 && (
                  <>
                    <div className="w-full pt-5 px-4 bg-blue-50">
                      <div className="text-sm text-gray-400 ">
                        <span className="text-gray-700 text-sm">
                          <div
                            className="text-sm text-gray-800 mt-2 text-justify"
                            dangerouslySetInnerHTML={{
                              __html: foodDetail?.description?.substring(0, show ? 2000 : 90)
                            }}
                          />

                          {show ? null : '...'}
                        </span>
                      </div>
                      {show ? (
                        <section
                          className="text-red-600 flex justify-start items-center    py-1 rounded mt-1"
                          onClick={() => {
                            setShow(false)
                          }}
                        >
                          <span className="text-xs">خلاصه</span>
                          <IoIosArrowUp className="text-red-600" />
                        </section>
                      ) : (
                        <section
                          className="text-blue-600 flex justify-start items-center     py-1 rounded mt-1"
                          onClick={() => {
                            setShow(true)
                          }}
                        >
                          <span className="text-xs">مطالعه بیشتر</span>
                          <IoIosArrowDown className="text-blue-600" />
                        </section>
                      )}
                    </div>
                    <div className="w-full px-4 pt-5 bg-blue-50 hidden">
                      <div className="w-full flex justify-between items-center bg-gray-200  rounded-[10px] px-4 py-2">
                        <p className="text-gray-800">تعداد پرس : </p>
                        <section className="flex justify-between items-center">
                          <span
                            className="w-8 h-8 bg-white rounded-xl shadow-lg flex justify-center items-center"
                            onClick={() => {
                              setCount((prevState) => prevState + 1)
                            }}
                          >
                            <FaPlus className="text-xl text-blue-700" />
                          </span>
                          <span className="mx-4">{count}</span>
                          <span
                            className="w-8 h-8 bg-white rounded-xl shadow-lg flex justify-center items-center"
                            onClick={() => {
                              if (count > 0) {
                                setCount((prevState) => prevState - 1)
                              }
                            }}
                          >
                            <FaMinus className="text-xl text-blue-700" />
                          </span>
                        </section>
                      </div>
                    </div>
                    <div className="w-full px-4 pt-5 bg-blue-50">
                      <div className="w-full flex justify-between items-center bg-gray-200 rounded-[10px] px-4 py-1 relative h-[40px]">
                        <span className={`active-food-component ${componentNumber} z-[10]`} />
                        <section
                          className="flex justify-center items-center w-4/12 px-4 z-[20] relative"
                          onClick={() => setComponentNumber('one')}
                        >
                          <span className="text-gray-700 text-sm">مواد لازم</span>
                        </section>

                        <section
                          className="flex justify-center items-center w-4/12 px-4 z-[20] relative"
                          onClick={() => setComponentNumber('two')}
                        >
                          <span className="text-gray-700 text-sm">دستور پخت</span>
                        </section>

                        <section
                          className="flex justify-center items-center w-4/12 px-4 z-[20] relative"
                          onClick={() => setComponentNumber('three')}
                        >
                          <span className="text-gray-700 text-sm">ارزش غذایی</span>
                        </section>
                      </div>
                    </div>
                    <MainComponent />
                  </>
                )}

              <div className="w-full px-4 pt-5 bg-blue-50 pb-32">
                {foodDetail?.consideration?.length > 3 && (
                  <div className="w-full px-4 rounded-xl overflow-hidden border border-gray-300 shadow-lg bg-white">
                    <section className="w-full border-b border-b-gray-300 flex justify-start items-center py-4">
                      <img src={Consideration} alt="matyar" />
                      <span className="text-gray-800 mr-2">ملاحظات</span>
                    </section>

                    {foodDetail?.consideration &&
                      JSON.parse(foodDetail.consideration).map((fod) => (
                        <div key={fod.id} className="w-full flex justify-start items-center my-2">
                          <span className="w-2 h-2 block rounded-full bg-[#3F54CB]" />
                          <span className="text-sm text-gray-700 mr-3">{fod.material}</span>
                        </div>
                      ))}
                  </div>
                )}

                {foodDetail?.advice?.length > 3 && (
                  <div className="w-full px-4 rounded-xl overflow-hidden border border-gray-300 shadow-lg mt-5 bg-white">
                    <section className="w-full border-b border-b-gray-300 flex justify-start items-center py-4">
                      <img src={Hints} alt="matyar" />
                      <span className="text-gray-800 mr-2">نکات و توصیه ها</span>
                    </section>

                    {foodDetail?.advice &&
                      JSON.parse(foodDetail.advice).map((fod) => (
                        <div key={fod.id} className="w-full flex justify-start items-center my-3">
                          <span className="w-4 h-4 block rounded-full border border-2 border-green-600 flex justify-center items-center rounded-full">
                            <TiTick className="text-green-600 text-xl" />
                          </span>
                          <span className="text-sm text-gray-700 mr-3">{fod.material}</span>
                        </div>
                      ))}
                  </div>
                )}

                <Comment
                  count={foodDetail?.commentCount}
                  rate={foodDetail?.rateCount}
                  id={foodDetail?.id}
                  API={FoodAPI + '/comment/user?id='}
                  type="food"
                  setShowForm={() => setShowForm(true)}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
export default FoodDetail
