import { IoIosArrowBack } from 'react-icons/io'
import { IoIosStar } from 'react-icons/io'

function Card() {
  return (
    <>
      <div className="w-full rounded-xl shadow-md flex justify-between items-center pl-2 mt-4 border border-gray-300">
        <section
          className="w-32 h-32 rounded-br-xl rounded-tr-xl"
          style={{
            backgroundImage: `url("https://cdn.tarhbama.com/1400/BigImage/2021/6/16/108/%D8%B9%DA%A9%D8%B3%20%D8%A8%D8%A7%20%DA%A9%DB%8C%D9%81%DB%8C%D8%AA%20%D9%BE%D8%B2%D8%B4%DA%A9%20%D8%B2%D9%86-small-1000-logo.jpg")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
        <div className="flex flex-col justify-between items-start text-sm">
          <p className="text-gray-700">اسم دکتر</p>
          <p className="text-gray-600 text-md flex justify-start items-start my-3">
            متخصص داخلی و عفونت
          </p>
          <section className="flex justify-start items-center text-gray-600 text-xs">
            <IoIosStar className="text-[#3F54CB]" />
            <span>4.7</span>(<span>1234 نظر</span>) - <span>1600</span> مشاور آنلاین
          </section>
        </div>
        <section className="w-7 h-7 rounded bg-blue-300 flex justify-center items-center">
          <IoIosArrowBack className="text-xl text-blue-600" />
        </section>
      </div>
    </>
  )
}
export default Card
