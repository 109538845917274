import Letter from './component/svg/letter.svg'
import Gift from './component/svg/gift.svg'
import Select from './component/svg/checked.svg'
import { useState } from 'react'
import { BiCopy } from 'react-icons/bi'
import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
function Invite() {
  const navigate = useNavigate()
  const [plan, setPlan] = useState(null)
  const [isCopied, setIsCopied] = useState(false)
  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 2000)
  }
  return (
    <>
      <div>
        <div className="header-bg w-full h-[50vh] flex justify-center items-center relative">
          <IoIosArrowForward
            className="text-3xl text-gray-600 border rounded bg-white absolute top-5 right-5"
            onClick={() => navigate(-1)}
          />
          <div className="w-full flex justify-center items-center">
            <img src={Letter} alt="letter matyar" />
          </div>
        </div>
        <div className="h-[50vh] rounded-3xl bg-white relative -top-10 w-full">
          <p className="w-full text-center text-gray-700 pt-10 text-lg">دعوت از دوستان</p>
          <p className="w-full px-4 text-gray-500 text-center mt-4">
            دوسان خود را به ماتیار دعوت کنید و از تخفیفات ویژه ما بهره مند شوید
          </p>
          <div className="w-full flex justify-evenly items-center mt-5">
            <div className="flex flex-col justify-center items-center">
              <div
                className={`w-24 h-24 bg-blue-200 rounded-lg flex justify-center items-center relative ${
                  plan === 'gift' ? 'border border-2 border-blue-600' : null
                }`}
                onClick={() => setPlan('gift')}
              >
                {plan === 'gift' && (
                  <img
                    src={Select}
                    alt="select gift matyar"
                    className="absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2 border border-white border-[3px] rounded-full"
                  />
                )}
                <img src={Gift} alt="gift matyar" />
              </div>
              <span
                className={`text-sm mt-2 ${plan === 'gift' ? 'text-gray-700' : 'text-gray-500'}`}
              >
                دریافت جایزه
              </span>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div
                className={`w-24 h-24 bg-blue-200 rounded-lg flex justify-center items-center relative ${
                  plan === 'mission' ? 'border border-2 border-blue-600' : null
                } `}
                onClick={() => setPlan('mission')}
              >
                {plan === 'mission' && (
                  <img
                    src={Select}
                    alt="select mission matyar"
                    className="absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2 border border-white border-[3px] rounded-full"
                  />
                )}
                <img src={Gift} alt="mission matyar" />
              </div>
              <span
                className={`text-sm mt-2 ${plan == 'mission' ? 'text-gray-700' : 'text-gray-500'}`}
              >
                ماموریت های ماتیار
              </span>
            </div>
          </div>
          {isCopied && (
            <div className={`success-message animate__animated animate__jello`}>
              کد در کلپیورد شما ذخیره شد
            </div>
          )}
          <div className="w-full flex justify-center items-center mt-4">
            <section
              className="w-8/12 border-2 border-gray-300 border-dashed border-gray-300 py-2 px-4 bg-blue-100 flex justify-between items-center rounded-xl mt-5"
              onClick={() => copyToClipboard('MJS132wDXAX')}
            >
              <span className="text-gray-500">کد دعوت:</span>
              <span>MJS132wDXAX</span>
              <BiCopy className="text-2xl text-blue-600" />
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
export default Invite
