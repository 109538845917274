export default function formatAmount(amount) {
  // Convert amount to string
  let amountStr = amount.toString()

  // Split the integer and decimal parts (if any)
  let parts = amountStr.split('.')
  let integerPart = parts[0]
  let decimalPart = parts.length > 1 ? '.' + parts[1] : ''

  // Insert commas after every three digits from the right in the integer part
  let formattedInteger = ''
  for (let i = integerPart.length - 1, j = 1; i >= 0; i--, j++) {
    formattedInteger = integerPart[i] + formattedInteger
    if (j % 3 === 0 && i !== 0) {
      formattedInteger = ',' + formattedInteger
    }
  }

  // Concatenate the integer and decimal parts
  let formattedAmount = formattedInteger + decimalPart

  return formattedAmount
}
