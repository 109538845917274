import { Route, Routes } from 'react-router-dom'
import Signup from './pages/authentication/signup'
import Login from './pages/authentication/login'
import DetailActivities from './pages/user/layout/detail-activities'
import ChildInfo from './pages/user/layout/child-info'
import CoachList from './pages/user/layout/coach-list'
import CoachDetailTeam from './pages/user/layout/coach-team-detail'
import Activity from './pages/user/layout/activity'
import Setting from './pages/user/layout/setting'
import Account from './pages/user/layout/account'
import ChildList from './pages/user/layout/child-list'
import ChildInfoSpecific from './pages/user/layout/child-info-specific'
import BuySubscription from './pages/user/layout/buy-subscription'
import RecentPay from './pages/user/layout/recent-pay'
import ContactUs from './pages/user/layout/contact-us'
import ContactSupport from './pages/user/layout/contact-support'
import Ticket from './pages/user/layout/contact-support/component/ticket'
import ListSubscription from './pages/user/layout/list-subscription'
import Comment from './pages/user/layout/comment'
import About from './pages/user/layout/about'
import FrequentlyQuestion from './pages/user/layout/frequently-question'
import Basket from './pages/user/layout/basket'
import Chat from './pages/user/chat'
import ChatTicket from './pages/user/chat-ticket'
import Workshop from './pages/user/layout/workshop'
import WorkshopDetail from './pages/user/layout/workshop-detail'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PrivateRoute from './private-route'
import AddChild from './pages/user/layout/add-child'
import 'react-multi-date-picker/styles/layouts/mobile.css'
import 'animate.css'
import 'react-loading-skeleton/dist/skeleton.css'
import PostDetail from './pages/user/layout/post-detail'
import Notification from './pages/user/layout/notification'
import Invite from './pages/user/layout/invite'
import ReceiveGift from './pages/user/layout/receive-gift'
import Question from './pages/user/layout/question'
import Save from './pages/user/layout/save'
import ActivitySaved from './pages/user/layout/activity-saved'
import Main from './pages/user/layout/main'
import PhotoAlbum from './pages/user/layout/album/photo-album'
import ChooseAlbum from './pages/user/layout/album/choose-album'
import MyAlbum from './pages/user/layout/album/my-album'
import Gallery from './pages/user/layout/album/gallery'
import MainAlbum from './pages/user/layout/album/main'
import SetPic from './pages/user/layout/album/set-pic'
import SoundPlay from './pages/user/layout/podcast/sound-play'
import MainPodcast from './pages/user/layout/podcast/main'
import PodcastCategory from './pages/user/layout/podcast/podcast-category'
import PodcastPlayList from './pages/user/layout/podcast/play-list'
import ChiefSuggestion from './pages/user/layout/podcast/chief-suggestion'
import AddLullaby from './pages/user/layout/lullaby/add-lullaby'
import DetailLullaby from './pages/user/layout/lullaby/detail-lullaby'
import CollectionLullaby from './pages/user/layout/lullaby/collection-lullaby'
import MainArticles from './pages/user/layout/articles/main'
import DetailArticles from './pages/user/layout/articles/detail'
import ListArticles from './pages/user/layout/articles/list'
import MainPlace from './pages/user/layout/place/main'
import PlaceCity from './pages/user/layout/place/city'
import DetailPlace from './pages/user/layout/place/detail'
import MyVaccine from './pages/user/layout/vaccine/my-vaccine'
import AnimationDetail from './pages/user/layout/animations/detail'
import EffectIndex from './pages/user/layout/animations/effect-index'
import MainAnimation from './pages/user/layout/animations/main'
import AnimationGroup from './pages/user/layout/animations/group'
import AnimationReview from './pages/user/layout/animations/review'
import AnimationTeamwork from './pages/user/layout/animations/teamwork'
import FoodDetail from './pages/user/layout/food/detail'
import FruitDetail from './pages/user/layout/food/detail/fruit'
import FoodList from './pages/user/layout/food/list'
import FoodMain from './pages/user/layout/food/main'
import PlaceCategory from './pages/user/layout/place/category'
import Doctor from './pages/user/layout/doctor'
import DoctorHistory from './pages/user/layout/doctor/history'
import DoctorTimeEnd from './pages/user/layout/doctor/time-end'
import DoctorDetail from './pages/user/layout/doctor/detail'
import DoctorReserve from './pages/user/layout/doctor/reserve'
import DoctorComment from './pages/user/layout/doctor/comment'
import DoctorCategory from './pages/user/layout/doctor/category'
import DoctorList from './pages/user/layout/doctor/list'
import DoctorAppointment from './pages/user/layout/doctor/appointmen'
import DoctorAppointmentYour from './pages/user/layout/doctor/appointment-your'
import VaccineDetail from './pages/user/layout/vaccine/vaccine-detail'
import BookmarkLullaby from './pages/user/layout/lullaby/bookmark-lullaby'
import VaccineQuestion from './pages/user/layout/vaccine-question'

function App() {
  return (
    <div>
      <ToastContainer
        className="vazir-bold"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/*<PopUp />*/}
      <Routes>
        {/*<Route path="/" element={<MyComponent />} />*/}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Main />} />
          <Route path="/detail-activities" element={<DetailActivities />} />
          <Route path="/post-detail" element={<PostDetail />} />
          <Route path="/progress" element={<ChildInfo />} />
          <Route path="/coach-list" element={<CoachList />} />
          <Route path="/coach-detail" element={<CoachDetailTeam />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/workshop" element={<Workshop />} />
          <Route path="/workshop-detail" element={<WorkshopDetail />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/account" element={<Account />} />
          <Route path="/child-list" element={<ChildList />} />
          <Route path="/child-info-specific" element={<ChildInfoSpecific />} />
          <Route path="/add-child" element={<AddChild />} />
          <Route path="/buy-subscription" element={<BuySubscription />} />
          <Route path="/list-subscription" element={<ListSubscription />} />
          <Route path="/list-comment" element={<Comment />} />
          <Route path="/recent-pay" element={<RecentPay />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/contact-support" element={<ContactSupport />} />
          <Route path="/new-ticket" element={<Ticket />} />
          <Route path="/about-matyar" element={<About />} />
          <Route path="/frequently-question" element={<FrequentlyQuestion />} />
          <Route path="/basket" element={<Basket />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/chat-ticket" element={<ChatTicket />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/invite" element={<Invite />} />
          <Route path="/receive-gift" element={<ReceiveGift />} />
          <Route path="/question" element={<Question />} />
          <Route path="/save" element={<Save />} />
          <Route path="/activity-saved" element={<ActivitySaved />} />
          <Route path="/photo-album" element={<PhotoAlbum />} />
          <Route path="/album-collection" element={<ChooseAlbum />} />
          <Route path="/my-album" element={<MyAlbum />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/main-album" element={<MainAlbum />} />
          <Route path="/set-pic" element={<SetPic />} />
          <Route path="/sound-play" element={<SoundPlay />} />
          <Route path="/main-podcast" element={<MainPodcast />} />
          <Route path="/podcast-category" element={<PodcastCategory />} />
          <Route path="/podcast-playlist" element={<PodcastPlayList />} />
          <Route path="/chief-suggestion" element={<ChiefSuggestion />} />
          <Route path="/add-lullaby" element={<AddLullaby />} />
          <Route path="/detail-lullaby" element={<DetailLullaby />} />
          <Route path="/bookmark-lullaby" element={<BookmarkLullaby />} />
          <Route path="/collection-lullaby" element={<CollectionLullaby />} />
          <Route path="/main-articles" element={<MainArticles />} />
          <Route path="/detail-articles" element={<DetailArticles />} />
          <Route path="/list-articles" element={<ListArticles />} />
          <Route path="/main-place" element={<MainPlace />} />
          <Route path="/collection-place" element={<MainPlace />} />
          <Route path="/city-place" element={<PlaceCity />} />
          <Route path="/category-place" element={<PlaceCategory />} />
          <Route path="/detail-place" element={<DetailPlace />} />
          <Route path="/my-vaccine" element={<MyVaccine />} />
          <Route path="/vaccine-question" element={<VaccineQuestion />} />
          <Route path="/vaccine-detail" element={<VaccineDetail />} />
          <Route path="/animation-detail" element={<AnimationDetail />} />
          <Route path="/animation-effect" element={<EffectIndex />} />
          <Route path="/animation-main" element={<MainAnimation />} />
          <Route path="/animation-group" element={<AnimationGroup />} />
          <Route path="/animation-review" element={<AnimationReview />} />
          <Route path="/animation-teamwork" element={<AnimationTeamwork />} />
          <Route path="/food-detail" element={<FoodDetail />} />
          <Route path="/food-list" element={<FoodList />} />
          <Route path="/food-main" element={<FoodMain />} />
          <Route path="/fruit-detail" element={<FruitDetail />} />
          <Route path="/doctor" element={<Doctor />} />
          <Route path="/doctor-history" element={<DoctorHistory />} />
          <Route path="/doctor-time-end" element={<DoctorTimeEnd />} />
          <Route path="/doctor-detail" element={<DoctorDetail />} />
          <Route path="/doctor-reserve" element={<DoctorReserve />} />
          <Route path="/doctor-comment" element={<DoctorComment />} />
          <Route path="/doctor-category" element={<DoctorCategory />} />
          <Route path="/doctor-list" element={<DoctorList />} />
          <Route path="/doctor-appointment" element={<DoctorAppointment />} />
          <Route path="/doctor-appointment-your" element={<DoctorAppointmentYour />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
