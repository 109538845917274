import HeaderAlbum from '../../album/photo-album/component/header'

import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { LullabyAPI } from '../../../../../api'

import { useSelector } from 'react-redux'
import Card from '../collection-lullaby/component/card'
function BookmarkLullaby() {
  const childInfo = useSelector((state) => state.childInfo)
  const [lullabies, setLullabies] = useState([])
  const [page, setPage] = useState(1)
  const listInnerRef = useRef()
  const [currentAudio, setCurrentAudio] = useState(null)
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }
  const getBookmark = () => {
    axios
      .get(LullabyAPI + `/bookmark?childId=${childInfo.id}&page=${page}&limit=10`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        if (page == 1) {
          setLullabies(data?.lullaby)
        } else {
          // eslint-disable-next-line no-unsafe-optional-chaining
          setLullabies([...lullabies, ...data?.lullaby])
        }
      })
  }

  useEffect(() => {
    getBookmark()
  }, [page])
  return (
    <>
      <HeaderAlbum title="ذخیره شده ها" />

      <>
        <div
          className="w-full flex flex-wrap justify-center items-center px-4 relative h-screen overflow-hidden overflow-y-scroll content-start"
          ref={listInnerRef}
          onScroll={onScroll}
        >
          {Array.isArray(lullabies) &&
            lullabies.map((lul) => (
              <Card
                lul={lul}
                key={lul.id}
                currentAudio={currentAudio}
                setCurrentAudio={setCurrentAudio}
              />
            ))}
        </div>
      </>
    </>
  )
}
export default BookmarkLullaby
