import HeaderAlbum from './component/header'

import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { AlbumAPI, Domain, GetAlbumOrderUser } from '../../../../../api'
import { useEffect, useState } from 'react'
import { BsFillTrashFill } from 'react-icons/bs'
import { GiCancel } from 'react-icons/gi'

import { toast } from 'react-toastify'
import { BiSelectMultiple } from 'react-icons/bi'

function PhotoAlbum() {
  const [detail, setDetail] = useState([])
  const [deleteItem, setDeleteItem] = useState([])

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const getAlbumDetail = () => {
    axios
      .get(GetAlbumOrderUser + '/' + id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setDetail(data)
      })
  }

  const updateAlbumOrder = (picture) => {
    const formData = new FormData()
    formData.append('picture', picture)
    axios
      .patch(AlbumAPI + '/user/' + detail.id, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        getAlbumDetail()
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }

  const updateAlbumOrderStatus = () => {
    const formData = new FormData()
    formData.append('status', 'analyze')
    axios
      .patch(AlbumAPI + '/user/' + detail.id, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        getAlbumDetail()
        toast.success(
          'آلبوم شما در وضعیت در حال بررسی قرار گرفت به زودی پشتیبانی با شما تماس میگیرد'
        )
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }

  const deletePictureAlbumOrder = () => {
    const formData = new FormData()
    formData.append('remove', deleteItem)
    axios
      .patch(AlbumAPI + '/user/' + detail.id, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        getAlbumDetail()
        setDeleteItem([])
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  useEffect(() => {
    if (id) {
      getAlbumDetail()
    }
  }, [])
  return (
    <>
      <HeaderAlbum title={detail.title} />
      <div className="grid grid-cols-2 gap-4 px-4">
        {detail?.pictures &&
          detail?.pictures.map((pic) => (
            <div key={pic} className="w-full relative">
              {deleteItem.includes(pic) ? (
                <section
                  className="bg-white w-8 h-10 absolute left-0 bottom-0 flex justify-between items-center rounded"
                  onClick={() => {
                    setDeleteItem((prevState) => prevState.filter((item) => item !== pic))
                  }}
                >
                  <GiCancel className="text-3xl text-red-600" />
                </section>
              ) : (
                <section
                  className="bg-white w-8 h-10 absolute left-0 bottom-0 flex justify-between items-center rounded"
                  onClick={() => {
                    setDeleteItem((prevState) => prevState.concat(pic))
                  }}
                >
                  <BsFillTrashFill className="text-3xl text-red-600" />
                </section>
              )}

              <section
                className="rounded-xl w-full h-32"
                style={{
                  backgroundImage: `url("${Domain + pic}")`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              />
            </div>
          ))}
      </div>

      {deleteItem && deleteItem?.length > 0 && (
        <div className="w-full px-4 grid grid-cols-2 gap-2">
          <button
            onClick={() => setDeleteItem([])}
            className="w-full bg-[#3F54CB] text-white rounded-lg py-4 vazir-medium mt-14"
          >
            بیخیال چیزی حذف نشه
          </button>

          <button
            className="w-full bg-red-600 text-white rounded-lg py-4 vazir-medium mt-14"
            onClick={() => {
              deletePictureAlbumOrder()
            }}
          >
            {deleteItem?.length} عکس حذف شود
          </button>
        </div>
      )}
      <div className="grid grid-cols-1    ">
        {deleteItem.length == 0 && detail?.album?.picture_count - detail?.pictures?.length > 0 && (
          <>
            <label htmlFor="upload" className="w-full px-4 z-50">
              <div className="w-full bg-[#3F54CB] text-white rounded-lg py-3 vazir-medium mt-14 flex justify-center items-center">
                <BiSelectMultiple className="text-4xl text-white" />
                <span className="mx-2">انتخاب عکس های جدید</span>
              </div>
            </label>

            <input
              type="file"
              id="upload"
              maxLength={detail?.album?.picture_count - detail?.pictures?.length}
              onChange={(e) => {
                updateAlbumOrder(e.target.files[0])
              }}
              className="hidden"
            />
          </>
        )}

        {detail?.pictures?.length > 0 && deleteItem.length == 0 && (
          <div className="w-full px-4">
            <button
              className="w-full bg-green-600 text-white rounded-lg py-4 vazir-medium mt-14"
              onClick={() => {
                updateAlbumOrderStatus()
              }}
            >
              سفارش نهایی آلبوم
            </button>
          </div>
        )}
      </div>
    </>
  )
}
export default PhotoAlbum
