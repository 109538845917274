function persianToEnglish(string) {
  const persianNums = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹']
  const englishNums = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

  for (let i = 0; i < persianNums.length; i++) {
    string = string.replace(new RegExp(persianNums[i], 'g'), englishNums[i])
  }

  return string
}
export default persianToEnglish
