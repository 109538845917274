import Layout from '../index'
import { FaHeadset, FaMoneyBillAlt, FaUserAlt } from 'react-icons/fa'
import { IoIosArrowBack } from 'react-icons/io'
import { IoDiamondSharp } from 'react-icons/io5'
import { MdOutlineChildCare } from 'react-icons/md'
import { BsFillBasketFill, BsQuestionOctagonFill, BsTelephoneFill } from 'react-icons/bs'
import { RxExit } from 'react-icons/rx'
import { useNavigate } from 'react-router-dom'
import Matyar from './svg/img.png'
import { FaBookBookmark } from 'react-icons/fa6'
import { RiGalleryLine } from 'react-icons/ri'

function Setting() {
  const navigate = useNavigate()
  return (
    <>
      <Layout>
        <div className="w-full bg-white pb-20">
          <ul className="w-full vazir-medium px-4 ">
            <li
              className="w-full flex justify-between items-center rounded-xl py-2 px-4 border shadow bg-white -translate-y-1/2"
              onClick={() => navigate('/account')}
            >
              <section className="flex justify-start items-center">
                <span className="w-10 h-10 bg-yellow-200 flex justify-center items-center rounded-full">
                  <FaUserAlt className="text-yellow-400 text-lg" />
                </span>
                <span className="mr-2 text-sm">حساب کاربری</span>
              </section>

              <span>
                <IoIosArrowBack />
              </span>
            </li>

            <li
              className="w-full flex justify-between items-center rounded-xl py-2 px-4 border shadow bg-white -translate-y-1/2 mt-2"
              onClick={() => navigate('/list-subscription')}
            >
              <section className="flex justify-start items-center">
                <span className="w-10 h-10 bg-orange-200 flex justify-center items-center rounded-full">
                  <IoDiamondSharp className="text-orange-400 text-lg" />
                </span>
                <span className="mr-2 text-sm">حق اشتراک</span>
              </section>

              <span>
                <IoIosArrowBack />
              </span>
            </li>

            <li
              className="w-full flex justify-between items-center rounded-xl py-2 px-4 border shadow bg-white -translate-y-1/2 mt-2"
              onClick={() => navigate('/recent-pay')}
            >
              <section className="flex justify-start items-center">
                <span className="w-10 h-10 bg-green-200 flex justify-center items-center rounded-full">
                  <FaMoneyBillAlt className="text-green-700 text-lg" />
                </span>
                <span className="mr-2 text-sm">سابقه پرداخت</span>
              </section>

              <span>
                <IoIosArrowBack />
              </span>
            </li>
          </ul>

          <ul className="w-full vazir-medium px-4 mt-10 ">
            <li
              className="w-full flex justify-between items-center rounded-xl py-2 px-4 border shadow bg-white -translate-y-1/2"
              onClick={() => navigate('/basket')}
            >
              <section className="flex justify-start items-center">
                <span className="w-10 h-10 bg-red-200 flex justify-center items-center rounded-full">
                  <BsFillBasketFill className="text-red-400 text-lg" />
                </span>
                <span className="mr-2 text-sm">سبد خرید</span>
              </section>

              <span>
                <IoIosArrowBack />
              </span>
            </li>

            <li
              className="w-full flex justify-between items-center rounded-xl py-2 px-4 border shadow bg-white -translate-y-1/2 mt-2"
              onClick={() => navigate('/save')}
            >
              <section className="flex justify-start items-center">
                <span className="w-10 h-10 bg-pink-200 flex justify-center items-center rounded-full">
                  <FaBookBookmark className="text-pink-400 text-lg" />
                </span>
                <span className="mr-2 text-sm">ذخیره شده</span>
              </section>

              <span>
                <IoIosArrowBack />
              </span>
            </li>

            <li
              className="w-full flex justify-between items-center rounded-xl py-2 px-4 border shadow bg-white -translate-y-1/2 mt-2"
              onClick={() => navigate('/my-album')}
            >
              <section className="flex justify-start items-center">
                <span className="w-10 h-10 bg-rose-200 flex justify-center items-center rounded-full">
                  <RiGalleryLine className="text-rose-400 text-lg" />
                </span>
                <span className="mr-2 text-sm">آلبوم های من</span>
              </section>

              <span>
                <IoIosArrowBack />
              </span>
            </li>

            <li
              className="w-full flex justify-between items-center rounded-xl py-2 px-4 border shadow bg-white -translate-y-1/2 mt-2"
              onClick={() => navigate('/child-list')}
            >
              <section className="flex justify-start items-center">
                <span className="w-10 h-10 bg-blue-200 flex justify-center items-center rounded-full">
                  <MdOutlineChildCare className="text-blue-400 text-lg" />
                </span>
                <span className="mr-2 text-sm">لیست کودکان</span>
              </section>

              <span>
                <IoIosArrowBack />
              </span>
            </li>

            <li
              className="w-full flex justify-between items-center rounded-xl py-2 px-4 border shadow bg-white -translate-y-1/2 mt-2"
              onClick={() => navigate('/contact-support')}
            >
              <section className="flex justify-start items-center">
                <span className="w-10 h-10 bg-purple-200 flex justify-center items-center rounded-full">
                  <FaHeadset className="text-purple-400 text-lg" />
                </span>
                <span className="mr-2 text-sm">پشتیبانی</span>
              </section>
              <span>
                <IoIosArrowBack />
              </span>
            </li>

            <li
              className="w-full flex justify-between items-center rounded-xl py-2 px-4 border shadow bg-white -translate-y-1/2 mt-2"
              onClick={() => navigate('/contact-us')}
            >
              <section className="flex justify-start items-center">
                <span className="w-10 h-10 bg-green-200 flex justify-center items-center rounded-full">
                  <BsTelephoneFill className="text-green-400 text-lg" />
                </span>
                <span className="mr-2 text-sm">ارتباط با ما</span>
              </section>
              <span>
                <IoIosArrowBack />
              </span>
            </li>
            <li
              className="w-full flex justify-between items-center rounded-xl py-2 px-4 border shadow bg-white -translate-y-1/2 mt-2"
              onClick={() => navigate('/frequently-question')}
            >
              <section className="flex justify-start items-center">
                <span className="w-10 h-10 bg-blue-200 flex justify-center items-center rounded-full">
                  <BsQuestionOctagonFill className="text-blue-400 text-lg" />
                </span>
                <span className="mr-2 text-sm">سوالات متداول</span>
              </section>
              <span>
                <IoIosArrowBack />
              </span>
            </li>
            <li
              className="w-full flex justify-between items-center rounded-xl py-2 px-4 border shadow bg-white -translate-y-1/2 mt-2"
              onClick={() => navigate('/about-matyar')}
            >
              <section className="flex justify-start items-center">
                <span className="w-10 h-10 bg-blue-800 flex justify-center items-center rounded-full">
                  <img src={Matyar} alt="about matyar logo" className="w-20 h-18" />
                </span>
                <span className="mr-2 text-sm">درباره ماتیار</span>
              </section>
              <span>
                <IoIosArrowBack />
              </span>
            </li>

            <li
              className="w-full flex justify-between items-center rounded-xl py-2 px-4 border shadow bg-white -translate-y-1/2 mt-7"
              onClick={() => {
                localStorage.clear()
                navigate('/login')
              }}
            >
              <section className="flex justify-start items-center">
                <span className="w-10 h-10 bg-red-200 flex justify-center items-center rounded-full">
                  <RxExit className="text-red-400 text-lg" />
                </span>
                <span className="mr-2 text-sm">خروج از حساب کاربری</span>
              </section>
              <span>
                <IoIosArrowBack />
              </span>
            </li>
          </ul>
        </div>
      </Layout>
    </>
  )
}

export default Setting
