import { useState, useRef, useEffect } from 'react'
import CircleBorder from '../canvas'
import Mic from '../svg/mic2.svg'
import { TiMediaStop } from 'react-icons/ti'
import Wavesurfer from 'wavesurfer.js'
import * as WaveformRegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions'
import PlayPauseButton from '../../../coach-team-detail/component/audio-player/play-button'
import { BsTrash } from 'react-icons/bs'
import { AiOutlineUpload } from 'react-icons/ai'

const VoiceRecorder = ({ SendLullaby, setVoice, voice, setVoiceFile, setDuration }) => {
  const [isRecording, setIsRecording] = useState(false)
  const [isSpanActive, setIsSpanActive] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const mediaRecorderRef = useRef(null)
  const chunksRef = useRef([])
  const timerRef = useRef(null)

  const startTimer = () => {
    timerRef.current = setTimeout(() => {
      stopRecording()
    }, 180000) // 3 minutes in milliseconds
  }

  const handleImageClick = () => {
    setIsSpanActive(true)
    setTimeout(() => {
      setIsSpanActive(false)
    }, 190000) // 20 seconds
  }
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = Math.floor(timeInSeconds % 60)
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }
  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setIsRecording(true)
        chunksRef.current = []
        mediaRecorderRef.current = new MediaRecorder(stream)

        mediaRecorderRef.current.addEventListener('dataavailable', (event) => {
          chunksRef.current.push(event.data)
        })

        mediaRecorderRef.current.addEventListener('stop', () => {
          const recordedBlob = new Blob(chunksRef.current, { type: 'audio/wav' })
          setVoice(URL.createObjectURL(recordedBlob))
          setVoiceFile(recordedBlob)
          // Calculate audio duration
          const audio = new Audio(URL.createObjectURL(recordedBlob))
          audio.onloadedmetadata = () => {
            audio.currentTime = 1000 // Attempt to load the metadata by seeking to a future time
            audio.ontimeupdate = () => {
              if (audio.currentTime > 0) {
                setDuration(formatTime(audio.duration))

                audio.pause()
              }
            }
          }
        })

        mediaRecorderRef.current.start()

        startTimer()
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error)
      })
  }
  const handleStop = () => {
    setIsRunning(false)
    setTime(0)
  }
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      handleStop()
      mediaRecorderRef.current.stop()
      setIsRecording(false)
      setIsSpanActive(false)
    }
  }

  const waveform = useRef(null)

  useEffect(() => {
    if (waveform.current && voice) {
      waveform.current = Wavesurfer.create({
        container: '#waveform',
        waveColor: '#899099',
        progressColor: '#E2664C',
        barGap: 4,
        barWidth: 4,
        barRadius: 10,
        cursorWidth: 0,
        cursorColor: 'red',
        height: 50,
        plugins: [WaveformRegionsPlugin.create({ maxLength: 30 })]
      })
      waveform.current.load(voice)
    }
  }, [voice])

  const playAudio = () => {
    if (waveform.current.isPlaying()) {
      waveform.current.pause()
    } else {
      waveform.current.play()
    }
  }
  const handleDeleteClick = () => {
    const elements = document.getElementsByClassName('waveform-box')
    setVoice(null)
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0])
    }
  }
  const [time, setTime] = useState(0)
  const [isRunning, setIsRunning] = useState(false)

  useEffect(() => {
    let interval

    if (isRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1000)
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [isRunning])

  const handleStart = () => {
    setIsRunning(true)
    setTime(0)
  }

  const formattedTime = new Date(time).toISOString().slice(14, 19)
  const maxTime = '03:00'

  return (
    <>
      <div>
        <section
          id="circle"
          className="w-40 h-40 rounded-full bg-red-200 flex  justify-center items-center relative z-50 circle outer-circle"
        >
          {isSpanActive && (
            <>
              <CircleBorder />
            </>
          )}
          {!isRecording ? (
            <img
              src={Mic}
              alt="mic matyar"
              className="w-24 h-24 z-30"
              onClick={() => {
                handleImageClick()
                startRecording()
                handleStart()
              }}
            />
          ) : (
            <TiMediaStop className="text-[100px] text-[#E2664C] z-[100]" onClick={stopRecording} />
          )}
        </section>
      </div>
      {voice ? (
        <>
          <div className="w-full grid grid-cols-12 py-2 bg-gray-200 rounded-full mt-1">
            <div className="w-full col-span-10">
              <div id="waveform" ref={waveform} className="w-full waveform-box" />
            </div>
            <div className="w-full col-span-2" onClick={playAudio}>
              <div className="w-full h-full flex justify-center items-center">
                <PlayPauseButton isPlaying={isPlaying} onClick={() => setIsPlaying(!isPlaying)} />
              </div>
            </div>
          </div>

          <div className="w-full flex justify-evenly items-center mt-2">
            <div className="flex justify-center items-center" onClick={SendLullaby}>
              <AiOutlineUpload className="text-xl text-blue-800" />
              <span className="text-sm mr-2">ارسال لالایی</span>
            </div>
            <span className="w-1 h-[30px] block bg-gray-300" />
            <div className="flex justify-center items-center" onClick={handleDeleteClick}>
              <BsTrash className="text-xl text-red-600" />
              <span className="text-sm mr-2">حذف آوا</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-full flex justify-center items-center">
            <p className="w-full text-center text-gray-300  ">
              {formattedTime} / {maxTime}
            </p>
          </div>
        </>
      )}
    </>
  )
}

export default VoiceRecorder
