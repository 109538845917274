import './card.css'
import Calender from '../../svg/calendar.svg'
import { BiTimeFive } from 'react-icons/bi'
import { HiOutlineUserGroup } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

function Card({
  src,
  name,
  children,
  count,
  date,
  time,
  title,
  id
  // coachImage,
}) {
  const navigate = useNavigate()
  return (
    <>
      <div
        className="w-full flex justify-end items-start h-32 relative coach-shadow rounded-xl mt-5"
        onClick={() => navigate(`/workshop-detail?id=${id}`)}
      >
        <img
          src={src}
          alt="coach picture"
          className="w-img-coach-item h-full block bg-gray-600 absolute right-0 rounded-xl"
        />
        <section className="  h-full vazir-medium rounded-xl px-4 w-8/12 flex flex-col justify-between items-start mt-2">
          <div className="flex justify-between items-center w-full px-2">
            <section className="flex flex-col">
              <span className="vazir-medium text-white main-gradient-270 opacity-90 px-2 py-1 text-xs text-center rounded-full w-fit">
                {title}
              </span>
              <p className="text-sm mt-2">{name}</p>
              <p className="  text-xs text-gray-500  ">{children}</p>
            </section>
          </div>

          <section className="w-full flex justify-evenly items-center relative -top-4">
            <section className="flex justify-start items-center">
              <img src={Calender} alt="calender matyar" className="w-4 mr-1 relative -top-0.5" />
              <span className="text-xs mr-1">{date}</span>
            </section>

            <section className="flex justify-center items-center ml-3">
              <BiTimeFive className="text-lg text-green-600 mr-1 relative -top-0.5" />
              <span className="text-xs mr-1">{time}</span>
            </section>

            <section className="flex justify-start items-center">
              <HiOutlineUserGroup className="text-lg text-orange-600 mr-1 relative -top-0.5" />
              <sapn className="text-xs mr-1">نفر{count}</sapn>
            </section>
          </section>
        </section>
      </div>
    </>
  )
}

export default Card
