import HeaderAlbum from '../../album/photo-album/component/header'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { AnimationBaseEffectAPI, Domain } from '../../../../../api'
import { useEffect, useState } from 'react'
function EffectIndex() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const name = searchParams.get('name')
  const [baseEffects, setBaseEffects] = useState([])
  const getEffectIndex = () => {
    axios
      .get(AnimationBaseEffectAPI + '/' + id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setBaseEffects(data)
      })
  }
  useEffect(() => {
    getEffectIndex()
  }, [])
  return (
    <>
      <HeaderAlbum title="شاخص اثر" />
      <div className="w-full px-4">
        <p className="my-2 text-gray-800">امتیاز دهی بر اساس شاخص اثر</p>
        <span className="text-sm text-gray-700 my-2">
          انیمیشن {name} از نظر شاخص ترس و طمع مورد ارزیابی قرار گرفته و به شاخص زیر از نظر امتیازی
          رسیده
        </span>
      </div>
      <div className="w-full px-4 mt-5">
        {baseEffects.map((bf) => (
          <div
            key={bf.id}
            className="w-full px-4 border border-blue-800 shadow rounded-xl   h-40 flex flex-col justify-between items-center mt-4"
          >
            <section className="w-full flex justify-between items-center ">
              <section className="w-6/12 flex flex-col justify-center items-center">
                <img
                  src={Domain + bf.picture}
                  alt="face is really angry"
                  className="w-28 h-28 mt-2"
                />
                <span className="text-gray-800 text-sm mt-1">{bf.title}</span>
              </section>
              <section className="flex justify-between items-center">
                {[1, 2, 3, 4, 5].map((index) => (
                  <span
                    key={index}
                    className="w-5 h-5 rounded-full block bg-orange-200 mr-1"
                    style={{
                      backgroundColor: bf.color,
                      opacity: bf.light >= index ? 1 : 0.5
                    }}
                  />
                ))}
              </section>
            </section>
          </div>
        ))}
      </div>
    </>
  )
}
export default EffectIndex
