import { BsFillBookmarkFill, BsPlayFill } from 'react-icons/bs'
import { MdArrowForwardIos, MdPlaylistAdd } from 'react-icons/md'
import Comment from './assets/comment-black2.svg'
import Heart from './assets/heart2.svg'
import Shuffle from './assets/Shuffle.svg'
import { AiFillHeart } from 'react-icons/ai'
import { TbRepeat, TbRepeatOff, TbRepeatOnce } from 'react-icons/tb'
import { useEffect, useRef, useState } from 'react'
import { GiNextButton, GiPreviousButton } from 'react-icons/gi'
import { IoPauseOutline } from 'react-icons/io5'
import './sound-play.css'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Domain, EpisodeAPI, PodcastAPI } from '../../../../../api'
import { useSelector } from 'react-redux'
import Second10Svg from './assets/icons8-forward-10-50.png'
import Second10Svgr from './assets/icons8-replay-10-50.png'
import { toast } from 'react-toastify'
import PopUp from '../../../components/pop-up/comment'
import { AddBookmark, RemoveBookmark } from '../../../../../bookmark'
import { CiBookmark } from 'react-icons/ci'

function SoundPlay() {
  const [showForm, setShowForm] = useState(false)
  const childInfo = useSelector((state) => state.childInfo)
  const navigate = useNavigate()
  const audioRef = useRef(null)
  const progressRef = useRef(null)
  const timelineRef = useRef(null)
  const [repeatSong, setRepeatSong] = useState('repeat')
  const [pause, setPause] = useState(false)
  const [like, setLike] = useState(false)
  const [detail, setDetail] = useState({})
  const [voice, setVoice] = useState('')
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [userHasComment, setUserHasCommnet] = useState(false)
  const [bookmark, setBookmark] = useState(false)
  const id = searchParams.get('id')
  const status = searchParams.get('status')
  const createPostLike = () => {
    const formData = new FormData()
    formData.append('child_id', childInfo.id)
    formData.append('episode_id', id)
    axios
      .post(PodcastAPI + '/like', formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {})
      .catch(() => {})
  }
  const getItem = () => {
    if (status == 'episode') {
      axios
        .get(EpisodeAPI + id + '?childId=' + childInfo?.id, {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        })
        .then((res) => res.data)
        .then((data) => {
          setDetail(data?.data)
          setLike(data?.liked)
          setBookmark(data?.save)
          setUserHasCommnet(data?.commented)
          setVoice(Domain + data?.data?.voice)
          audioRef.current.src = Domain + data?.data?.voice
        })
    }
  }

  const getOtherItem = (podcastId, session) => {
    axios
      .get(
        EpisodeAPI +
          'baseOnPodcast?podcastId=' +
          podcastId +
          '&childId=' +
          childInfo?.id +
          '&session=' +
          session,
        {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        }
      )
      .then((res) => res.data)
      .then((data) => {
        setDetail(data?.data)
        setLike(data?.liked)
        setUserHasCommnet(data?.commented)
        setVoice(Domain + data?.data?.voice)
        audioRef.current.src = Domain + data?.data?.voice
      })
      .catch(() => {
        toast.error('اپیزودی وجود ندارد')
      })
  }
  const handlePlayPause = () => {
    if (pause) {
      audioRef.current.pause()
    } else {
      audioRef.current.play()
    }
    setPause(!pause)
  }

  useEffect(() => {
    const audioElement = audioRef.current

    const handleLoadedMetadata = () => {
      setDuration(audioElement.duration)
    }

    const handleTimeUpdate = () => {
      setCurrentTime(audioElement.currentTime)
      const progress = (audioElement.currentTime / audioElement.duration) * 100
      progressRef.current.style.width = `${progress}%`

      if (audioRef.current.currentTime >= audioRef.current.duration) {
        if (repeatSong === 'repeat') {
          // Play the next song
          // Implement your logic to play the next audio here
        } else if (repeatSong === 'repeat-once') {
          // Repeat the current song
          // Implement your logic to repeat the audio here
          audioRef.current.currentTime = 0 // Rewind to the beginning of the audio
          audioRef.current.play()
        } else {
          // Pause the audio when the song finishes
          audioRef.current.pause()
          setPause(true)
        }
      }
    }

    audioElement.addEventListener('loadedmetadata', handleLoadedMetadata)
    audioElement.addEventListener('timeupdate', handleTimeUpdate)

    return () => {
      audioElement.removeEventListener('loadedmetadata', handleLoadedMetadata)
      audioElement.removeEventListener('timeupdate', handleTimeUpdate)
    }
  }, [])
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0')
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, '0')
    return `${minutes}:${seconds}`
  }
  const handleProgressClick = (event) => {
    const timelineWidth = timelineRef.current.offsetWidth
    const clickX = event.clientX - timelineRef.current.getBoundingClientRect().left
    const progress = clickX / timelineWidth
    const newTime = progress * audioRef.current.duration
    audioRef.current.currentTime = newTime
  }

  const handleProgressDrag = (event) => {
    const timelineWidth = timelineRef.current.offsetWidth
    const dragX = event.clientX - timelineRef.current.getBoundingClientRect().left
    const progress = dragX / timelineWidth
    const newTime = progress * audioRef.current.duration
    audioRef.current.currentTime = newTime
  }
  const totalTime = formatTime(duration)
  useEffect(() => {
    getItem()
  }, [])

  const handleNextClick = () => {
    // Increase the audio time by 10 seconds
    audioRef.current.currentTime += 10
    setCurrentTime(audioRef.current.currentTime)
  }

  const handleBeforeClick = () => {
    // Decrease the audio time by 10 seconds
    audioRef.current.currentTime -= 10
    setCurrentTime(audioRef.current.currentTime)
  }
  return (
    <div className="  overflow-hidden flex flex-col justify-between items-center">
      <PopUp
        setShowMessage={setShowForm}
        showMessage={showForm}
        titleMessage="نظر خود را با ما به اشتراک بگذارید"
        userTeam={detail.title}
        API={PodcastAPI + '/comment'}
        id={detail.id}
        body={`لطفا برای بهبود و بالا بردن خدمات ما نظر خود را درباره     ${detail.title}  ثبت کنید`}
      />
      <div className="w-full">
        <div className="w-full flex justify-between items-center px-4 my-5 text-[#3F54CB]">
          <MdArrowForwardIos
            className="text-xl"
            onClick={() => {
              navigate(-1)
            }}
          />
          <span>پخش پادکست</span>

          {bookmark ? (
            <BsFillBookmarkFill
              className="text-2xl text-[#3F54CB]"
              onClick={() => {
                RemoveBookmark(bookmark?.id)
                setBookmark(false)
              }}
            />
          ) : (
            <CiBookmark
              className="text-2xl"
              onClick={() => {
                AddBookmark('episode', detail?.id, childInfo?.id, setBookmark)
                setBookmark(true)
              }}
            />
          )}
        </div>
        <div className="w-full px-4 flex justify-center items-center">
          <section
            className="w-full h-[45vh] rounded-xl"
            style={{
              backgroundImage: `url("${Domain + detail.cover}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          />
        </div>

        <audio controls className="hidden" ref={audioRef} loop={repeatSong === 'repeat'}>
          <source src={voice} />
          Your browser does not support the audio tag.
        </audio>
        <div className="w-full flex flex-wrap justify-center items-center px-4">
          <span className="text-[#3F54CB] w-full text-center my-4">اپیزود {detail.session}</span>
        </div>
      </div>
      <div className="w-full flex flex-wrap justify-center items-center px-4">
        <span className="text-gray-800 w-full text-center mb-2">
          پادکست {detail?.podcast?.title}
        </span>
        <span className="text-gray-600 w-full text-center text-sm mb-4">{detail.description}</span>
        <div className="w-full flex justify-between items-center">
          <MdPlaylistAdd className="text-2xl" onClick={() => navigate(-1)} />
          <img
            src={Comment}
            alt="Padcoast setting in matyar"
            onClick={() => {
              if (userHasComment) {
                navigate(
                  `/list-comment?id=${detail.id}&type=episode&commentCount=${detail.commentCount}&rateCount=${detail.rateCount}`
                )
              } else {
                setShowForm(true)
              }
            }}
          />
          {like ? (
            <AiFillHeart
              className="text-2xl text-red-600"
              onClick={() => {
                setLike(false)
                createPostLike()
              }}
            />
          ) : (
            <img
              src={Heart}
              alt="Padcoast setting in matyar"
              onClick={() => {
                setLike(true)
                createPostLike()
              }}
            />
          )}

          {repeatSong === 'repeat-once' && (
            <TbRepeat className="text-2xl" onClick={() => setRepeatSong('repeat')} />
          )}
          {repeatSong === 'repeat' && (
            <TbRepeatOnce
              className="text-2xl text-green-600"
              onClick={() => setRepeatSong('no-repeat')}
            />
          )}
          {repeatSong === 'no-repeat' && (
            <TbRepeatOff
              className="text-2xl text-red-600"
              onClick={() => setRepeatSong('repeat-once')}
            />
          )}

          <img src={Shuffle} alt="Padcoast setting in matyar " className="hidden" />
        </div>
      </div>
      <div className="w-full px-4">
        <span
          className="w-full h-1 rounded-md bg-gray-200 block dir-ltr mt-6"
          ref={timelineRef}
          onClick={handleProgressClick}
          onMouseDown={() => {
            window.addEventListener('mousemove', handleProgressDrag)
          }}
          onMouseUp={() => {
            window.removeEventListener('mousemove', handleProgressDrag)
          }}
        >
          <span ref={progressRef} className="w-6/12 h-1 rounded-md bg-blue-200 block" />
        </span>
        <div className="dir-ltr text-gray-400 text-center mt-4 text-sm">
          {formatTime(currentTime)} / {totalTime}
        </div>
        <div className="w-full flex justify-between items-center mt-5">
          <GiNextButton
            className="text-2xl"
            onClick={() => {
              getOtherItem(detail?.podcast?.id, detail?.session + 1)
            }}
          />

          <span onClick={handleNextClick} className="">
            <img alt="10second" className="w-7" src={Second10Svg} />
          </span>
          {pause ? (
            <div className="bg-blue-200 rounded-full w-10 h-10 flex justify-center items-center">
              <IoPauseOutline
                className="text-3xl text-[#3F54CB]"
                onClick={() => {
                  setPause(false)
                  handlePlayPause()
                }}
              />
            </div>
          ) : (
            <div className="bg-blue-200 rounded-full w-10 h-10 flex justify-center items-center">
              <BsPlayFill
                className="text-3xl text-[#3F54CB]"
                onClick={() => {
                  setPause(true)
                  handlePlayPause()
                }}
              />
            </div>
          )}
          <span onClick={handleBeforeClick} className="">
            <img alt="10second" className="w-7" src={Second10Svgr} />
          </span>
          <GiPreviousButton
            className="text-2xl"
            onClick={() => {
              getOtherItem(detail?.podcast?.id, detail?.session - 1)
            }}
          />
        </div>
      </div>
    </div>
  )
}
export default SoundPlay
