import { useEffect, useState } from 'react'
import { BsCheck2 } from 'react-icons/bs'
import Header from '../../components/detail-header'
import PlayVideo from './svg/playicon.svg'
import Calendar from './svg/calendar.svg'

// import EducationalTools from './component/educational-tools'
import './detail-activities.css'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import {
  ActivityAPI,
  CreateLikePost,
  CreateSavePost,
  CreateViewPost,
  Domain,
  GetLikePost,
  PostAPI
} from '../../../../api'
import { toast } from 'react-toastify'
import returnTime from '../../../../convert-time'
import { useSelector } from 'react-redux'
import DatePicker from 'react-multi-date-picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import transition from 'react-element-popper/animations/transition'
import DateObject from 'react-date-object'
import Message from '../../components/message'
import EducationalTools from './component/educational-tools'
import Loading from '../../../../component/loading'
import {
  ControlBar,
  CurrentTimeDisplay,
  ForwardControl,
  PlaybackRateMenuButton,
  Player,
  ReplayControl,
  TimeDivider,
  VolumeMenuButton
} from 'video-react'
import VideoBox from '../post-detail/component/video-box'
function DetailActivities() {
  const [save, setSave] = useState(false)
  const [loading, setLoading] = useState(false)
  const datePicker = new DateObject({ calendar: persian, locale: persian_fa })
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const activityId = searchParams.get('activity_id')
  const childInfo = useSelector((state) => state.childInfo)
  const [canDoIt, setCanDoIt] = useState(false)
  const [canNotDoIt, setCanNotDoIt] = useState(false)
  const [post, setPost] = useState([])
  const [date, setDate] = useState(
    `${datePicker.year}/${datePicker.month.number}/${datePicker.day}`
  )
  const [activityDetail, setActivityDetail] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [postLike, setPostLike] = useState(0)
  const [postView, setPostView] = useState(0)
  const [message, setMessage] = useState(null)
  const [liked, setLiked] = useState(false)
  const AgeRange = (daysDifference) => {
    if (daysDifference <= 180) {
      return 'نوزاد'
    } else if (daysDifference <= 720) {
      return 'نوپا'
    } else if (daysDifference <= 2520) {
      return 'خردسال'
    }
  }
  const [state, setState] = useState({ format: 'dddd DD MMMM YYYY' })
  const convert = (date, format = state.format) => {
    let object = { date, format }

    setState({
      persian: new DateObject(object).format(),
      jsDate: date.toDate(),
      ...object
    })
  }
  const getPostDetail = () => {
    setLoading(true)
    axios
      .get(PostAPI + id + '?id=' + childInfo?.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setLoading(false)
        setPost(data.data)
        setPostView(data.data.numViews)
        setLiked(data.liked)
        setSave(data?.save)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message)
      })
  }
  const getActivityDetail = () => {
    axios
      .get(ActivityAPI + activityId, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setActivityDetail(data)
        if (data.status == 'done') {
          setCanDoIt(true)
        } else if (data.status == 'undone') {
          setCanDoIt(false)
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  const getPostLike = () => {
    axios
      .get(GetLikePost + `?id=${id}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setPostLike(data.numLikes)
      })
  }
  const createPostLike = () => {
    const formData = new FormData()
    formData.append('child_id', childInfo.id)
    formData.append('post_id', id)

    axios
      .post(CreateLikePost, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setPostLike(data)
        getPostLike()
      })
      .catch(() => {
        getPostLike()
      })
  }
  const createPostView = () => {
    const formData = new FormData()
    formData.append('child_id', childInfo.id)
    formData.append('post_id', id)

    axios
      .post(CreateViewPost, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {})
      .catch(() => {})
  }
  const updateActivities = () => {
    const formData = new FormData()
    formData.append('id', activityId)
    formData.append('status', canDoIt ? 'done' : 'undone')
    formData.append('time_activity', date)

    axios
      .patch(ActivityAPI + activityId, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        setMessage({
          icon: <img src="/assets/message/checked.svg" alt="check-workshop" className="w-full" />,
          className: 'bg-[#3F54CB]',
          title: 'تبریک میگم',
          subtitle: 'فعالیت ثبت شد',
          bgButton: 'bg-[#3F54CB]',
          children: 'فعالیت کودک شما با موفقیت ثبت شد و برای مشاور ارسال شد'
        })
      })
      .catch((e) => {
        setMessage({
          icon: <img src="/assets/message/cancel.svg" alt="check-workshop" className="w-full" />,
          className: 'bg-red-500',
          title: 'متاسفم',
          subtitle: e.response.data,
          bgButton: 'bg-red-600',
          children: e.response.data.message
        })
      })
  }

  const createPostSave = () => {
    const formData = new FormData()
    formData.append('child_id', childInfo.id)
    formData.append('post_id', id)

    axios
      .post(CreateSavePost, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        getPostLike()
      })
      .catch(() => {
        getPostLike()
      })
  }
  useEffect(() => {
    getPostDetail()
    getPostLike()
    createPostView()
    getActivityDetail()
    convert(datePicker)
  }, [])
  return (
    <>
      <Header title="جزییات فعالیت" />
      {message && <Message {...message} OnChange={setMessage} />}
      {loading ? (
        <Loading color="#1e40af" width="100px" />
      ) : (
        <div className="w-full px-5 mb-10">
          {showModal ? (
            <div className="w-full rounded-2xl overflow-hidden">
              <div
                className="w-screen h-screen left-0 top-0 fixed z-[98] bg-[rgba(0,0,0,0.5)] "
                onClick={() => setShowModal(false)}
              />

              <Player autoPlay className="z-[98]   dir-ltr  ">
                <source src={Domain + post.video} className="z-[98]   dir-ltr" />

                <ControlBar autoHide={false}>
                  <ReplayControl seconds={10} order={1.1} />
                  <ForwardControl seconds={30} order={1.2} />
                  <CurrentTimeDisplay order={4.1} />
                  <TimeDivider order={4.2} />

                  <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                  <VolumeMenuButton disabled />
                </ControlBar>
              </Player>
            </div>
          ) : (
            <section className="w-full relative mt-6">
              <section className="absolute w-full h-full bg-black opacity-60 rounded-2xl" />
              <img
                className="w-full h-full rounded-2xl "
                src={Domain + post.poster}
                alt={post.title}
              />
              <section
                onClick={() => setShowModal(true)}
                className=" w-14 absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 cursor-pointer bg-white p-4 rounded-full"
              >
                <img src={PlayVideo} alt="play video" className="w-10" />
              </section>
            </section>
          )}

          <div className="px-4 flex justify-center items-center">
            <VideoBox
              id={post.id}
              save={save}
              SaveClick={createPostSave}
              OnClick={createPostLike}
              title={post.title}
              code={post.code}
              like={postLike}
              view={postView}
              time={returnTime(post.created_at).split(',')[0]}
              ageDetail={`  رده سنی ${AgeRange(post.age)}
           
             `}
              liked={liked}
              setLiked={() => {
                setLiked(!liked)
              }}
            />
          </div>
          <div className="px-4 vazir-medium">
            <p className="text-gray-700">توضیح فعالیت</p>
            <div
              className="text-sm text-gray-400 mt-4 text-justify"
              dangerouslySetInnerHTML={{
                __html: post.text
              }}
            />
          </div>
          <div className="px-4 vazir-medium mt-6">
            <p className="text-gray-700">ابزارهای آموزشی</p>
            <div className="flex flex-wrap justify-start items-center mt-4">
              {post.tools &&
                Array.isArray(JSON.parse(JSON.parse(post.tools))) &&
                JSON.parse(JSON.parse(post.tools)).map((tool) => (
                  <EducationalTools
                    key={tool}
                    text={tool}
                    className="border-blue-500 text-blue-500"
                  />
                ))}
            </div>
            <div className="choose-activities-done w-full rounded-3xl flex flex-col flex-wrap justify-center items-center px-4 mt-10">
              <p className="mt-4 text-right w-full text-gray-800">
                در رابطه با فعالیت بالا انتخاب کنید:
              </p>
              <div
                className={`w-full flex justify-start items-start py-3 px-3 text-sm rounded-xl mt-4 box-done ${canDoIt}`}
              >
                <span className={canDoIt ? 'checked' : 'unchecked'}>
                  <input
                    type="radio"
                    id="done"
                    defaultValue={activityDetail && activityDetail.status == 'done' ? true : false}
                    name="ability"
                    className="ml-4 w-5 h-5 absolute z-50 opacity-0"
                    onChange={(e) => {
                      setCanNotDoIt(!e.target.checked)
                      setCanDoIt(e.target.checked)
                    }}
                    disabled={
                      (activityDetail && activityDetail.status == 'done') ||
                      (activityDetail && activityDetail.status == 'undone')
                    }
                  />
                  <BsCheck2 className="text-xl" />
                </span>
                <label htmlFor="done" className="text-gray-600">
                  کودکم توانست فعالیت را انجام دهد
                  <span className="checkmark" />
                </label>
              </div>
              <div
                className={`w-full flex justify-start items-start py-3 px-3 text-sm rounded-xl mt-4 box-done ${canNotDoIt}`}
              >
                <span className={canNotDoIt ? 'checked' : 'unchecked'}>
                  <input
                    type="radio"
                    id="undone"
                    defaultValue={
                      activityDetail && activityDetail.status == 'undone' ? true : false
                    }
                    name="ability"
                    className="ml-4 w-5 h-5 absolute z-50 opacity-0"
                    onChange={(e) => {
                      setCanDoIt(!e.target.checked)
                      setCanNotDoIt(e.target.checked)
                    }}
                    disabled={
                      (activityDetail && activityDetail.status == 'undone') ||
                      (activityDetail && activityDetail.status == 'done')
                    }
                  />
                  <BsCheck2 className="text-xl" />
                </span>
                <label htmlFor="undone" className="text-gray-600">
                  کودکم نتوانست فعالیت را انجام دهد
                  <span className="checkmark" />
                </label>
              </div>
              <DatePicker
                className="rmdp-mobile"
                onChange={(e) => {
                  setDate(`${e.year}/${e.month.number}/${e.day}`)
                  convert(e)
                }}
                multiple={false}
                calendar={persian}
                locale={persian_fa}
                calendarPosition="bottom-right"
                mobileLabels={{
                  OK: 'تایید',
                  CANCEL: 'بستن'
                }}
                animations={[
                  transition({
                    from: 35,
                    transition: 'all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)'
                  })
                ]}
                render={(value, openCalendar) => {
                  return (
                    <div
                      onClick={openCalendar}
                      className="flex justify-between items-center text-gray-600 border border-gray-300 rounded-xl mt-4 w-[60vw] py-2 px-3"
                    >
                      <p className="text-sm">
                        {activityDetail.time_activity
                          ? new DateObject(activityDetail.time_activity)
                              .convert(persian, persian_fa)
                              .format('dddd DD MMMM YYYY')
                          : state.persian}
                      </p>
                      <img src={Calendar} alt="calendar svg" className="w-6 h-6" />
                    </div>
                  )
                }}
              />

              <button
                className="w-10/12 text-white rounded-xl  py-3 bg-blue-800 my-4 shadow-md"
                onClick={updateActivities}
              >
                ثبت فعالیت
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default DetailActivities
