import { motion } from 'framer-motion'
import { FaPlay, FaPause } from 'react-icons/fa'

function PlayPauseButton({ isPlaying, onClick }) {
  return (
    <motion.button onClick={onClick} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
      {isPlaying ? (
        <FaPause className="text-2xl text-[#E2664C]" />
      ) : (
        <FaPlay className="text-2xl text-[#E2664C] " />
      )}
    </motion.button>
  )
}
export default PlayPauseButton
