import HeaderAlbum from '../../../album/photo-album/component/header'
import FoodImage from '../svg/burger.svg'
import { BsFillBookmarkFill } from 'react-icons/bs'
import ClockImage from '../svg/clock.svg'
import FireImage from '../svg/fire.svg'
import StarImage from '../svg/star.svg'

import Nutritional from './svg/nutritional-value.svg'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useState } from 'react'

import { FaPencilAlt } from 'react-icons/fa'

function FruitDetail() {
  const [show, setShow] = useState(false)

  const text =
    'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
  return (
    <>
      <HeaderAlbum title="همبرگر مخصوص پنیری" />
      <div
        className="w-full h-96 relative"
        style={{
          backgroundImage: `url("${FoodImage}")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <section className="background-food w-full h-24 absolute bottom-0" />
      </div>
      <div className="w-full flex justify-between items-center px-4 pt-4 bg-blue-50">
        <div className="W-full ">
          <p className="text-gray-800">همبرگر مخصوص</p>
          <section className="flex justify-between items-center text-xs text-gray-600 mt-3">
            <span>نوشیدنی سرد</span>
            &nbsp;&nbsp; <span>.</span>&nbsp;&nbsp;
            <span>رده سنی بالای دو سال</span>
          </section>
        </div>
        <BsFillBookmarkFill className="text-2xl text-[#3F54CB]" />
      </div>
      <div className="w-full flex justify-between items-center px-4 text-sm text-gray-700 pt-4 bg-blue-50">
        <section className="flex justify-center items-center">
          <img src={ClockImage} alt="clock food matyar" />
          <span className="mx-2">90</span>
          <span>دقیقه</span>
        </section>
        <section className="flex justify-center items-center">
          <img src={FireImage} alt="clock food matyar" />
          <span className="mx-2">300</span>
          <span>کالری</span>
        </section>
        <section className="flex justify-center items-center">
          <span>+</span>
          <span className="ml-2">۴</span>
          <img src={StarImage} alt="clock food matyar" />
        </section>
      </div>
      <div className="w-full pt-5 px-4 bg-blue-50">
        <div className="text-sm text-gray-400 ">
          <span className="text-gray-700 text-sm">
            {text.substring(0, show ? 2000 : 90)}
            {show ? null : '...'}
          </span>
        </div>
        {show ? (
          <section
            className="text-red-600 flex justify-start items-center    py-1 rounded mt-1"
            onClick={() => {
              setShow(false)
            }}
          >
            <span className="text-xs">خلاصه</span>
            <IoIosArrowUp className="text-red-600" />
          </section>
        ) : (
          <section
            className="text-blue-600 flex justify-start items-center     py-1 rounded mt-1"
            onClick={() => {
              setShow(true)
            }}
          >
            <span className="text-xs">مطالعه بیشتر</span>
            <IoIosArrowDown className="text-blue-600" />
          </section>
        )}
      </div>
      <div className="w-full bg-blue-50 px-4 pt-10">
        <div className="w-full px-4 rounded-xl overflow-hidden border border-gray-300 shadow-lg mt-5 bg-white">
          <div className="w-full px-4  pt-5 pb-20 flex flex-wrap">
            <div className="w-full flex justify-between items-center">
              <section className="flex justify-start items-center">
                <img src={Nutritional} alt="matyar" className="ml-2" />
                <p className="text-gray-800 w-full">ارزش غذایی:</p>
              </section>
              <span className="text-sm text-blue-700 flex justify-between items-center">
                در هر ۱۰۰ گرم
                <FaPencilAlt className="mr-2" />
              </span>
            </div>

            <div className="w-full flex justify-between items-center mt-2 pt-1 pb-4 border-t border-t-gray-400">
              <span className="text-sm text-gray-600">گوشت گوسفندی</span>
              <span className="text-sm text-gray-800">۲۵۰ گرم</span>
            </div>

            <div className="w-full flex justify-between items-center mt-2 pt-1 pb-4 border-t border-t-gray-400">
              <span className="text-sm text-gray-600">گوشت گوسفندی</span>
              <span className="text-sm text-gray-800">۲۵۰ گرم</span>
            </div>

            <div className="w-full flex justify-between items-center mt-2 pt-1 pb-4 border-t border-t-gray-400">
              <span className="text-sm text-gray-600">گوشت گوسفندی</span>
              <span className="text-sm text-gray-800">۲۵۰ گرم</span>
            </div>

            <div className="w-full flex justify-between items-center mt-2 pt-1 pb-4 border-t border-t-gray-400">
              <span className="text-sm text-gray-600">گوشت گوسفندی</span>
              <span className="text-sm text-gray-800">۲۵۰ گرم</span>
            </div>

            <div className="w-full flex justify-between items-center mt-2 pt-1 pb-4 border-t border-t-gray-400">
              <span className="text-sm text-gray-600">گوشت گوسفندی</span>
              <span className="text-sm text-gray-800">۲۵۰ گرم</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default FruitDetail
