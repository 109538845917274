import HeaderAlbum from '../../album/photo-album/component/header'
// import Swiper core and required modules
import { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { TfiLocationPin } from 'react-icons/tfi'
import { AiFillStar } from 'react-icons/ai'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useEffect, useState } from 'react'
import { FaHamburger, FaStar } from 'react-icons/fa'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { useLocation, useNavigate } from 'react-router-dom'
import { Domain, LocationAPI } from '../../../../../api'
import axios from 'axios'
import PopUp from '../../../components/pop-up/comment'
import Comment from '../../coach-team-detail/component/comment'
import Subscription from '../../buy-subscription/svg/subscription-model.svg'
import { useSelector } from 'react-redux'

function DetailPlace() {
  useEffect(() => {
    window.scrollTo({ top: true })
  }, [])
  const navigate = useNavigate()
  const position = [51.505, -0.09]
  const [show, setShow] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [post, sePost] = useState([])
  const [needSub, setNeedSub] = useState(false)
  const childInfo = useSelector((state) => state.childInfo)

  const detailLocation = () => {
    axios
      .get(LocationAPI + '/user/' + id + '?childId=' + childInfo?.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setData(data.location)
        sePost(data.posts)
      })
      .catch((e) => {
        if (e.response.data === 'subscribe') {
          setNeedSub(true)
        }
        if (e.response.data === 'child') {
          console.log('child')
        }
      })
  }

  useEffect(() => {
    detailLocation()
  }, [id])
  const [showForm, setShowForm] = useState(false)

  return (
    <>
      <PopUp
        setShowMessage={setShowForm}
        showMessage={showForm}
        titleMessage="نظر خود را با ما به اشتراک بگذارید"
        userTeam={data.title}
        API={LocationAPI + '/comment'}
        id={data.id}
        body={`لطفا برای بهبود و بالا بردن خدمات ما نظر خود را درباره مکان   ${data.title}  ثبت کنید`}
      />
      <HeaderAlbum title={data.title} />

      {needSub ? (
        <>
          <div className="flex justify-center items-center flex-wrap vazir-medium mt-14 px-4">
            <div className="w-48 h-48 bg-blue-200 rounded-full flex justify-center items-center">
              <img src={Subscription} alt="buy subscription matyar" />
            </div>
            <div className="w-full flex flex-col justify-center items-center flex-wrap mt-5">
              <p className="text-lg text-center"> شما اشتراک فعالی ندارید</p>
              <p className="text-sm text-gray-500 text-center w-8/12 mt-3">
                شما اشتراک فعالی ندارید برای دیدن این جزییات این مکان باید اشتراکی خریداری کنید
              </p>
            </div>

            <button
              className="w-full bg-[#3F54CB] text-white rounded-lg py-4 vazir-medium mt-14"
              onClick={() => navigate('/list-subscription')}
            >
              خرید اشتراک
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="w-full">
            <Swiper
              modules={[Pagination, Autoplay]}
              loop={true}
              autoplay={{
                delay: 3000, // autoplay every 3 seconds
                disableOnInteraction: false
              }}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{
                clickable: true,
                renderBullet: function (index, className) {
                  return `
        <span class="${className}   slider-navigation ${
                    index === this.activeIndex ? 'slider-active' : ''
                  }"></span>
      `
                }
              }}
            >
              {data.picture1 && (
                <SwiperSlide>
                  {' '}
                  <div
                    className="w-full h-64 "
                    style={{
                      backgroundImage: `url("${Domain + data.picture1}")`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}
                  />
                </SwiperSlide>
              )}

              {data.picture2 && (
                <SwiperSlide>
                  {' '}
                  <div
                    className="w-full h-64 "
                    style={{
                      backgroundImage: `url("${Domain + data.picture2}")`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}
                  />
                </SwiperSlide>
              )}

              {data.picture3 && (
                <SwiperSlide>
                  {' '}
                  <div
                    className="w-full h-64 "
                    style={{
                      backgroundImage: `url("${Domain + data.picture3}")`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}
                  />
                </SwiperSlide>
              )}
              {data.picture4 && (
                <SwiperSlide>
                  {' '}
                  <div
                    className="w-full h-64 "
                    style={{
                      backgroundImage: `url("${Domain + data.picture4}")`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}
                  />
                </SwiperSlide>
              )}

              {data.picture5 && (
                <SwiperSlide>
                  {' '}
                  <div
                    className="w-full h-64 "
                    style={{
                      backgroundImage: `url("${Domain + data.picture5}")`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}
                  />
                </SwiperSlide>
              )}

              {data.picture6 && (
                <SwiperSlide>
                  {' '}
                  <div
                    className="w-full h-64 "
                    style={{
                      backgroundImage: `url("${Domain + data.picture6}")`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}
                  />
                </SwiperSlide>
              )}
            </Swiper>
          </div>
          <div className="w-full flex justify-between items-center px-4 mt-4">
            <section className="flex flex-col justify-between items-start">
              <p className="text-gray-800">{data.title}</p>
              <div className="w-full flex justify-start items-center z-50 mt-2  ">
                <TfiLocationPin className="text-gray-600 text-xl ml-2" />
                <span className="text-gray-600 text-sm">{data?.city?.title}</span>
              </div>
            </section>
            <section className=" px-2 py-1 rounded-md flex justify-between items-center bg-[#1e40af]">
              <FaStar className="text-2xl text-white" />
              <span className="text-white mt-1 mr-2">{data?.rateCount}</span>
            </section>
          </div>
          <div className="w-full px-4 mt-5">
            <p className="text-gray-800">معرفی</p>
            <div className="text-sm text-gray-400  mt-1">
              <span className="text-gray-400 text-sm">
                <div
                  className={`overflow-hidden ${show ? 'h-auto' : 'h-[20px]'} `}
                  dangerouslySetInnerHTML={{
                    __html: data?.description
                  }}
                />
                {show ? null : '...'}
              </span>
            </div>
            {show ? (
              <section
                className="text-red-600 flex justify-start items-center    py-1 rounded mt-1"
                onClick={() => {
                  setShow(false)
                }}
              >
                <span className="text-xs">خلاصه</span>
                <IoIosArrowUp className="text-red-600" />
              </section>
            ) : (
              <section
                className="text-blue-600 flex justify-start items-center     py-1 rounded mt-1"
                onClick={() => {
                  setShow(true)
                }}
              >
                <span className="text-xs">مطالعه بیشتر</span>
                <IoIosArrowDown className="text-blue-600" />
              </section>
            )}
          </div>
          <div className="w-full px-4 mt-5">
            <section className="w-full border-t border-t-gray-400 flex justify-between items-center py-3">
              <span className="text-sm text-gray-600">ساعات بازدید</span>
              <span className="text-sm text-gray-600">{data.time}</span>
            </section>

            <section className="w-full border-t border-t-gray-400 flex justify-between items-center py-3">
              <span className="text-sm text-gray-600">نوع مکان </span>
              <span className="text-sm text-gray-600">{data?.category?.title}</span>
            </section>

            <section className="w-full border-t border-t-gray-400 flex justify-start items-center py-3 flex-wrap">
              <span className="text-sm text-gray-600 w-full mb-2">امکانات مجموعه </span>
              {data?.possibilities?.map((ps) => (
                <span
                  key={ps.id}
                  className="text-sm text-gray-600 flex justify-center items-center border border-gray-300 rounded-xl px-4 py-2 ml-2"
                >
                  {ps.title}

                  {ps.picture ? (
                    <img
                      src={Domain + ps.picture}
                      alt="picture"
                      className="w-[20px] h-[20px] mr-2"
                    />
                  ) : (
                    <FaHamburger className="text-lg mr-2" />
                  )}
                </span>
              ))}
            </section>

            <section className="w-full flex justify-between items-center py-3 flex-wrap  border-t border-t-gray-400">
              <span className="text-gray-800 w-full mb-2">آدرس </span>
              <span className="text-sm text-gray-600 ">{data.address}</span>
            </section>

            <section className="w-full mt-5 hidden">
              <p className="text-gray-800 mb-3">موقعیت</p>
              <div className="w-full   h-[300px] overflow-hidden dir-ltr rounded-xl">
                <MapContainer center={[35.6998, 51.3376]} zoom={13} scrollWheelZoom={false}>
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={data?.geographical ? JSON.parse(data?.geographical) : position}>
                    <Popup>
                      A pretty CSS3 popup. <br /> Easily customizable.
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>
            </section>

            <div className="w-full flex justify-between items-center  mt-8 mb-4">
              <p className="text-sm text-gray-800">{data?.city?.title}، لذت هیجان انگیز</p>
            </div>

            <Swiper spaceBetween={10} slidesPerView={2.1}>
              {post?.map((ps) => (
                <SwiperSlide key={ps.id} className="rounded-xl px-0.5 py-2">
                  <div className="w-full">
                    <section
                      className="w-full relative rounded-xl h-48"
                      style={{
                        backgroundImage: `url("${Domain + ps.picture1}")`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center'
                      }}
                    ></section>
                    <p className="text-gray-700 text-sm my-2">{ps?.category?.title}</p>
                    <div className="w-full flex justify-between items-center flex-wrap">
                      <p
                        className="w-full"
                        onClick={() => {
                          navigate('/detail-place?id=' + ps.id)
                          window.scrollTo(0, 0)
                        }}
                      >
                        {ps.title}
                      </p>
                      <div className="w-full flex justify-between items-center mt-4">
                        <button className="px-2 py-1 rounded-lg bg-[#3F54CB] flex justify-between">
                          <AiFillStar className="text-white" />
                          <span className="text-white mr-1 text-xs">
                            {!isNaN(Math.ceil(ps.rateCount / ps.commentCount))
                              ? Math.ceil(ps.rateCount / ps.commentCount)
                              : 0}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <Comment
              count={data?.commentCount}
              rate={data?.rateCount}
              id={data?.id}
              API={LocationAPI + '/comment/user?id='}
              type="location"
              setShowForm={() => setShowForm(true)}
            />
          </div>
        </>
      )}
      <br />
    </>
  )
}

export default DetailPlace
