import HeaderAlbum from '../../album/photo-album/component/header'
import Card from './component/card'
import { MdGroups, MdInsertComment } from 'react-icons/md'
import { FaUser } from 'react-icons/fa'

function DoctorAppointment() {
  return (
    <>
      <HeaderAlbum title="نوبت های چزشکی" />
      <div className="w-full px-4 py-5">
        <Card />
        <div className="w-full grid grid-cols-3 gap-4 border border-[#3F54CB] rounded-xl py-2 my-5">
          <section className="w-full flex flex-col justify-between items-center">
            <section className="w-12 h-12 bg-blue-300 rounded-full flex justify-center items-center">
              <MdGroups className="text-[#3F54CB] text-xl" />
            </section>
            <span className=" text-[#3F54CB]">5000</span>
            <span className="text-md text-gray-700">ویزیت آنلاین</span>
          </section>

          <section className="w-full flex flex-col justify-between items-center">
            <section className="w-12 h-12 bg-blue-300 rounded-full flex justify-center items-center">
              <FaUser className="text-[#3F54CB] text-xl" />
            </section>
            <span className="text-[#3F54CB]">5000</span>
            <span className="text-md text-gray-700">تجربه کاری</span>
          </section>

          <section className="w-full flex flex-col justify-between items-center">
            <section className="w-12 h-12 bg-blue-300 rounded-full flex justify-center items-center">
              <MdInsertComment className="text-[#3F54CB] text-xl" />
            </section>
            <span className="text-[#3F54CB]">5000</span>
            <span className="text-md text-gray-700">نظرات کاربران</span>
          </section>
        </div>

        <div className="w-full border-t border-t-gray-300 pt-5 mt-5">
          <p className="text-gray-700"> زمان ویزیت</p>
          <div className="w-full  flex justify-between items-center mt-3 ">
            <span className="text-sm text-gray-500">ساعت:</span>
            <span className="text-gray-700  ">08:00 - 09:00</span>
          </div>
          <div className="w-full flex justify-between items-center mt-3">
            <span className="text-sm text-gray-500">روز:</span>
            <span className="text-gray-700">دوشنبه</span>
          </div>
          <div className="w-full  flex justify-between items-center mt-3">
            <span className="text-sm text-gray-500">تاریخ:</span>
            <span className="text-gray-700">1402/12/01</span>
          </div>
        </div>

        <div className="w-full border-t border-t-gray-300 pt-5 mt-5">
          <p className="text-gray-700"> اطلاعات بیمار</p>
          <div className="w-full  flex justify-between items-center mt-3 ">
            <span className="text-sm text-gray-500">نام و نام خانوادگی:</span>
            <span className="text-gray-700  ">08:00 - 09:00</span>
          </div>
          <div className="w-full flex justify-between items-center mt-3">
            <span className="text-sm text-gray-500">سال تولد:</span>
            <span className="text-gray-700">دوشنبه</span>
          </div>
          <div className="w-full  flex justify-between items-center mt-3">
            <span className="text-sm text-gray-500">شماره تماس:</span>
            <span className="text-gray-700">1402/12/01</span>
          </div>
        </div>

        <div className="w-full border-t border-t-gray-300 pt-5 mt-5">
          <p className="text-gray-700"> هزینه ویزیت</p>
          <section className="w-full flex justify-start items-center text-[#3F54CB] text-sm mt-2">
            <span>30,000 تومان</span>
            <span>(هزینه چرداخت شده)</span>
          </section>
        </div>

        <button className="bg-[#3F54CB] rounded-lg text-white flex justify-center items-center my-5 w-full py-2 px-4 text-sm">
          <span>برقراری تماس</span>
        </button>
      </div>
    </>
  )
}
export default DoctorAppointment
