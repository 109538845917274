import { AlbumAPI, Domain } from '../../../../../../../api'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
function Card({ data, ReloadFunction }) {
  const navigate = useNavigate()
  const [canSelect, setCanSelect] = useState(false)
  const updateAlbumOrder = (id) => {
    const formData = new FormData()
    formData.append('title', id)
    axios
      .patch(AlbumAPI + '/user/' + data.id, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        toast.success('نام آلبوم با موفقیت تغییر کرد')
        ReloadFunction()
      })
      .catch((e) => {
        e.response.data.messages.map((msg) => toast.error(msg))
      })
  }

  return (
    <>
      <div className="w-full flex flex-col justify-start items-start relative">
        <section
          onClick={() => {
            navigate('/photo-album?id=' + data?.id)
          }}
          className="rounded-xl w-full h-32"
          style={{
            backgroundImage: `url("${Domain + data?.album?.picture}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />

        {!canSelect ? (
          <p
            className="text-gray-700 mt-1"
            onClick={() => {
              setCanSelect(true)
            }}
          >
            {data.title}
          </p>
        ) : (
          <input
            type="text"
            placeholder="لطفا نام آلبوم خود را بنویسید"
            onBlur={(e) => {
              updateAlbumOrder(e.target.value)
              setCanSelect(false)
            }}
          />
        )}
      </div>
    </>
  )
}
export default Card
