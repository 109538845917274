import { SlArrowDown } from 'react-icons/sl'
import './accordion.css'
import ChooseBox from '../../../components/choose-box'
function Accordion({ title, className, children }) {
  return (
    <div className="vazir-medium ">
      <section
        className={`${className} text-sm px-4 rounded-xl accordion-question z-30`}
        onClick={(e) => ChooseBox(e, 'accordion-question')}
      >
        <section className="flex justify-between items-center w-full mt-4 z-10">
          <span>{title}</span>
          <SlArrowDown />
        </section>
        <p className="w-full mt-4 text-xs text-gray-500 pb-4 z-10">{children}</p>
      </section>
    </div>
  )
}
export default Accordion
