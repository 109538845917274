function Message({ icon, className, title, subtitle, children, bgButton, OnChange }) {
  return (
    <>
      <div className="w-full vazir-medium fixed z-[54]">
        <div
          className="w-full h-screen overflow-hidden fixed top-0 left-0 bg-[rgba(0,0,0,0.4)] z-[40]"
          onClick={() => OnChange(false)}
        />
        <div className="w-full absolute top-[50px] px-4 z-[55]">
          <div className="w-full flex flex-col bg-white py-5 rounded-xl justify-center items-center px-4">
            <section
              className={`w-32 h-32 rounded-full flex justify-center items-center ${className}`}
            >
              {icon}
            </section>
            <p className="text-3xl text-gray-700 mt-5">{title}</p>
            <p className="text-lg text-gray-500 my-5">{subtitle}</p>
            <span className="text-center text-sm text-gray-400">{children}</span>
            <button
              className={`w-full py-4 text-center rounded-xl ${bgButton} shadow-md text-white mt-10`}
              onClick={() => OnChange(false)}
            >
              تایید
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Message
