import HeaderAlbum from '../../album/photo-album/component/header'
import { VscSearch, VscSettings } from 'react-icons/vsc'
import { MdKeyboardArrowLeft, MdPlayArrow } from 'react-icons/md'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade, Pagination } from 'swiper'
import Heart from '../../articles/detail/svg/Heart.svg'
import Comment from '../../articles/detail/svg/comment-green.svg'
import Show from '../../articles/detail/svg/Show.svg'
import axios from 'axios'
import {
  AnimationBannerAPI,
  AnimationBaseCategoryAPI,
  AnimationCategoryAPI,
  AnimationSectionAPI,
  Domain
} from '../../../../../api'
import { useEffect, useState } from 'react'
import { CgMenuGridO } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom'

function MainAnimation() {
  const navigate = useNavigate()
  const [category, setCategory] = useState([])
  const [banner, setBanner] = useState([])
  const [animationList, setAnimationList] = useState([])
  const [sectionList, setSectionList] = useState([])
  const [search, setSearch] = useState('')

  const getCategory = () => {
    axios
      .get(AnimationCategoryAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCategory(data)
      })
  }
  const getBanner = () => {
    axios
      .get(AnimationBannerAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setBanner(data)
      })
  }

  const getAnimation = () => {
    axios
      .get(AnimationBaseCategoryAPI + `/?search=${search}&category=`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setAnimationList(data)
      })
  }
  const getSection = () => {
    axios
      .get(AnimationSectionAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setSectionList(data)
      })
  }
  useEffect(() => {
    getCategory()
    getBanner()
    getAnimation()
    getSection()
  }, [])
  return (
    <>
      <HeaderAlbum title="انیمیشن ها" />
      <div className="w-full flex justify-start items-center px-4 hidden">
        <section className="w-11/12 relative">
          <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
          <input
            onChange={(e) => setSearch(e.target.value)}
            className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
            placeholder="انیمیشن خود را جستجو کنید..."
          />
        </section>
        <section className="w-12 h-12 flex justify-center items-center card-shadow rounded-lg mr-2">
          <VscSettings className="text-2xl text-gray500" />
        </section>
      </div>
      <div className="w-full flex justify-between items-center px-4 mt-4">
        <p>دسته بندی ها</p>
        <section className="flex justify-start items-center text-[#3F54CB] hidden">
          <span className="text-xs">مشاهده همه</span>
          <MdKeyboardArrowLeft />
        </section>
      </div>
      <div className="flex-container mt-4">
        <div className="scrollable-content">
          <div className="flex flex-col justify-center items-center w-24 h-24 mx-2">
            <section
              className="w-16 h-16 block rounded-full overflow-hidden bg-[#3F54CB] flex justify-center items-center cursor-pointer"
              onClick={() => navigate('/animation-group')}
            >
              <CgMenuGridO className="text-5xl text-white" />
            </section>
            <span
              className="text-sm text-gray-700 mt-2 cursor-pointer"
              onClick={() => navigate('/animation-group')}
            >
              همه
            </span>
          </div>
          {category.map((cat) => (
            <div
              key={cat.id}
              className="flex flex-col justify-center items-center w-24 h-24 mx-2"
              onClick={() => navigate('/animation-group?id=' + cat.id + '&catName=' + cat.title)}
            >
              <section
                className="w-16 h-16 block rounded-full overflow-hidden cursor-pointer"
                style={{
                  backgroundImage: `url("${Domain + cat.picture}")`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              />
              <span className="text-sm text-gray-700 mt-2 cursor-pointer">{cat.title}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full px-4">
        <Swiper
          effect="fade" // Enable fade effect
          fadeEffect={{
            crossFade: true // Enable crossfade for smooth transition
          }}
          loop={true} // Enables infinite loop mode
          autoplay={{
            delay: 3000 // Sets the delay between transitions (in ms)
          }}
          modules={[Autoplay, Pagination, EffectFade]}
          spaceBetween={10}
          slidesPerView={1}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return `
        <span class="${className}   slider-navigation ${
                index === this.activeIndex ? 'slider-active' : ''
              }"></span>
      `
            }
          }}
        >
          {banner.map((baner) => (
            <SwiperSlide key={baner.id} className="rounded-xl px-0.5 py-2">
              <div
                className="w-full h-64 relative rounded-xl"
                style={{
                  backgroundImage: `url("${Domain + baner.picture}")`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              >
                <section className="w-full h-full absolute top-0 left-0 rounded-xl bg-[rgba(0,0,0,0.3)]">
                  <p className="text-white text-lg bottom-20 absolute right-5">{baner.title}</p>
                  <div className="w-full flex justify-between items-center bottom-10 absolute px-4">
                    <span className="text-sm text-white">{baner.description}</span>
                    <div
                      className="px-4 py-2 bg-white rounded-lg text-gray-800 flex justify-between items-center"
                      onClick={() => {
                        navigate('/animation-detail?id=' + baner.animation.id)
                      }}
                    >
                      {' '}
                      <MdPlayArrow className="text-xl text-[#3F54CB]" />
                      <span className="mr-2">اطلاعات بیشتر</span>
                    </div>
                  </div>
                </section>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="w-full flex justify-between items-center px-4 mt-4 mb-2">
        <p>جدید ترین ها</p>
        <section
          className="flex justify-start items-center text-[#3F54CB]"
          onClick={() => navigate('/animation-group')}
        >
          <span className="text-xs">مشاهده همه</span>
          <MdKeyboardArrowLeft />
        </section>
      </div>
      <Swiper spaceBetween={10} slidesPerView={1.1}>
        {Array.isArray(animationList) &&
          animationList.map((animation, idx) => (
            <SwiperSlide
              key={animation.id}
              className={`rounded-xl px-0.5 py-2 ${
                (idx + 1) % 2 === 0 ? 'bg-gray-300' : 'bg-gray-100'
              }`}
            >
              <div className="w-full flex flex-col justify-between items-center px-4">
                <section className="w-full flex justify-between items-center">
                  <div
                    className="w-32 h-20  relative rounded-xl ml-4 "
                    onClick={() => {
                      navigate('/animation-detail?id=' + animation.id)
                    }}
                    style={{
                      backgroundImage: `url("${Domain + animation.cover}")`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}
                  >
                    <section className="flex justify-center items-center w-full h-full absolute left-0 top-0 bg-[rgba(0,0,0,0.3)]  rounded-xl">
                      <MdPlayArrow className="w-10 h-10 p-2 rounded-full bg-[#3F54CB] text-white" />
                    </section>
                  </div>
                  <div className="w-9/12">
                    <p className="text-sm text-gray-800">{animation.title}</p>
                    <span className="text-xs text-gray-700 my-2">{animation?.category?.title}</span>
                    <section className="w-full flex justify-start items-center mt-2">
                      <div className="flex justify-start items-center ml-5">
                        <img src={Heart} alt="show icon matyar" className="w-5 h-5 ml-2" />
                        <span className="text-gray-700 text-xs">{animation.rateCount}</span>
                      </div>
                      <div className="flex justify-start items-center ml-5">
                        <img src={Comment} alt="show icon matyar" className="w-5 h-5 ml-2" />
                        <span className="text-gray-700 text-xs">{animation.commentCount}</span>
                      </div>
                      <div className="flex justify-start items-center ml-5">
                        <img src={Show} alt="show icon matyar" className="w-5 h-5 ml-2" />
                        <span className="text-gray-700 text-xs">{animation.viewCount}</span>
                      </div>
                    </section>
                  </div>
                </section>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
      {sectionList.map((sec) => (
        <div key={sec.id} className="w-full px-4 mt-6">
          <p className="mb-4">{sec.title}</p>
          <Swiper spaceBetween={10} slidesPerView={1.3}>
            {sec?.animation.map((fod) => (
              <SwiperSlide key={fod.id} className="rounded-xl px-0.5 py-2">
                <section className="w-full rounded-xl relative shadow-food-box  ">
                  <section className="w-full h-48 absolute top-0 left-0 rounded-tr-xl bg-[rgba(0,0,0,0.3)] rounded-tl-xl flex justify-center items-center">
                    <MdPlayArrow
                      className="w-10 h-10 p-2 rounded-full bg-[#3F54CB] text-white"
                      onClick={() => {
                        navigate('/animation-detail?id=' + fod.id)
                      }}
                    />
                  </section>
                  <div
                    className="w-full h-48 rounded-tr-xl rounded-tl-xl"
                    style={{
                      backgroundImage: `url("${Domain + fod.cover}")`,
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center'
                    }}
                  />
                  <div className="w-full flex-col justify-col items-center pt-2 pb-10 px-2">
                    <div className="w-full flex-col justify-start ">
                      <p className="text-gray-800 text-md">{fod?.title}</p>
                      <section className="flex justify-between items-center text-sm text-gray-600  ">
                        سبک: {fod?.category?.title}
                      </section>
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <section className="w-full flex justify-between items-center mt-2">
                        <div className="flex justify-start items-center ">
                          <img src={Heart} alt="show icon matyar" className="w-5 h-5 ml-2" />
                          <span className="text-gray-700 text-xs">{fod.rateCount}</span>
                        </div>
                        <div className="flex justify-start items-center ">
                          <img src={Comment} alt="show icon matyar" className="w-5 h-5 ml-2" />
                          <span className="text-gray-700 text-xs">{fod.commentCount}</span>
                        </div>
                        <div className="flex justify-start items-center  ">
                          <img src={Show} alt="show icon matyar" className="w-5 h-5 ml-2" />
                          <span className="text-gray-700 text-xs">{fod.viewCount}</span>
                        </div>
                      </section>
                    </div>
                  </div>
                </section>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ))}
    </>
  )
}
export default MainAnimation
