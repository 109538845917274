import HeaderAlbum from '../../album/photo-album/component/header'
import Music from './svg/music-alt.svg'

import { useEffect, useState } from 'react'
import { CgCloseO } from 'react-icons/cg'
import './add-lullaby.css'
import VoiceRecorder from './voice'

import axios from 'axios'
import { LullabyAPI } from '../../../../../api'
import { toast } from 'react-toastify'

import Card from '../collection-lullaby/component/card'
function AddLullaby() {
  const [startCreateLullaby, setStartCreateLullaby] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [voice, setVoice] = useState('')
  const [voiceFile, setVoiceFile] = useState('')
  const [lullabies, setLullabies] = useState([])
  const [duration, setDuration] = useState()
  const getLullaby = () => {
    axios
      .get(LullabyAPI + '/your/sound', {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setLullabies(data)
      })
  }

  const createLullaby = () => {
    const formData = new FormData()
    formData.append('title', title)
    formData.append('description', description)
    formData.append('duration', duration)
    formData.append(
      'voice',
      new File([voiceFile], 'recording.wav', {
        type: 'audio/wav',
        lastModified: Date.now()
      })
    )
    axios
      .post(LullabyAPI, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        toast.success(data.message)
        setStartCreateLullaby(false)
        getLullaby()
      })
      .catch((e) => {
        e.response.data.messages.map((msg) => toast.error(msg))
      })
  }
  useEffect(() => {
    getLullaby()
  }, [])

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value
    const formattedValue = inputValue.replace(/\n/g, '<br/>')
    setDescription(formattedValue)
  }

  return (
    <>
      <HeaderAlbum title="لالایی اخیر" />
      {startCreateLullaby ? (
        <>
          <div
            className={`w-full fixed top-0 left-0 bg-[rgba(0,0,0,0.7)] h-screen z-40 ${
              startCreateLullaby ? 'animate__animated animate__fadeIn' : null
            }`}
            onClick={() => setStartCreateLullaby(false)}
          />

          <div
            className={`fixed bottom-0 w-full pb-1 pt-10 px-4 bg-white rounded-t-3xl z-50 vazir-medium h-[90vh] ${
              startCreateLullaby ? 'animate__animated animate__fadeInUp' : null
            }`}
          >
            <div className="w-full flex justify-between items-center">
              <span>افزودن لالایی</span>
              <CgCloseO className="text-2xl" onClick={() => setStartCreateLullaby(false)} />
            </div>
            <div className="w-full">
              <span className="text-gray-600 text-sm">
                والدین عزیز لالایی خود را اضافه کنید، پس از تایید توسط مدیریت منتشر می شود
              </span>
            </div>

            <div className="w-full mt-2">
              <p className="w-full mb-1">عنوان لالایی</p>
              <input
                className="px-4 py-2 border border-gray-300 focus:border-[#3F54CB] rounded-xl w-full mt-1"
                placeholder="لطفا عنوان لالایی خود را بنویسید"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="w-full mt-4">
              <p className="w-full mb-1">متن لالایی</p>
              <textarea
                className="px-4 py-2 border border-gray-300 focus:border-[#3F54CB] rounded-xl w-full mt-1"
                rows={5}
                placeholder="لطفا متن لالایی خود را بنویسید"
                onChange={handleDescriptionChange}
                style={{ whiteSpace: 'pre-wrap' }}
              />
            </div>

            <div className="w-full flex flex-wrap justify-center items-center   relative mt-10">
              <VoiceRecorder
                setVoice={setVoice}
                SendLullaby={createLullaby}
                voice={voice}
                setVoiceFile={setVoiceFile}
                setDuration={setDuration}
              />
            </div>

            <button
              className="w-full bg-blue-800 text-white rounded-xl py-4 shadow-md hidden "
              onClick={createLullaby}
            >
              ارسال لالایی
            </button>
          </div>
        </>
      ) : null}

      {lullabies.length == 0 ? (
        <div className="w-full flex flex-wrap justify-center items-center mt-52 px-4">
          <section className="w-24 h-24 bg-[#3F54CB] flex justify-center items-center rounded-[30%]">
            <img src={Music} alt="Music " />
          </section>
          <section className="w-full">
            <p className="text-center px-4 mt-4 mb-10">لالایی موجود نیست!!!</p>
            <p className="text-sm text-gray-600 text-center px-4 mb-20">
              برای افزودن لالایی شخصی لطفا از طریق دکمه زیر اقدام کنید
            </p>
          </section>
          <buttom
            className="w-full text-center py-3 px-4 bg-[#3F54CB] text-white rounded-xl"
            onClick={() => setStartCreateLullaby(true)}
          >
            افزودن لالایی جدید
          </buttom>
        </div>
      ) : (
        <>
          <div className="w-full flex flex-wrap justify-center items-center px-4 relative">
            {Array.isArray(lullabies) && lullabies.map((lul) => <Card lul={lul} key={lul.id} />)}

            <buttom
              className="w-11/12 text-center py-3 px-4 bg-[#3F54CB] text-white rounded-xl fixed bottom-10"
              onClick={() => setStartCreateLullaby(true)}
            >
              افزودن لالایی جدید
            </buttom>
          </div>
        </>
      )}
    </>
  )
}
export default AddLullaby
