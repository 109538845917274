import Header from '../../components/detail-header'
import axios from 'axios'
import { BookmarkAPI } from '../../../../api'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Loading from '../../../../component/loading'
import PodcastIcon from '../main/home/component/subject/svg/podcast.svg'
import ArticleIcon from '../main/home/component/subject/svg/article.svg'
import FoodIcon from '../main/home/component/subject/svg/food.svg'
import AnimationIcon from '../main/home/component/subject/svg/animation.svg'
import ActivitySVG from '../../components/footer/svg/activity.svg'
import { useNavigate } from 'react-router-dom'

function Save() {
  const [saveItem, setSaveItem] = useState([])
  const [loading, setLoading] = useState(false)
  const childInfo = useSelector((state) => state.childInfo)
  const navigate = useNavigate()
  const getSaveForChild = () => {
    setLoading(false)
    axios
      .get(BookmarkAPI + '?id=' + childInfo?.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setSaveItem([])
        setSaveItem(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    getSaveForChild()
  }, [])
  return (
    <>
      <div>
        <Header title="ذخیره شده ها" />
        {loading ? (
          <Loading color="#1e40af" width="100px" />
        ) : (
          <div className="w-full grid grid-cols-2 gap-4 mt-4">
            {Array.isArray(saveItem) &&
              saveItem?.map((si, idx) => (
                <div
                  key={idx}
                  className="w-full flex flex-col justify-between items-center"
                  onClick={() => {
                    navigate('/activity-saved?type=' + si.type)
                  }}
                >
                  <section className="rounded-full bg-pink-200 w-32 h-32 flex justify-center items-center">
                    <img
                      src={
                        (si.type === 'animation' && AnimationIcon) ||
                        (si.type === 'food' && FoodIcon) ||
                        (si.type === 'episode' && PodcastIcon) ||
                        (si.type === 'post' && ActivitySVG) ||
                        (si.type === 'article' && ArticleIcon)
                      }
                      alt="save "
                      className="h-20 w-20"
                    />
                  </section>
                  <span className="text-gray-600 my-2">
                    {(si.type === 'animation' && 'انیمیشن') ||
                      (si.type === 'food' && 'تغذیه') ||
                      (si.type === 'episode' && 'اپیزود') ||
                      (si.type === 'post' && 'فعالیت') ||
                      (si.type === 'article' && 'مقاله')}
                  </span>
                  <span className="text-gray-700">
                    مورد <span className="text-gray-500">{si.count}</span>{' '}
                  </span>
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  )
}

export default Save
