import StarsRating from 'react-star-rate'
import { useState } from 'react'
import GetTimeDiff from '../../../../../time-diff'
import { AiFillStar } from 'react-icons/ai'
import { Domain } from '../../../../../api'

function CommentItem({ comment }) {
  const [value, setValue] = useState(0)
  return (
    <>
      <div className="w-full flex justify-between items-center">
        <section className="w-6/12 flex justify-start items-center">
          <span
            className="w-12 h-12 rounded-full   ml-4"
            style={{
              backgroundImage: `url("${Domain + comment?.child?.profile}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          />
          <section className="flex flex-col justify-between items-start">
            <span className="text-gray-800 text-sm">{comment.child && comment.child.name}</span>
            <span className="text-gray-600 text-xs mt-1 hidden">شهر کاربر</span>
          </section>
        </section>
        <span className="text-gray-600 text-xs">{GetTimeDiff(comment.created_at)}</span>
      </div>
      <div className="w-full mt-5 relative rounded pr-4 ">
        <span className="absolute right-0 top-0 h-full w-1 rounded-full bg-blue-100" />
        <p className="text-sm">{comment.comment}</p>
        <div className="w-full dir-ltr">
          <button className="px-2 py-1 rounded-lg bg-[#3F54CB] flex justify-between">
            <span className="text-white mr-1 text-xs">{comment?.rate}</span>
            <AiFillStar className="text-white" />
          </button>
        </div>
      </div>
      <span className="w-full h-[1px] bg-gray-300 block my-4" />
      <section className="shadow-coach-member rounded-xl px-4  py-2 my-2 hidden">
        <p className="text-justify text-gray-600 text-sm mt-1">{comment.comment}</p>
        <section className="w-full flex justify-between items-center">
          <div className="flex justify-start items-center">
            <span className="text-xs text-gray-400">{GetTimeDiff(comment.created_at)}</span>
            <span className="w-2 h-2 rounded-full block bg-gray-300 mx-1" />
            <span className="text-xs text-gray-400">{comment.child && comment.child.name}</span>
          </div>
          <div>
            <StarsRating
              direction="rtl"
              count={5}
              classNamePrefix="star-rating-comment"
              allowHalf={false}
              value={value}
              onChange={(value) => {
                setValue(value)
              }}
            />
          </div>
        </section>
      </section>
    </>
  )
}
export default CommentItem
