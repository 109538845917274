import Header from '../../components/detail-header'
import { MdArrowBackIosNew, MdArrowForwardIos, MdOutlineCancel } from 'react-icons/md'
import { IoIosArrowBack } from 'react-icons/io'
import Input from '../../../../component/input'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ChildAPI, Domain } from '../../../../api'
import axios from 'axios'
import { TiTick } from 'react-icons/ti'
import DatePicker from 'react-multi-date-picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import transition from 'react-element-popper/animations/transition'
import { toast } from 'react-toastify'
import { Radio } from '@mantine/core'
const moment = require('moment-jalaali')
function CustomButton({ direction, handleClick, disabled }) {
  return (
    <i
      onClick={handleClick}
      style={{
        padding: '0 10px',
        fontWeight: 'bold',
        color: disabled ? 'gray' : 'blue'
      }}
      className={disabled ? 'cursor-default' : 'cursor-pointer'}
    >
      {direction === 'right' ? <MdArrowBackIosNew /> : <MdArrowForwardIos />}
    </i>
  )
}
function ChildInfoSpecific() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')

  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [sex, setSex] = useState('')
  const [profile, setProfile] = useState('')
  const [birthday, setBirthday] = useState('')
  const [profileURL, setProfileURL] = useState('')
  const [sexEdit, setSexEdit] = useState(false)
  const convertGregorianToSolar = (date) => {
    // create a moment object with the input Gregorian date
    const gregorianDate = moment(date, 'YYYY-MM-DD HH:mm:ss')

    // convert to Shamsi (Iranian) calendar and format without the time part
    const shamsiDate = gregorianDate.format('jYYYY/jMM/jDD')

    return shamsiDate
  }
  const getChildDetail = () => {
    axios
      .get(ChildAPI + id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        // convertGregorianToSolar(data.birthday)
        setBirthday(data?.childInfo?.birthday)
        setName(data?.childInfo?.name)
        setLastName(data?.childInfo?.name)
        setSex(data?.childInfo?.sex)
        setProfile(data?.childInfo?.profile)
        setBirthday(data?.childInfo?.birthday)
      })
  }
  useEffect(() => {
    getChildDetail()
  }, [])
  const logDate = (e) => {
    const inputDateString = new Date(e)
    // Parse input date string into a Date object
    const inputDate = new Date(inputDateString)

    // Extract date and time components from input Date object
    const year = inputDate.getFullYear()
    const month = inputDate.getMonth() + 1 // add 1 to adjust for 0-based indexing
    const day = inputDate.getDate()
    const hours = inputDate.getHours()
    const minutes = inputDate.getMinutes()
    const seconds = inputDate.getSeconds()

    // Format extracted components into output date string
    const outputDateString = `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    return outputDateString
  }
  const handleImageChange = (e) => {
    setProfileURL(URL.createObjectURL(e.target.files[0]))
    return e.target.files[0]
  }
  const updateSex = () => {
    const formData = new FormData()
    formData.append('sex', sex)
    axios
      .patch(ChildAPI + id, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        setSexEdit(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  const updateProfile = (e) => {
    const formData = new FormData()
    formData.append('profile', handleImageChange(e))
    axios
      .patch(ChildAPI + id, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        setSexEdit(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  const updateBirthday = (e) => {
    const formData = new FormData()
    formData.append('birthday', logDate(e))
    axios
      .patch(ChildAPI + id, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        setSexEdit(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  return (
    <>
      <Header title="اطلاعات کودک" />
      <div className="flex flex-wrap justify-center">
        <input
          type="file"
          className="hidden"
          id="child-add"
          onChange={(e) => {
            updateProfile(e)
          }}
        />
        <label
          htmlFor="child-add"
          className="w-52 h-52 rounded-full  bg-blue-200 flex justify-center items-center my-10"
          style={{
            backgroundImage:
              profileURL.length > 1 ? `url("${profileURL}")` : `url("${Domain + profile}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }}
        ></label>

        <ul className="w-full vazir-medium px-4">
          <li className="w-full flex justify-between items-center border-t pt-4 mt-3">
            <Input
              keyFormData="name"
              onChange={setName}
              defaultValue={lastName}
              setDefaultValue={setLastName}
              Change={name}
              Label="نام نام خانوادگی"
              id={id}
              API={ChildAPI}
            />
          </li>

          <li className="w-full flex justify-between items-center border-t pt-4 mt-3">
            <section>
              <p className="text-gray-400 text-xs">جنسیت کودک</p>
              <p className="text-gray-800 mt-2">کودک {sex === 'girl' ? 'دختر' : 'پسر'} است </p>
              {sexEdit ? (
                <div className="w-full flex justify-evenly">
                  <div className="flex justify-start items-center ml-4">
                    <Radio
                      color="indigo"
                      name="sex-child"
                      id="sex-child-boy"
                      onChange={(e) => setSex(e.target.checked ? 'boy' : null)}
                    />
                    <label htmlFor="sex-child-boy">
                      <p className="text-gray-800 mt-2  relative -top-1 mr-2">پسر</p>
                    </label>
                  </div>

                  <div className="flex justify-start items-center">
                    <Radio
                      color="indigo"
                      type="radio"
                      name="sex-child"
                      id="sex-child-girl"
                      onChange={(e) => setSex(e.target.checked ? 'girl' : null)}
                    />
                    <label htmlFor="sex-child-girl">
                      <p className="text-gray-800 mt-2 relative -top-1 mr-2">دختر</p>
                    </label>
                  </div>
                </div>
              ) : null}
            </section>

            <section>
              {sexEdit ? (
                <>
                  <TiTick className="text-2xl text-gray-400  " onClick={updateSex} />
                  <MdOutlineCancel
                    className="text-2xl text-gray-400 mt-2"
                    onClick={() => {
                      setSexEdit(false)
                      getChildDetail()
                    }}
                  />
                </>
              ) : (
                <IoIosArrowBack
                  className="text-xl text-gray-400"
                  onClick={() => setSexEdit(true)}
                />
              )}
            </section>
          </li>

          <li className="w-full flex justify-between items-center border-t pt-4 mt-3 ">
            <section>
              <p className="text-gray-400 text-xs"> تاریخ تولد</p>
              <div className="w-full">
                <DatePicker
                  className="rmdp-mobile"
                  calendarPosition="bottom-right"
                  value={convertGregorianToSolar(birthday)}
                  onChange={(e) => {
                    updateBirthday(e)
                  }}
                  multiple={false}
                  calendar={persian}
                  locale={persian_fa}
                  renderButton={<CustomButton />}
                  mobileLabels={{
                    OK: 'تایید',
                    CANCEL: 'بستن'
                  }}
                  animations={[
                    transition({
                      from: 35,
                      transition: 'all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)'
                    })
                  ]}
                  render={(value, openCalendar) => {
                    return (
                      <button
                        style={{
                          width: 'calc(100vw - 30px)'
                        }}
                        className="flex justify-between items-center  mt-2"
                        onClick={openCalendar}
                      >
                        <span>{value ? value : 'لطفا تاریخ تولد را انتخاب کنید'}</span>
                        <IoIosArrowBack className="text-xl text-gray-400" />
                      </button>
                    )
                  }}
                />
              </div>
            </section>
          </li>
        </ul>
      </div>
    </>
  )
}

export default ChildInfoSpecific
