import { MdMessage } from 'react-icons/md'
import { useState } from 'react'
import StarsRating from 'react-star-rate'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
function PopUp({ showMessage, setShowMessage, body, titleMessage, API, id }) {
  const childInfo = useSelector((state) => state.childInfo)
  const [value, setValue] = useState(0)
  const [comment, setComment] = useState('')
  const createCommentAndRate = () => {
    const formData = new FormData()
    formData.append('rate', value)
    formData.append('child_id', childInfo.id)
    formData.append('comment', comment)
    formData.append('id', id)
    axios
      .post(API, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        setShowMessage(false)
        toast.success('نظر شما با موفقیت ثبت شد پس از تایید نمایش داده میشود')
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  return (
    <>
      {showMessage && (
        <div
          className="fixed top-0 left-0 w-screen h-screen bg-[rgba(0,0,0,0.3)] block z-[40]"
          onClick={() => {
            setShowMessage(false)
          }}
        />
      )}
      {showMessage ? (
        <div className="w-full fixed  px-4 py-10 vazir-medium z-[41]">
          <div className="bg-white w-full rounded-xl px-4 py-10 flex flex-col justify-center items-center border-2 border-gray-200 shadow-xl  overflow-hidden overflow-y-scroll h-[80vh]">
            <section className="h-32 w-32 mb-4 rounded-full   flex justify-center items-center bg-blue-200">
              <MdMessage className="text-blue-600 text-5xl" />
            </section>

            <p className="text-gray-800 text-center text-3xl">ثبت دیدگاه</p>
            <p className="text-gray-600 text-center text-xl my-2"> {titleMessage}</p>
            <p className="text-gray-400 text-center mb-2">{body}</p>
            <StarsRating
              direction="rtl"
              allowHalf={false}
              classNamePrefix="text-4xl"
              value={value}
              onChange={(value) => {
                setValue(value)
              }}
            />
            <textarea
              onChange={(e) => setComment(e.target.value)}
              rows="5"
              className="w-full rounded-xl border border-gray-300 p-4 mt-5 min-h-[100px]"
              placeholder="نظر و دیدگاه خود را بیان کنید..."
            />

            <button
              className="w-full bg-[#3F54CB] text-white rounded-lg py-4 mt-3"
              onClick={() => {
                createCommentAndRate()
                // setShowMessage(false)
              }}
            >
              تایید
            </button>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default PopUp
