function Input({
  labelText,
  labelClassName,
  inputClassName,
  inputId,
  inputPlaceholder,
  inputType,
  errorText,
  iconLeft,
  iconRight,
  passwordTest,
  onChange
}) {
  return (
    <div className="flex flex-col">
      <section className="flex justify-between items-center">
        <label className={labelClassName} htmlFor={inputId}>
          {labelText}
        </label>
        {passwordTest}
      </section>
      <section className="w-full relative">
        {iconRight}
        <input
          type={inputType}
          placeholder={inputPlaceholder}
          id={inputId}
          className={inputClassName}
          onChange={onChange}
        />
        {iconLeft}
      </section>
      <span className="text-red-600 text-sm mr-1">{errorText}</span>
    </div>
  )
}
export default Input
