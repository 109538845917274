import Chart from 'react-apexcharts'

const ScatterChart = ({ data }) => {
  return (
    <div className="w-full">
      {data && data.series && data.options ? (
        <Chart options={data.options} series={data.series} type="scatter" />
      ) : null}
    </div>
  )
}

export default ScatterChart
