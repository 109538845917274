function Box({ Tag, text }) {
  return (
    <>
      <div className="bg-blue-100 flex justify-start items-center px-4 py-2 rounded-lg w-full">
        <Tag className="text-xl text-blue-800 ml-3" />
        <p className=" text-gray-700">{text}</p>
      </div>
    </>
  )
}
export default Box
