import Card from './card'
import Header from '../../album/photo-album/component/header'
import { MdGroups } from 'react-icons/md'
import { FaUser } from 'react-icons/fa'
import { MdInsertComment } from 'react-icons/md'
import { TbInfoCircle } from 'react-icons/tb'
import { FaUmbrella, FaCheckCircle } from 'react-icons/fa'
import { IoTimeOutline } from 'react-icons/io5'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { MdOutlineVerified } from 'react-icons/md'
import { MdVerifiedUser } from 'react-icons/md'
import { FaStar } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import Visit from './visit.svg'
import DateWeek from './date'

import PopUp from '../../../components/pop-up/comment'
import { DoctorAPI } from '../../../../../api'
import Comment from '../../coach-team-detail/component/comment'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import DateObject from 'react-date-object'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'

function DoctorDetail() {
  const [show, setShow] = useState(false)
  const [data, setData] = useState({})
  const [showForm, setShowForm] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const getDoctorByCategory = () => {
    axios
      .get(DoctorAPI + `/${id}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setData(data)
      })
  }

  useEffect(() => {
    getDoctorByCategory()
  }, [])
  return (
    <>
      <PopUp
        setShowMessage={setShowForm}
        showMessage={showForm}
        titleMessage="نظر خود را با ما به اشتراک بگذارید"
        userTeam={data.name}
        API={DoctorAPI + '/comment'}
        id={data.id}
        body={`لطفا برای بهبود و بالا بردن خدمات ما نظر خود را درباره تفذیه   ${data.name}  ثبت کنید`}
      />
      <Header title="اطلاعات پزشک" />
      <div className="w-full px-4">
        <section className="flex justify-between items-center">
          <p className="text-md text-gray-700 font-bold">دکتر {data.name}</p>
        </section>
        <section>
          <Card data={data} />
          <div className="w-full grid grid-cols-3 gap-4 border border-[#3F54CB] rounded-xl py-2 my-5">
            <section className="w-full flex flex-col justify-between items-center">
              <section className="w-12 h-12 bg-blue-300 rounded-full flex justify-center items-center">
                <MdGroups className="text-[#3F54CB] text-xl" />
              </section>
              <span className=" text-[#3F54CB]">{data.visitCount}</span>
              <span className="text-md text-gray-700">ویزیت آنلاین</span>
            </section>

            <section className="w-full flex flex-col justify-between items-center">
              <section className="w-12 h-12 bg-blue-300 rounded-full flex justify-center items-center">
                <FaUser className="text-[#3F54CB] text-xl" />
              </section>
              <span className="text-[#3F54CB]">{data.yearExperience}</span>
              <span className="text-md text-gray-700">سال تجربه کاری </span>
            </section>

            <section className="w-full flex flex-col justify-between items-center">
              <section className="w-12 h-12 bg-blue-300 rounded-full flex justify-center items-center">
                <MdInsertComment className="text-[#3F54CB] text-xl" />
              </section>
              <span className="text-[#3F54CB]">{data?.commentCount}</span>
              <span className="text-md text-gray-700">نظرات کاربران</span>
            </section>
          </div>
          <div className="w-full flex flex-wrap justify-start items-center text-gray-700 ">
            <TbInfoCircle className="text-2xl text-gray-700 ml-3" />
            <p className="text-gray-700">درباره ی پزشک</p>
            <div className="w-full flex flex-wrap justify-between items-start  mt-2 pb-2">
              <div className="text-sm text-gray-400  w-full  ">
                <span className="text-gray-400 text-sm">
                  {data?.about?.substring(0, show ? 2000 : 90)}
                  {show ? null : '...'}
                </span>
              </div>
              <div className="w-full flex justify-end">
                {show ? (
                  <section
                    className="text-red-600 flex justify-end items-center hover:bg-red-200 px-2 py-1 rounded"
                    onClick={() => {
                      setShow(false)
                    }}
                  >
                    <span className="text-xs">خلاصه</span>
                    <IoIosArrowUp className="text-red-600" />
                  </section>
                ) : (
                  <section
                    className="text-blue-600 flex justify-end items-center hover:bg-blue-200 px-2 py-1 rounded"
                    onClick={() => {
                      setShow(true)
                    }}
                  >
                    <span className="text-xs">ادامه</span>
                    <IoIosArrowDown className="text-blue-600" />
                  </section>
                )}
              </div>
            </div>
          </div>
          <div className="w-full border-t border-t-gray-300 pt-3">
            <section className="flex justify-start items-center">
              <FaUmbrella className="text-xl text-gray-700 ml-3" />
              <span className="text-gray-700 text-sm">پوشش بیمه تکمیلی</span>
            </section>
            <div className="w-full flex justify-start items-center flex-wrap  mt-4">
              {data &&
                data.openInsurance &&
                Array.isArray(JSON.parse(data.openInsurance)) &&
                JSON.parse(data.openInsurance).map((ins) => (
                  <div key={ins.key} className="w-4/12 flex justify-start items-center  mt-4">
                    <span className="w-2 h-2 block rounded-full bg-blue-500 ml-3" />
                    <span className="text-sm text-gray-700">{ins.name}</span>
                  </div>
                ))}
            </div>
          </div>
          <div className="w-full  border-t border-t-gray-300 pt-3 mt-5">
            <section className="flex justify-start items-center">
              <IoTimeOutline className="text-xl text-gray-700 ml-3" />
              <span className="text-gray-700 text-sm">ساعات پاسخگویی و مشاور تلفنی</span>
            </section>
            <div className="w-full flex justify-start items-center mt-3 px-4 flex-container">
              <div className="scrollable-content">
                {data &&
                  data?.time &&
                  Array.isArray(JSON.parse(data.time)) &&
                  JSON.parse(data.time).map((item) => {
                    const dataa = new DateObject(item)
                    return (
                      <section
                        key={item}
                        className="w-44  mx-2  bg-blue-100 border border-gray-400 rounded-xl       px-4 py-2 mt-4"
                      >
                        {dataa.convert(persian, persian_fa).format('dddd DD MMMM HH:mm')}
                      </section>
                    )
                  })}
              </div>
            </div>
          </div>
          <div className="w-full  border-t border-t-gray-300 pt-3 mt-5">
            <section className="flex justify-start items-center">
              <img src={Visit} alt="visit" className="text-xl text-gray-700 ml-3" />
              <span className="text-gray-700 text-sm">ویزیت آنلاین</span>
            </section>
            <p className="text-xs text-gray-600 mt-2">ابتدا روز مورد نظر خود را انتخاب کنید</p>
            <DateWeek />
          </div>
          <div className="w-full  border-t border-t-gray-300 pt-3 mt-5">
            <section className="flex justify-start items-center">
              <MdOutlineVerified className="text-xl text-gray-700 ml-3" />
              <span className="text-gray-700  ">تضمین ماتیار</span>
            </section>
            <div className="w-full mt-4">
              <div className="w-full flex justify-start items-center">
                <MdVerifiedUser className="text-xl text-gray-700" />
                <span className="text-gray-700 text-sm">100% حفظ حریم شخصی</span>
              </div>
              <p className="text-xs text-gray-300 mt-2">
                جزییات مشاوره و شماره تماس شما محرمانه خواهد بود
              </p>
            </div>

            <div className="w-full mt-4">
              <div className="w-full flex justify-start items-center">
                <FaStar className="text-xl text-gray-700" />
                <span className="text-gray-700 text-sm">رضایت تضمین شده</span>
              </div>
              <p className="text-xs text-gray-300 mt-2">
                اگر از کیفیت مشاوره راضی نبودید هزینه به شما عودت داده می شود
              </p>
            </div>

            <div className="w-full mt-4">
              <div className="w-full flex justify-start items-center">
                <FaCheckCircle className="text-xl text-gray-700" />
                <span className="text-gray-700 text-sm">مشاوران تایید شده</span>
              </div>
              <p className="text-xs text-gray-300 mt-2">
                {' '}
                همه پزشکان و مشاوران طی یک فرایند دقیق تایید می شوند
              </p>
            </div>
          </div>
          <div className="w-full border-t border-t-gray-300 pt-3  mt-5"></div>
        </section>

        <Comment
          count={data?.commentCount}
          rate={data?.rateCount}
          id={data?.id}
          API={DoctorAPI + '/comment/user?id='}
          type="doctor"
          setShowForm={() => setShowForm(true)}
        />
      </div>
    </>
  )
}
export default DoctorDetail
