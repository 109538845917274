import HeaderAlbum from '../../album/photo-album/component/header'
import { FiSend } from 'react-icons/fi'
import { BsBookmark } from 'react-icons/bs'
import Show from './svg/Show.svg'
import CommentSVG from './svg/comment-green.svg'
import Heart from './svg/Heart.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { ArticleAPI, ArticleLikeAPI, ArticleViewAPI, Domain } from '../../../../../api'
import ConvertTime from '../../../../../convert-time'
import { useSelector } from 'react-redux'
import PopUp from '../../../components/pop-up/comment'
import Comment from '../../coach-team-detail/component/comment'
import Subscription from '../../buy-subscription/svg/subscription-model.svg'
function DetailArticles() {
  const childInfo = useSelector((state) => state.childInfo)
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [data, setData] = useState({})
  const [viewCount, setViewCount] = useState(0)
  const [needSub, setNeedSub] = useState(false)
  const getDetail = () => {
    axios
      .get(ArticleAPI + '/' + id + '?childId=' + childInfo?.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setData(data?.data)
      })
      .catch((e) => {
        if (e.response.data === 'subscribe') {
          setNeedSub(true)
        }
        if (e.response.data === 'child') {
          console.log('child')
        }
      })
  }
  const addView = () => {
    axios
      .get(ArticleViewAPI + '/' + id + '?childId=' + childInfo.id, {
        headers: { Authorization: `Brear ${localStorage.getItem('token')}` }
      })
      .then((res) => res.data)
      .then((data) => {
        setViewCount(data)
      })
  }

  const handelLike = () => {
    axios
      .get(ArticleLikeAPI + '/' + id + '?childId=' + childInfo.id, {
        headers: { Authorization: `Brear ${localStorage.getItem('token')}` }
      })
      .then((res) => res.data)
      .then(() => {})
  }

  useEffect(() => {
    getDetail()
    addView()
  }, [])
  const [showForm, setShowForm] = useState(false)

  return (
    <>
      <PopUp
        setShowMessage={setShowForm}
        showMessage={showForm}
        titleMessage="نظر خود را با ما به اشتراک بگذارید"
        userTeam={data.title}
        API={ArticleAPI + '/comment'}
        id={data.id}
        body={`لطفا برای بهبود و بالا بردن خدمات ما نظر خود را درباره مقاله   ثبت کنید`}
      />
      <HeaderAlbum title={'جزییات مقاله'} />

      {needSub ? (
        <>
          <div className="flex justify-center items-center flex-wrap vazir-medium mt-14 px-4">
            <div className="w-48 h-48 bg-blue-200 rounded-full flex justify-center items-center">
              <img src={Subscription} alt="buy subscription matyar" />
            </div>
            <div className="w-full flex flex-col justify-center items-center flex-wrap mt-5">
              <p className="text-lg text-center"> شما اشتراک فعالی ندارید</p>
              <p className="text-sm text-gray-500 text-center w-8/12 mt-3">
                شما اشتراک فعالی ندارید برای دیدن این مقاله باید اشتراکی خریداری کنید
              </p>
            </div>

            <button
              className="w-full bg-[#3F54CB] text-white rounded-lg py-4 vazir-medium mt-14"
              onClick={() => navigate('/list-subscription')}
            >
              خرید اشتراک
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="w-full px-4 bg-gray-100 pt-4 pb-8">
            <div className="w-full flex justify-between items-center">
              <section className="flex justify-start items-center">
                <span
                  className="h-12 w-12 rounded-xl ml-2"
                  style={{
                    backgroundImage: `url("${Domain + data.picture}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                />
                <section className="flex flex-col justify-between items-start">
                  <p className="text-gray-800 text-sm">{data.writer}</p>
                  <span className="text-gray-600 text-xs mt-2">
                    {ConvertTime(data?.created_at).split(', ')[0]}
                  </span>
                </section>
              </section>
              <section className="flex justify-between items-center">
                <BsBookmark className="text-red-600 text-xl ml-4 hidden" />

                <FiSend className="text-red-600 text-xl hidden" />
              </section>
            </div>
            <p className="text-gray-800 my-3">{data.title}</p>
            <section className="w-full flex justify-start items-center mb-5">
              <div className="flex justify-start items-center ml-5">
                <img
                  src={Heart}
                  alt="show icon matyar"
                  className="w-5 h-5 ml-2"
                  onClick={handelLike}
                />
                <span className="text-gray-700 text-xs">{data.rateCount}</span>
              </div>

              <div className="flex justify-start items-center ml-5">
                <img src={CommentSVG} alt="show icon matyar" className="w-5 h-5 ml-2" />
                <span className="text-gray-700 text-xs">{data.commentCount}</span>
              </div>
              <div className="flex justify-start items-center ml-5">
                <img src={Show} alt="show icon matyar" className="w-5 h-5 ml-2" />
                <span className="text-gray-700 text-xs">{viewCount}</span>
              </div>
            </section>
          </div>
          <div
            className="w-full h-64 rounded-t-3xl relative bottom-10"
            style={{
              backgroundImage: `url("${Domain + data.picture}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          />
          <div className="w-full px-4 bottom-6 relative">
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
          </div>
          <Comment
            count={data?.commentCount}
            rate={data?.rateCount}
            id={data?.id}
            API={ArticleAPI + '/comment/user?id='}
            type="article"
            setShowForm={() => setShowForm(true)}
          />
        </>
      )}
    </>
  )
}
export default DetailArticles
