import { Domain } from '../../../../../../../api'

function Card({ data }) {
  return (
    <>
      <div
        className="w-full h-24 relative rounded-xl flex justify-center items-center overflow-hidden"
        style={{
          backgroundImage: `url("${Domain + data.picture}")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        <div className="w-full h-24 bg-[rgba(0,0,0,0.3)] absolute top-0 left-0 z-10" />
        <p className="text-white shadow z-20 relative">{data.title}</p>
      </div>
    </>
  )
}
export default Card
