import PodcastIcon from './svg/podcast.svg'
import DoctorIcon from './svg/doctor.svg'
import ArticleIcon from './svg/article.svg'
import FoodIcon from './svg/food.svg'
import AnimationIcon from './svg/animation.svg'
import PlaceIcon from './svg/tourist-places.svg'
import LullabyIcon from './svg/lullaby.svg'
import StoreIcon from './svg/store.svg'
import { useNavigate } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa6'
import { useState } from 'react'
import { MdOutlineVaccines } from 'react-icons/md'
import { BiPhotoAlbum } from 'react-icons/bi'

function Subject() {
  const [active, setActive] = useState(false)
  const navigate = useNavigate()
  return (
    <>
      {active && (
        <>
          <div
            className="w-full h-screen top-0 fixed bg-[rgba(0,0,0,0.3)] z-[99] animate__animated animate__fadeIn"
            onClick={() => {
              setActive(false)
            }}
          />

          <div className="w-full grid mt-5 grid-cols-4 gap-5 bg-white rounded-t-3xl fixed bottom-0 h-96 z-[100] pt-10 px-4 pb-10 animate__animated animate__fadeInUp">
            <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
              <section
                onClick={() => navigate('/main-podcast')}
                className="border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
              >
                <img src={PodcastIcon} alt="podcast icon" />
                <span className="text-sm text-gray-700">آوا </span>
              </section>
            </div>

            <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
              <section
                onClick={() => navigate('/doctor')}
                className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
              >
                <img src={DoctorIcon} alt="doctor icon" />
                <span className="text-sm text-gray-700">پزشک</span>
              </section>
            </div>

            <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
              <section
                onClick={() => navigate('/main-articles')}
                className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
              >
                <img src={ArticleIcon} alt="article icon" />
                <span className="text-sm text-gray-700">مقالات</span>
              </section>
            </div>

            <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
              <section
                onClick={() => navigate('/food-main')}
                className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
              >
                <img src={FoodIcon} alt="food icon" />
                <span className="text-sm text-gray-700">تغذیه</span>
              </section>
            </div>

            <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
              <section
                onClick={() => navigate('/animation-main')}
                className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
              >
                <img src={AnimationIcon} alt="animation icon" />
                <span className="text-sm text-gray-700">انیمیشن </span>
              </section>
            </div>

            <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
              <section
                onClick={() => navigate('/main-place')}
                className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
              >
                <img src={PlaceIcon} alt="place icon" />
                <span className="text-sm text-gray-700">گردشگری</span>
              </section>
            </div>

            <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
              <section
                onClick={() => navigate('/collection-lullaby')}
                className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
              >
                <img src={LullabyIcon} alt="lullaby icon" />
                <span className="text-sm text-gray-700">لالایی </span>
              </section>
            </div>

            <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
              <section className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full">
                <img src={StoreIcon} alt="lullaby icon" />
                <span className="text-sm text-gray-700">فروشگاه </span>
              </section>
            </div>

            <div
              onClick={() => navigate('/my-vaccine')}
              className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl"
            >
              <section className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full">
                <MdOutlineVaccines className="text-4xl text-blue-600 mt-5 mb-2" />
                <span className="text-sm text-gray-700">واکسن </span>
              </section>
            </div>

            <div
              onClick={() => navigate('/album-collection')}
              className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl"
            >
              <section className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full">
                <BiPhotoAlbum className="text-4xl text-blue-600 mt-5 mb-2" />
                <span className="text-sm text-gray-700">آلبوم </span>
              </section>
            </div>
          </div>
        </>
      )}

      <div className="w-full grid mt-5 grid-cols-4 gap-5 px-4">
        <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
          <section
            onClick={() => navigate('/main-podcast')}
            className="border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
          >
            <img src={PodcastIcon} alt="podcast icon" />
            <span className="text-sm text-gray-700">آوا </span>
          </section>
        </div>

        <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
          <section
            onClick={() => navigate('/doctor')}
            className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
          >
            <img src={DoctorIcon} alt="doctor icon" />
            <span className="text-sm text-gray-700">پزشک</span>
          </section>
        </div>

        <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
          <section
            onClick={() => navigate('/main-articles')}
            className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
          >
            <img src={ArticleIcon} alt="article icon" />
            <span className="text-sm text-gray-700">مقالات</span>
          </section>
        </div>

        <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
          <section
            onClick={() => navigate('/food-main')}
            className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
          >
            <img src={FoodIcon} alt="food icon" />
            <span className="text-sm text-gray-700">تغذیه</span>
          </section>
        </div>

        <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
          <section
            onClick={() => navigate('/animation-main')}
            className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
          >
            <img src={AnimationIcon} alt="animation icon" />
            <span className="text-sm text-gray-700">انیمیشن </span>
          </section>
        </div>

        <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
          <section
            onClick={() => navigate('/main-place')}
            className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
          >
            <img src={PlaceIcon} alt="place icon" />
            <span className="text-sm text-gray-700">گردشگری</span>
          </section>
        </div>

        <div className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl">
          <section
            onClick={() => navigate('/collection-lullaby')}
            className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full"
          >
            <img src={LullabyIcon} alt="lullaby icon" />
            <span className="text-sm text-gray-700">لالایی </span>
          </section>
        </div>

        <div
          className=" border-2 border-blue-100 flex justify-center items-center flex-col   rounded-xl"
          onClick={() => {
            setActive(true)
          }}
        >
          <section className="   border-2 border-white p-1 rounded-xl flex justify-center items-center flex-col bg-blue-100 w-full h-full">
            <FaPlus className="text-3xl mb-3 mt-5 text-blue-600" />
            <span className="text-sm text-gray-700">بیشتر</span>
          </section>
        </div>
      </div>
    </>
  )
}
export default Subject
