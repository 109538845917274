import HeaderAlbum from '../../album/photo-album/component/header'
import {
  ControlBar,
  CurrentTimeDisplay,
  ForwardControl,
  PlaybackRateMenuButton,
  Player,
  PlayToggle,
  ReplayControl,
  TimeDivider,
  VolumeMenuButton
} from 'video-react'
import { CiBookmark } from 'react-icons/ci'

import { AiFillHeart } from 'react-icons/ai'
import { BsFillBookmarkFill, BsFillPlayFill } from 'react-icons/bs'
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useEffect, useRef, useState } from 'react'
import Heart from '../../articles/detail/svg/Heart.svg'
import CommentSVG from '../../articles/detail/svg/comment-green.svg'
import Show from '../../articles/detail/svg/Show.svg'

import { Swiper, SwiperSlide } from 'swiper/react'

import Evaluation from './assets/evaluation.svg'
import Scoring from './assets/scoring.svg'
import MovieReview from './assets/movie-review.svg'
import PlayMovie from './assets/play-movie.svg'
import axios from 'axios'
import { AnimationAPI, AnimationViewAPI, Domain } from '../../../../../api'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from '../../../../../component/loading'
import PopUp from '../../../components/pop-up/comment'
import Comment from '../../coach-team-detail/component/comment'
import { useSelector } from 'react-redux'
import { AddBookmark, RemoveBookmark } from '../../../../../bookmark'
import Subscription from '../../buy-subscription/svg/subscription-model.svg'

function AnimationDetail() {
  const [save, setSave] = useState(false)
  const childInfo = useSelector((state) => state.childInfo)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [show, setShow] = useState(false)
  const [data, setData] = useState({})
  const [animations, setAnimations] = useState([])
  const [viewCount, setViewCount] = useState(0)
  const [needSub, setNeedSub] = useState(false)
  const addView = () => {
    axios
      .get(AnimationViewAPI + '/' + id + '?childId=' + childInfo.id, {
        headers: { Authorization: `Brear ${localStorage.getItem('token')}` }
      })
      .then((res) => res.data)
      .then((data) => {
        setViewCount(data)
      })
  }

  const getAnimationDetail = () => {
    setLoading(true)
    axios
      .get(AnimationAPI + '/detail/user/' + id + '?childId=' + childInfo?.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setData(data.data)
        setSave(data.save)
        setAnimations([])
        setLoading(false)
        addView()
      })
      .catch((e) => {
        if (e.response.data === 'subscribe') {
          setNeedSub(true)
          setLoading(false)
        }
      })
  }
  useEffect(() => {
    getAnimationDetail()
  }, [])
  const [showForm, setShowForm] = useState(false)

  const playerRef = useRef(null)

  useEffect(() => {
    // Start playing the video when the component mounts
    if (playerRef.current) {
      playerRef.current.play()
    }
  }, [])

  return (
    <>
      <HeaderAlbum title={data.title} />
      {loading ? (
        <Loading width="100px" color="#1e40af" />
      ) : (
        <>
          {needSub ? (
            <div className="flex justify-center items-center flex-wrap vazir-medium mt-14 px-4">
              <div className="w-48 h-48 bg-blue-200 rounded-full flex justify-center items-center">
                <img src={Subscription} alt="buy subscription matyar" />
              </div>
              <div className="w-full flex flex-col justify-center items-center flex-wrap mt-5">
                <p className="text-lg text-center"> شما اشتراک فعالی ندارید</p>
                <p className="text-sm text-gray-500 text-center w-8/12 mt-3">
                  شما اشتراک فعالی ندارید برای دیدن این انیمیشن باید اشتراکی خریداری کنید
                </p>
              </div>

              <button
                className="w-full bg-[#3F54CB] text-white rounded-lg py-4 vazir-medium mt-14"
                onClick={() => navigate('/list-subscription')}
              >
                خرید اشتراک
              </button>
            </div>
          ) : (
            <>
              <PopUp
                setShowMessage={setShowForm}
                showMessage={showForm}
                titleMessage="نظر خود را با ما به اشتراک بگذارید"
                userTeam={data.title}
                API={AnimationAPI + '/comment'}
                id={data.id}
                body={`لطفا برای بهبود و بالا بردن خدمات ما نظر خود را درباره انیمیشن  ${data.title}  ثبت کنید`}
              />
              <div className="dir-ltr">
                <Player ref={playerRef} autoPlay>
                  <source src={Domain + data.video} />

                  <ControlBar autoHide={false}>
                    <PlayToggle order={1.0} />
                    <ReplayControl seconds={10} order={1.1} />
                    <ForwardControl seconds={30} order={1.2} />
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                    <VolumeMenuButton disabled />
                  </ControlBar>
                </Player>
              </div>
              <div className="w-full flex justify-between items-center px-4 my-5">
                <p>{data.title}</p>
                <section className="flex justify-between items-center">
                  {save ? (
                    <BsFillBookmarkFill
                      className="text-xl text-[#3F54CB]"
                      onClick={() => {
                        RemoveBookmark(save.id)
                        setSave(false)
                      }}
                    />
                  ) : (
                    <CiBookmark
                      className="text-xl"
                      onClick={() => {
                        setSave(true)
                        AddBookmark('animation', data.id, childInfo.id, setSave)
                      }}
                    />
                  )}

                  <section className="w-8 h-8 rounded-xl bg-[#E2664C] flex justify-center items-center mr-2 hidden">
                    <AiFillHeart className="text-xl text-white" />
                  </section>
                </section>
              </div>
              <div className="w-full px-4">
                <div className="text-sm text-gray-400 ">
                  <span className="text-gray-400 text-sm">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data?.description?.substring(0, show ? 200000000 : 90)
                      }}
                    />
                    {show && null}
                  </span>
                </div>
                {show ? (
                  <section
                    className="text-red-600 flex justify-start items-center    py-1 rounded mt-1"
                    onClick={() => {
                      setShow(false)
                    }}
                  >
                    <span className="text-xs">خلاصه</span>
                    <IoIosArrowUp className="text-red-600" />
                  </section>
                ) : (
                  <section
                    className="text-blue-600 flex justify-start items-center     py-1 rounded mt-1"
                    onClick={() => {
                      setShow(true)
                    }}
                  >
                    <span className="text-xs">توضیحات ویدیو</span>
                    <IoIosArrowDown className="text-blue-600" />
                  </section>
                )}
              </div>
              <section className="w-full flex justify-start items-center mb-5 px-4 mt-2">
                <div className="flex justify-start items-center ml-5">
                  <img src={Heart} alt="show icon matyar" className="w-5 h-5 ml-2" />
                  <span className="text-gray-700 text-xs">{data.rateCount}</span>
                </div>

                <div className="flex justify-start items-center ml-5">
                  <img src={CommentSVG} alt="show icon matyar" className="w-5 h-5 ml-2" />
                  <span className="text-gray-700 text-xs">{data.commentCount}</span>
                </div>
                <div className="flex justify-start items-center ml-5">
                  <img src={Show} alt="show icon matyar" className="w-5 h-5 ml-2" />
                  <span className="text-gray-700 text-xs">{viewCount}</span>
                </div>
              </section>
              <div className="w-full px-4 border-t border-t-gray-300">
                <section className="w-full py-2">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex justify-start items-center ml-10">
                      <span className="block w-2 h-2 rounded-full bg-[#3F54CB] ml-2" />
                      <span className="text-gray-600 text-sm">ژانر :</span>
                    </div>
                    <span className="text-sm text-gray-800">{data?.category?.title}</span>
                  </div>
                </section>

                <section className="w-full py-2">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex justify-start items-center ml-10">
                      <span className="block w-2 h-2 rounded-full bg-[#3F54CB] ml-2" />
                      <span className="text-gray-600 text-sm">کارگردان :</span>
                    </div>
                    <span className="text-sm text-gray-800">{data.director}</span>
                  </div>
                </section>

                <section className="w-full py-2">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex justify-start items-center ml-10">
                      <span className="block w-2 h-2 rounded-full bg-[#3F54CB] ml-2" />
                      <span className="text-gray-600 text-sm">نویسنده :</span>
                    </div>
                    <span className="text-sm text-gray-800">{data.writer}</span>
                  </div>
                </section>

                <section className="w-full py-2">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex justify-start items-center ml-10">
                      <span className="block w-2 h-2 rounded-full bg-[#3F54CB] ml-2" />
                      <span className="text-gray-600 text-sm">کشور سازنده :</span>
                    </div>
                    <span className="text-sm text-gray-800">{data.country}</span>
                  </div>
                </section>

                <section className="w-full py-2">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex justify-start items-center ml-10">
                      <span className="block w-2 h-2 rounded-full bg-[#3F54CB] ml-2" />
                      <span className="text-gray-600 text-sm">زمان انیمیشن :</span>
                    </div>
                    <span className="text-sm text-gray-800">{data.company}</span>
                  </div>
                </section>

                <section className="w-full py-2">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex justify-start items-center ml-10">
                      <span className="block w-2 h-2   rounded-full bg-[#3F54CB] ml-2" />
                      <span className="text-gray-600 text-sm">تهیه کننده :</span>
                    </div>
                    <span className="text-sm text-gray-800">{data.producer}</span>
                  </div>
                </section>

                <section className="w-full py-2">
                  <div className="w-full flex justify-between items-center">
                    <div className="flex justify-start items-center ml-10">
                      <span className="block w-2 h-2   rounded-full bg-[#3F54CB] ml-2" />
                      <span className="text-gray-600 text-sm">مناسب برای :</span>
                    </div>
                    <div className="flex justify-start items-center  ">
                      <span className="text-white rounded-xl text-sm bg-[#3F54CB] px-4 py-2">
                        +{data.age}
                      </span>
                    </div>
                  </div>
                </section>
              </div>
              <div className="mt-4 px-4">
                <p className="mb-3">پیشنهاد های مشابه</p>

                <Swiper spaceBetween={10} slidesPerView={2.8}>
                  {animations?.map((animatin) => (
                    <SwiperSlide key={animatin.id} className="rounded-xl px-0.5 py-2">
                      <div
                        className="w-full h-48 flex justify-center items-center rounded-xl"
                        style={{
                          backgroundImage: `url("${Domain + animatin.cover}")`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center'
                        }}
                      >
                        <BsFillPlayFill className="text-xl w-12 h-12 rounded-full text-white bg-[#3F54CB] p-1" />
                      </div>
                      <p className="text-sm text-gray-700">{animatin.title}</p>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="w-full px-4 mt-4">
                <div className="w-full flex justify-between items-center  border-t border-t-gray-300 py-3 hidden">
                  <section className="flex justify-start items-center">
                    <img src={Evaluation} alt="matyar" className="ml-4 w-5 h-5" />
                    <span className="text-sm text-gray-600">شاخص امتیاز دهی تربیتی</span>
                  </section>
                  <IoIosArrowBack className="text-xl text-gray-600" />
                </div>

                <div
                  className="w-full flex justify-between items-center  border-t border-t-gray-300 py-3"
                  onClick={() => {
                    navigate('/animation-effect?id=' + data.id + '&name=' + data.title)
                  }}
                >
                  <section className="flex justify-start items-center">
                    <img src={Scoring} alt="matyar" className="ml-4 w-5 h-5" />
                    <span className="text-sm text-gray-600">
                      شاخص امتیاز دهی بر اساس شاخص های اثر
                    </span>
                  </section>
                  <IoIosArrowBack className="text-xl text-gray-600" />
                </div>

                <div
                  className="w-full flex justify-between items-center border-t border-t-gray-300 py-3"
                  onClick={() => {
                    navigate('/animation-review?id=' + data.id + '&name=' + data.title)
                  }}
                >
                  <section className="flex justify-start items-center">
                    <img src={MovieReview} alt="matyar" className="ml-4 w-5 h-5" />
                    <span className="text-sm text-gray-600">نقد و بررسی انیمیشن</span>
                  </section>
                  <IoIosArrowBack className="text-xl text-gray-600" />
                </div>
                <div className="w-full flex justify-between items-center  border-t border-t-gray-300 py-3 hidden">
                  <section className="flex justify-start items-center">
                    <img src={PlayMovie} alt="matyar" className="ml-4 w-5 h-5" />
                    <span className="text-sm text-gray-600">قسمت های بعدی انیمیشن</span>
                  </section>
                  <IoIosArrowBack className="text-xl text-gray-600" />
                </div>
              </div>
              <Comment
                count={data?.commentCount}
                rate={data?.rateCount}
                id={data?.id}
                API={AnimationAPI + '/comment/user?id='}
                type="animation"
                setShowForm={() => setShowForm(true)}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default AnimationDetail
