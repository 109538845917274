// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'
import Slide1 from './svg/1.svg'
import Slide2 from './svg/2.svg'
import Slide3 from './svg/3.svg'
import Slide4 from './svg/4.svg'
import Slide5 from './svg/5.svg'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Link } from 'react-router-dom'

const Banner = () => {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
      loop={true}
      autoplay={{
        delay: 3000, // autoplay every 3 seconds
        disableOnInteraction: false
      }}
      spaceBetween={0}
      slidesPerView={1}
      pagination={{
        clickable: true,
        dynamicBullets: true
      }}
    >
      <SwiperSlide>
        <Link to="/album-collection">
          <img src={Slide1} className="w-full h-auto   block rounded-xl " />
        </Link>
      </SwiperSlide>
      <SwiperSlide>
        <Link to="/album-collection">
          <img src={Slide2} className="w-full h-auto   block rounded-xl " />
        </Link>
      </SwiperSlide>
      <SwiperSlide>
        <Link to="/progress">
          <img src={Slide3} className="w-full h-auto   block rounded-xl " />
        </Link>
      </SwiperSlide>
      <SwiperSlide>
        <Link to="/progress?status=behavior">
          <img src={Slide4} className="w-full h-auto   block rounded-xl " />
        </Link>
      </SwiperSlide>

      <SwiperSlide>
        <Link to="/my-vaccine">
          <img src={Slide5} className="w-full h-auto   block rounded-xl " />
        </Link>
      </SwiperSlide>
    </Swiper>
  )
}
export default Banner
