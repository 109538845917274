import { useNavigate } from 'react-router-dom'
import { BsSearch, BsPlusLg } from 'react-icons/bs'
import { MdHeadsetMic } from 'react-icons/md'
import Header from '../../components/detail-header'
import axios from 'axios'
import { TicketAPI } from '../../../../api'
import { useEffect, useState } from 'react'
import Loading from '../../../../component/loading'
import { useSelector } from 'react-redux'
function ContactSupport() {
  const [loading, setLoading] = useState(false)
  const [tickets, setTickets] = useState([])
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(0)
  const navigate = useNavigate()
  const childInfo = useSelector((state) => state.childInfo)
  const getAllTicket = () => {
    setPage(1)
    setLimit(9)
    setLoading(true)
    axios
      .get(
        TicketAPI + `/all/parent?search=${search}&page=${page}&limit=${limit}&id=${childInfo?.id}`,
        {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        }
      )
      .then((res) => res.data)
      .then((data) => {
        setTickets(data.tickets)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    getAllTicket()
  }, [search])

  return (
    <>
      <div>
        <Header title="ارتباط با پشتیبانی" />
        <div className="w-full vazir-medium px-4 relative my-5">
          <input
            className="w-full bg-gray-200 text-gray-500 text-sm py-3 rounded-lg pr-10"
            placeholder="جستوجو کنید..."
            onChange={(e) => setSearch(e.target.value)}
          />
          <BsSearch className="absolute top-3 right-7 text-gray-500" />
        </div>
        {loading ? (
          <div className="w-full h-full flex justify-center items-center">
            <Loading color="#1e40af" width="100px" />
          </div>
        ) : (
          <>
            <div
              className="px-4 vazir-medium flex justify-start items-center mt-4 cursor-pointer"
              onClick={() => navigate('/new-ticket')}
            >
              <section className="w-10 h-10 bg-green-100 text-green-500 flex justify-center items-center rounded-full ml-2">
                <BsPlusLg />
              </section>
              <section>
                <p className="text-green-500">تیکت جدید</p>
                <p className="text-gray-400 text-sm">گفتگوی جدید خود را آغاز کنید</p>
              </section>
            </div>
            <div className="px-4">
              {tickets &&
                tickets.map((ticket) => {
                  if (ticket.answer == 'true') {
                    return (
                      <div
                        key={ticket.id}
                        className=" vazir-medium flex justify-start items-center mt-4 w-full border-b pb-4"
                        onClick={() => navigate('/chat-ticket?id=' + ticket.id)}
                      >
                        <section className="w-10 h-10 bg-gray-200 text-gray-500 flex justify-center items-center rounded-full ml-2 relative">
                          <MdHeadsetMic className="text-xl" />
                          <span className="w-3 h-3 bg-green-600  border border-[3px] border-white rounded-full absolute bottom-0.5 right-0 " />
                        </section>
                        <section className="w-10/12">
                          <div className="text-gray-700  flex justify-between w-full">
                            <p className="text-xs">{ticket.title}</p>

                            <span className="text-green-600 text-xs">تیکت باز شده</span>
                          </div>
                          <p className="text-gray-400 text-[10px]">
                            {ticket.text.substring(0, 300)}...
                          </p>
                        </section>
                      </div>
                    )
                  }
                  if (ticket.answer == 'false') {
                    return (
                      <div
                        key={ticket.id}
                        className=" vazir-medium flex justify-start items-center mt-4 w-full border-b pb-4"
                        onClick={() => navigate('/chat-ticket?id=' + ticket.id)}
                      >
                        <section className="w-10 h-10 bg-gray-200 text-gray-500 flex justify-center items-center rounded-full ml-2 relative">
                          <MdHeadsetMic className="text-xl" />
                          <span className="w-3 h-3 bg-red-600 border border-[3px] border-white rounded-full absolute bottom-0.5 right-0 " />
                        </section>
                        <section className="w-10/12">
                          <div className="text-gray-700 text-sm flex justify-between w-full">
                            <p className="text-xs">{ticket.title}</p>

                            <span className="text-red-600 text-xs">تیکت بسته</span>
                          </div>
                          <p className="text-gray-400 text-[10px]">
                            {ticket.text.substring(0, 300)}...
                          </p>
                        </section>
                      </div>
                    )
                  }
                })}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ContactSupport
