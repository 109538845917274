import Wavesurfer from 'wavesurfer.js'
import { useEffect, useRef, useState } from 'react'
import * as WaveformRegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions'
import PlayPauseButton from './play-button'

function Waveform({ url }) {
  const [isPlaying, setIsPlaying] = useState(false)

  const waveform = useRef(null)
  useEffect(() => {
    return () => {
      waveform.current.stop() // Stop audio playback when the component unmounts
    }
  }, [])
  useEffect(() => {
    if (!waveform.current) {
      waveform.current = Wavesurfer.create({
        container: '#waveform',
        waveColor: '#e2e8f0',
        progressColor: '#E2664C',
        barGap: 4,
        barWidth: 4,
        barRadius: 10,
        cursorWidth: 0,
        cursorColor: 'transparent',
        height: 50,
        // More info here https://wavesurfer-js.org/plugins/regions.html
        plugins: [WaveformRegionsPlugin.create({ maxLength: 30 })]
      })

      waveform.current.load(url)
    }
  }, [])

  const playAudio = () => {
    if (waveform.current.isPlaying()) {
      waveform.current.pause()
    } else {
      waveform.current.play()
    }
  }

  return (
    <div className="w-full grid grid-cols-12 py-2">
      <div className="w-full col-span-10">
        <div id="waveform" />
      </div>
      <div className="w-full col-span-2" onClick={playAudio}>
        <div className="w-full h-full flex justify-center items-center">
          <PlayPauseButton isPlaying={isPlaying} onClick={() => setIsPlaying(!isPlaying)} />
        </div>
      </div>
    </div>
  )
}

export default Waveform
