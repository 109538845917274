import HeaderAlbum from '../../album/photo-album/component/header'
import { VscSearch, VscSettings } from 'react-icons/vsc'
import Card from '../../podcast/podcast-category/component/card'
import axios from 'axios'
import { LocationCategoryAPI } from '../../../../../api'
import { useEffect, useState } from 'react'

function PlaceCategory() {
  const [categoryList, setCategoryList] = useState([])
  const getCategory = (search) => {
    axios
      .get(LocationCategoryAPI + '?search=' + search, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCategoryList(data)
      })
  }

  useEffect(() => {
    getCategory()
  }, [])
  return (
    <>
      <HeaderAlbum title="دسته بندی" />
      <div className="px-4">
        <p className="text-gray-800">دسته بندی ها</p>
        <p className="text-gray-600 text-sm my-2">
          هرآنچه را میخواهید توسط جستجو و یا دسته بندی زیر به راحتی به آن رسید
        </p>
      </div>
      <div className="w-full flex justify-start items-center px-4">
        <section className="w-11/12 relative">
          <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
          <input
            className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
            placeholder="شهر مورد نظر خود را جستجو کنید..."
            onChange={(e) => getCategory(e.target.value)}
          />
        </section>
        <section className="w-12 h-12 flex justify-center items-center card-shadow rounded-lg mr-2">
          <VscSettings className="text-2xl text-gray500" />
        </section>
      </div>
      <div className="w-full px-4 mt-5">
        <p className="my-3">دسته بندی مکان ها</p>
        <div className="w-full grid grid-cols-2 gap-2">
          {categoryList.map((ci) => (
            <Card key={ci.id} data={ci} />
          ))}
        </div>
      </div>
    </>
  )
}
export default PlaceCategory
