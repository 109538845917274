import HeaderAlbum from '../../album/photo-album/component/header'
import Card from '../main/component/suggestion/card'

function ChiefSuggestion() {
  return (
    <>
      <HeaderAlbum title="پیشنهادات سردبیر" />
      <div className="w-full grid grid-cols-3 gap-4 px-4">
        <Card data={{}} />
      </div>
    </>
  )
}
export default ChiefSuggestion
