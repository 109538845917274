import { MdMessage } from 'react-icons/md'
import { useSelector } from 'react-redux'

function CommentButton({ userHasComment, setShowForm }) {
  const childInfo = useSelector((state) => state.childInfo)
  return (
    <>
      {!userHasComment && childInfo?.id && (
        <button
          className="w-full py-4 rounded-xl bg-[#3F54CB] flex justify-center items-center"
          onClick={() => setShowForm()}
        >
          <MdMessage className="text-white text-2xl mx-2" />
          <span className="text-white">ثبت نظر</span>
        </button>
      )}
    </>
  )
}

export default CommentButton
