import Layout from '../index'
// import { TitleBox } from '../../components/text-box'
import GrowthChart from './component/growth-chart'
import './chart.css'
import ChartActivities from './component/chart-activities'
import { useSelector } from 'react-redux'
import BehavioralGoals from '../behavioral-goals'
import { useLocation, useNavigate } from 'react-router-dom'
// import Skeleton from 'react-loading-skeleton'
// import Message from '../../components/message'
function ChildInfo() {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const status = searchParams.get('status')
  const childInfo = useSelector((state) => state.childInfo)
  const ChangePage = () => {
    if (childInfo && childInfo.id && childInfo.id.length > 1) {
      if (status === 'chart') {
        return <GrowthChart />
      } else if (status === 'activity') {
        return <ChartActivities />
      } else return <BehavioralGoals />
    } else
      return (
        <div>
          <p className="text-xl text-gray-500 text-center mt-10 px-4">
            لطفا از طریق نشانگر کنار عکس کاربری خود کودک خود را انتخاب کنید
          </p>
        </div>
      )
  }

  return (
    <>
      <Layout>
        <div className="w-full bg-white   rounded-t-2xl relative -top-5 pt-4  pb-[80px]">
          {/*<section className="flex justify-start items-start flex-wrap vazir-medium mt-4 px-4">*/}
          {/*  <TitleBox text="نمودار رشد" onClick={() => setPage('نمودار رشد')} isActive={true} />*/}
          {/*  <TitleBox text="فعالیت های تکمیل شده" onClick={() => setPage('فعالیت های تکمیل شده')} />*/}
          {/*  <TitleBox text="اهداف رفتاری" onClick={() => setPage('اهداف رفتاری')} />*/}
          {/*</section>*/}
          <section className="flex justify-between items-start w-full flex justify-between items-center  border-b-2 border-b-gray-200 pb-2">
            <section
              className={`w-3/12 flex justify-center items-center opacity-70 cursor-pointer ${
                status == 'chart' ? 'grouping-active' : null
              }`}
              onClick={() => navigate('?status=chart')}
            >
              <span className="text-sm">نمودار رشد</span>{' '}
            </section>

            <section
              className={`w-6/12 flex justify-center items-center opacity-70 cursor-pointer ${
                status == 'activity' ? 'grouping-active' : null
              }`}
              onClick={() => navigate('?status=activity')}
            >
              <span className="text-sm">فعالیت های تکمیل شده</span>{' '}
            </section>

            <section
              className={`w-3/12 flex justify-center items-center opacity-70 cursor-pointer ${
                status == 'behavior' ? 'grouping-active' : null
              }`}
              onClick={() => navigate('?status=behavior')}
            >
              <span className="text-sm">اهداف رفتاری</span>{' '}
            </section>
          </section>
          <ChangePage />
        </div>
      </Layout>
    </>
  )
}
export default ChildInfo
