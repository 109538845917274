import Layout from '../index'
import { useEffect } from 'react'
import PresenceWorkshop from './component/Presence'
import OnlineWorkshop from './component/online'
import WorkshopOffline from './component/offline'
import MyWorkshop from './component/me/my-workshop'
import { useLocation, useNavigate } from 'react-router-dom'
function Workshop() {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const status = searchParams.get('status')
  const ChangePage = () => {
    if (status === 'presence') {
      return <PresenceWorkshop />
    } else if (status === 'online') {
      return <OnlineWorkshop />
    } else if (status === 'offline') {
      return <WorkshopOffline />
    } else if (status === 'my-workshop') {
      return <MyWorkshop />
    }
  }
  useEffect(() => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    return () => {
      document.getElementsByTagName('body')[0].style.overflow = ''
    }
  }, [])

  return (
    <>
      <Layout>
        <div className="w-full bg-white rounded-t-2xl px-2 relative -top-5 pt-4">
          <section className="flex justify-between items-start w-full flex justify-between items-center  border-b-2 border-b-gray-200 pb-2">
            <section
              className={`flex justify-center items-center opacity-70 cursor-pointer ${
                status == 'presence' ? 'grouping-active' : null
              }`}
              onClick={() => navigate('?status=presence')}
            >
              <span className="text-sm">کارگاه حضوری</span>{' '}
            </section>

            <section
              className={`flex justify-center items-center opacity-70 cursor-pointer ${
                status == 'online' ? 'grouping-active' : null
              }`}
              onClick={() => navigate('?status=online')}
            >
              <span className="text-sm">کارگاه آنلاین</span>{' '}
            </section>

            <section
              className={`flex justify-center items-center opacity-70 cursor-pointer ${
                status == 'offline' ? 'grouping-active' : null
              }`}
              onClick={() => navigate('?status=offline')}
            >
              <span className="text-sm">کارگاه آفلاین</span>{' '}
            </section>

            <section
              className={`flex justify-center items-center opacity-70 cursor-pointer ${
                status == 'my-workshop' ? 'grouping-active' : null
              }`}
              onClick={() => navigate('?status=my-workshop')}
            >
              <span className="text-sm">کارگاه من</span>
            </section>
          </section>
          <ChangePage />
        </div>
      </Layout>
    </>
  )
}

export default Workshop
