import HeaderAlbum from '../../album/photo-album/component/header'
import { VscSearch, VscSettings } from 'react-icons/vsc'
import Box from './component/box'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { LullabyByCategoryAPI, LullabyCategoryAPI } from '../../../../../api'
import Card from './component/card'
import './index.css'
import { MdOutlineAccessTimeFilled } from 'react-icons/md'
import { FaCirclePlus } from 'react-icons/fa6'
import { FaBookmark } from 'react-icons/fa'
import { useSelector } from 'react-redux'

function CollectionLullaby() {
  const [categoryList, setCategoryList] = useState([])
  const [lullabies, setLullabies] = useState([])
  const [groupingIdState, setGroupingIdState] = useState('')
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [currentAudio, setCurrentAudio] = useState(null)
  const listInnerRef = useRef()
  const childInfo = useSelector((state) => state.childInfo)

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
        getLullabyByCategory(groupingIdState, search, page + 1)
      }
    }
  }

  const getLullabyCategory = () => {
    axios
      .get(LullabyCategoryAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCategoryList(data.data)
      })
  }

  const getLullabyByCategory = (id = groupingIdState, Search = search, Page = page) => {
    axios
      .get(
        LullabyByCategoryAPI +
          `?catId=${id}&title=${Search}&page=${Page}&limit=10&childId=${childInfo?.id}`,
        {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        }
      )
      .then((res) => res.data)
      .then((data) => {
        if (Page == 1) {
          setLullabies(data.lullabies)
        } else {
          setLullabies([...lullabies, ...data.lullabies])
        }
      })
  }

  useEffect(() => {
    getLullabyCategory()
    getLullabyByCategory()
  }, [])
  return (
    <>
      <HeaderAlbum title="مجموعه لالایی ها" />
      <div className="w-full flex justify-start items-center px-4">
        <section className="w-11/12 relative">
          <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
          <input
            className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
            placeholder="عنوان لالایی خود را جستجو کنید..."
            onChange={(e) => {
              getLullabyByCategory(groupingIdState, e.target.value, 1)
              setPage(1)
              setSearch(e.target.value)
            }}
          />
        </section>
        <section className="w-12 h-12 flex justify-center items-center card-shadow rounded-lg mr-2">
          <VscSettings className="text-2xl text-gray500" />
        </section>
      </div>
      <div className="w-full grid grid-cols-3 gap-3 px-4 mt-4">
        <Box
          ClassName="bg1"
          title="لالایی اخیر"
          Icon={<MdOutlineAccessTimeFilled className="text-2xl" />}
          link={'/add-lullaby'}
        />
        <Box
          ClassName="bg2 "
          title="افزودن لالایی"
          Icon={<FaCirclePlus className="text-2xl" />}
          link={'/add-lullaby'}
        />
        <Box
          ClassName="bg3"
          title="نشان شده ها"
          Icon={<FaBookmark className="text-2xl" />}
          link={'/bookmark-lullaby'}
        />
      </div>

      <div className="flex-container border-b-2 border-b-gray-200 pb-2 mt-10 sticky top-0 bg-white z-50">
        <div className="scrollable-content">
          <div
            className={`w-20 flex justify-center items-center opacity-70 cursor-pointer ${
              groupingIdState === '' ? 'grouping-active' : ''
            }`}
            onClick={() => {
              setGroupingIdState('')
              getLullabyByCategory('', search, 1)
              setPage(1)
            }}
          >
            <span>همه</span>
          </div>

          {Array.isArray(categoryList) &&
            categoryList.map((cat) => (
              <div
                key={cat.id}
                className={`w-28 flex justify-center items-center opacity-70 cursor-pointer  ${
                  groupingIdState === cat.id ? 'grouping-active' : ''
                }`}
                onClick={() => {
                  setGroupingIdState(cat.id)
                  getLullabyByCategory(cat.id, search, 1)
                  setPage(1)
                }}
              >
                <span>{cat.title}</span>
              </div>
            ))}
        </div>
      </div>

      <div
        className="w-full px-4 h-screen overflow-hidden overflow-y-scroll "
        onScroll={onScroll}
        ref={listInnerRef}
      >
        {Array.isArray(lullabies) &&
          lullabies.map((lul) => (
            <Card
              lul={lul}
              key={lul.id}
              currentAudio={currentAudio}
              setCurrentAudio={setCurrentAudio}
            />
          ))}
      </div>
    </>
  )
}
export default CollectionLullaby
