import { IoIosArrowBack } from 'react-icons/io'
import { TiTick } from 'react-icons/ti'
import { MdOutlineCancel } from 'react-icons/md'
import { useRef, useState } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'

function Input({
  onChange,
  Change,
  Placeholder,
  keyFormData,
  id,
  API,
  isNumber,
  isPassword,
  Label,
  defaultValue
}) {
  const ref = useRef(null)
  const [edit, setEdit] = useState(false)
  const updateFunction = () => {
    const formData = new FormData()
    formData.append(keyFormData, Change)

    axios
      .patch(API + id, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        setEdit(false)
      })
      .catch((e) => {
        toast.error(e.response.data.message)
      })
  }
  if (isPassword) {
    return (
      <>
        <section>
          <p className="text-gray-400 text-xs mb-1">{Label}</p>
          <input
            className="w-full bg-transparent"
            type="password"
            name="child-name"
            onChange={(e) => onChange(e.target.value)}
            defaultValue={Change}
            disabled={!edit}
          />
        </section>

        <section>
          {edit ? (
            <>
              <TiTick className="text-xl text-gray-400" />
              <MdOutlineCancel className="text-xl text-gray-400" />
            </>
          ) : (
            <IoIosArrowBack className="text-xl text-gray-400" onClick={() => setEdit(true)} />
          )}
        </section>
      </>
    )
  } else {
    return (
      <>
        <section className="w-full">
          <p className="text-gray-400 text-xs mb-1">{Label}</p>
          <input
            ref={ref}
            type={isNumber ? 'number' : 'text'}
            className="w-full bg-transparent"
            name="child-name"
            onChange={(e) => onChange(e.target.value)}
            placeholder={Placeholder}
            defaultValue={defaultValue}
            disabled={!edit}
          />
        </section>

        <section>
          {edit ? (
            <>
              <TiTick className="text-2xl text-gray-400 mb-1" onClick={updateFunction} />
              <MdOutlineCancel
                className="text-2xl text-gray-400 mt-1"
                onClick={() => {
                  setEdit(false)
                  ref.current.value = defaultValue
                }}
              />
            </>
          ) : (
            <IoIosArrowBack className="text-xl text-gray-400" onClick={() => setEdit(true)} />
          )}
        </section>
      </>
    )
  }
}
export default Input
