import { SlArrowDown } from 'react-icons/sl'
import './ticket.css'
import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import { TicketAPI } from '../../../../../../api'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
function Ticket() {
  const navigate = useNavigate()
  const childInfo = useSelector((state) => state.childInfo)

  const [departman, setDepartman] = useState('')
  const [departemanActive, setDepartemanActive] = useState(false)
  const [order, setOrder] = useState('')
  const [orderActive, setOrderActive] = useState(false)
  const [text, setText] = useState('')
  const [title, setTitle] = useState('')
  const createTicket = () => {
    const formData = new FormData()
    formData.append('department', departman)
    formData.append('order', order)
    formData.append('title', title)
    formData.append('text', text)
    formData.append('id', childInfo?.id)
    axios
      .post(TicketAPI, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then(() => {
        navigate('/contact-support')
        toast.success('تیکت با موفقیت ثبت شد')
      })
      .catch((err) => {
        err.response.data.message.map((msg) => {
          toast.error(msg)
        })
      })
  }
  return (
    <>
      {orderActive || departemanActive ? (
        <div
          className="w-full h-screen top-0 left-0  fixed  z-20"
          onClick={() => {
            setOrderActive(false)
            setDepartemanActive(false)
          }}
        />
      ) : null}
      <div className="bg-contact-us" />
      <header>
        <div className="flex justify-start items-center mt-10 pb-4 relative px-4">
          <IoIosArrowForward
            className="text-3xl text-gray-600 border rounded"
            onClick={() => navigate(-1)}
          />
          <p className="vazir-medium mr-4">ارتباط با پشتیبانی</p>
        </div>
      </header>

      <div className="w-full px-4">
        <div className="w-full bg-white shadow rounded-xl vazir-medium px-4 py-5">
          <div className="mt-5">
            <p>انتخاب دپارتمان</p>
            <section
              className="w-full flex justify-between items-center border border-gray-300 px-3 py-3 rounded-xl text-gray-400 relative"
              onClick={() => setDepartemanActive(!departemanActive)}
            >
              <span> {departman.length > 1 ? departman : ' -انتخاب دپارتمان مورد نظر-'}</span>
              <SlArrowDown />
              {departemanActive && (
                <ul className="absolute w-full top-12 left-0 rounded-xl bg-gray-100 z-40">
                  <li className="w-full py-2 px-4 " onClick={() => setDepartman('مالی')}>
                    <span className="text-sm text-gray-700">بخش مالی</span>
                  </li>

                  <li className="w-full py-2 px-4 " onClick={() => setDepartman('اداری')}>
                    <span className="text-sm text-gray-700">بخش اداری</span>
                  </li>

                  <li className="w-full py-2 px-4 " onClick={() => setDepartman('راهبرها')}>
                    <span className="text-sm text-gray-700">بخش راهبرها</span>
                  </li>

                  <li className="w-full py-2 px-4 " onClick={() => setDepartman('مدیران')}>
                    <span className="text-sm text-gray-700">بخش مدیران</span>
                  </li>
                </ul>
              )}
            </section>
          </div>

          <div className="mt-4">
            <p>انتخاب اولویت</p>
            <section
              className="w-full flex justify-between items-center border border-gray-300 px-3 py-3 rounded-xl text-gray-400 relative"
              onClick={() => setOrderActive(!orderActive)}
            >
              <span>{order.length > 1 ? order : '-انتخاب اولویت پیام-'}</span>
              <SlArrowDown />
              {orderActive && (
                <ul className="absolute w-full top-12 left-0 rounded-xl bg-gray-100 rounded-xl overflow-hidden z-30">
                  <li className="w-full py-2 px-4  bg-gray-100" onClick={() => setOrder('بالا')}>
                    <span className="text-sm text-gray-700 text-red-600">اولویت بالا</span>
                  </li>

                  <li className="w-full py-2 px-4 bg-gray-100 " onClick={() => setOrder('متوسط')}>
                    <span className="text-sm text-gray-700 text-orange-600">اولویت متوسط</span>
                  </li>

                  <li className="w-full py-2 px-4 bg-gray-100" onClick={() => setOrder('پایین')}>
                    <span className="text-sm text-gray-700 text-yellow-600">اولویت پایین</span>
                  </li>
                </ul>
              )}
            </section>
          </div>

          <div className="mt-4">
            <p>عنوان متن</p>
            <input
              type="text"
              className="w-full flex justify-between items-center border border-gray-300 px-3 py-3 rounded-xl text-gray-400"
              placeholder="لطفا عنوان متن خود را وارد کنید"
              onChange={(e) => setTitle(e.target.value)}
            ></input>
          </div>

          <div className="mt-4 ">
            <p>عنوان متن</p>
            <textarea
              className="w-full flex justify-between items-center border border-gray-300 px-3 py-3 rounded-xl text-gray-400 h-32"
              placeholder="موضوع خود را شرح دهید"
              onChange={(e) => setText(e.target.value)}
            ></textarea>
          </div>
        </div>
        <button
          className="w-full bg-green-500 opacity-70 text-white rounded-lg py-4 vazir-medium mt-4"
          onClick={createTicket}
        >
          ارسال پیام
        </button>
      </div>
    </>
  )
}

export default Ticket
