import { createSlice } from '@reduxjs/toolkit'

export const Basket = createSlice({
  name: 'basket',
  initialState: {
    basket: {
      workshops: [],
      subscribe: [],
      workshopPrice: 0,
      subscribePrice: 0,
      price: 0
    }
  },
  reducers: {
    addWorkshop: (state, action) => {
      const newWorkshop = action.payload
      state.basket.workshops.push(newWorkshop.workshops)
      state.basket.workshopPrice += newWorkshop.price // Update the price
      state.basket.price = state.basket.workshopPrice + state.basket.subscribePrice
    },
    addSubscribe: (state, action) => {
      const newSubscribe = action.payload
      state.basket.subscribe = [newSubscribe.subscribe]
      state.basket.subscribePrice = newSubscribe.price // Add the new subscribe price
      state.basket.price = state.basket.workshopPrice + state.basket.subscribePrice
    },
    clearBasket: (state) => {
      state.basket = {
        workshops: [],
        subscribe: [],
        workshopPrice: 0,
        subscribePrice: 0,
        price: 0
      }
    }
  }
})

export const { addWorkshop, addSubscribe, clearBasket } = Basket.actions

export default Basket.reducer
