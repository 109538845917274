import Header from '../../components/detail-header'
import QuestionItem from './component/QuestionItem'
import { MdArrowBackIos } from 'react-icons/md'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { GetQuestion } from '../../../../api'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function Question() {
  const childInfo = useSelector((state) => state.childInfo)
  const navigate = useNavigate()
  const [data, setData] = useState({})
  const [page, setPage] = useState(1)
  const getQuestion = () => {
    axios
      .get(`${GetQuestion}?limit=3&search=&type=&child_id=${childInfo.id}&page=${page}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setData(data)
      })
  }
  useEffect(() => {
    getQuestion()
  }, [page])
  return (
    <>
      <Header title="سوالات" />
      <div className="w-full px-4 mt-4   pb-32 ">
        <span>میزان پیشرفت!</span>
        <div className="w-full h-2 rounded-xl bg-blue-50 overflow-hidden mt-2">
          <section
            style={{
              height: '100%',
              backgroundColor: '#3F54CB',
              width: `${data.progress}%`
            }}
          />
        </div>
        <div className="w-full flex flex-col items-start justify-start mt-5">
          {data &&
            data.data &&
            data?.data?.map((dta) => (
              <QuestionItem
                answer={dta?.answer?.[0]?.value}
                options={dta?.options?.split(',')}
                questionType={dta?.type}
                key={dta?.id}
                numQuestion={dta?.number}
                question={dta?.text}
                nameInput={dta?.id}
                limitAns={dta?.limit}
                dta={dta}
              />
            ))}
        </div>
        <div className="w-full flex justify-between items-center fixed bottom-5  left-0 px-4">
          {page > 1 && (
            <button
              className="border border-gray-300 rounded px-4 py-2 bg-white"
              onClick={() => {
                if (page > 1) {
                  setPage(page - 1)
                }
              }}
            >
              <span>قبلی</span>
            </button>
          )}

          {data?.isLastPage == false ? (
            <button
              className="bg-[#3F54CB] rounded px-4 py-2 text-white flex justify-center items-center"
              onClick={() => setPage(page + 1)}
            >
              <span>بعدی</span>
              <MdArrowBackIos className="" />
            </button>
          ) : (
            <button
              className="bg-[#3F54CB] rounded px-4 py-2 text-white flex justify-center items-center"
              onClick={() => navigate(-1)}
            >
              <span>پایان</span>
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default Question
