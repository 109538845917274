import { createSlice } from '@reduxjs/toolkit'

export const AlbumSetting = createSlice({
  name: 'album',
  initialState: {
    albums: []
  },
  reducers: {
    addAlbum: (state, action) => {
      const albumIndex = state.albums.findIndex((album) => album === action.payload)

      if (albumIndex !== -1) {
        // Album found, remove it from the array
        state.albums.splice(albumIndex, 1)
      } else {
        // Album not found, add it to the array
        state.albums.push(action.payload)
      }
    },

    clearAlbum: (state) => {
      state.albums = []
    }
  }
})
export const { addAlbum, clearAlbum } = AlbumSetting.actions

export default AlbumSetting.reducer
