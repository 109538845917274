import UserIcon from './svg/image 1421.png'
import { IoIosArrowBack } from 'react-icons/io'
import './profile-complete.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ParentAPI } from '../../../../../../../api'
import { useEffect, useState } from 'react'
import CountUp from 'react-countup'
function ProfileComplete() {
  const [maxPercent, setMaxPercent] = useState(0)
  const [total, setTotal] = useState(0)
  const [percent, setPercent] = useState(0)
  const navigate = useNavigate()
  const getPercent = () => {
    axios
      .get(ParentAPI + 'percent', {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setTotal(data)
        setMaxPercent(data)
        if (percent < data) {
          setTimeout(() => {
            setPercent((prevState) => prevState + 1)
          }, [10])
        }
      })
  }
  useEffect(() => {
    getPercent()
    const interval = setInterval(() => {
      setPercent((prevState) => {
        if (prevState < maxPercent) {
          return prevState + 1
        } else {
          clearInterval(interval) // Clear the interval if the percent has reached maxPercent
          return prevState
        }
      })
    }, 28)

    // Cleanup the interval on component unmount
    return () => clearInterval(interval)
  }, [maxPercent])
  return (
    <>
      <div
        className={`w-full h-36 relative shadow-box rounded-xl px-4 py-2 my-3 mt-5  ${
          total == 100 || total === 0 ? 'hidden' : null
        }`}
      >
        <section className="w-full flex justify-between items-center">
          <img src={UserIcon} alt="compelete matyar icon" className="bg-blue-100 rounded-lg p-1" />
          <span className="text-blue-600   text-sm">
            <CountUp end={maxPercent} /> % پروفایل شما تکمیل شده است!!!
          </span>
        </section>

        <div className="w-full h-2 rounded-xl bg-blue-100 my-2">
          <section className=" bg-blue-600 rounded-xl h-full" style={{ width: `${percent}%` }} />
        </div>

        <p className="text-xs text-gray-700">
          به منظور تکمیل اطلاعات درخواستی تیم مشاوران ماتیار لطفا پروفایل خود را تکمیل کنید
        </p>

        <div
          className="flex justify-between items-center text-blue-600 text-xs absolute left-2 bottom-3"
          onClick={() => navigate('/account')}
        >
          <span>تکمیل پروفایل</span>
          <IoIosArrowBack />
        </div>
      </div>
    </>
  )
}
export default ProfileComplete
