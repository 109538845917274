import { FallingLines } from 'react-loader-spinner'
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
function Loading({ color, width }) {
  return (
    <div className="w-full flex justify-center items-center">
      <FallingLines color={color} width={width} visible={true} ariaLabel="falling-lines-loading" />
    </div>
  )
}
export default Loading
