import { useEffect, useRef, useState } from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { useLocation, useNavigate } from 'react-router-dom'
import Rate from './component/rate'
import axios from 'axios'
import {
  AnimationAPI,
  ArticleAPI,
  DoctorAPI,
  EpisodeAPI,
  FoodAPI,
  LocationAPI,
  LullabyAPI,
  PodcastAPI,
  TeamAPI,
  VaccineAPI
} from '../../../../api'
import CommentItem from './component/comment'

function Comment() {
  const navigate = useNavigate()
  const location = useLocation()
  const [page, setPage] = useState(1)
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const type = searchParams.get('type')
  const commentCount = searchParams.get('commentCount')
  const rateCount = searchParams.get('rateCount')
  const [commentDetail, setCommentDetail] = useState([])
  const listInnerRef = useRef()
  const getCommentTeam = () => {
    axios
      .get(TeamAPI + `comment/all?id=${id}&page=${page}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCommentDetail(data)
      })
  }
  const getCommentArticle = () => {
    axios
      .get(ArticleAPI + `/comment/all?id=${id}&page=${page}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCommentDetail(data)
      })
  }
  const getCommentAnimation = () => {
    axios
      .get(AnimationAPI + `/comment/all?id=${id}&page=${page}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCommentDetail(data)
      })
  }
  const getCommentLocation = () => {
    axios
      .get(LocationAPI + `/comment/all?id=${id}&page=${page}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCommentDetail(data)
      })
  }
  const getCommentFood = () => {
    axios
      .get(FoodAPI + `/comment/all?id=${id}&page=${page}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCommentDetail(data)
      })
  }
  const getCommentDoctor = () => {
    axios
      .get(DoctorAPI + `/comment/all?id=${id}&page=${page}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCommentDetail(data)
      })
  }
  const getCommentLullaby = () => {
    axios
      .get(LullabyAPI + `/comment/all?id=${id}&page=${page}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCommentDetail(data)
      })
  }
  const getCommentEpisode = () => {
    axios
      .get(EpisodeAPI + `comment/all?id=${id}&page=${page}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCommentDetail(data)
      })
  }
  const getCommentPodcast = () => {
    axios
      .get(PodcastAPI + `/comment/all?id=${id}&page=${page}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCommentDetail(data)
      })
  }
  const getCommentVaccine = () => {
    axios
      .get(VaccineAPI + `/comment/all?id=${id}&page=${page}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCommentDetail(data)
      })
  }
  useEffect(() => {
    if (type == 'team') {
      getCommentTeam()
    }
    if (type == 'vaccine') {
      getCommentVaccine()
    }
    if (type == 'animation') {
      getCommentAnimation()
    }
    if (type == 'episode') {
      getCommentEpisode()
    }
    if (type == 'podcast') {
      getCommentPodcast()
    }
    if (type == 'lullaby') {
      getCommentLullaby()
    }
    if (type == 'article') {
      getCommentArticle()
    }
    if (type == 'doctor') {
      getCommentDoctor()
    }
    if (type == 'food') {
      getCommentFood()
    }
    if (type == 'location') {
      getCommentLocation()
    }
  }, [])

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }
  return (
    <>
      <header className="shadow-bottom">
        <div className="flex justify-center items-center mt-10 pb-4 relative px-4">
          <IoIosArrowForward
            className="text-3xl text-gray-600 border rounded absolute right-4"
            onClick={() => navigate(-1)}
          />
          <p className="vazir-medium mr-4">دیدگاه کاربران</p>
        </div>
      </header>
      {commentCount != 'null' && rateCount != 'null' ? (
        <div className="flex justify-between items-center w-full vazir-medium my-5 px-2">
          <section className="w-6/12 flex flex-col justify-center items-center">
            <p className="text-green-600 text-3xl">
              {commentCount == 0 ? 0 : Math.ceil(rateCount / commentCount)}
            </p>

            <p className="text-gray-600 text-xs">
              از مجموعه&nbsp;{rateCount}&nbsp;امتیاز و&nbsp;{commentCount}&nbsp;رای
            </p>
          </section>
          <section className="w-6/12 px-2">
            {commentDetail.result &&
              commentDetail.result.map((res) => {
                return (
                  <Rate
                    key={res.rating}
                    percentage={parseInt(res.percentage)}
                    num={parseInt(res.rating)}
                  />
                )
              })}
          </section>
        </div>
      ) : (
        <div className="flex justify-between items-center w-full vazir-medium my-5 px-2">
          <section className="w-6/12 flex flex-col justify-center items-center">
            <p className="text-green-600 text-3xl">
              {commentCount == 'null' && commentDetail?.commentCnt == 0
                ? 0
                : Math.ceil(commentDetail?.rateCnt / commentDetail?.commentCnt)}
            </p>

            <p className="text-gray-600 text-xs">
              از مجموعه&nbsp;{commentDetail?.rateCnt}&nbsp;امتیاز و&nbsp;
              {commentDetail?.commentCnt}&nbsp;رای
            </p>
          </section>
          <section className="w-6/12 px-2">
            {commentDetail.result &&
              commentDetail.result.map((res) => {
                return (
                  <Rate
                    key={res.rating}
                    percentage={parseInt(res.percentage)}
                    num={parseInt(res.rating)}
                  />
                )
              })}
          </section>
        </div>
      )}
      <div className="vazir-medium px-4 mt-5">
        <section className="w-full mt-4" onScroll={onScroll}>
          {commentDetail &&
            commentDetail.comment &&
            commentDetail.comment.map((res) => <CommentItem key={res.id} comment={res} />)}
        </section>
      </div>
    </>
  )
}

export default Comment
