import React, { useEffect, useState } from 'react'
import { UilCalendarAlt } from '@iconscout/react-unicons'

import DatePicker from 'react-multi-date-picker'
import persian from 'react-date-object/calendars/persian'
import persian_fa from 'react-date-object/locales/persian_fa'
import transition from 'react-element-popper/animations/transition'
import DateObject from 'react-date-object'
import { useSelector } from 'react-redux'

import gregorian from 'react-date-object/calendars/gregorian'
import gregorian_en from 'react-date-object/locales/gregorian_en'
const moment = require('moment-jalaali')
function DateWeek() {
  const childInfo = useSelector((state) => state.childInfo)

  const [daysOFWeek, setDaysOFWeek] = useState([
    { name: 'شنبه', numDay: null, numMonth: null, numYear: null },
    { name: 'یکشنبه', numDay: null, numMonth: null, numYear: null },
    { name: 'دوشنبه', numDay: null, numMonth: null, numYear: null },
    { name: 'سه‌شنبه', numDay: null, numMonth: null, numYear: null },
    { name: 'چهارشنبه', numDay: null, numMonth: null, numYear: null },
    { name: 'پنجشنبه', numDay: null, numMonth: null, numYear: null },
    { name: 'جمعه', numDay: null, numMonth: null, numYear: null }
  ])
  const [dateToObject, setDateToObject] = useState('')

  const [state, setState] = useState({ format: 'dddd DD MMMM YYYY' })
  const convert = (date, format = state.format) => {
    let object = { date, format }

    setState({
      persian: new DateObject(object).format(),
      jsDate: date.toDate(),
      ...object
    })
  }
  const persianToGregorian = (persianDate) => {
    const result = {
      year: new DateObject({ calendar: persian, date: persianDate }).convert(
        gregorian,
        gregorian_en
      ).year,
      month: new DateObject({ calendar: persian, date: persianDate }).convert(
        gregorian,
        gregorian_en
      ).month.number,
      day: new DateObject({ calendar: persian, date: persianDate }).convert(gregorian, gregorian_en)
        .day
    }

    return new Date(result.year, result.month - 1, result.day)
  }

  const generateDaysOfWeak = (jdstring) => {
    const momentDate = moment(jdstring, 'jYYYYY/jMM/jDD')

    const dateobjectNew = new DateObject(persianToGregorian(jdstring))

    dateobjectNew.calendar = persian
    dateobjectNew.locale = persian_fa

    for (let i = 0; i < daysOFWeek.length; i++) {
      const dayNumber = i - dateobjectNew.weekDay.index

      const date = momentDate.clone().add(dayNumber, 'd')
      daysOFWeek[i].numDay = date.jDate()
      daysOFWeek[i].numMonth = date.jMonth() + 1
      daysOFWeek[i].numYear = date.jYear()
    }

    setDaysOFWeek(daysOFWeek)
  }
  const dateObjectFunction = async (date = Date.now(), year, month, day) => {
    const datePicker = await new DateObject(date)
    datePicker.calendar = await persian
    datePicker.locale = await persian_fa
    await setDateToObject(datePicker)
    await convert(datePicker)

    await generateDaysOfWeak(
      `${year ? year : datePicker.year}/${month ? month : datePicker.month.number}/${
        day ? day : datePicker.day
      }`
    )
  }

  useEffect(() => {
    dateObjectFunction()
  }, [childInfo.id])

  return (
    <React.Fragment>
      <div className={`w-full bg-white rounded-t-2xl    pt-4 pb-20`}>
        <section className="w-full flex justify-between items-center ">
          <div className="w-full">
            <DatePicker
              className="rmdp-mobile"
              onChange={(e) => {
                convert(e)
                setDateToObject(e)
                generateDaysOfWeak(`${e.year}/${e.month.number}/${e.day}`)
              }}
              multiple={false}
              calendar={persian}
              locale={persian_fa}
              calendarPosition="bottom-right"
              mobileLabels={{
                OK: 'تایید',
                CANCEL: 'بستن'
              }}
              animations={[
                transition({
                  from: 35,
                  transition: 'all 400ms cubic-bezier(0.335, 0.010, 0.030, 1.360)'
                })
              ]}
              render={(value, openCalendar) => {
                return (
                  <button
                    className="w-[95vw] flex justify-between items-center  mt-2"
                    onClick={openCalendar}
                  >
                    <span>{state.persian}</span>

                    <UilCalendarAlt className="text-gray-700 relative left-2" />
                  </button>
                )
              }}
            />
          </div>
        </section>
        <div className="flex justify-between items-center mt-5">
          {daysOFWeek &&
            daysOFWeek.map((day) => (
              <section
                key={day.name}
                className={`vazir-medium flex flex-col justify-between items-center border h-28  w-12 rounded-xl py-2 
                    ${day.numDay == dateToObject.day ? 'active-date-toady-activities' : null}
  
                     cursor-pointer`}
                onClick={() => {
                  dateObjectFunction(
                    persianToGregorian(`${day.numYear}/${day.numMonth}/${day.numDay}`),
                    day.numYear,
                    day.numMonth,
                    day.numDay
                  )
                }}
              >
                <span className="text-gray-400  text-[10px]">{day.name}</span>
                <span className="text-gray-500">{day.numDay}</span>
              </section>
            ))}
        </div>
      </div>
    </React.Fragment>
  )
}
export default DateWeek
