import { FaPencilAlt } from 'react-icons/fa'

function NutritionalValue({ data }) {
  return (
    <>
      {' '}
      <div className="w-full px-4  pt-5 bg-blue-50 pb-20">
        <p className="text-gray-800 w-full">ارزش غذایی:</p>
        <div className="w-full flex justify-between items-center my-3">
          <span className="text-sm text-gray-600">میزان واحد</span>
          <span className="text-sm text-blue-700 flex justify-between items-center">
            در هر ۱۰۰ گرم
            <FaPencilAlt className="mr-2" />
          </span>
        </div>

        {data &&
          JSON.parse(data).map((dta) => (
            <div key={dta.id} className="w-full flex justify-between items-center my-3">
              <span className="text-sm text-gray-600">{dta.material}</span>
              <span className="text-sm text-gray-800">
                {dta.count} {dta.unit}
              </span>
            </div>
          ))}
      </div>
    </>
  )
}
export default NutritionalValue
