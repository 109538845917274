import HeaderAlbum from '../../album/photo-album/component/header'

import { VscSearch, VscSettings } from 'react-icons/vsc'
import { TfiLocationPin } from 'react-icons/tfi'
import { useEffect, useRef, useState } from 'react'
import { AiFillStar, AiOutlineHeart } from 'react-icons/ai'
import { SlArrowDown } from 'react-icons/sl'

import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import {
  Domain,
  LocationAPI,
  LocationBannerAPI,
  LocationCategoryAPI,
  LocationCityAPI,
  LocationSectionAPI
} from '../../../../../api'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade, Pagination } from 'swiper'
import StarsRating from 'react-star-rate'
function MainPlace() {
  const navigate = useNavigate()
  const [groupingIdState, setGroupingIdState] = useState('')
  const [bannerList, setBannerList] = useState([])
  const [search, setSearch] = useState('')
  const [categoryList, setCategoryList] = useState([])
  const [sectionList, setSectionList] = useState([])
  const [cityList, setCityList] = useState([])
  const [city, setCity] = useState({})
  const [showCity, setShowCity] = useState(false)
  const [lists, setLists] = useState([])
  const [page, setPage] = useState(1)
  const [showStory, setShowStory] = useState(false)

  const [width, setWidth] = useState(0) // Starting width at 0%
  const listInnerRef = useRef()
  const getBanner = () => {
    axios
      .get(LocationBannerAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setBannerList(data)
      })
  }
  const getCategory = () => {
    axios
      .get(LocationCategoryAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCategoryList(data)
      })
  }
  const getCity = () => {
    axios
      .get(LocationCityAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setCityList(data)
      })
  }
  const getSection = () => {
    axios
      .get(LocationSectionAPI, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setSectionList(data)
      })
  }

  const getLocations = (Page = page, CatId = groupingIdState, City = city, Search = search) => {
    axios
      .get(
        LocationAPI + `/user/list?catId=${CatId}&cityId=${City?.id}&page=${Page}&search=${Search}`,
        {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        }
      )
      .then((res) => res.data)
      .then((data) => {
        if (Page === 1) {
          setLists(data)
        } else {
          setLists([...lists, ...data])
        }
      })
  }

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current

      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
        getLocations(page + 1, groupingIdState, city, search)
      }
    }
  }

  useEffect(() => {
    getBanner()
    getCategory()
    getSection()
    getCity()
  }, [])
  useEffect(() => {
    getLocations()
  }, [groupingIdState, city, search, page])

  useEffect(() => {
    // Calculate the increment per frame assuming 60 fps
    const incrementPerFrame = 100 / (30 * 60)
    const interval = setInterval(() => {
      setWidth((prevWidth) => {
        // Increase width by increment
        const nextWidth = prevWidth + incrementPerFrame
        // If the next width is greater than 100, set it to 100 and clear the interval
        if (nextWidth >= 100) {
          setShowStory(false)
          clearInterval(interval)
          return 100
        }
        return nextWidth
      })
    }, 1000 / 60) // Update approximately every 16.67ms (60 frames per second)

    // Clear interval on unmount
    return () => clearInterval(interval)
  }, [])

  const [storyObject, setStoryObject] = useState({
    picture: '',
    rate: 0
  })
  return (
    <>
      {showStory == true && (
        <div
          onClick={() => {
            setShowStory(false)
            setWidth(0)
            setStoryObject({
              picture: '',
              rate: 0
            })
          }}
          className="w-full fixed top-0 left-0 z-[500001] h-[100vh] bg-[rgba(0,0,0,0.7)] flex flex-col justify-between items-center py-10 px-4"
        >
          <span className="w-full block h-[10px] bg-[rgba(255,255,255,0.4)] rounded-2xl">
            <span
              className=" block h-full bg-white rounded-2xl "
              style={{
                width: `${width}%`
              }}
            />
          </span>
          <div
            className="w-full h-72 relative py-4 z-[999] rounded-2xl"
            style={{
              backgroundImage: `url("${storyObject?.picture}")`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center'
            }}
          />

          <div className="w-full flex justify-center items-center">
            <StarsRating
              disabled={true}
              defaultValue={storyObject?.rate}
              value={storyObject?.rate}
              classNamePrefix="text-xs"
              direction="rtl"
              allowHalf={false}
            />
          </div>
        </div>
      )}
      {showCity && (
        <div
          className="w-full h-[100vh] z-[99] fixed top-0 left-0 "
          onClick={() => {
            setShowCity(false)
          }}
        />
      )}
      <HeaderAlbum title="مکان گردشگری" />
      <div className="relative">
        <div className="w-10/12 grid grid-cols-4 gap-1 z-[100]  absolute top-20 left-10  h-[150px] overflow-hidden overflow-y-scroll">
          {showCity && (
            <>
              <p
                className="bg-white  rounded-lg text-xs text-center py-2 h-[40px] z-[100] relative"
                onClick={() => {
                  setCity('')
                  setShowCity(false)
                  getLocations(1, groupingIdState, '', search)
                  setPage(1)
                }}
              >
                همه
              </p>
              {cityList.map((ci) => (
                <p
                  key={ci.id}
                  className="bg-white  rounded-lg text-xs text-center py-2 h-[40px] z-[100] relative"
                  onClick={() => {
                    setCity(ci)
                    setShowCity(false)
                    getLocations(1, groupingIdState, ci, search)
                    setPage(1)
                  }}
                >
                  {ci?.title}
                </p>
              ))}
            </>
          )}
        </div>
        <div className="w-full flex justify-start items-center px-4 z-40 absolute top-5">
          <section className="w-11/12 relative">
            <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
            <input
              className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
              placeholder="عنوان عکس خود را جستجو کنید..."
              onChange={(e) => {
                setSearch(e.target.value)
                getLocations(1, groupingIdState, city, e.target.value)
                setPage(1)
              }}
            />
          </section>
          <section className="w-12 h-12 flex justify-center items-center card-shadow rounded-lg mr-2 bg-white z-40">
            <VscSettings className="text-2xl text-gray500" />
          </section>
        </div>
      </div>
      <div className="w-full">
        <Swiper
          effect="fade" // Enable fade effect
          fadeEffect={{
            crossFade: true // Enable crossfade for smooth transition
          }}
          loop={true} // Enables infinite loop mode
          autoplay={{
            delay: 3000 // Sets the delay between transitions (in ms)
          }}
          modules={[Autoplay, Pagination, EffectFade]}
          spaceBetween={10}
          slidesPerView={1}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return `
        <span class='${className}   slider-navigation ${
                index === this.activeIndex ? 'slider-active' : ''
              }'></span>
      `
            }
          }}
        >
          {bannerList.map((ban) => (
            <SwiperSlide key={ban.id} className="rounded-xl px-0.5 py-2">
              <div
                key={ban.id}
                className="w-full h-72 relative py-4 z-[999]"
                style={{
                  backgroundImage: `url("${Domain + ban.picture}")`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              >
                <section
                  className="w-full h-full bg-[rgba(0,0,0,0.4)] absolute top-0 left-0 rounded-t-xl"
                  onClick={() => {
                    navigate('/detail-place?id=' + ban?.location?.id)
                  }}
                />

                <div className="w-full flex justify-between items-center z-50 absolute bottom-12 px-4">
                  <div className="flex justify-start items-center">
                    <TfiLocationPin className="text-white text-xl ml-2" />
                    <span className="text-white">{ban.title}</span>
                  </div>

                  <div
                    className="flex justify-start items-center relative z-[50]  "
                    onClick={() => {
                      setShowCity(true)
                    }}
                  >
                    <SlArrowDown className="text-white text-xl ml-2" />
                    <span className="text-white">{city?.title ? city?.title : 'همه'}</span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="sticky top-7 z-[500000] ">
        <div className="flex-container border-b-2 border-b-gray-200 pb-2 -top-8 bg-white pt-4 rounded-t-2xl relative z-50  ">
          <div className="scrollable-content relative">
            <div
              className={`w-24 flex justify-center items-center opacity-70 cursor-pointer ${
                groupingIdState === '' ? 'grouping-active' : ''
              }`}
              onClick={() => {
                setGroupingIdState('')
                getLocations(1, '', city, search)
                setPage(1)
              }}
            >
              <span>همه</span>
            </div>
            {categoryList.map((cat) => (
              <div
                key={cat.id}
                className={`w-24 flex justify-center items-center opacity-70 cursor-pointer ${
                  groupingIdState === cat.id ? 'grouping-active' : ''
                }`}
                onClick={() => {
                  setGroupingIdState(cat.id)
                  getLocations(1, cat.id, city, search)
                  setPage(1)
                }}
              >
                <span>{cat.title}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {groupingIdState == '' &&
        sectionList.map((sec, index) => {
          if (index == 0) {
            return (
              <Swiper spaceBetween={10} slidesPerView={1} key={sec.id}>
                <div>
                  <div className="w-full flex justify-between items-center px-4 mt-8 mb-4"></div>

                  <Swiper spaceBetween={10} slidesPerView={2.1}>
                    {sec?.location?.map((loc) => (
                      <SwiperSlide key={loc.id} className="rounded-xl px-2 py-2">
                        <div className="w-full  ">
                          <section
                            className="w-full relative rounded-xl h-48"
                            style={{
                              backgroundImage: `url("${Domain + loc.picture1}")`,
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center'
                            }}
                          >
                            <AiOutlineHeart className="text-white text-3xl absolute top-3 left-3 hidden" />
                            <button className="px-2 py-1 rounded-lg bg-[#3F54CB] flex justify-between absolute top-3 left-3 ">
                              <AiFillStar className="text-white" />
                              <span className="text-white mr-1 text-xs">
                                {' '}
                                {loc.commentCount == 0
                                  ? 0
                                  : Math.round(loc.rateCount / loc.commentCount)}
                              </span>
                            </button>
                          </section>
                          <p className="text-gray-700 text-sm my-2">{loc?.category?.title}</p>
                          <div className="w-full flex justify-between items-center flex-wrap">
                            <p
                              className="w-full"
                              onClick={() => {
                                navigate('/detail-place?id=' + loc?.id)
                              }}
                            >
                              {loc.title}
                            </p>
                            <div className="w-full flex justify-between items-center mt-4"></div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </Swiper>
            )
          }
          if (sec.isLand == true) {
            return (
              <Swiper spaceBetween={10} slidesPerView={2.6} key={sec.id}>
                <div>
                  <div className="w-full flex justify-between items-center px-4 mt-8 mb-4"></div>

                  <Swiper spaceBetween={10} slidesPerView={3.5}>
                    {sec?.location?.map((loc) => (
                      <SwiperSlide
                        key={loc.id}
                        className="rounded-xl px-2 py-2 relative"
                        onClick={() => {
                          setShowStory(true)
                          setWidth(0)
                          setStoryObject({
                            picture: Domain + loc.picture1,
                            rate:
                              loc.commentCount == 0
                                ? 0
                                : Math.round(loc.rateCount / loc.commentCount)
                          })
                        }}
                      >
                        <div className="w-full relative">
                          <div className="w-full z-20 bg-[rgba(0,0,0,0.4)] top-0 left-0 absolute rounded-xl h-52 " />
                          <section
                            className="w-full relative z-10 rounded-xl h-52 outline outline-offset-2 outline-blue-500 border-2 border-white"
                            style={{
                              backgroundImage: `url("${Domain + loc.picture1}")`,
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center'
                            }}
                          ></section>
                          <p className="text-gray-700 text-sm my-2">{loc?.category?.title}</p>
                          <div className="w-full flex justify-between items-center flex-wrap">
                            <p
                              className="w-full"
                              onClick={() => {
                                navigate('/detail-place?id=' + loc?.id)
                              }}
                            >
                              {loc.title}
                            </p>
                            <div className="w-full flex justify-between items-center mt-4"></div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </Swiper>
            )
          } else {
            return (
              <div key={sec.id}>
                <div className="w-full flex justify-between items-center px-4 mt-8 mb-4">
                  <p className="text-sm text-gray-800">{sec.title}</p>
                </div>

                <Swiper spaceBetween={10} slidesPerView={2.1}>
                  {sec?.location?.map((loc) => (
                    <SwiperSlide key={loc.id} className="rounded-xl px-2 py-2">
                      <div className="w-full  ">
                        <section
                          className="w-full relative rounded-xl h-48"
                          style={{
                            backgroundImage: `url("${Domain + loc.picture1}")`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                          }}
                        >
                          <AiOutlineHeart className="text-white text-3xl absolute top-3 left-3 hidden" />
                          <button className="px-2 py-1 rounded-lg bg-[#3F54CB] flex justify-between absolute top-3 left-3 ">
                            <AiFillStar className="text-white" />
                            <span className="text-white mr-1 text-xs">
                              {' '}
                              {loc.commentCount == 0
                                ? 0
                                : Math.round(loc.rateCount / loc.commentCount)}
                            </span>
                          </button>
                        </section>
                        <p className="text-gray-700 text-sm my-2">{loc?.category?.title}</p>
                        <div
                          className="w-full flex justify-between items-center flex-wrap"
                          onClick={() => {
                            navigate('/detail-place?id=' + loc?.id)
                          }}
                        >
                          <p className="w-full">{loc.title}</p>
                          <div className="w-full flex justify-between items-center mt-4"></div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )
          }
        })}

      {groupingIdState !== '' && (
        <div
          className="w-full grid grid-cols-2 gap-1 px-2  h-screen overflow-hidden overflow-y-scroll  "
          onScroll={onScroll}
          ref={listInnerRef}
        >
          {groupingIdState !== '' &&
            lists.map((ban) => (
              <div
                key={ban.id}
                className="w-full h-72   py-4 z-[999] rounded-2xl shadow-lg relative"
                style={{
                  backgroundImage: `url("${Domain + ban.picture1}")`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              >
                <button className="px-2 py-1 rounded-lg bg-[#3F54CB] flex justify-between top-2 left-2 absolute">
                  <AiFillStar className="text-white" />
                  <span className="text-white mr-1 text-xs">
                    {ban.commentCount == 0 ? 0 : Math.round(ban.rateCount / ban.commentCount)}
                  </span>
                </button>
                <div className="w-full flex justify-between items-center z-50 absolute bottom-0 pb-3 px-1 bg-gradient-to-t from-[rgba(0,0,0,0.6)] rounded-b-2xl ">
                  <div
                    className="flex justify-start items-center"
                    onClick={() => {
                      navigate('/detail-place?id=' + ban?.id)
                    }}
                  >
                    <TfiLocationPin className="text-white text-xl ml-1" />
                    <span className="text-white text-xs">{ban.title}</span>
                  </div>

                  <div
                    className="flex justify-start items-center relative z-[50]  "
                    onClick={() => {
                      setShowCity(true)
                    }}
                  >
                    <span className="text-white text-sm">{ban?.city?.title}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  )
}

export default MainPlace
