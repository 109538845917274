function calculateDiscount(offerType, offerAmount, amount) {
  if (offerType === 'درصدی') {
    const discount = (offerAmount / 100) * amount
    return amount - discount
  } else if (offerType === 'مبلغی') {
    return amount - offerAmount
  } else {
    return amount
  }
}
export default calculateDiscount
