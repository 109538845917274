import { MdArrowForwardIos } from 'react-icons/md'
import { GrAttachment } from 'react-icons/gr'
import { useLocation, useNavigate } from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize'
import { IoIosSend } from 'react-icons/io'
import { useEffect, useRef, useState } from 'react'
import './chat-ticket.css'
import axios from 'axios'
import { Domain, TicketAPI } from '../../../api'
import ConvertTime from '../../../convert-time'
import returnTime from '../../../convert-time'
import { useSelector } from 'react-redux'
import { io } from 'socket.io-client'

function ChatTicket() {
  let socket = io(`${Domain}chat`, {
    query: { id: localStorage.getItem('token') },
    extraHeaders: {
      Authorization: `Brear ${localStorage.getItem('token')}`
    }
  })
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const chatPageRef = useRef(null)
  const input = useRef(null)
  const chatUserRef = useRef(null)
  const chatScroll = useRef(null)
  const [page, setPage] = useState(1)
  const info = useSelector((state) => state.information)
  const [textBoxBlur, setTextBuxBlur] = useState(false)
  const [message, setMessage] = useState('')
  const [ticketDetail, setTicketDetail] = useState({})
  const [messageList, setMessageList] = useState([])
  const [roomId, setRoomId] = useState('')
  const scrollToTop = () => {
    const topRef = chatPageRef.current
    if (topRef) {
      topRef.scrollIntoView({ behavior: 'smooth' })
    }
  }
  const getRoom = (ticketId) => {
    socket.emit('getRoom', {
      ticketId: ticketId
    })

    socket.on('getRoom', (room) => {
      setRoomId(room.roomId)
      joinRoom(room.roomId)
      getLastMessage(room.roomId)
    })
  }
  const joinRoom = (room) => {
    socket.emit('join', {
      roomId: room
    })
  }
  const getLastMessage = (room) => {
    socket.emit('messages', {
      chatRoomId: room,
      ticketId: ticketDetail?.id ? ticketDetail.id : null,
      page: page
    })
    socket.on('messages', (messages) => {
      if (page === 1) {
        setMessageList(messages.messages)
      } else {
        setMessageList([...messageList, messages.messages])
      }
      scrollToTop()
    })
  }
  const create = () => {
    if (message.trim().length > 1) {
      socket.emit('create', {
        ticketId: ticketDetail.id,
        text: message,
        file: null,
        chatRoomId: roomId,
        senderId: info.id,
        receiverId: ticketDetail?.admin?.id,
        senderType: info.role,
        receiverType: ticketDetail?.admin?.role,
        page: page
      })
    }
  }
  socket.on('create', (messages) => {
    if (page === 1) {
      setMessageList(messages.messages)
    } else {
      setMessageList([...messageList, messages.messages])
    }
    scrollToTop()
    setMessage('')
    input.current.value = ''
  })
  const getTicketDetail = () => {
    axios
      .get(TicketAPI + '/' + id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setTicketDetail(data)

        getRoom(data?.id)
      })
  }

  useEffect(() => {
    getTicketDetail()
  }, [page])

  const onScroll = () => {
    if (chatScroll.current) {
      const { scrollTop } = chatScroll.current
      if (scrollTop === 0 && messageList?.length % 300 === 0) {
        setPage(page + 1)
      }
    }
  }

  const updateTicket = () => {
    const formData = new FormData()
    formData.append('answer', 'false')
    axios
      .patch(TicketAPI + '/' + ticketDetail?.id, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        navigate('/contact-support')
      })
  }

  return (
    <div className="chat-body">
      <header className="flex justify-between items-center px-4  vazir-medium shadow-md pb-4 sticky top-0 w-full bg-white z-10">
        <div className="flex justify-start items-center mt-2">
          <MdArrowForwardIos
            className="text-2xl text-blue-700"
            onClick={() => {
              navigate('/?status=home')
            }}
          />
          {ticketDetail?.admin?.profile ? (
            <>
              <section
                className="w-16 h-16 rounded-full ml-2 block mt-2"
                style={{
                  backgroundImage: `url("${Domain + ticketDetail?.admin?.profile}")`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              />
              <section className="flex flex-col">
                <span className="text-sm">{ticketDetail?.admin?.name}</span>
                <span className="text-xs text-gray-400">
                  {ticketDetail?.admin?.role == 'admin' ? 'ادمین' : 'مدیر'}
                </span>
              </section>
            </>
          ) : (
            !ticketDetail?.admin?.profile &&
            ticketDetail.answer == 'true' && (
              <div className="w-full flex justify-center items-center">
                <span className="text-sm text-gray-500">تیکت هنوز بررسی نشده</span>
              </div>
            )
          )}
        </div>
        <div className="flex justify-start items-center mt-2">
          {ticketDetail.answer == 'true' ? (
            <p
              className="bg-gray-300 text-sm px-4 py-2 rounded-lg"
              onClick={() => {
                updateTicket()
              }}
            >
              بستن تیکت
            </p>
          ) : (
            <div className="w-full flex justify-center items-center">
              <span className="text-sm text-gray-500">تیکت بسته است</span>
            </div>
          )}
        </div>
      </header>

      <div className="w-full vazir-medium">
        <div className="chat-page" ref={chatUserRef}>
          <div
            className="w-full vazir-medium text-xs px-4 mt-5"
            ref={chatScroll}
            onScroll={onScroll}
          >
            <section className="w-full flex flex-col justify-between items-start border border-gray-300 shadow rounded-xl px-4">
              <section className="flex justify-start items-center mt-5">
                <p className="ml-5">دپارتمان:</p>
                <p>{ticketDetail.department}</p>
              </section>
              <section className="flex justify-start items-center my-3">
                <p className="ml-5">اولویت:</p>
                <p>{ticketDetail.order}</p>
              </section>
              <section className="flex justify-start items-center mb-5">
                <p className="ml-5">عنوان:</p>
                <p>{ticketDetail.title}</p>
              </section>
            </section>
          </div>
          <div className="w-9/12 vazir-medium px-6">
            <p className="w-fit px-3 bg-gray-200 rounded-lg text-gray-700 mt-4 py-2 chat-triangel relative text-sm">
              {ticketDetail.text}
            </p>
            <span className="text-[10px] text-gray-400">
              {ConvertTime(ticketDetail.created_at).split(', ')[1]}
            </span>
          </div>
          {messageList &&
            messageList.map((msg) => {
              if (msg.sender === info.id) {
                return (
                  <div className="w-10/12 vazir-medium px-3 sender" key={msg.id}>
                    <p className="px-3 w-fit bg-gray-200 rounded-lg text-gray-700 mt-4 py-3 chat-triangel relative">
                      {msg.text}
                    </p>
                    <span className="text-[10px] text-gray-400">
                      {returnTime(msg.created_at).split(',')[1]}
                    </span>
                  </div>
                )
              } else {
                return (
                  <div className="w-full flex justify-end reciver" key={msg.id}>
                    <div className="w-10/12 vazir-medium px-3 dir-ltr">
                      <p className="px-3 w-fit bg-blue-700 text-white rounded-lg mt-4 py-3 chat-triangel relative">
                        {msg.text}
                      </p>
                      <span className="text-[10px] text-gray-400">
                        {returnTime(msg.created_at).split(',')[1]}
                      </span>
                    </div>
                  </div>
                )
              }
            })}
          <div ref={chatPageRef}></div>
        </div>
        {ticketDetail.answer == 'true' ? (
          <div className="w-full flex justify-between items-center px-4 fixed bottom-0 bg-white">
            <div className={`relative ${textBoxBlur || message.length > 0 ? 'w-full' : ' w-full'}`}>
              <TextareaAutosize
                ref={input}
                className="bg-blue-100 w-full min-h-[50px] pr-10 rounded-[30px] pt-3"
                placeholder="پیام خود را بنویسید"
                onFocus={() => setTextBuxBlur(true)}
                onBlur={() => setTextBuxBlur(false)}
                onChange={(e) => setMessage(e.target.value)}
              />
              {textBoxBlur || message.length > 0 ? (
                <IoIosSend
                  className="text-blue-800 absolute top-3 right-2 text-2xl"
                  onClick={() => create()}
                />
              ) : (
                <>
                  <input type="file" className="hidden" id="attachment" />
                  <label htmlFor="attachment">
                    <GrAttachment className="text-gray-600 absolute top-3 left-5 text-2xl  " />
                  </label>
                </>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ChatTicket
