import { useEffect, useState } from 'react'
import Header from '../../components/detail-header'
import PlayVideo from './svg/playicon.svg'
import VideoBox from './component/video-box'
import EducationalTools from './component/educational-tools'
import './post-detail.css'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import {
  CreateLikePost,
  CreateSavePost,
  CreateViewPost,
  Domain,
  GetLikePost,
  PostAPI
} from '../../../../api'
import { toast } from 'react-toastify'
import returnTime from '../../../../convert-time'
import { useSelector } from 'react-redux'
import Loading from '../../../../component/loading'
import {
  ControlBar,
  CurrentTimeDisplay,
  ForwardControl,
  PlaybackRateMenuButton,
  Player,
  ReplayControl,
  TimeDivider,
  VolumeMenuButton
} from 'video-react'

function PostDetail() {
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const childInfo = useSelector((state) => state.childInfo)
  const [post, setPost] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [postLike, setPostLike] = useState(0)
  const [postView, setPostView] = useState(0)
  const [liked, setLiked] = useState(false)
  const [save, setSave] = useState(false)
  const getPostDetail = () => {
    setLoading(true)
    axios
      .get(PostAPI + id + `?id=${childInfo?.id}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setLoading(false)
        setPost(data.data)
        setPostView(data.data.numViews)
        setPostLike(data.data.numLikes)
        setLiked(data.liked)
        setSave(data.save)
      })
      .catch((e) => {
        setLoading(false)
        toast.error(e.response.data.message)
      })
  }
  const getPostLike = () => {
    axios
      .get(GetLikePost + `?id=${id}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setPostLike(data.numLikes)
      })
  }

  const createPostSave = () => {
    const formData = new FormData()
    formData.append('child_id', childInfo.id)
    formData.append('post_id', id)

    axios
      .post(CreateSavePost, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        getPostLike()
      })
      .catch(() => {
        getPostLike()
      })
  }
  const createPostLike = () => {
    const formData = new FormData()
    formData.append('child_id', childInfo.id)
    formData.append('post_id', id)

    axios
      .post(CreateLikePost, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setPostLike(data)
        getPostLike()
      })
      .catch(() => {
        getPostLike()
      })
  }
  const createPostView = () => {
    const formData = new FormData()
    formData.append('child_id', childInfo.id)
    formData.append('post_id', id)

    axios
      .post(CreateViewPost, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {})
      .catch(() => {})
  }
  useEffect(() => {
    getPostDetail()
    createPostView()
  }, [])
  const AgeRange = (daysDifference) => {
    if (daysDifference <= 180) {
      return 'نوزاد'
    } else if (daysDifference <= 720) {
      return 'نوپا'
    } else if (daysDifference <= 2520) {
      return 'خردسال'
    }
  }

  return (
    <>
      <Header title="جزییات فعالیت" />
      {loading ? (
        <Loading color="#1e40af" width="100px" />
      ) : (
        <>
          <div className="w-full px-5 mb-10">
            {showModal ? (
              <div className="w-full rounded-2xl overflow-hidden">
                <div
                  className="w-screen h-screen left-0 top-0 fixed z-[98] bg-[rgba(0,0,0,0.5)] "
                  onClick={() => setShowModal(false)}
                />

                <Player autoPlay className="z-[98]   dir-ltr  ">
                  <source src={Domain + post.video} className="z-[98]   dir-ltr" />

                  <ControlBar autoHide={false}>
                    <ReplayControl seconds={10} order={1.1} />
                    <ForwardControl seconds={30} order={1.2} />
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />

                    <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                    <VolumeMenuButton disabled />
                  </ControlBar>
                </Player>
              </div>
            ) : (
              <section className="w-full relative mt-6">
                <section className="absolute w-full h-full bg-black opacity-60 rounded-2xl" />
                <img
                  className="w-full h-full rounded-2xl "
                  src={Domain + post.poster}
                  alt={post.title}
                />
                <section
                  onClick={() => setShowModal(true)}
                  className=" w-14 absolute top-1/2 right-1/2 translate-x-1/2 -translate-y-1/2 cursor-pointer bg-white p-4 rounded-full"
                >
                  <img src={PlayVideo} alt="play video" className="w-10" />
                </section>
              </section>
            )}

            <div className="px-4 flex justify-center items-center">
              <VideoBox
                id={post.id}
                save={save}
                SaveClick={createPostSave}
                OnClick={createPostLike}
                title={post.title}
                code={post.code}
                like={postLike}
                view={postView}
                time={returnTime(post.created_at).split(',')[0]}
                ageDetail={`  رده سنی ${AgeRange(post.age)}
           
             `}
                liked={liked}
                setLiked={() => {
                  setLiked(!liked)
                }}
              />
            </div>
            <div className="px-4 vazir-medium">
              <p className="text-gray-700">توضیح فعالیت</p>

              <div
                className="text-sm text-gray-400 mt-4 text-justify"
                dangerouslySetInnerHTML={{
                  __html: post.text
                }}
              />
            </div>
            <div className="px-4 vazir-medium mt-6">
              <p className="text-gray-700">ابزارهای آموزشی</p>
              <div className="flex flex-wrap justify-start items-center mt-4">
                {post.tools &&
                  JSON.parse(JSON.parse(post.tools)).map((tool) => (
                    <EducationalTools
                      key={tool}
                      text={tool}
                      className="border-blue-500 text-blue-500"
                    />
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default PostDetail
