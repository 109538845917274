import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { BsSearch } from 'react-icons/bs'
import { useEffect, useRef, useState } from 'react'
import './notification.css'
import chooseBox from '../../components/choose-box'
import CoachNotificationItem from './component/coach'
// import OfferNotificationItem from './component/offer'
import axios from 'axios'
import { NotificationAPI } from '../../../../api'
import { useSelector } from 'react-redux'
function Notification() {
  const navigate = useNavigate()
  const childInfo = useSelector((state) => state.childInfo)
  const [status, setStatus] = useState('')
  const [notif, setNotif] = useState([])
  const [page, setPage] = useState(1)
  const listInnerRef = useRef()
  const getNotification = () => {
    axios
      .get(NotificationAPI + 'all/' + childInfo?.id + `?sortBy=${status}&page=${page}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        if (page === 1) {
          setNotif(data)
        } else {
          setNotif([...notif, ...data])
        }
      })
  }
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current

      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }
  useEffect(() => {
    getNotification()
  }, [status, page])
  // const StatusComponent = () => {
  //   if (status === '') {
  //     return <span></span>
  //   }
  //   if (status === 'personal') {
  //     return <span>personal</span>
  //   }
  //   if (status === 'coach') {
  //     return <CoachNotificationItem />
  //   }
  //   if (status === 'general') {
  //     return <span>general</span>
  //   }
  //   if (status === 'offer') {
  //     return <OfferNotificationItem />
  //   }
  // }
  return (
    <>
      <div className="w-full">
        <div className="w-full flex justify-between items-center">
          <section className="w-4/12 flex justify-start items-center">
            <div className="flex justify-center items-center mt-10 pb-4 relative px-2">
              <IoIosArrowForward
                className="text-3xl text-gray-600 border rounded   right-5"
                onClick={() => navigate(-1)}
              />
              <p className="vazir-medium mr-2 mt-1">اعلان ها</p>
            </div>
          </section>
          <section className="w-8/12 flex justify-end items-center px-2">
            <BsSearch className="text-xl mt-5 cursor-pointer" />
          </section>
        </div>
        <div className="w-full flex justify-start items-center border-b-2 border-b-gray-300 pb-3 text-sm">
          <div
            className="mx-2 status active"
            onClick={(e) => {
              chooseBox(e, 'status')
              setStatus('')
            }}
          >
            <span className="px-2">همه</span>
          </div>
          <div
            className="mx-2 status hidden"
            onClick={(e) => {
              chooseBox(e, 'status')
              setStatus('personal')
            }}
          >
            <span className="px-2">شخصی</span>
          </div>

          <div
            className="mx-2 status"
            onClick={(e) => {
              chooseBox(e, 'status')
              setStatus('admin')
            }}
          >
            <span className="px-2">ادمین</span>
          </div>
          <div
            className="mx-2 status"
            onClick={(e) => {
              chooseBox(e, 'status')
              setStatus('coach')
            }}
          >
            <span className="px-2">راهبر</span>
          </div>
          <div
            className="mx-2 status hidden"
            onClick={(e) => {
              chooseBox(e, 'status')
              setStatus('general')
            }}
          >
            <span className="px-2">عمومی</span>
          </div>
          <div
            className="mx-2 status hidden"
            onClick={(e) => {
              chooseBox(e, 'status')
              setStatus('offer')
            }}
          >
            <span className="px-2">تخفیفات</span>
          </div>
        </div>
        <div
          className="w-full  h-screen overflow-hidden overflow-y-scroll "
          onScroll={onScroll}
          ref={listInnerRef}
        >
          {notif.map((not) => (
            <CoachNotificationItem key={not.id} data={not} />
          ))}
        </div>
      </div>
    </>
  )
}
export default Notification
