import Header from '../../components/detail-header'
import Medal from './component/svg/star.svg'
import Gift from './component/gift'
function ReceiveGift() {
  return (
    <>
      <div>
        <Header title="دریافت جایزه" />
        <div className="w-full">
          <div className="w-full flex justify-start items-center px-4 pt-2">
            <div className="w-12 h-12 flex justify-center items-center rounded-full bg-yellow-400 border border-4 border-orange-400">
              <img src={Medal} alt="medal matyar" className="w-6 h-6" />
            </div>
            <section className="flex flex-col justify-between items-start text-sm mr-2">
              <span>
                <span className="text-gray-700">8</span>
                <span className="text-gray-600">امتیاز</span>
              </span>
              <span className="text-blue-600">امتیاز شما در ماتیار</span>
            </section>
          </div>
          <div className="px-4">
            <Gift />
          </div>
        </div>
      </div>
    </>
  )
}
export default ReceiveGift
