import { HiUser } from 'react-icons/hi'
import { Link } from 'react-router-dom'
function ActivitiesListItem({ done, color, data }) {
  return (
    <div className="w-6/12 p-1 overflow-hidden">
      <Link
        to={`/detail-activities?id=${data && data.post && data.post.id}&activity_id=${data.id}`}
      >
        <section
          className={`w-full border border-1 ${
            done ? 'border-red-200 bg-blue-50' : 'border-gray-200'
          }  rounded-xl flex justify-items-start items-center px-2 py-4`}
        >
          <section
            className={`rounded-xl w-10 h-10 relative flex justify-center items-center ml-3`}
          >
            <section
              className={`rounded-xl w-10 h-10 opacity-50 absolute top-0 left-0 `}
              style={{
                backgroundColor: color
              }}
            />
            <HiUser
              className={` text-2xl`}
              style={{
                color: color
              }}
            />
          </section>
          <section className="vazir-medium">
            <p className="text-sm text-gray-700">{data && data.post && data.post.title}</p>
            {done ? (
              <del
                className="text-xs text-gray-600 overflow-hidden w-24 h-4"
                dangerouslySetInnerHTML={{
                  __html: data && data.post && data.post.text
                }}
              />
            ) : (
              <p
                className="text-xs text-gray-600 overflow-hidden w-24 h-4"
                dangerouslySetInnerHTML={{
                  __html: data && data.post && data.post.text
                }}
              />
            )}
          </section>
        </section>
      </Link>
    </div>
  )
}

export default ActivitiesListItem
