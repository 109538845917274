import HeaderAlbum from '../../album/photo-album/component/header'
import StarsRating from 'react-star-rate'
import './radio.css'

function DoctorComment() {
  return (
    <>
      <HeaderAlbum title="ثبت نظر و امتیاز" />
      <div className="w-full flex  flex-col justify-center items-center py-5">
        <section
          className="w-40 h-40 rounded-full my-5"
          style={{
            backgroundImage: `url("https://media.istockphoto.com/id/1311511363/photo/headshot-portrait-of-smiling-male-doctor-with-tablet.jpg?s=612x612&w=0&k=20&c=w5TecWtlA_ZHRpfGh20II-nq5AvnhpFu6BfOfMHuLMA=")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />

        <p className="w-10/12 text-center text-md my-5">
          تجربه و نظر شما درباره جلسه با
          <span className="text-[#3F54CB] "> &nbsp; دکتر کاوه &nbsp;</span>
          چگونه بود
        </p>
        <div className="scale-150">
          <StarsRating direction="rtl" allowHalf={false} classNamePrefix="star-rating-comment" />
        </div>
        <span className="w-10/12 h-[0.5px] block bg-gray-300 my-5" />
        <p className="text-sm w-10/12 mb-2">نظر خود را بنویسید</p>
        <textarea
          className="border border-gray-300 rounded-xl h-24 mb-3 w-10/12 px-2 pt-2 text-xs"
          placeholder="تجربه و نظر خود را ثبت کنید"
        />

        <p className="text-sm w-10/12">
          آیا
          <span className="">&nbsp;دکتر کاوه&nbsp;</span>
          به دوستان خود پیشنهاد میدهید؟
        </p>
        <section className="flex flex-wrap justify-start items-center w-10/12 mt-3">
          <div className="flex  justify-start items-center ml-5">
            <input type="radio" id="yes" className="ml-2" name="accept-doc" />
            <label htmlFor="yes">بله</label>
          </div>

          <div className="flex   justify-start items-center">
            <input type="radio" id="no" className="ml-2" name="accept-doc" />
            <label htmlFor="no">خیر</label>
          </div>
        </section>

        <button className="w-10/12 flex justify-center items-center px-4 py-2 bg-[#3F54CB] text-white rounded-lg mt-2">
          <span>ثبت نظر و امتیاز</span>
        </button>
      </div>
    </>
  )
}
export default DoctorComment
