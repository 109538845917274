import HeaderAlbum from '../../album/photo-album/component/header'
import { VscSearch } from 'react-icons/vsc'
import { useEffect, useRef, useState } from 'react'
import Heart from '../detail/svg/Heart.svg'
import Comment from '../detail/svg/comment-green.svg'
import Show from '../detail/svg/Show.svg'
import { MdKeyboardArrowLeft } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ArticleAPI, ArticleSubsetAPI, Domain } from '../../../../../api'
import ConvertTime from '../../../../../convert-time'

function ListArticles() {
  const navigate = useNavigate()
  const [subsetList, setSubsetList] = useState([])
  const [Data, setData] = useState([])
  const [subsetTarget, setSubsetTarget] = useState('')

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const catId = searchParams.get('catId')
  const catName = searchParams.get('catName')
  const [page, setPage] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [search, setSearch] = useState('')
  const listInnerRef = useRef()
  const getAllSubset = () => {
    axios
      .get(ArticleSubsetAPI + `/category?catId=${catId}`, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setSubsetList(data)
      })
  }

  const getAllArticles = (sub = subsetTarget, Search = search, Page = page) => {
    axios
      .get(
        ArticleAPI +
          `/list/subset?catId=${catId}&subId=${sub}&search=${Search}&page=${Page}&limit=20`,
        {
          headers: {
            Authorization: `Brear ${localStorage.getItem('token')}`
          }
        }
      )
      .then((res) => res.data)
      .then((data) => {
        if (Page === 1) {
          setData(data)
        } else {
          setData([...Data, ...data])
        }
      })
  }

  useEffect(() => {
    getAllSubset()
    getAllArticles()
  }, [])

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        setPage((prevPage) => prevPage + 1)
        getAllArticles(subsetTarget, search, page + 1)
      }
    }
  }
  return (
    <>
      <HeaderAlbum title={catName} />
      <div className="w-full flex justify-start items-center px-4">
        <section className="w-full relative">
          <VscSearch className="text-2xl absolute right-2 top-3 text-gray-400" />
          <input
            className="w-full px-4 py-3 rounded-lg text-sm card-shadow pr-10 h-12"
            placeholder="مقاله خود را جستجو کنید..."
            onChange={(e) => {
              getAllArticles(subsetTarget, e.target.value, 1)
              setPage(1)
              setSearch(e.target.value)
            }}
          />
        </section>
      </div>
      <div className="sticky top-0 bg-white  ">
        <div className="flex-container border-b-2 border-b-gray-200 pb-2 mt-10  ">
          <div className="scrollable-content">
            <div
              className={`  w-28 flex justify-center items-center opacity-70 cursor-pointer ${
                subsetTarget === '' ? 'grouping-active' : ''
              }`}
              onClick={() => {
                setSubsetTarget('')
                getAllArticles('', search, 1)
                setPage(1)
              }}
            >
              <span>همه</span>
            </div>

            {subsetList.length > 0 &&
              subsetList.map((sub) => (
                <div
                  key={sub.id}
                  className={` w-28  flex justify-center items-center opacity-70 cursor-pointer ${
                    subsetTarget === sub.id ? 'grouping-active' : ''
                  }`}
                  onClick={() => {
                    getAllArticles(sub.id, search, 1)
                    setPage(1)
                    setSubsetTarget(sub.id)
                  }}
                >
                  <span> {sub.title}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div
        className="w-full px-4 mt-4 h-screen overflow-hidden overflow-y-scroll "
        ref={listInnerRef}
        onScroll={onScroll}
      >
        {Data.length > 0 &&
          Data.map((dta) => (
            <div key={dta.id} className="w-full px-4 py-6 bg-gray-100 rounded-3xl my-2">
              <div className="w-full flex justify-start items-center">
                <span
                  className="w-24 h-24  ml-5 rounded-xl"
                  style={{
                    backgroundImage: `url("${Domain + dta.picture}")`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                  }}
                />
                <div className="flex flex-col justify-between items-start w-10/12">
                  <p className="text-xs w-full  ">{dta.title}</p>
                  <section className="w-full flex justify-start items-center mt-4">
                    <div className="flex justify-start items-center ml-5">
                      <img src={Heart} alt="show icon matyar" className="w-5 h-5 ml-2" />
                      <span className="text-gray-700 text-xs">{dta.rateCount}</span>
                    </div>

                    <div className="flex justify-start items-center ml-5">
                      <img src={Comment} alt="show icon matyar" className="w-5 h-5 ml-2" />
                      <span className="text-gray-700 text-xs">{dta.commentCount}</span>
                    </div>
                    <div className="flex justify-start items-center ml-5">
                      <img src={Show} alt="show icon matyar" className="w-5 h-5 ml-2" />
                      <span className="text-gray-700 text-xs">{dta.viewCount}</span>
                    </div>
                  </section>
                </div>
              </div>
              <div className="w-full flex justify-between items-center mt-2">
                <span className="text-xs text-gray-600">
                  {ConvertTime(dta.created_at).split(', ')[0]}
                </span>
                <section
                  className="flex justify-end items-center text-[#3F54CB]"
                  onClick={() => {
                    navigate('/detail-articles?id=' + dta.id)
                  }}
                >
                  <span className="text-xs">مطالعه مقاله</span>
                  <MdKeyboardArrowLeft />
                </section>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}
export default ListArticles
