import Header from '../../components/detail-header'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { MdArrowBackIos } from 'react-icons/md'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { BookmarkAPI, Domain } from '../../../../api'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loading from '../../../../component/loading'

function ActivitySaved() {
  const childInfo = useSelector((state) => state.childInfo)
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const type = searchParams.get('type')
  const [loading, setLoading] = useState(false)
  const [saveItem, setSaveItem] = useState([])
  const getSaveItemByType = () => {
    axios
      .get(BookmarkAPI + '/child/type?type=' + type + '&id=' + childInfo?.id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setSaveItem(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const deleteItemBookmark = (id) => {
    axios
      .delete(BookmarkAPI + '/' + id, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then(() => {
        getSaveItemByType()
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    getSaveItemByType()
  }, [])
  return (
    <>
      <div>
        <Header title="ذخیره شده ها" />
        <div className="w-full">
          {loading ? (
            <Loading width="100px" color="#1e40af" />
          ) : (
            <div className="w-full px-2">
              {Array.isArray(saveItem) &&
                saveItem.map((si) => (
                  <section
                    key={si.id}
                    className="w-full flex flex-wrap justify-start items-start border-b border-gray-300 pt-5"
                  >
                    <div className="w-4/12">
                      <section
                        className="w-24 h-24 bg-gray-300 rounded-xl"
                        style={{
                          backgroundImage: `url("${
                            Domain + (si.picture ? si.picture : si.cover)
                          }")`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center'
                        }}
                      />
                    </div>
                    <div className="w-8/12">
                      <p className="text-sm text-gray-700 mb-2">{si.title}</p>
                      <p
                        className="text-xs text-gray-600  w-full h-[70px] overflow-hidden"
                        dangerouslySetInnerHTML={{
                          __html: si.description ? si.description : si.about
                        }}
                      />
                    </div>
                    <div className="w-full flex justify-between items-center my-5">
                      <section
                        className="flex justify-start items-center"
                        onClick={() => deleteItemBookmark(si?.save[0]?.id)}
                      >
                        <RiDeleteBin6Line className="text-gray-600 ml-2" />
                        <span className="text-xs text-gray-600">حذف از لیست</span>
                      </section>
                      <section
                        className="flex justify-start items-center"
                        onClick={() => {
                          ;(type === 'animation' && navigate('/animation-detail?id=' + si.id)) ||
                            (type === 'food' && navigate('/food-detail?id=' + si.id)) ||
                            (type === 'episode' && navigate('/sound-play' + si.id)) ||
                            (type === 'post' && navigate('/post-detail?id=' + si.id)) ||
                            (type === 'article' && navigate('/detail-articles?id=' + si.id))
                        }}
                      >
                        <span className="text-xs text-blue-800">مشاهده و بررسی</span>
                        <MdArrowBackIos className="text-blue-800 ml-2" />
                      </section>
                    </div>
                  </section>
                ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ActivitySaved
