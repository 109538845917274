import HeaderAlbum from '../album/photo-album/component/header'
import axios from 'axios'
import { VaccineAPI } from '../../../../api'
import { useEffect, useState } from 'react'
import Loading from '../../../../component/loading'
import Accordion from '../frequently-question/accordion'

function VaccineQuestion() {
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState([])
  const getAllQuestion = () => {
    setLoading(true)
    axios
      .get(VaccineAPI + '/question/user', {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        setQuestions(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    getAllQuestion()
  }, [])

  return (
    <>
      <HeaderAlbum title="سوالات واکسن" />
      <div className="w-full">
        {loading ? (
          <Loading color="#1e40af" width="100px" />
        ) : (
          <>
            <div className="w-full px-4">
              {Array.isArray(questions) &&
                questions.map((qs) => (
                  <Accordion key={qs.id} title={qs.question}>
                    {qs.answer}
                  </Accordion>
                ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}
export default VaccineQuestion
