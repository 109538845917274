import { Navigate, Outlet } from 'react-router-dom'

export default function PrivateRoute() {
  let auth = false
  if (localStorage.getItem('token') !== null) {
    auth = true
  }

  return auth ? <Outlet /> : <Navigate to="/login" />
}
