import { IoClose } from 'react-icons/io5'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useEffect, useState } from 'react'
import { Domain, NotificationAPI } from '../../../../../../api'
import axios from 'axios'
import returnTime from '../../../../../../convert-time'
import OfferNotificationItem from '../offer'
function CoachNotificationItem({ data }) {
  const [show, setShow] = useState(false)
  const [close, setClose] = useState(false)
  const profile = data?.coach ? data?.coach?.profile : data?.admin?.profile
  const text = data.text
  const [style, setStyle] = useState({})

  // Update style based on close
  useEffect(() => {
    if (close) {
      setStyle({ height: 0, overflow: 'hidden', transition: 'height 0.5s ease-out' })

      // Wait for the transition to finish before setting display to none
      const timeoutId = setTimeout(() => {
        setStyle((prevStyle) => ({ ...prevStyle, display: 'none' }))
      }, 500) // Matches the transition duration

      // Clear the timeout if the component unmounts
      return () => clearTimeout(timeoutId)
    } else {
      setStyle({})
    }
  }, [close])
  const updateNotification = () => {
    const formData = new FormData()
    formData.append('seen', true)
    axios.patch(NotificationAPI + 'user/' + data.id, formData, {
      headers: {
        Authorization: `Brear ${localStorage.getItem('token')}`
      }
    })
  }

  const closeNotification = () => {
    setClose(true)
    const formData = new FormData()
    formData.append('close', true)
    axios.patch(NotificationAPI + 'user/' + data.id, formData, {
      headers: {
        Authorization: `Brear ${localStorage.getItem('token')}`
      }
    })
  }

  if (data?.offer) {
    return (
      <>
        <OfferNotificationItem
          data={data}
          updateNotification={updateNotification}
          closeNotification={closeNotification}
        />
      </>
    )
  } else
    return (
      <>
        <div
          style={style}
          className={`w-full px-4 py-2 border-b border-b-gray-300 ${
            data.seen ? '' : 'bg-gray-100'
          }`}
          onClick={() => updateNotification()}
        >
          <div className="w-full flex justify-between items-center  ">
            <div
              className="w-20 h-20 bg-blue-100 rounded-full  "
              style={{
                backgroundImage: `url("${Domain + profile}")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            />

            <section className="w-8/12 px-2">
              <div className="w-full flex justify-between items-start">
                <span className="text-gray-700">{data?.title}</span>
                <IoClose className="text-xl text-gray-500" onClick={closeNotification} />
              </div>
              <div className="text-sm text-gray-400  mt-1">
                <span className="text-gray-400 text-sm">
                  {text.substring(0, show ? 2000 : 90)}
                  {show ? null : '...'}
                </span>
              </div>
              <div className="w-full flex justify-between items-start mt-3 pb-2">
                <span className="text-gray-700 text-xs">
                  {returnTime(data.created_at).split(',')[0]}
                </span>
                {show ? (
                  <section
                    className="text-red-600 flex justify-end items-center hover:bg-red-200 px-2 py-1 rounded"
                    onClick={() => {
                      setShow(false)
                    }}
                  >
                    <span className="text-xs">خلاصه</span>
                    <IoIosArrowUp className="text-red-600" />
                  </section>
                ) : (
                  <section
                    className="text-blue-600 flex justify-end items-center hover:bg-blue-200 px-2 py-1 rounded"
                    onClick={() => {
                      setShow(true)
                    }}
                  >
                    <span className="text-xs">ادامه</span>
                    <IoIosArrowDown className="text-blue-600" />
                  </section>
                )}
              </div>
            </section>
          </div>
        </div>
      </>
    )
}
export default CoachNotificationItem
