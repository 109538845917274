import { FaPlayCircle, FaPauseCircle } from 'react-icons/fa'
import { Domain } from '../../../../../../api'
import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Episode({ data, currentEpisode, setCurrentEpisode, addView }) {
  const audioRef = useRef()
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const navigate = useNavigate()
  const toggleAudio = () => {
    const audioElement = audioRef.current
    if (data.id === currentEpisode?.id) {
      if (isPlaying) {
        audioElement.pause()
        setIsPlaying(false)
      } else {
        audioElement.play()
        addView()
        setIsPlaying(true)
      }
    } else {
      if (currentEpisode) {
        currentEpisode.audioElement.pause()
      }
      audioElement.play()
      addView()
      setIsPlaying(true)
      setCurrentEpisode({ id: data.id, audioElement })
    }
  }

  useEffect(() => {
    const audioElement = audioRef.current

    audioElement.addEventListener('timeupdate', () => {
      setCurrentTime(audioElement.currentTime)
      setDuration(audioElement.duration)
    })
  }, [])

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = Math.floor(timeInSeconds % 60)
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
  }

  const timeLeft = duration - currentTime

  return (
    <>
      <div className="w-full flex justify-between items-center mt-5 ">
        <audio controls controlsList="nodownload" className="hidden" ref={audioRef}>
          <source src={Domain + data.voice} type="audio/mpeg" />
          Your browser does not support the audio tag.
        </audio>
        <section
          className="w-20 h-20 rounded-xl ml-5"
          style={{
            backgroundImage: `url("${Domain + data.cover}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
        <section className="flex flex-col justify-between items-start w-8/12">
          <p
            className="text-gray-800 text-sm mb-2"
            onClick={() => navigate('/sound-play?status=episode&id=' + data.id)}
          >
            اپیزود <span> {data.session} </span>: <span>{data.title}</span>
          </p>

          <p className="text-gray-600 text-sm mt-2">
            <span>
              {' '}
              {audioRef?.current?.duration && formatTime(audioRef?.current?.duration)} /{' '}
              {formatTime(timeLeft)}
            </span>{' '}
            | <span>{data.description}</span>
          </p>
        </section>
        {currentEpisode?.id === data.id && isPlaying ? (
          <FaPauseCircle className="text-[#3F54CB] text-2xl w-10" onClick={toggleAudio} />
        ) : (
          <FaPlayCircle className="text-[#3F54CB] text-2xl w-10" onClick={toggleAudio} />
        )}
      </div>
    </>
  )
}

export default Episode
