import { useState } from 'react'
import { CreateAlbumOrder, Domain } from '../../../../../../../api'
import { BsFillStarFill, BsHandbag } from 'react-icons/bs'
import { IoIosArrowBack } from 'react-icons/io'
import './card.css'
import axios from 'axios'
import { toast } from 'react-toastify'

function Card({ data, ReloadFunction, isAddToBasket }) {
  const [showDetail, setShowDetail] = useState(false)

  const createOrder = (id) => {
    const formData = new FormData()
    formData.append('album_id', id)
    axios
      .post(CreateAlbumOrder, formData, {
        headers: {
          Authorization: `Brear ${localStorage.getItem('token')}`
        }
      })
      .then((res) => res.data)
      .then((data) => {
        toast.success(data.messages)
        ReloadFunction()
      })
      .catch((e) => {
        toast.error(e.response.data)
      })
  }

  return (
    <>
      {showDetail ? (
        <>
          <div
            className={`w-full fixed top-0 left-0 bg-[rgba(0,0,0,0.7)] w-full h-screen z-[400] ${
              showDetail ? 'animate__animated animate__fadeIn' : null
            }`}
            onClick={() => setShowDetail(false)}
          />
          <div
            className={`fixed bottom-0 w-full py-10 px-4 bg-white rounded-t-3xl z-[401] vazir-medium ${
              showDetail ? 'animate__animated animate__fadeInUp' : null
            }`}
          >
            <div className="w-full flex flex-wrap justify-between items-start">
              <div
                className="w-6/12 h-32 rounded-xl"
                style={{
                  backgroundImage: `url("${Domain + data.picture}")`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center'
                }}
              />
              <div className="w-6/12 px-2">
                <div className="flex justify-start items-center hidden">
                  <BsFillStarFill className="text-yellow-400 text-xl ml-2" />
                  <span className="text-gray-600 text-lg ">4.6</span>
                </div>
                <p className="text-gray-700 text-sm my-2">{data.name}</p>
                <div className="my-2 flex justify-start items-center">
                  <p className="text-gray-700 text-xl text-blue-700">{data.price}</p>
                  <p className="text-gray-700  text-xl text-blue-700 mr-2">تومان</p>
                </div>

                {isAddToBasket ? (
                  <>
                    <div className="flex mt-5 border border-green-600 rounded-lg justify-center items-center py-2">
                      <BsHandbag className="text-green-600 ml-4 text-xl" />
                      <span className="text-green-600">به سبد اضافه شد</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="flex mt-5 border border-blue-600 rounded-lg justify-center items-center py-2"
                      onClick={() => createOrder(data.id)}
                    >
                      <BsHandbag className="text-blue-700 ml-4 text-xl" />
                      <span className="text-blue-700">افزودن به سبد خرید</span>
                    </div>
                  </>
                )}
              </div>
              <span className="w-full block h-0.5 bg-gray-300 my-2" />
              <div className="w-full">
                <p>ویژگی های آلبوم</p>
                <div className="w-full flex justify-between items-center flex-wrap my-2">
                  <div className="w-6/12 flex justify-start items-center">
                    <span className="w-1 h-1 bg-gray-400 rounded-full block ml-2" />
                    <span className="text-gray-400 text-sm">فرم صفحات :</span>
                  </div>
                  <div className="w-6/12 flex justify-start flex-wrap items-center border-b border-b-gray-300 text-sm pb-2 text-gray-700">
                    {data.page_style}
                  </div>
                </div>
                <div className="w-full flex justify-between items-center flex-wrap my-2">
                  <div className="w-6/12 flex justify-start items-center">
                    <span className="w-1 h-1 bg-gray-400 rounded-full block ml-2" />
                    <span className="text-gray-400 text-sm">جنس صفحات :</span>
                  </div>
                  <div className="w-6/12 flex justify-start flex-wrap items-center border-b border-b-gray-300 text-sm pb-2 text-gray-700">
                    {data.album_material}
                  </div>
                </div>
                <div className="w-full flex justify-between items-center flex-wrap my-2">
                  <div className="w-6/12 flex justify-start items-center">
                    <span className="w-1 h-1 bg-gray-400 rounded-full block ml-2" />
                    <span className="text-gray-400 text-sm">طول آلبوم :</span>
                  </div>
                  <div className="w-6/12 flex justify-start flex-wrap items-center border-b border-b-gray-300 text-sm pb-2 text-gray-700">
                    {data.album_length}
                  </div>
                </div>
                <div className="w-full flex justify-between items-center flex-wrap my-2">
                  <div className="w-6/12 flex justify-start items-center">
                    <span className="w-1 h-1 bg-gray-400 rounded-full block ml-2" />
                    <span className="text-gray-400 text-sm">عرض آلبوم :</span>
                  </div>
                  <div className="w-6/12 flex justify-start flex-wrap items-center border-b border-b-gray-300 text-sm pb-2 text-gray-700">
                    {data.album_width}
                  </div>
                </div>
                <div className="w-full flex justify-between items-center flex-wrap my-2">
                  <div className="w-6/12 flex justify-start items-center">
                    <span className="w-1 h-1 bg-gray-400 rounded-full block ml-2" />
                    <span className="text-gray-400 text-sm">ارتفاع آلبوم :</span>
                  </div>
                  <div className="w-6/12 flex justify-start flex-wrap items-center border-b border-b-gray-300 text-sm pb-2 text-gray-700">
                    {data.album_height}
                  </div>
                </div>
                <div className="w-full flex justify-between items-center flex-wrap my-2">
                  <div className="w-6/12 flex justify-start items-center">
                    <span className="w-1 h-1 bg-gray-400 rounded-full block ml-2" />
                    <span className="text-gray-400 text-sm">طول عکس :</span>
                  </div>
                  <div className="w-6/12 flex justify-start flex-wrap items-center border-b border-b-gray-300 text-sm pb-2 text-gray-700">
                    {data.picture_length}
                  </div>
                </div>

                <div className="w-full flex justify-between items-center flex-wrap my-2">
                  <div className="w-6/12 flex justify-start items-center">
                    <span className="w-1 h-1 bg-gray-400 rounded-full block ml-2" />
                    <span className="text-gray-400 text-sm">عرض عکس :</span>
                  </div>
                  <div className="w-6/12 flex justify-start flex-wrap items-center border-b border-b-gray-300 text-sm pb-2 text-gray-700">
                    {data.picture_width}
                  </div>
                </div>

                <div className="w-full flex justify-between items-center flex-wrap my-2">
                  <div className="w-6/12 flex justify-start items-center">
                    <span className="w-1 h-1 bg-gray-400 rounded-full block ml-2" />
                    <span className="text-gray-400 text-sm">تعداد عکس :</span>
                  </div>
                  <div className="w-6/12 flex justify-start flex-wrap items-center border-b border-b-gray-300 text-sm pb-2 text-gray-700">
                    {data.picture_count}
                  </div>
                </div>

                <div className="w-full flex justify-between items-center flex-wrap my-2">
                  <div className="w-6/12 flex justify-start items-center">
                    <span className="w-1 h-1 bg-gray-400 rounded-full block ml-2" />
                    <span className="text-gray-400 text-sm"> تعداد عکس برای هر صفحه :</span>
                  </div>
                  <div className="w-6/12 flex justify-start flex-wrap items-center border-b border-b-gray-300 text-sm pb-2 text-gray-700">
                    {data.picture_count_per_page}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <div className="w-11/12 bg-white rounded-xl flex flex-wrap justify-start items-start p-4 card-shadow mt-5">
        <div
          className="w-6/12 h-32 rounded-xl"
          style={{
            backgroundImage: `url("${Domain + data.picture}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
        <div className="w-6/12 px-2">
          <div className="flex justify-start items-center hidden">
            <BsFillStarFill className="text-yellow-400 text-xl ml-2" />
            <span className="text-gray-600 text-lg ">4.6</span>
          </div>
          <p className="text-gray-700 text-sm my-2">{data.name}</p>
          <div className="flex justify-start items-center" onClick={() => setShowDetail(true)}>
            <p className="text-gray-700 text-sm text-orange-400">توضیح بیشتر</p>
            <IoIosArrowBack className="text-orange-400 text-xl" />
          </div>
          <div className="my-2 flex justify-start items-center">
            <p className="text-gray-700 text-xl text-blue-700">{data.price}</p>
            <p className="text-gray-700  text-xl text-blue-700 mr-2">تومان</p>
          </div>
        </div>
        <span className="w-full block h-0.5 bg-gray-300 my-2" />
        <div
          className="w-full flex justify-center items-center cursor-pointer"
          onClick={() => createOrder(data.id)}
        >
          {isAddToBasket ? (
            <>
              <BsHandbag className="text-green-600 ml-4 text-xl" />
              <span className="text-green-600">به سبد اضافه شد</span>
            </>
          ) : (
            <>
              <BsHandbag className="text-blue-700 ml-4 text-xl" />
              <span className="text-blue-700">افزودن به سبد خرید</span>
            </>
          )}
        </div>
      </div>
    </>
  )
}
export default Card
